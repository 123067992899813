export const ElementPriorityForCommercialProperty = {
  isNewBtnPriority: [
    'EDUCATIONAL',
    'PETROL_STATION',
    'TOURIST_RESORT',
    'FACTORY',
    'HOSPITAL',
    'OFFICE',
    'RETIAL_SHOP',
    'STOREHOUSE',
    'HOTEL',
    'COMMERCIAL_COMPLEX',
    'COMMERCIAL_VILLA',
    'OTHER',
  ],
  landAreaFieldPriority: [
    'EDUCATIONAL',
    'PETROL_STATION',
    'TOURIST_RESORT',
    'FACTORY',
    'HOSPITAL',
    'COMMERCIAL_VILLA',
    'HOTEL',
    'COMMERCIAL_COMPLEX',
  ],
  buildingAreaFieldPriority: [
    'EDUCATIONAL',
    'PETROL_STATION',
    'TOURIST_RESORT',
    'FACTORY',
    'HOSPITAL',
    'COMMERCIAL_VILLA',
    'HOTEL',
    'OFFICE',
    'RETIAL_SHOP',
    'STOREHOUSE',
    'COMMERCIAL_COMPLEX',
    'OTHER',
  ],
  creationYearFieldPriority: [
    'EDUCATIONAL',
    'PETROL_STATION',
    'TOURIST_RESORT',
    'FACTORY',
    'HOSPITAL',
    'COMMERCIAL_VILLA',
    'HOTEL',
    'OFFICE',
    'RETIAL_SHOP',
    'STOREHOUSE',
    'COMMERCIAL_COMPLEX',
    'OTHER',
  ],
  bedRoomsPriority: ['OFFICE'],
  bathRoomsPriority: ['OFFICE'],
  floorPriority: ['OFFICE'],
  CommercialComplexZonePriority: ['COMMERCIAL_COMPLEX'],
  isRentedPriority: ['COMMERCIAL_COMPLEX'],
  HotelZonePriority: ['HOTEL'],
  EducationalZonePriority: ['EDUCATIONAL'],
  HospitalZonePriority: ['HOSPITAL'],
  UnitsFieldPriority: ['HOSPITAL'],
  saleIncomeAmountPriority: {
    SALE: [
      'COMMERCIAL_COMPLEX',
      'TOURIST_RESORT',
      'PETROL_STATION',
      'EDUCATIONAL',
      'FACTORY',
      'HOTEL',
    ],
    RENT: ['EDUCATIONAL'],
  },
};

export const zonesByAdType = {
  zoneValues: {
    HOSPITAL: ['HOSPITAL_A', 'HOSPITAL_B', 'HOSPITAL_C'],
    HOTEL: ['HOTEL_APARTMENTS', 'STAR_3', 'STAR_5'],
    EDUCATIONAL: ['SCHOOL', 'KINDERGARTEN', 'BABY_DAYCARE'],
    COMMERCIAL_COMPLEX: ['STORES_OFFICES', 'STORES'],
    LAND: [
      'ZONE_A',
      'ZONE_B',
      'ZONE_C',
      'ZONE_D',
      'COMMERCIAL',
      'INDUSTRIAL',
      'SPECIAL_ZONE',
      'GREEN_FARMING_RURAL',
      'POPULATION',
    ],
  },
  zoneLabels: {
    HOSPITAL: ['أ', 'ب', 'ج'],
    HOTEL: ['شقق فندقية', 'نجمة +3', '5 نجوم'],
    EDUCATIONAL: ['مدرسة', 'روضة', 'حضانة'],
    COMMERCIAL_COMPLEX: ['مخازن/مكاتب', 'مخازن'],
    LAND: [
      'تنظيم أ',
      'تنظيم ب',
      'تنظيم ج',
      'تنظيم د',
      'تجاري',
      'صناعي',
      'أحكام خاصة',
      'زراعي',
      'شعبي',
    ],
  },
};

export const commercialTypeByADType = {
  commercialTypeValues: {
    SALE: [
      'COMMERCIAL_COMPLEX',
      'OFFICE',
      'TOURIST_RESORT',
      'RETIAL_SHOP',
      'PETROL_STATION',
      'EDUCATIONAL',
      'FACTORY',
      'STOREHOUSE',
      'HOTEL',
      'HOSPITAL',
      'COMMERCIAL_VILLA',
      'OTHER',
    ],
    RENT: [
      'OFFICE',
      'RETIAL_SHOP',
      'STOREHOUSE',
      'EDUCATIONAL',
      'COMMERCIAL_VILLA',
      'OTHER',
    ],
  },
  commercialTypeLabels: {
    SALE: [
      'مجمع تجاري',
      'مكاتب',
      'منتجع سياحي',
      'محلات',
      'كازيات',
      'مدرسة/روضة/حضانة',
      'مصانع/معامل',
      'مخازن/مستودعات',
      'فنادق',
      'مستشفيات',
      'فيلا تجارية',
      'غير ذلك',
    ],
    RENT: [
      'مكاتب',
      'محلات',
      'مخازن/مستودعات',
      'مدرسة/روضة/حضانة',
      'فيلا تجارية',
      'غير ذلك',
    ],
  },
};
