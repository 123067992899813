import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, Row, Switch } from 'antd';
import * as moment from 'moment';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { MainLayout } from '../../components/Layout';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

const ABUSE_STATUS = {
  COMPLETED: { value: 'Completed', color: 'rgba(0,0,0,.45)' },
  PENDING_REVIEW: { value: 'Pending Review', color: '#faad14' },
};

const ABUSE_TYPES = {
  USER: 'User',
  LISTING: 'Listing',
};

const CATEGORY_TYPES = {
  INAPPROPRIATE_IMAGE_LISTING: {
    value: 'Inappropriate Image',
    color: '#000000',
  },
  INACCURATE_PRICE_LISTING: { value: 'Inaccurate Price', color: '#000000' },
  SOLD_UNAVAILABLE_LISTING: { value: 'Sold', color: '#000000' },
  INVALID_LOCATION_LISTING: { value: 'Invalid Location', color: '#000000' },
  MISC_LISTING: { value: 'Miscellaneous', color: '#000000' },
};

const { Text } = Typography;
let client = null;

export default function Abuses(props) {
  const [isLoading, setLoading] = useState(false);
  const [abuses, setAbuses] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });

  const getAbuses = async ({
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination,
  }) => {
    try {
      setLoading(true);
      const abusesResponse = await client.query({
        query: Admin.Abuse.GET_ABUSES,
        variables: {
          where,
          orderBy,
          skip,
          take,
        },
      });
      const abusesCount = await client.query(
        Admin.Abuse.getAbusesCount({ where })
      );
      setPagination({
        ...newPagination,
        total: abusesCount.data.abusesConnection._count.id,
      });
      setAbuses(abusesResponse.data.abuses);
    } catch (error) {
      console.log('🚀 ~ file: abuses.js ~ line 45 ~ getAbuses ~ error', error);
    } finally {
      setLoading(false);
    }
  };

  const changeAbuseStatus = async (id, status) => {
    try {
      setLoading(true);
      await client.query({
        query: Admin.Abuse.CHANGE_ABUSE_STATUS,
        variables: {
          abuseId: id,
          status,
        },
      });
      await getAbuses({});
    } catch (error) {
      console.log(
        '🚀 ~ file: abuses.js ~ line 57 ~ changeAbuseStatus ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getAbuses({ where, orderBy, skip, newPagination });
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getAbuses({});
  }, []);
  return (
    <MainLayout title="Abuses">
      <Row>
        <Button
          type="primary"
          onClick={() => {
            getAbuses({});
          }}
          icon={<ReloadOutlined />}
        >
          Reload
        </Button>
      </Row>
      <Table
        columns={[
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) =>
              status ? (
                <Text
                  style={{
                    color: ABUSE_STATUS[status].color,
                  }}
                >
                  {ABUSE_STATUS[status].value}
                </Text>
              ) : null,
            sorter: (a, b) => a.status.length - b.status.length,
            filters: [
              {
                text: 'Pending Review',
                value: 'PENDING_REVIEW',
              },
              {
                text: 'Completed',
                value: 'COMPLETED',
              },
            ],
            filterMultiple: true,
            onFilter: (value, row) => row.status.indexOf(value) === 0,
          },
          {
            title: 'Listing Number',
            dataIndex: 'listing',
            key: 'actions',
            render: (listing) => listing.no,
            title: 'Housing',
            dataIndex: 'listing',
            key: 'isHousing',
            filters: [
              {
                text: 'Yes',
                value: true,
              },
              {
                text: 'No',
                value: false,
              },
            ],
            onFilter: (value, record) =>
              record.listing && record.listing.isHousing === value,
            filterMultiple: false,
            render: (listing) => {
              return listing && listing.isHousing ? 'Yes' : 'No';
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => ABUSE_TYPES[type],
            sorter: (a, b) => a.type.length - b.type.length,
            filters: [
              {
                text: 'User',
                value: 'USER',
              },
              {
                text: 'Listing',
                value: 'LISTING',
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.type.indexOf(value) === 0,
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (category) =>
              category && CATEGORY_TYPES[category] ? (
                <Text
                  style={{
                    color: CATEGORY_TYPES[category].color,
                  }}
                >
                  {CATEGORY_TYPES[category].value}
                </Text>
              ) : null,
            sorter: (a, b) => a.category.length - b.category.length,
            filters: [
              {
                text: 'Inappropriate Image',
                value: 'INAPPROPRIATE_IMAGE_LISTING',
              },
              {
                text: 'Inaccurate Price',
                value: 'INACCURATE_PRICE_LISTING',
              },
              {
                text: 'Sold',
                value: 'SOLD_UNAVAILABLE_LISTING',
              },
              {
                text: 'Invalid Location',
                value: 'INVALID_LOCATION_LISTING',
              },
              {
                text: 'Miscellaneous',
                value: 'MISC_LISTING',
              },
            ],
            filterMultiple: true,
            onFilter: (value, row) => row.category.indexOf(value) === 0,
          },
          {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
            render: (message) => (message ? message : ''),
            sorter: (a, b) => a.message - b.message,
          },
          {
            title: 'Reporter',
            dataIndex: 'reporter',
            key: 'reporter',
            render: (reporter) => (
              <Button
                type="primary"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/user/profile/${reporter.user.id}`}
                target="_blank"
                icon={<UserOutlined />}
              >
                View {reporter.user.name}
              </Button>
            ),
          },
          {
            title: 'Reportee',
            dataIndex: 'reportee',
            key: 'reportee',
            render: (reportee) => (
              <Button
                type="primary"
                href={`${process.env.REACT_APP_DASHBOARD_URL}/user/profile/${reportee.user.id}`}
                target="_blank"
                icon={<UserOutlined />}
              >
                View {reportee.user.name}
              </Button>
            ),
          },
          {
            title: 'Listing',
            dataIndex: 'listing',
            key: 'actions',
            render: (listing) => {
              return listing ? (
                <Button
                  type="primary"
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/listing/edit/${listing.id}`}
                  target="_blank"
                  icon={<UserOutlined />}
                >
                  Review Listing
                </Button>
              ) : null;
            },
          },
          {
            title: 'Action',
            dataIndex: 'id',
            key: 'action',
            render: (id, row) => {
              return (
                <div style={{ textAlign: 'center' }}>
                  <Switch
                    checkedChildren="Completed"
                    unCheckedChildren="Pending Review"
                    checked={row.status === 'COMPLETED'}
                    onChange={async (value) => {
                      await changeAbuseStatus(
                        id,
                        value ? 'COMPLETED' : 'PENDING_REVIEW'
                      );
                    }}
                  ></Switch>
                </div>
              );
            },
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={abuses}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
