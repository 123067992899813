import { Button, Modal, Spin, Table, Tag } from 'antd';
import {
  EyeOutlined,
  EditTwoTone,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { MainLayout } from '../../components/Layout';
import {
  getManyNotifications,
  setNotificationIsReaded,
} from '../../utils/NoSqlUtils';
import * as moment from 'moment';
import { withRouter } from 'react-router-dom';

function ShowDetails(props) {
  const {
    match: {
      params: { id },
    },
  } = props;
  const [listingChanges, setListingChanges] = useState(null);
  const [contactMethodChanges, setContactMethodChanges] = useState(null);
  const [featuresChanges, setFeaturesChanges] = useState(null);
  const [imagesChanges, setImagesChanges] = useState(null);
  const [locationChanges, setLocationChanges] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);
    await getManyNotifications({
      where: {
        id: {
          equals: id,
        },
      },
      take: 1,
      skip: 0,
    }).then((result) => {
      if (result.data.findManyNotificationReceivers) {
        const data = result.data.findManyNotificationReceivers.map(
          ({ id, isReaded, notifications }, i) => ({
            ...notifications,
            key: i,
            message: notifications.headTitle,
            createdAt: notifications.createdAt,
            isReaded,
            notificationId: id,
          })
        );
        const { contactMethod, features, images, location, listing, user } =
          JSON.parse(data[0].listingChanges);
        setListingChanges(listing);
        setContactMethodChanges(contactMethod);
        setFeaturesChanges(features);
        setImagesChanges(images);
        setLocationChanges(location);
        setUser(user);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const reviewData = (array, title) => (
    <>
      <h1 style={{ fontWeight: '700' }}>{title}</h1>
      {array.map(({ title, oldValue, newValue }, i) => (
        <div
          key={i}
          style={{
            border: 'solid 1px #bcbbc14d',
            width: '50%',
            minHeight: '100px',
            margin: '10px 0',
            borderRadius: '5px',
            backgroundColor: '#EAEEF280',
          }}
        >
          <p
            style={{
              borderBottom: 'solid 1px #bcbbc14d',
              padding: '5px',
              fontWeight: '700',
              fontSize: '16px',
            }}
          >
            {title}
          </p>
          <div
            style={{
              display: 'flex',
              height: '100%',
              margin: '0',
              padding: '0',
            }}
          >
            <p
              style={{
                display: 'flex',
                width: '50%',
                height: '100%',
                backgroundColor: '#FFD7D5',
                borderLeft: 'solid 1px #bcbbc14d',
                padding: '5px',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              {oldValue === false
                ? 'لا'
                : oldValue === true
                ? 'نعم'
                : oldValue
                ? oldValue
                : 'لا يوجد'}
            </p>
            <p
              style={{
                display: 'flex',
                width: '50%',
                height: '100%',
                backgroundColor: '#CCFFD8',
                padding: '5px',
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
              }}
            >
              {newValue === false
                ? 'لا'
                : newValue === true
                ? 'نعم'
                : newValue
                ? newValue
                : 'لا يوجد'}
            </p>
          </div>
        </div>
      ))}
    </>
  );
  return (
    <MainLayout title="Details">
      {loading ? (
        <Spin>loading</Spin>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          dir="rtl"
        >
          <div
            style={{
              border: 'solid 1px #bcbbc14d',
              width: '50%',
              minHeight: '100px',
              margin: '10px 0',
              borderRadius: '5px',
              backgroundColor: '#EAEEF280',
            }}
          >
            {user && (
              <>
                <p
                  style={{
                    padding: '5px',
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                >
                  تم تعديل الإعلان من قبل: {user.name}
                </p>
                <p
                  style={{
                    padding: '5px',
                    fontWeight: '700',
                    fontSize: '16px',
                  }}
                >
                  معرف المستخدم: {user.id}
                </p>
              </>
            )}
          </div>
          {listingChanges && reviewData(listingChanges, 'معلومات الاعلان')}
          {featuresChanges && reviewData(featuresChanges, 'ميزات الاعلان')}
          {contactMethodChanges &&
            reviewData(contactMethodChanges, 'معلومات التواصل')}
          {locationChanges && reviewData(locationChanges, 'موقع الاعلان')}

          {((imagesChanges && imagesChanges.delete) ||
            (imagesChanges && imagesChanges.create)) && (
            <>
              <h1 style={{ fontWeight: '700' }}>صور الاعلان</h1>
              <div
                style={{
                  border: 'solid 1px #bcbbc14d',
                  width: '50%',
                  minHeight: '100px',
                  margin: '10px 0',
                  borderRadius: '5px',
                  backgroundColor: '#EAEEF280',
                }}
              >
                {imagesChanges.delete && (
                  <p
                    style={{
                      borderBottom: 'solid 1px #bcbbc14d',
                      padding: '5px',
                      fontWeight: '700',
                      fontSize: '16px',
                    }}
                  >
                    تم حذف صور عدد: {imagesChanges.delete.length}
                  </p>
                )}
                {imagesChanges.create && (
                  <>
                    <p
                      style={{
                        padding: '5px',
                        fontWeight: '700',
                        fontSize: '16px',
                      }}
                    >
                      تم اضافة صور عدد: {imagesChanges.create.length}
                    </p>
                    {imagesChanges.create.map(({ s3Prefix, fileName }, i) => (
                      <img
                        style={{ width: '100%' }}
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}/listings/full/${s3Prefix}/${fileName}`}
                        alt={''}
                      />
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </MainLayout>
  );
}

export default withRouter(ShowDetails);
