export const key = 'AIzaSyCJdotpwucH-glhNhfLPYWqIFSe5cIbGO0';

export const mapboxToken =
  'pk.eyJ1Ijoia2hhcmV0YSIsImEiOiJjbDFheDRiem4wM2x6M2lvem9qMXlkbmR1In0.359vbPKNwI0AUPb6UBEO6g';

// calc the distance between 2 geo points with unit.
export const distance = (lat1, lon1, lat2, lon2, unit = 'K') => {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist = dist * 1.609344;
  }
  if (unit === 'N') {
    dist = dist * 0.8684;
  }
  return dist;
};
