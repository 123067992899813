import React, { useState } from 'react';
import { Modal, Form, Button, message } from 'antd';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Admin } from '../../../queries';
import './update-banner-ad-images-modal.css';
import { generateImageName } from '../../../utils';

export default function UpdateBannerAdImages(props) {
  const { isUpdateImagesModalOpen, setUpdateImagesModalOpen, selectedRow } =
    props;
  const [desktopSmallImage, setDesktopSmallImage] = useState({
    uid: selectedRow.desktopSmallImageFile.id,
    name: selectedRow.desktopSmallImageFile.fileName,
    status: 'done',
    url: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-small/${selectedRow.desktopSmallImageFile.fileName}`,
    thumbUrl: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-small/${selectedRow.desktopSmallImageFile.fileName}`,
  });
  const [desktopBigImage, setDesktopBigImage] = useState({
    uid: selectedRow.desktopBigImageFile.id,
    name: selectedRow.desktopBigImageFile.fileName,
    status: 'done',
    url: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-big/${selectedRow.desktopBigImageFile.fileName}`,
    thumbUrl: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-big/${selectedRow.desktopBigImageFile.fileName}`,
  });
  const [mobileImage, setMobileImage] = useState({
    uid: selectedRow.mobileImageFile.id,
    name: selectedRow.mobileImageFile.fileName,
    status: 'done',
    url: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/mobile/${selectedRow.mobileImageFile.fileName}`,
    thumbUrl: `${process.env.REACT_APP_IMAGES_BASE_URL}/ka/mobile/${selectedRow.mobileImageFile.fileName}`,
  });
  const [imagesToUpdate, setImagesToUpdate] = useState({});
  const { client } = props;

  const onSelect = (type, file) => {
    const fileSizeInMBs = file.size / (1024 * 1024);
    if (fileSizeInMBs > 3) {
      message.warning('Please select images less than 3MBs in size');
      return false;
    }
    if (type === 'MOBILE') {
      setMobileImage(file);
    } else if (type === 'DESKTOP_SMALL') {
      setDesktopSmallImage(file);
    } else if (type === 'DESKTOP_BIG') {
      setDesktopBigImage(file);
    }
    return false;
  };

  const onRemove = (type) => {
    if (type === 'MOBILE') {
      setMobileImage(null);
      setImagesToUpdate({ ...imagesToUpdate, MOBILE: true });
    } else if (type === 'DESKTOP_SMALL') {
      setDesktopSmallImage(null);
      setImagesToUpdate({ ...imagesToUpdate, DESKTOP_SMALL: true });
    } else if (type === 'DESKTOP_BIG') {
      setDesktopBigImage(null);
      setImagesToUpdate({ ...imagesToUpdate, DESKTOP_BIG: true });
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Modal
        title="Update Banner Ad Images"
        visible={isUpdateImagesModalOpen}
        centered={true}
        onOk={() => {
          form
            .validateFields()
            .then(async () => {
              try {
                const variables = {
                  where: {
                    id: selectedRow.id,
                  },
                  filesObj: {},
                };
                if (Object.keys(imagesToUpdate).length > 0) {
                  for (const key of Object.keys(imagesToUpdate)) {
                    const generatedName = generateImageName();
                    if (key === 'MOBILE') {
                      if (!variables.data) {
                        variables.data = {};
                      }
                      if (!mobileImage) {
                        message.warn('Please upload missing images');
                        return;
                      }
                      variables.data.mobileImageFile = {
                        update: {
                          fileName: {
                            set: generatedName,
                          },
                          fileType: {
                            set: mobileImage.type,
                          },
                        },
                      };

                      variables.filesObj.mobileImage = mobileImage;
                    } else if (key === 'DESKTOP_SMALL') {
                      if (!variables.data) {
                        variables.data = {};
                      }
                      if (!desktopSmallImage) {
                        message.warn('Please upload missing images');
                        return;
                      }
                      variables.data.desktopSmallImageFile = {
                        update: {
                          fileName: {
                            set: generatedName,
                          },
                          fileType: {
                            set: desktopSmallImage.type,
                          },
                        },
                      };

                      variables.filesObj.desktopSmallImage = desktopSmallImage;
                    } else if (key === 'DESKTOP_BIG') {
                      if (!variables.data) {
                        variables.data = {};
                      }
                      if (!desktopBigImage) {
                        message.warn('Please upload missing images');
                        return;
                      }
                      variables.data.desktopBigImageFile = {
                        update: {
                          fileName: {
                            set: generatedName,
                          },
                          fileType: {
                            set: desktopBigImage.type,
                          },
                        },
                      };

                      variables.filesObj.desktopBigImage = desktopBigImage;
                    }
                  }

                  await client.mutate({
                    variables,
                    mutation: Admin.BannerAd.UPDATE_BANNER_AD,
                  });

                  setDesktopSmallImage(null);
                  setDesktopBigImage(null);
                  setMobileImage(null);
                  const { getBannerAds } = props;
                  getBannerAds();
                  setUpdateImagesModalOpen(false);
                  form.resetFields();
                } else {
                  message.info('Nothing changed');
                }
              } catch (error) {
                console.log(
                  '🚀 ~ file: create-banner-ad.modal.js ~ line 60 ~ .then ~ error',
                  error
                );
              }
            })
            .catch((errors) => console.error('errors: ', errors));
        }}
        onCancel={() => {
          setUpdateImagesModalOpen(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form
          form={form}
          name="update-banner-ad-images"
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            name="desktopSmallImage"
            label="Desktop Small Image(900px width)"
          >
            <Upload
              listType="picture"
              fileList={desktopSmallImage ? [desktopSmallImage] : []}
              onRemove={() => onRemove('DESKTOP_SMALL')}
              beforeUpload={(file) => onSelect('DESKTOP_SMALL', file)}
              className="upload-list-inline"
            >
              {!desktopSmallImage && (
                <Button icon={<UploadOutlined />}>Select File</Button>
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            name="desktopBigImage"
            label="Desktop Big Image(1600px width)"
          >
            <Upload
              listType="picture"
              fileList={desktopBigImage ? [desktopBigImage] : []}
              onRemove={() => onRemove('DESKTOP_BIG')}
              beforeUpload={(file) => onSelect('DESKTOP_BIG', file)}
              className="upload-list-inline"
            >
              {!desktopBigImage && (
                <Button icon={<UploadOutlined />}>Select File</Button>
              )}
            </Upload>
          </Form.Item>

          <Form.Item name="mobileImage" label="Mobile Image">
            <Upload
              listType="picture"
              fileList={mobileImage ? [mobileImage] : []}
              onRemove={() => onRemove('MOBILE')}
              beforeUpload={(file) => onSelect('MOBILE', file)}
              className="upload-list-inline"
            >
              {!mobileImage && (
                <Button icon={<UploadOutlined />}>Select File</Button>
              )}
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
