import React, { lazy, Suspense } from 'react';

const LazyCommercialAds = lazy(() => import('./CommercialAds'));

const CommercialAds = (props) => (
  <Suspense fallback={null}>
    <LazyCommercialAds {...props} />
  </Suspense>
);

export { CommercialAds };
