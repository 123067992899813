import React, { useEffect, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Row, Switch, Divider, Col, Input, Button } from 'antd';
import { MainLayout } from '../../components/Layout';
import { Admin, Shared } from '../../queries';

export default function SystemConfig(props) {
  const [isLoading, setLoading] = useState(false);
  const [systemConfig, setSystemConfig] = useState(null);
  const [mobileAppConfig, setMobileAppConfig] = useState(null);
  const [IOSVer, setIOSVer] = useState(null);
  const [androidVer, setAndroidVer] = useState(null);
  const [huaweiVer, setHuaweiVer] = useState(null);
  const [orderedCampaignDailyViewsLimit, setOrderedCampaignDailyViewsLimit] =
    useState(0);
  const getSystemConfig = async () => {
    try {
      setLoading(true);
      const systemConfigResponse = await API.graphql(
        graphqlOperation(Shared.SystemConfig.GET_SYSTEM_CONFIG, {})
      );
      setSystemConfig(systemConfigResponse.data.findFirstSystemConfig);
      setOrderedCampaignDailyViewsLimit(
        systemConfigResponse.data.findFirstSystemConfig
          .orderedCampaignDailyViewsLimit
      );
    } catch (error) {
      console.log(
        '🚀 ~ file: system-config.js ~ line 19 ~ getSystemConfig ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const getMobileAppConfig = async () => {
    try {
      setLoading(true);
      const mobileAppConfigResponse = await API.graphql(
        graphqlOperation(Shared.MobileAppConfig.GET_MOBILE_APP_CONFIGS, {})
      );
      setMobileAppConfig(mobileAppConfigResponse.data.mobileAppConfigs[0]);
      setIOSVer(mobileAppConfigResponse.data.mobileAppConfigs[0].ios);
      setAndroidVer(mobileAppConfigResponse.data.mobileAppConfigs[0].android);
      setHuaweiVer(mobileAppConfigResponse.data.mobileAppConfigs[0].huawei);
    } catch (error) {
      console.log(
        '🚀 ~ file: system-config.js ~ line 42 ~ getMobileAppConfig ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const style = { padding: '8px 0' };

  useEffect(() => {
    const { token } = props;
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
    getMobileAppConfig();
    getSystemConfig();
  }, []);

  return (
    <MainLayout title="System Config">
      {!isLoading && systemConfig && (
        <>
          <Divider orientation="center">BannerAds</Divider>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  Show banner ads:
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    paddingRight: '10px',
                    color: systemConfig.withBannerAd ? '#41ce41' : '#f34646',
                    fontWeight: 500,
                  }}
                >
                  {systemConfig.withBannerAd ? ' Enabled' : ' Disabled'}
                </span>
                <Switch
                  checked={systemConfig.withBannerAd}
                  onChange={async (value) => {
                    try {
                      await API.graphql(
                        graphqlOperation(
                          Admin.SystemConfig.UPDATE_SYSTEM_CONFIG,
                          {
                            where: {
                              id: systemConfig.id,
                            },
                            data: {
                              withBannerAd: { set: value },
                            },
                          }
                        )
                      );
                      getSystemConfig();
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: system-config.js ~ line 89 ~ onChange={ ~ error',
                        error
                      );
                    }
                  }}
                ></Switch>
              </div>
            </Col>
          </Row>
          <Divider orientation="center">Hyperpay</Divider>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  3D Secure 2.0:
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    paddingRight: '10px',
                    color: systemConfig.with3DSecure2Hyperpay
                      ? '#41ce41'
                      : '#f34646',
                    fontWeight: 500,
                  }}
                >
                  {systemConfig.with3DSecure2Hyperpay
                    ? ' Enabled'
                    : ' Disabled'}
                </span>
                <Switch
                  checked={systemConfig.with3DSecure2Hyperpay}
                  onChange={async (value) => {
                    try {
                      await API.graphql(
                        graphqlOperation(
                          Admin.SystemConfig.UPDATE_SYSTEM_CONFIG,
                          {
                            where: {
                              id: systemConfig.id,
                            },
                            data: {
                              with3DSecure2Hyperpay: { set: value },
                            },
                          }
                        )
                      );
                      getSystemConfig();
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: system-config.js ~ line 147 ~ onChange={ ~ error',
                        error
                      );
                    }
                  }}
                ></Switch>
              </div>
            </Col>
          </Row>
          <Divider orientation="center">Ordered Campaigns</Divider>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  Daily Views Limit
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <Row gutter={6}>
                  <Col>
                    <Input
                      type="number"
                      min={0}
                      onChange={(e) => {
                        setOrderedCampaignDailyViewsLimit(e.target.value);
                      }}
                      value={orderedCampaignDailyViewsLimit}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={async (value) => {
                        try {
                          await API.graphql(
                            graphqlOperation(
                              Admin.SystemConfig.UPDATE_SYSTEM_CONFIG,
                              {
                                where: {
                                  id: systemConfig.id,
                                },
                                data: {
                                  orderedCampaignDailyViewsLimit: {
                                    set: parseInt(
                                      orderedCampaignDailyViewsLimit || 0,
                                      10
                                    ),
                                  },
                                },
                              }
                            )
                          );
                          getSystemConfig();
                        } catch (error) {
                          console.log(
                            '🚀 ~ file: system-config.js ~ line 218 ~ onClick={ ~ error',
                            error
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Divider orientation="center">Mobile App Config</Divider>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  Force Update:
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    paddingRight: '10px',
                    color:
                      mobileAppConfig && mobileAppConfig.forceUpdate
                        ? '#41ce41'
                        : '#f34646',
                    fontWeight: 500,
                  }}
                >
                  {mobileAppConfig && mobileAppConfig.forceUpdate
                    ? ' Enabled'
                    : ' Disabled'}
                </span>
                <Switch
                  checked={mobileAppConfig && mobileAppConfig.forceUpdate}
                  onChange={async (value) => {
                    try {
                      await API.graphql(
                        graphqlOperation(
                          Shared.MobileAppConfig.UPDATE_MOBILE_APP_CONFIG,
                          {
                            where: {
                              id: mobileAppConfig.id,
                            },
                            data: {
                              forceUpdate: { set: value },
                            },
                          }
                        )
                      );
                      getMobileAppConfig();
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: system-config.js ~ line 298 ~ onChange={ ~ error',
                        error
                      );
                    }
                  }}
                ></Switch>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  IOS ver
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <Row gutter={6}>
                  <Col>
                    <Input
                      type="text"
                      min={0}
                      onChange={(e) => {
                        setIOSVer(e.target.value);
                      }}
                      value={IOSVer}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={async (value) => {
                        try {
                          await API.graphql(
                            graphqlOperation(
                              Shared.MobileAppConfig.UPDATE_MOBILE_APP_CONFIG,
                              {
                                where: {
                                  id: mobileAppConfig.id,
                                },
                                data: {
                                  ios: { set: IOSVer },
                                },
                              }
                            )
                          );
                          getMobileAppConfig();
                        } catch (error) {
                          console.log(
                            '🚀 ~ file: system-config.js ~ line 218 ~ onClick={ ~ error',
                            error
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  Android ver
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <Row gutter={6}>
                  <Col>
                    <Input
                      type="text"
                      min={0}
                      onChange={(e) => {
                        setAndroidVer(e.target.value);
                      }}
                      value={androidVer}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={async (value) => {
                        try {
                          await API.graphql(
                            graphqlOperation(
                              Shared.MobileAppConfig.UPDATE_MOBILE_APP_CONFIG,
                              {
                                where: {
                                  id: mobileAppConfig.id,
                                },
                                data: {
                                  android: { set: androidVer },
                                },
                              }
                            )
                          );
                          getMobileAppConfig();
                        } catch (error) {
                          console.log(
                            '🚀 ~ file: system-config.js ~ line 218 ~ onClick={ ~ error',
                            error
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <span
                  style={{
                    padding: '25px',
                    fontWeight: 'bolder',
                  }}
                >
                  huawei ver
                </span>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div style={style}>
                <Row gutter={6}>
                  <Col>
                    <Input
                      type="text"
                      min={0}
                      onChange={(e) => {
                        setHuaweiVer(e.target.value);
                      }}
                      value={huaweiVer}
                    />
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={async (value) => {
                        try {
                          await API.graphql(
                            graphqlOperation(
                              Shared.MobileAppConfig.UPDATE_MOBILE_APP_CONFIG,
                              {
                                where: {
                                  id: mobileAppConfig.id,
                                },
                                data: {
                                  huawei: { set: huaweiVer },
                                },
                              }
                            )
                          );
                          getMobileAppConfig();
                        } catch (error) {
                          console.log(
                            '🚀 ~ file: system-config.js ~ line 218 ~ onClick={ ~ error',
                            error
                          );
                        }
                      }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </>
      )}
    </MainLayout>
  );
}
