import React, { useState } from 'react';
import { Modal, Form, Button, Input, Switch, Select, message } from 'antd';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { Admin } from '../../../queries';
import './create-banner-ad-modal.css';
import { generateImageName } from '../../../utils';

const { Option } = Select;

export default function CreateBannerAd(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [desktopSmallImage, setDesktopSmallImage] = useState(null);
  const [desktopBigImage, setDesktopBigImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const { client } = props;

  const onSelect = (type, file) => {
    const fileSizeInMBs = file.size / (1024 * 1024);
    if (fileSizeInMBs > 3) {
      message.warning('Please select images less than 3MBs in size');
      return false;
    }
    if (type === 'MOBILE') {
      setMobileImage(file);
    } else if (type === 'DESKTOP_SMALL') {
      setDesktopSmallImage(file);
    } else if (type === 'DESKTOP_BIG') {
      setDesktopBigImage(file);
    }
    return false;
  };

  const onRemove = (type) => {
    if (type === 'MOBILE') {
      setMobileImage(null);
    } else if (type === 'DESKTOP_SMALL') {
      setDesktopSmallImage(null);
    } else if (type === 'DESKTOP_BIG') {
      setDesktopBigImage(null);
    }
  };

  const [form] = Form.useForm();
  return (
    <>
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Create New Banner Ad
      </Button>
      <Modal
        title="Create New Banner Ad"
        visible={isModalOpen}
        centered={true}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              try {
                const dataToCreate = {
                  ...values,
                  desktopSmallImageFile: {
                    create: {
                      fileName: generateImageName(),
                      fileType: values.desktopSmallImage.file.type,
                      main: true,
                    },
                  },
                  desktopBigImageFile: {
                    create: {
                      fileName: generateImageName(),
                      fileType: values.desktopSmallImage.file.type,
                      main: true,
                    },
                  },
                  mobileImageFile: {
                    create: {
                      fileName: generateImageName(),
                      fileType: values.mobileImage.file.type,
                      main: true,
                    },
                  },
                  user: {
                    connect: {
                      id: props.allCookies.user.id,
                    },
                  },
                };
                delete dataToCreate.desktopSmallImage;
                delete dataToCreate.desktopBigImage;
                delete dataToCreate.mobileImage;

                await client.mutate({
                  variables: {
                    data: dataToCreate,
                    filesObj: {
                      desktopSmallImage: values.desktopSmallImage.file,
                      desktopBigImage: values.desktopBigImage.file,
                      mobileImage: values.mobileImage.file,
                    },
                  },
                  mutation: Admin.BannerAd.CREATE_BANNER_AD,
                });
                setDesktopSmallImage(null);
                setDesktopBigImage(null);
                setMobileImage(null);
                const { getBannerAds } = props;
                getBannerAds();
                setModalOpen(false);
                form.resetFields();
              } catch (error) {
                console.log(
                  '🚀 ~ file: create-banner-ad.modal.js ~ line 60 ~ .then ~ error',
                  error
                );
              }
            })
            .catch((errors) => console.error('errors: ', errors));
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form
          form={form}
          name="create-banner-ad"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          requiredMark={false}
        >
          <Form.Item
            name="page"
            label="Page"
            rules={[
              {
                required: true,
                message: 'Please select banner ad page',
              },
            ]}
          >
            <Select style={{ width: 125 }}>
              <Option value="HOME">Home</Option>
              <Option value="PROPERTY">Property</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="url"
            label="Url"
            rules={[
              {
                required: true,
                message: 'Please enter url',
              },
            ]}
          >
            <Input type="text" minLength="10" />
          </Form.Item>
          <Form.Item
            name="notes"
            label="Notes"
            rules={[
              {
                required: false,
                message: 'Please input notes',
              },
            ]}
          >
            <Input type="text" minLength="10" />
          </Form.Item>
          <Form.Item name="isActive" label="Status">
            <div>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={(value) => {
                  form.setFieldsValue({ isActive: value });
                }}
              ></Switch>
            </div>
          </Form.Item>

          <Form.Item
            name="desktopSmallImage"
            label="Desktop Image (900px)"
            rules={[
              {
                required: true,
                message: 'Please select 900px width desktop image',
              },
            ]}
          >
            <Upload
              fileList={desktopSmallImage ? [desktopSmallImage] : []}
              beforeUpload={(file) => onSelect('DESKTOP_SMALL', file)}
              onRemove={() => onRemove('DESKTOP_SMALL')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="desktopBigImage"
            label="Desktop Image (1600px)"
            rules={[
              {
                required: true,
                message: 'Please select 1600px width desktop image',
              },
            ]}
          >
            <Upload
              fileList={desktopBigImage ? [desktopBigImage] : []}
              beforeUpload={(file) => onSelect('DESKTOP_BIG', file)}
              onRemove={() => onRemove('DESKTOP_BIG')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="mobileImage"
            label="Mobile Image"
            rules={[
              {
                required: true,
                message: 'Please select mobile image',
              },
            ]}
          >
            <Upload
              fileList={mobileImage ? [mobileImage] : []}
              beforeUpload={(file) => onSelect('MOBILE', file)}
              onRemove={() => onRemove('MOBILE')}
            >
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
