import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Switch, Select, Input } from 'antd';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../../queries';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function CreatePaymentMethod(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    const { token } = props;
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
  }, []);
  return (
    <>
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Create New Payment Method
      </Button>
      <Modal
        title="Create New Payment Method"
        visible={isModalOpen}
        centered={true}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              try {
                await API.graphql(
                  graphqlOperation(Admin.PaymentMethod.CREATE_PAYMENT_METHOD, {
                    data: {
                      ...values,
                    },
                  })
                );
                const { getPaymentMethods } = props;
                getPaymentMethods();
                setModalOpen(false);
                form.resetFields();
              } catch (error) {
                console.log(
                  '🚀 ~ file: create-payment-method.modal.js ~ line 62 ~ .then ~ error',
                  error
                );
              }
            })
            .catch((errors) => console.error('errors: ', errors));
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form {...layout} form={form} name="create-payment-method">
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter payment method name!',
              },
            ]}
          >
            <Input type="text" minLength="10" />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Please select payment method type!',
              },
            ]}
          >
            <Select style={{ width: 125 }}>
              <Option value="HYPER_PAY">Hyperpay</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="hyperpayTestUrl"
            label="Hyperpay Test Url"
            rules={[
              {
                required: true,
                message: 'Please enter test url!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>
          <Form.Item
            name="hyperpayLiveUrl"
            label="Hyperpay Live Url"
            rules={[
              {
                required: true,
                message: 'Please enter live url!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>

          <Form.Item
            name="hyperpayTestWebhookDecryptSecret"
            label="Hyperpay Test Webhook Secret"
            rules={[
              {
                required: true,
                message: 'Please enter test decrypt secret!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>

          <Form.Item
            name="hyperpayLiveWebhookDecryptSecret"
            label="Hyperpay Live Webhook Secret"
            rules={[
              {
                required: true,
                message: 'Please enter live decrypt secret!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>

          <Form.Item
            name="hyperpayTestEntityId"
            label="Hyperpay Test Entity Id"
            rules={[
              {
                required: true,
                message: 'Please enter test entity id!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>
          <Form.Item
            name="hyperpayLiveEntityId"
            label="Hyperpay Live Entity Id"
            rules={[
              {
                required: false,
                message: 'Please enter entity id!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>
          <Form.Item
            name="hyperpayTestToken"
            label="Hyperpay Test Token"
            rules={[
              {
                required: true,
                message: 'Please enter test token!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>
          <Form.Item
            name="hyperpayLiveToken"
            label="Hyperpay Live Token"
            rules={[
              {
                required: false,
                message: 'Please enter live token!',
              },
            ]}
          >
            <Input type="text" minLength="7" />
          </Form.Item>
          <Form.Item name="isActive" label="Status">
            <div>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={(value) => {
                  form.setFieldsValue({ isActive: value });
                }}
              ></Switch>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
