export default class Feature {
  constructor() {
    this.isNew = null;
    this.isFurnished = null;
    this.bedRooms = null;
    this.bathRooms = null;
    this.buildingArea = null;
    this.floor = null;
    this.buildingAge = null;
    this.withBuilding = null;
    this.withPool = null;
    this.commercialType = null;
    this.landArea = null;
    this.withTerrace = null;
    this.withRoof = null;
    this.terraceArea = null;
    this.streetFront = null;
    this.incline = null;
    this.totalFloors = null;
    this.totalUnits = null;
    this.totalApartments = null;
    this.zone = null;
    this.isResidential = null;
    this.isCommercial = null;
    this.isRented = null;
    this.floorApartments = null;
    this.kitchenWooden = null;
    this.kitchenAluminium = null;
    this.kitchen = null;
    this.barbeque = null;
    this.centralHeating = null;
    this.developmentHeating = null;
    this.centralConditioning = null;
    this.developmentConditioning = null;
    this.doubleGlaze = null;
    this.electricShades = null;
    this.heating = null;
    this.sunHeater = null;
    this.waterWell = null;
    this.houseStore = null;
    this.washRoom = null;
    this.barbecueArea = null;
    this.parking = null;
    this.parking2 = null;
    this.swimmingPool = null;
    this.swimmingPool2 = null;
    this.playArea = null;
    this.parkings = null;
  }

  static getOthers = () => {
    // const others = [
    //     "kitchenWooden",
    //     "kitchenAluminium",
    //     "kitchen",
    //     "barbeque",
    //     "centralHeating",
    //     "developmentHeating",
    //     "centralConditioning",
    //     "developmentConditioning",
    //     "doubleGlaze",
    //     "electricShades",
    //     "heating",
    //     "sunHeater",
    //     "waterWell",
    //     "houseStore",
    //     "washRoom",
    //     "barbecueArea",
    //     "parking",
    //     "parking2",
    //     "swimmingPool",
    //     "playArea",
    // ];

    return {
      kitchenWooden: 'مطبخ خشب',
      kitchenAluminium: 'مطبخ ألمونيوم',
      kitchen: 'مطبخ هاي غلوس',
      barbeque: 'فيربليس',
      centralHeating: 'تدفئة راكبة',
      developmentHeating: 'تأسيس تدفأة',
      centralConditioning: 'مكيفات راكبة',
      developmentConditioning: 'تأسيس مكيفات',
      doubleGlaze: 'زجاج عازل',
      electricShades: 'اباجورت كهرباء',
      heating: 'سخان شمسي',
      sunHeater: 'خلايا شمسية',
      waterWell: 'بئر ماء منفصل',
      houseStore: 'مخزن مستقل',
      washRoom: 'غرفة غسيل/خادمة',
      barbecueArea: 'منطقة للشوي',
      parking: 'موقف سيارة',
      parking2: 'موقفين سيارة',
      swimmingPool: 'مسبح',
      swimmingPool2: '2مسبح',
      playArea: 'منطقة للألعاب',
      parkings: 'مواقف سيارات',
    };
  };
}
