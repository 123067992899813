import React from 'react';
export default function StorageRoom({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.372 0c-.043-.001-.087.006-.128.02L.26 2.663c-.155.051-.26.196-.26.358v13.222c0 .21.17.378.38.378h2.217c.041.007.083.007.124 0h11.282c.041.007.083.007.124 0H16.348c.21 0 .38-.169.38-.378V3.022c0-.162-.105-.307-.26-.358L8.484.019C8.448.007 8.41.001 8.372 0zm-.008.776l7.604 2.518v12.573h-1.521V5.289c0-.209-.17-.378-.38-.378H2.66c-.21 0-.38.17-.38.378v10.578H.761V3.294L8.363.776zm-5.323 4.89h10.646v10.2h-1.14v-3.4c0-.208-.171-.377-.381-.377h-1.52v-3.4c0-.209-.171-.378-.381-.378H6.463c-.21 0-.38.17-.38.378v3.4h-1.52c-.21 0-.381.169-.381.378v3.4h-1.14v-10.2zm3.802 3.4h3.042v3.023H6.844V9.067zm-1.9 3.778h3.04v3.023h-3.04v-3.023zm3.801 0h3.041v3.023h-3.04v-3.023z"
                  transform="translate(-150 -1382) translate(0 1078) translate(20 294) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
