import React from 'react';
import InfiniteListExample from './hooks/scroll_list';
import {
  Table,
  Button,
  Row,
  Col,
  Card,
  Tag,
  Select,
  message,
  Input,
  List,
  Form,
  Divider,
} from 'antd';
import Icon from '@ant-design/icons';
import { API, graphqlOperation } from 'aws-amplify';
import CustomNotification from './CustomnotificationUser';
import {
  SearchOutlined,
  FilterOutlined,
  EditTwoTone,
  EyeTwoTone,
  FacebookOutlined,
  GoogleOutlined,
  PhoneOutlined,
  AppleOutlined,
} from '@ant-design/icons';
import { MainLayout } from '../../components/Layout';
import ListingStatusCtrl from './listingStatusCtrl';
import kharetaLogo from '../../assets/images/logo.png';
import profilePic from '../../assets/images/profile.jpg';
import { withRouter } from 'react-router-dom';
import { Admin } from '../../queries';

const { Option } = Select;

const defaultUsersDataGrid = {
  sortedInfo: {
    field: 'createdAt',
    order: 'desc',
  },
  filteredInfo: {},
  pagination: {
    pageSize: 12,
    defaultPageSize: 12,
    current: 1,
    position: ['topLeft', 'bottomRight'],
    total: 0,
  },
  searchText: '',
};

class Users extends React.Component {
  state = {
    users: [],
    usersCount: 0,
    loading: false,
    usersDataGrid: {
      ...defaultUsersDataGrid,
    },
  };

  getVariables() {
    const { usersDataGrid } = this.state;
    let { sortedInfo, filteredInfo, pagination, searchText } = usersDataGrid;
    if (filteredInfo)
      Object.keys(filteredInfo).forEach((key) => {
        if (filteredInfo[key] == null || filteredInfo[key].length <= 0) {
          delete filteredInfo[key];
        }
      });

    let where = {};
    if (searchText && searchText !== 'status_None') {
      if (
        searchText.split('_')[0] === 'id' ||
        searchText.split('_')[0] === 'status'
      ) {
        try {
          let key = searchText.split('_')[0];
          if (key === 'status') {
            where[key] = { in: searchText.split('_')[1].trim() };
          } else {
            where[key] = { contains: searchText.split('_')[1].trim() };
          }
        } catch (e) {}
      } else if (searchText.split('_')[0] === 'signUp') {
        try {
          if (searchText.split('_')[1] === 'mobile') {
            where['facebookId'] = { equals: null };
            where['googleId'] = { equals: null };
          }
          let key = searchText.split('_')[1];
          where[key] = { not: null };
        } catch (e) {}
      } else {
        try {
          let key = searchText.split('_')[0];

          where[key] = {
            contains: searchText.split('_')[1].trim(),
          };
        } catch (e) {}
      }
    }

    const variables = {
      orderBy: {
        [`${sortedInfo.field}`]: sortedInfo.order === 'ascend' ? 'asc' : 'desc',
      },
      take: pagination.pageSize || 12,
      skip: pagination.pageSize
        ? pagination.pageSize * (pagination.current - 1)
        : 0,
      where: where,
    };
    return { variables, where };
  }

  async componentDidMount() {
    await this.fetchUsers();
  }

  async fetchUsers() {
    this.setState({
      loading: true,
    });
    const { variables, where } = this.getVariables();
    const count = await this.getUsersCount({ variables: { where } });
    const usersCount = Number(count.data.usersConnection._count.id);
    const { data: { users = [] } = {} } = await this.getUsers({
      variables,
    });
    this.setState({
      users,
      usersCount,
      loading: false,
      usersDataGrid: {
        ...this.state.usersDataGrid,
        pagination: {
          ...this.state.pagination,
          total: usersCount,
        },
      },
    });
  }

  handleChange = (pagination) => {
    this.setState(
      {
        usersDataGrid: {
          ...this.state.usersDataGrid,
          pagination,
        },
      },
      async () => {
        await this.fetchUsers();
      }
    );
  };

  clearAll = () => {
    this.setState(
      {
        usersDataGrid: { ...defaultUsersDataGrid },
      },
      async () => {
        await this.fetchUsers();
      }
    );
  };

  getUserById = async ({ variables }) => {
    const { data: { userWithListingsByImpression = {} } = {} } =
      await API.graphql(
        graphqlOperation(
          Admin.User.GET_USER_WITH_LISTINGS_BY_IMPRESSION_ID_ONLY,
          variables
        )
      );
    return userWithListingsByImpression;
  };

  handleSearch = (searchType, selectedKeys, confirm) => {
    confirm();
    let values = selectedKeys;
    if (searchType === 'id') {
      values = values[0].replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'id_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'name') {
      values = values[0].replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'name_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'email') {
      values = values[0].replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'email_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'mobile') {
      values = values[0].replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'mobile_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'status') {
      values = values.replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'status_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'phone') {
      values = values[0].replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'phone_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
    if (searchType === 'signUp') {
      values = values.replace(/^\s+/g, '');
      if (values.length > 0) {
        this.setState(
          {
            usersDataGrid: {
              ...this.state.usersDataGrid,
              searchText: 'signUp_' + values,
            },
          },
          async () => {
            await this.fetchUsers();
          }
        );
      }
    }
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState(
      {
        usersDataGrid: {
          ...this.state.usersDataGrid,
          searchText: '',
        },
      },
      async () => {
        await this.fetchUsers();
      }
    );
  };

  getListingCard = (listing) => {
    const { history } = this.props;
    if (listing.status === 'ACTIVE' || listing.status === 'ACTIVEUPDATED') {
      return [
        <EditTwoTone
          onClick={() => {
            history.push(`/listing/edit/${listing.id}`);
          }}
        />,
        <EyeTwoTone
          onClick={() =>
            window.open(
              `${process.env.REACT_APP_HOST_NAME}/properties/${listing.url}`,
              '_blank'
            )
          }
        />,
      ];
    } else {
      return [
        <EditTwoTone
          twoToneColor="blue"
          onClick={() => {
            history.push(`/listing/edit/${listing.id}`);
          }}
        />,
      ];
    }
  };

  getDetails = ({ user }) => {
    const { Meta } = Card;
    const { history } = this.props;
    if (!user || Object.keys(user).length === 0) {
      return <></>;
    }
    return (
      <div style={{ position: 'relative' }}>
        {user.id}
        <Row gutter={24} style={{ width: 1720 }}>
          <Col span={5}>
            <Card
              style={{ width: 370, textAlign: 'justify' }}
              title="User Details"
              cover={
                <img
                  style={{ marginBottom: 20 }}
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/users/${user.id}/${user.photo}`} // was 150x150
                  onError={(i) => (i.target.src = profilePic)}
                  alt={''}
                />
              }
            >
              <Tag color="green">{user.role}</Tag>
              <Tag color="green">
                {user.createdAt.split('').splice(0, 10).join('')}
              </Tag>
              <Tag color={user.status === 'ACTIVE' ? 'green' : 'red'}>
                {user.status}
              </Tag>
              <Tag color="green">
                {user.facebookId ? (
                  <FacebookOutlined />
                ) : user.googleId ? (
                  <GoogleOutlined />
                ) : user.appleId ? (
                  <AppleOutlined />
                ) : (
                  <PhoneOutlined />
                )}
              </Tag>
              <h2>{user.name}</h2>
              {user.email ? (
                <p> البريد الالكتروني : {user.email}</p>
              ) : (
                <div></div>
              )}
              {user.mobile ? <p> رقم التسجيل: {user.mobile}</p> : <div></div>}
              {user.phone ? <p> رقم التواصل : {user.phone}</p> : <div></div>}
              {user.about ? <p> العمل \ عن : {user.about}</p> : <div></div>}
              {user.company ? (
                <p> اسم العمل \ الشركة : {user.company}</p>
              ) : (
                <div></div>
              )}
              {user.businessPhone ? (
                <p> هاتف العمل : {user.businessPhone}</p>
              ) : (
                <div></div>
              )}
              {user.businessAddress ? (
                <p> مكان العمل : {user.businessAddress}</p>
              ) : (
                <div></div>
              )}
              {user.mobileVerificationCode ? (
                <p> رمز التأكيد للموبايل : {user.mobileVerificationCode}</p>
              ) : (
                <div></div>
              )}
              <Row>
                <Tag
                  style={{ marginTop: 5 }}
                  color={
                    user['FAV'] && user['FAV'].length !== 0 ? 'green' : 'red'
                  }
                >
                  الاعلانات المفضلة : {user['FAV'] && user['FAV'].length}
                </Tag>
                <br></br>
                <Tag
                  style={{ marginTop: 5 }}
                  color={
                    user['VISIT'] && user['VISIT'].length !== 0
                      ? 'green'
                      : 'red'
                  }
                >
                  الزيارات : {user['VISIT'] && user['VISIT'].length}
                </Tag>
                <br></br>
                <Tag
                  style={{ marginTop: 5 }}
                  color={
                    user['CALL'] && user['CALL'].length !== 0 ? 'green' : 'red'
                  }
                >
                  المتصل بها : {user['CALL'] && user['CALL'].length}
                </Tag>
              </Row>
              <br></br> <br></br>
              <Button
                onClick={() => {
                  history.push(`/user/profile/${user.id}`);
                }}
                style={{ width: '100%', height: 50 }}
              >
                <EditTwoTone />
              </Button>
            </Card>
            <CustomNotification props={this.props} user={user.id} />
          </Col>

          <Col span={19}>
            <div>
              <Card
                title="اعلانات المستخدم العقارية والاسكانية"
                style={{ marginLeft: 30 }}
              >
                <div>
                  <InfiniteListExample
                    data={user.listings}
                    component={
                      <List
                        grid={{
                          gutter: 6,
                          Col: 18,
                        }}
                        dataSource={user.listings}
                        renderItem={(listing) => (
                          <List.Item>
                            <Card
                              style={{
                                width: 380,

                                height: 400,
                              }}
                              cover={
                                <img
                                  style={{ width: '100%', height: 400 }}
                                  alt="example"
                                  src={
                                    listing.images.length > 0
                                      ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${listing.s3Prefix}/${listing.images[0].fileName}`
                                      : kharetaLogo
                                  }
                                />
                              }
                              actions={this.getListingCard(listing)}
                            >
                              <Meta
                                title={listing.title}
                                description={
                                  listing.description &&
                                  listing.description.length > 120
                                    ? listing.description.slice(0, 120) + '....'
                                    : ''
                                }
                              />
                              <br></br>
                              <Tag
                                color={
                                  listing.status === 'PENDING' ||
                                  listing.status === 'DELETED'
                                    ? 'red'
                                    : 'green'
                                }
                              >
                                {listing.status}
                              </Tag>
                              <Tag color="green">
                                {listing.views || '0'} عدد المشاهدات
                              </Tag>
                              <Tag color="green">
                                {listing.isHousing ? 'اسكان' : 'عقار'}
                              </Tag>
                              <div style={{ marginTop: 20, width: '100%' }}>
                                <ListingStatusCtrl
                                  listing={listing}
                                  page={'user'}
                                />
                              </div>
                            </Card>
                            <Divider dashed style={{ height: 20 }} />
                          </List.Item>
                        )}
                      />
                    }
                  ></InfiniteListExample>
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  async updateListing({ variables }) {
    await API.graphql(graphqlOperation(Admin.Auth.AUTH_SIGNUP, variables));
  }

  async getUsers({ variables }) {
    return API.graphql(graphqlOperation(Admin.User.GET_ALL_USERS, variables));
  }

  async getUsersCount({ variables }) {
    return API.graphql(graphqlOperation(Admin.User.GET_USERS_COUNT, variables));
  }

  render() {
    const { usersDataGrid, users, loading } = this.state;
    const { pagination } = usersDataGrid;

    const columns = [
      {
        title: 'المستخدم ID',
        dataIndex: 'id',
        key: 'id',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Search using user id `}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={async () =>
                await this.handleSearch('id', selectedKeys, confirm)
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={async () => {
                if (
                  !selectedKeys ||
                  (Array.isArray(selectedKeys) && selectedKeys.length === 0)
                ) {
                  return;
                }
                await this.handleSearch('id', selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              بحث
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined />,
      },
      {
        title: 'حالة المستخدم',
        dataIndex: '',
        key: 'status',
        render: (user) => {
          return (
            <div>
              <Tag color={user.status === 'ACTIVE' ? 'green' : 'red'}>
                {user.status}
              </Tag>
            </div>
          );
        },
        filterDropdown: ({ confirm, clearFilters }) => (
          <div style={{ padding: 8, width: 340 }}>
            <Select
              defaultValue="None"
              style={{ width: 200, marginLeft: 5, marginRight: 10 }}
              onChange={async (e) =>
                await this.handleSearch('status', e, confirm)
              }
            >
              <Option value="None">None</Option>
              <Option value="ACTIVE">ACTIVE</Option>
              <Option value="PENDING">PENDING</Option>
              <Option value="BLOCKED">BLOCKED</Option>
            </Select>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <FilterOutlined />,
      },
      {
        title: 'طريقة التسجيل',
        dataIndex: '',
        key: 'sign up',
        render: (user) => {
          return (
            <div>
              <Tag color="green">
                {user.facebookId ? (
                  <FacebookOutlined />
                ) : user.googleId ? (
                  <GoogleOutlined />
                ) : user.appleId ? (
                  <AppleOutlined />
                ) : (
                  <PhoneOutlined />
                )}
              </Tag>
            </div>
          );
        },
        filterDropdown: ({ confirm, clearFilters }) => (
          <div style={{ padding: 8, width: 340 }}>
            <Select
              defaultValue="None"
              style={{ width: 200, marginLeft: 5, marginRight: 10 }}
              onChange={async (e) =>
                await this.handleSearch('signUp', e, confirm)
              }
            >
              <Option value="None">None</Option>
              <Option value="mobile">Mobile</Option>
              <Option value="facebookId">Facebook</Option>
              <Option value="googleId">Gmail</Option>
              <Option value="appleId">Apple</Option>
            </Select>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <FilterOutlined />,
      },
      {
        width: '30',
        title: 'اسم المستخدم',
        dataIndex: 'name',
        key: 'users',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Search using name `}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={async () =>
                await this.handleSearch('name', selectedKeys, confirm)
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={async () => {
                if (
                  !selectedKeys ||
                  (Array.isArray(selectedKeys) && selectedKeys.length === 0)
                ) {
                  return;
                }
                await this.handleSearch('name', selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              بحث
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined />,
      },

      {
        width: '12%',
        title: 'رقم التسجيل',
        dataIndex: 'mobile',
        key: 'mobile',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Search using mobile `}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={async () =>
                await this.handleSearch('mobile', selectedKeys, confirm)
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={async () => {
                if (
                  !selectedKeys ||
                  (Array.isArray(selectedKeys) && selectedKeys.length === 0)
                ) {
                  return;
                }
                await this.handleSearch('mobile', selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              بحث
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined />,
      },

      {
        width: '12%',
        title: '  رقم التواصل ',
        dataIndex: 'phone',
        key: 'phone',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Search using phone `}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={async () =>
                await this.handleSearch('phone', selectedKeys, confirm)
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={async () => {
                if (
                  !selectedKeys ||
                  (Array.isArray(selectedKeys) && selectedKeys.length === 0)
                ) {
                  return;
                }
                await this.handleSearch('phone', selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              بحث
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined />,
      },
      {
        width: '20%',
        title: 'البريد الالكتروني',
        dataIndex: 'email',
        key: 'email',
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={(node) => {
                this.searchInput = node;
              }}
              placeholder={`Search using email`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={async () =>
                await this.handleSearch('email', selectedKeys, confirm)
              }
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={async () => {
                if (
                  !selectedKeys ||
                  (Array.isArray(selectedKeys) && selectedKeys.length === 0)
                ) {
                  return;
                }
                await this.handleSearch('email', selectedKeys, confirm);
              }}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              بحث
            </Button>
            <Button
              onClick={() => this.handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              اعادة ضبط
            </Button>
          </div>
        ),
        filterIcon: (filtered) => <SearchOutlined />,
      },
      {
        title: 'عدد الاعلانات',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {listing.length}
            </Tag>
          </div>
        ),
      },
      {
        title: 'الاعلانات الفعالة',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {
                listing.filter(
                  (item) =>
                    item.status === 'ACTIVE' || item.status === 'ACTIVEUPDATED'
                ).length
              }
            </Tag>
          </div>
        ),
      },
      {
        title: 'الاعلانات المعلقة',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {
                listing.filter(
                  (item) =>
                    item.status === 'PENDING' ||
                    item.status === 'REJECTEDUPDATED'
                ).length
              }
            </Tag>
          </div>
        ),
      },
      {
        title: 'الاعلانات الموقوفة',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {listing.filter((item) => item.status === 'STOPPED').length}
            </Tag>
          </div>
        ),
      },
      {
        title: 'الاعلانات المرفوضة',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {listing.filter((item) => item.status === 'REJECTED').length}
            </Tag>
          </div>
        ),
      },
      {
        title: 'الاعلانات المحذوفة',
        dataIndex: 'listings',
        key: 'listings',
        render: (listing) => (
          <div>
            <Tag color={listing.length !== 0 ? 'green' : 'red'}>
              {listing.filter((item) => item.status === 'DELETED').length}
            </Tag>
          </div>
        ),
      },
    ];
    // DRAFT
    // PENDING
    // ACTIVE
    // STOPPED
    // DELETED
    // REJECTED
    // REJECTEDUPDATED
    // ACTIVEUPDATED
    return (
      <MainLayout title="المستخدمين">
        <Row>
          <Col span={4}>
            <div className="table-operations">
              <Button onClick={this.clearAll}>
                اعادة ضبط الفلاتر و الترتيب
              </Button>
            </div>
          </Col>
          <Col span={15}>
            <Form
              id="NewUserForm"
              layout="inline"
              onFinish={async (e) => {
                if (e.phone.length === 13 && e.phone[0] === '+') {
                  try {
                    await this.updateListing({
                      variables: {
                        mobile: e.phone,
                        name: e.name,
                        password: e.password,
                      },
                    });
                    message.success('تم اضافة المستخدم بنجاح');
                    this.fetchUsers();
                  } catch (error) {
                    message.error(error.message);
                  }
                } else {
                  message.error(
                    'رقم الهاتف يجب ان يكون بصيغة التالية:  +96279999999'
                  );
                }
              }}
            >
              <Form.Item label="اسم المستخدم" name="name">
                <Input
                  prefix={
                    <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="اسم المستخدم"
                  id="name"
                />
              </Form.Item>

              <Form.Item label="كلمة السر" name="password">
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="text"
                  placeholder="كلمة السر من 6 ~ 12"
                  id="password"
                />
              </Form.Item>
              <Form.Item label="رقم التسجيل" name="phone">
                <Input
                  prefix={
                    <Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="phone"
                  placeholder="رقم الهاتف x (+962XXXXXXXXX)"
                  id="phone"
                />
              </Form.Item>
              <Form.Item>
                <Button form="NewUserForm" type="primary" htmlType="submit">
                  اضافة مستخدم جديد
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={users}
          pagination={{ ...pagination }}
          bordered
          scroll={{ x: 1000 }}
          onChange={this.handleChange}
          loading={loading}
          rowKey={(record) => record.id}
          expandable={{
            onExpand: async (expanded, { id }) => {
              this.setState({ [id]: null });
              const user = await this.getUserById({
                variables: {
                  where: {
                    id,
                  },
                },
              });
              this.setState({ [id]: user });
            },
            expandedRowRender: (record) => {
              if (!this.state[record.id]) {
                return <p>wait please</p>;
              }

              if (this.state[record.id]) {
                return (
                  <div style={{ height: 'auto' }}>
                    {this.getDetails({ user: this.state[record.id] })}
                  </div>
                );
              }

              if (!this.state[record.id]) return <p>error!</p>;
            },
          }}
          pageSizeOptions={[12, 22]}
        />
      </MainLayout>
    );
  }
}

export default withRouter(Users);
