import React, { useEffect, useState } from 'react';
import { Table, Typography, Button, Row } from 'antd';
import * as moment from 'moment';
import { EyeTwoTone, UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { MainLayout } from '../../components/Layout';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

const ORDERED_CAMPAIGN_STATUS = {
  ACTIVE: { value: 'Active', color: '#52c41a' },
  COMPLETED: { value: 'Completed', color: 'rgba(0,0,0,.45)' },
  PENDING_BY_CLIENT: { value: 'Pending By Client', color: '#faad14' },
  PENDING_BY_ADMIN: { value: 'Pending By Admin', color: '#ff4d4f' },
  PENDING_FROM_PROVIDER: { value: 'Pending From Provider', color: '#faad14' },
  PENDING_PAYMENT: { value: 'Pending Payment', color: '#ff4d4f' },
  PENDING_PAYMENT_REVIEW: { value: 'Pending Payment Review', color: '#ff4d4f' },
  REJECTED_PAYMENT: { value: 'Rejected Payment', color: '#ff4d4f' },
  ABANDONED: { value: 'Abandoned', color: '#ff4d4f' },
  CHECKING_STATUS: { value: 'Checking Status', color: '#ff4d4f' },
};

const LISTING_OR_COMMERCIAL_AD_STATUS = {
  ACTIVE: { value: 'Active', color: '#52c41a' },
  ACTIVEUPDATED: { value: 'Active-Updated', color: '#52c41a' },
  STOPPED: { value: 'Stopped', color: 'rgba(0,0,0,.45)' },
  PENDING: { value: 'Pending', color: '#faad14' },
  DELETED: { value: 'Deleted', color: '#ff4d4f' },
  REJECTED: { value: 'Rejected', color: '#ff4d4f' },
  REJECTEDUPDATED: { value: 'Rejected-Updated', color: '#ff4d4f' },
};

const CAMPAIGN_TYPES = {
  PRIME: 'Prime',
  FEATURED: 'Featured',
  ON_FACEBOOK: 'On Facebook',
};

const STATUS_TO_TAB = {
  PENDING_BY_ADMIN: 'pending-by-admin',
  PENDING_BY_CLIENT: 'pending-by-client',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  PENDING_PAYMENT: 'pending-payment',
  PENDING_PAYMENT_REVIEW: 'pending-payment-review',
  PENDING_FROM_PROVIDER: 'pending-from-provider',
  REJECTED_PAYMENT: 'rejected-payment',
  ABANDONED: 'abandoned',
};

const { Text } = Typography;

let client = null;

export default function OrderedCampaigns(props) {
  const [isLoading, setLoading] = useState(false);
  const [OrderedCampaigns, setOrderedCampaigns] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });

  const getOrderedCampaigns = async ({
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination,
  }) => {
    try {
      setLoading(true);
      const orderedCampaignsResponse = await client.query({
        query: Admin.OrderedCampaign.GET_ORDERED_CAMPAIGNS,
        variables: {
          where,
          orderBy,
          skip,
          take,
        },
      });
      const orderedCampaignsCount = await client.query({
        query: Admin.OrderedCampaign.GET_ORDERED_CAMPAIGNS_COUNT,
        variables: {
          where,
        },
      });

      setPagination({
        ...newPagination,
        total: orderedCampaignsCount.data.orderedCampaignsConnection._count.id,
      });
      setOrderedCampaigns(orderedCampaignsResponse.data.orderedCampaigns);
    } catch (error) {
      console.log(
        '🚀 ~ file: ordered-campaigns.js ~ line 45 ~ getOrderedCampaigns ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    if (filters.campaignType) {
      where.campaign = {
        is: {
          type: {
            in: filters.campaignType,
          },
        },
      };
    }
    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getOrderedCampaigns({ where, orderBy, skip, newPagination });
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getOrderedCampaigns({});
  }, []);
  return (
    <MainLayout title="Ordered Campaigns">
      <Row>
        <Button
          type="primary"
          onClick={() => {
            getOrderedCampaigns({});
          }}
          icon={<ReloadOutlined />}
        >
          Reload
        </Button>
      </Row>
      <Table
        columns={[
          {
            title: 'Campaign Type',
            dataIndex: ['campaign', 'type'],
            key: 'campaignType',
            render: (type) => CAMPAIGN_TYPES[type],
            sorter: (a, b) => a.campaign.type.length - b.campaign.type.length,
            filters: [
              {
                text: 'Featured',
                value: 'FEATURED',
              },
              {
                text: 'Prime',
                value: 'PRIME',
              },
              {
                text: 'On Facebook',
                value: 'ON_FACEBOOK',
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.campaign.type.indexOf(value) === 0,
          },
          {
            title: 'Type',
            dataIndex: 'isAdditional',
            key: 'isAdditional',
            render: (isAdditional) => (isAdditional ? 'Additional' : 'Main'),
            sorter: (a, b) => a.isAdditional - b.isAdditional,
            filters: [
              {
                text: 'Main',
                value: false,
              },
              {
                text: 'Additional',
                value: true,
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.isAdditional === value,
          },
          // {
          //   title: 'Daily Views',
          //   dataIndex: 'dailyViews',
          //   key: 'dailyViews',
          //   render: (dailyViews, row) => (row.isAdditional ? '' : dailyViews),
          //   sorter: (a, b) => a.dailyViews - b.dailyViews,
          // },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
              if (!ORDERED_CAMPAIGN_STATUS[status]) {
                console.log(
                  '🚀 ~ file: ordered-campaigns.js ~ line 158 ~ OrderedCampaigns ~ status',
                  status
                );
              }
              return (
                <Text
                  style={{
                    color: ORDERED_CAMPAIGN_STATUS[status].color,
                  }}
                >
                  {ORDERED_CAMPAIGN_STATUS[status].value}
                </Text>
              );
            },
            sorter: (a, b) => a.status.length - b.status.length,
            filters: [
              {
                text: 'Pending By Admin',
                value: 'PENDING_BY_ADMIN',
              },
              {
                text: 'Active',
                value: 'ACTIVE',
              },
              {
                text: 'Pending By Client',
                value: 'PENDING_BY_CLIENT',
              },
              {
                text: 'Completed',
                value: 'COMPLETED',
              },
              {
                text: 'Pending Payment',
                value: 'PENDING_PAYMENT',
              },
              {
                text: 'Pending Payment Review',
                value: 'PENDING_PAYMENT_REVIEW',
              },
              {
                text: 'Pending From Provider',
                value: 'PENDING_FROM_PROVIDER',
              },
              {
                text: 'Rejected Payment',
                value: 'REJECTED_PAYMENT',
              },
              {
                text: 'Abandoned',
                value: 'ABANDONED',
              },
              {
                text: 'Checking Status',
                value: 'CHECKING_STATUS',
              },
            ],
            filterMultiple: true,
            onFilter: (value, row) => row.status.indexOf(value) === 0,
          },
          {
            title: 'Listing Status',
            dataIndex: ['listing', 'status'],
            key: 'listing-status',
            render: (status) => {
              if (!status) {
                return <></>;
              }
              return (
                <Text
                  style={{
                    color: LISTING_OR_COMMERCIAL_AD_STATUS[status].color,
                  }}
                >
                  {LISTING_OR_COMMERCIAL_AD_STATUS[status].value}
                </Text>
              );
            },
            sorter: (a, b) => a.listing.status.length - b.listing.status.length,
            filters: [
              {
                text: 'Active',
                value: 'ACTIVE',
              },
              {
                text: 'Active - Updated',
                value: 'ACTIVEUPDATED',
              },
              {
                text: 'Pending',
                value: 'PENDING',
              },
              {
                text: 'Stopped',
                value: 'STOPPED',
              },
              {
                text: 'Rejected',
                value: 'REJECTED',
              },
              {
                text: 'Rejected - Updated',
                value: 'REJECTEDUPDATED',
              },
              {
                text: 'Deleted',
                value: 'DELETED',
              },
            ],
            filterMultiple: true,
            onFilter: (value, row) =>
              row.listing && row.listing.status.indexOf(value) === 0,
          },
          {
            title: 'Commercial Ad Status',
            dataIndex: ['commercialAd', 'status'],
            key: 'commercial-ad-status',
            render: (status) => {
              if (!status) {
                return <></>;
              }
              return (
                <Text
                  style={{
                    color: LISTING_OR_COMMERCIAL_AD_STATUS[status].color,
                  }}
                >
                  {LISTING_OR_COMMERCIAL_AD_STATUS[status].value}
                </Text>
              );
            },
            sorter: (a, b) =>
              a.commercialAd.status.length - b.commercialAd.status.length,
            filters: [
              {
                text: 'Active',
                value: 'ACTIVE',
              },
              {
                text: 'Active - Updated',
                value: 'ACTIVEUPDATED',
              },
              {
                text: 'Pending',
                value: 'PENDING',
              },
              {
                text: 'Stopped',
                value: 'STOPPED',
              },
              {
                text: 'Rejected',
                value: 'REJECTED',
              },
              {
                text: 'Rejected - Updated',
                value: 'REJECTEDUPDATED',
              },
              {
                text: 'Deleted',
                value: 'DELETED',
              },
            ],
            filterMultiple: true,
            onFilter: (value, row) =>
              row.listing && row.listing.status.indexOf(value) === 0,
          },
          {
            title: 'Housing',
            dataIndex: 'listing',
            key: 'isHousing',
            filters: [
              {
                text: 'Yes',
                value: true,
              },
              {
                text: 'No',
                value: false,
              },
            ],
            onFilter: (value, record) =>
              record.listing && record.listing.isHousing === value,
            filterMultiple: false,
            render: (listing) => {
              return listing && listing.isHousing ? 'Yes' : 'No';
            },
          },
          {
            title: 'Listing Number',
            dataIndex: 'listing',
            key: 'listing-number',
            render: (listing) => listing && listing.no,
          },
          {
            title: 'User Name',
            dataIndex: 'user',
            key: 'user-name',
            render: (user) => user.name,
          },
          // {
          //   title: 'Paused At',
          //   dataIndex: 'pausedAt',
          //   key: 'pausedAt',
          //   render: (pausedAt) =>
          //     pausedAt ? moment(pausedAt).format('L') : '',
          //   sorter: (a, b) => {
          //     if (!a.pausedAt || !b.pausedAt) return false;
          //     const diff = moment(a.pausedAt).diff(
          //       moment(b.pausedAt),
          //       'seconds'
          //     );
          //     return diff;
          //   },
          // },
          {
            title: 'First Started At',
            dataIndex: 'firstStartedAt',
            key: 'firstStartedAt',
            render: (firstStartedAt) =>
              firstStartedAt ? moment(firstStartedAt).format('L') : '',
            sorter: (a, b) => {
              if (!a.firstStartedAt || !b.firstStartedAt) return false;
              const diff = moment(a.firstStartedAt).diff(
                moment(b.firstStartedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Remaining Active Days',
            dataIndex: 'remainingActiveDays',
            key: 'remainingActiveDays',
            render: (remainingActiveDays, row) => `${remainingActiveDays} days`,
            sorter: (a, b) => a.dailyViews - b.dailyViews,
          },
          {
            title: 'Pro Photography',
            dataIndex: 'withProPhotography',
            key: 'withProPhotography',
            render: (withProPhotography, row) =>
              row.isAdditional ? '' : withProPhotography ? 'Yes' : 'No',
            sorter: (a, b) => a.withProPhotography - b.withProPhotography,
          },
          {
            title: 'Listing',
            dataIndex: 'listing',
            key: 'view-listing',
            render: (listing) =>
              listing ? (
                <Button
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/listing/edit/${listing.id}`}
                  target="_blank"
                  icon={<EyeTwoTone />}
                >
                  View
                </Button>
              ) : null,
          },
          {
            title: 'Commercial Ad',
            dataIndex: 'commercialAd',
            key: 'view-commercial-ad',
            render: (commercialAd) =>
              commercialAd ? (
                <Button
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/commercial-ad/edit/${commercialAd.id}`}
                  target="_blank"
                  icon={<EyeTwoTone />}
                >
                  View
                </Button>
              ) : null,
          },
          {
            title: 'Review',
            key: 'actions',
            render: (row) => {
              return (
                <Button
                  type="primary"
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/${
                    row.listing ? 'listing' : 'commercial-ad'
                  }/edit/${
                    row.listing ? row.listing.id : row.commercialAd.id
                  }/ordered-campaigns/${STATUS_TO_TAB[row.status]}`}
                  target="_blank"
                  icon={<UserOutlined />}
                >
                  Review {row.listing ? 'Listing' : 'Commercial Ad'}
                </Button>
              );
            },
          },
          {
            title: 'User',
            dataIndex: 'user',
            key: 'view-user',
            render: (user) =>
              user ? (
                <Button
                  type="primary"
                  href={`${process.env.REACT_APP_DASHBOARD_URL}/user/profile/${user.id}`}
                  target="_blank"
                  icon={<UserOutlined />}
                >
                  View
                </Button>
              ) : null,
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={OrderedCampaigns}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
