import React from 'react';
// import { Query, Mutation } from 'react-apollo';
import { createGeoHash } from '../../utils/geo';
import { Admin } from '../../queries';

export default class GenerateGeo extends React.Component {
  state = {
    id: 0,
  };

  _updateAd = (id, geo, updateListing) => {
    this.setState({ id });
    updateListing({
      variables: {
        where: {
          id: id,
        },
        data: {
          location: {
            update: { ...geo },
          },
        },
      },
    });
  };

  render() {
    return (
      <></>
      // <Query
      //   query={Admin.Location.GET_LOCATIONS}
      //   variables={{
      //     where: {
      //       g1: null,
      //     },
      //   }}
      //   fetchPolicy="cache-and-network"
      // >
      //   {({ loading, error, data }) => {
      //     if (error) return <div> ERROR </div>;
      //     if (loading) return <div>loading ... </div>;

      //     const { locations } = data;
      //     const count = locations.length;

      //     return (
      //       <div>
      //         <div>total: {count}</div>
      //         {/* <Mutation mutation={Admin.Location.UPDATE_LOCATION}>
      //           {(updateListing, { data, loading: updating, error }) => {
      //             if (error) return <div> error </div>;

      //             //const rest = data ? locations.filter(loc => loc.listing.id !== data.updateListing.id) : locations
      //             return locations.map((loc) => {
      //               const geo = createGeoHash(loc.lat, loc.lng);
      //               return (
      //                 <div key={loc.id}>
      //                   <div>
      //                     <button
      //                       onClick={() =>
      //                         this._updateAd(loc.listing.id, geo, updateListing)
      //                       }
      //                     >
      //                       {updating && this.state.id === loc.listing.id
      //                         ? 'Updating ...'
      //                         : 'Generate GEO data'}
      //                     </button>
      //                   </div>
      //                 </div>
      //               );
      //             });
      //           }}
      //         </Mutation>
      //         ) }) } */}
      //       </div>
      //     );
      //   }}
      // </Query>
    );
  }
}
