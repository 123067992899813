import React from 'react';
export default function SunHeater({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <defs>
        <path
          id="fm2ss9vvba"
          d="M7.115 0.157L8.059 0.157 8.059 2.186 7.115 2.186z"
        />
        <path id="xbll94redb" d="M0.57 2.579L16.572 2.579 16.572 17 0.57 17z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-150 -1340) translate(0 1078) translate(20 252) translate(130.828 10)">
                <mask id="p3kp0pkmkc" fill="#fff">
                  <use xlinkHref="#xbll94redb" />
                </mask>
                <path
                  fill={color}
                  d="M5.77 5.706h5.655l-.01-.079c-.005-.048-.01-.096-.021-.15-.132-.741-.541-1.365-1.183-1.804-.606-.428-1.36-.59-2.126-.457-1.232.232-2.174 1.25-2.315 2.49zM1.886 7.599h2.328l.122-1.309H2.028L1.886 7.6zm2.918 0h2.125L7.01 6.29H4.925L4.804 7.6zm2.714 0H9.75L9.69 6.29H7.6L7.517 7.6zm2.822 0h2.123l-.121-1.309h-2.064L10.34 7.6zm2.736 0h2.171l-.121-1.309h-2.172l.122 1.309zM1.662 9.625h2.372l.122-1.442h-2.33l-.164 1.442zm2.962 0h2.192l.082-1.442h-2.13l-.144 1.442zm2.781 0h2.457L9.78 8.183H7.487l-.082 1.442zm3.046 0h2.237l-.144-1.442H10.37l.082 1.442zm2.826 0h2.216l-.164-1.442h-2.195l.143 1.442zM1.437 11.65h2.396l.143-1.442H1.601l-.164 1.442zm2.985 0h2.304l.082-1.442H4.566l-.144 1.442zm2.894 0h2.658l-.082-1.442H7.398l-.082 1.442zm3.225 0h2.326l-.143-1.442h-2.265l.082 1.442zm2.938 0h2.239l-.164-1.442h-2.218l.143 1.442zM1.191 13.788H3.63l.144-1.553H1.376l-.185 1.553zm3.029 0H6.59l.083-1.553h-2.31l-.144 1.553zm2.96 0h2.884l-.083-1.553H7.263l-.082 1.553zm3.473 0h2.44l-.145-1.553H10.57l.082 1.553zm3.028 0h2.26l-.184-1.553h-2.24l.164 1.553zM6.4 16.416h4.353l-.273-2.044H6.674l-.273 2.044zm7.868.584H2.91c-.174 0-.296-.12-.296-.292s.122-.292.295-.292h2.897l.257-2.044H.842c-.059 0-.145-.026-.206-.087l-.013-.018c-.016-.03-.053-.114-.053-.209l.921-8.09c.031-.154.154-.262.294-.262H5.2c.12-1.519 1.26-2.777 2.792-3.071.913-.162 1.824.031 2.568.545.756.5 1.283 1.296 1.443 2.183.019.112.038.227.044.343h3.323c.15 0 .272.121.272.27l.92 8.074c.025.07.004.162-.058.228-.043.07-.137.094-.214.094h-5.22l.258 2.044h2.941c.174 0 .295.12.295.292s-.121.292-.295.292z"
                  mask="url(#p3kp0pkmkc)"
                />
                <path
                  fill={color}
                  d="M7.77 2.186c-.152 0-.269-.093-.299-.237L7.13.52c-.024-.059-.018-.139.02-.21.043-.077.116-.132.196-.148.071-.016.154.004.224.05.07.047.12.115.134.187l.342 1.428c.024.059.018.139-.02.21-.043.077-.116.132-.196.148l-.06.001zM9.73 2.267l-.091-.002c-.08-.014-.15-.062-.191-.13-.044-.074-.05-.16-.017-.236L9.905.505c.013-.075.068-.146.148-.187.08-.04.173-.046.257-.016.077.01.156.06.201.132.045.071.051.156.018.23l-.474 1.395c-.07.129-.2.208-.325.208M11.712 3.683c-.054 0-.112-.032-.162-.06l-.053-.033c-.122-.125-.122-.314 0-.44l1.098-1.074c.122-.124.306-.124.43.001.122.125.122.314 0 .44l-1.1 1.074c-.061.063-.151.092-.213.092M12.566 5.383c-.147 0-.261-.082-.291-.21-.027-.076-.015-.154.032-.213.045-.057.116-.09.192-.09l1.39-.324c.06-.021.138-.016.208.018.076.038.13.103.146.174.024.05.017.122-.02.186-.042.069-.113.118-.19.133l-1.405.324-.062.002zM4.548 5.1h-.044l-1.429-.31c-.075-.013-.141-.059-.183-.124-.043-.068-.053-.15-.025-.225.012-.058.061-.117.133-.154.066-.033.145-.045.216-.032l1.416.308c.07.013.134.058.174.124.037.06.05.132.036.197-.03.135-.173.216-.294.216M5.681 3.683c-.085 0-.151-.028-.214-.093L4.37 2.517c-.124-.126-.124-.315-.001-.44.122-.125.307-.125.43 0l1.096 1.072c.124.127.124.316.001.441-.063.064-.13.093-.215.093"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
