import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import { ViewListings, ExportListings } from '../containers/Listings';
import { Login } from '../containers/Auth';
import GenerateGeo from '../containers/Processes/GenerateGeo';
import newLocationView from '../containers/Locations/newLocationView.js';
import Notifications from '../containers/Notifications';
import { Users } from '../containers/Users';
import { UserProfile } from '../containers/UserProfile';
import { HomePageView } from '../containers/homePage';
import { EditListing } from '../containers/Listings';
import EditCommercialAd from '../components/EditCommercialAd';
import { Bundles } from '../containers/Bundles';
import { CommercialAds } from '../containers/CommercialAds';
import { Campaigns } from '../containers/Campaigns';
import { PaymentMethods } from '../containers/PaymentMethods';
import { OrderedCampaigns } from '../containers/OrderedCampaigns';
import { Abuses } from '../containers/Abuses';
import { Transactions } from '../containers/Transactions';
import { Orders } from '../containers/Orders';
import { BannerAds } from '../containers/BannerAds';
import { SystemConfig } from '../containers/SystemConfig';
import NotificationsDetails from '../containers/NotificationsDetails';
import ShowDetails from '../containers/NotificationsDetails/ShowDetails';
class AppRoute extends Component {
  render() {
    const { cookies } = this.props;
    return (
      <Router>
        <Switch>
          <Route path="/login" component={Login} />
          <AuthRoute
            path="/"
            exact
            component={ViewListings}
            cookies={cookies}
          />
          <AuthRoute path="/users" component={Users} cookies={cookies} />
          <AuthRoute path="/orders" component={Orders} cookies={cookies} />
          <AuthRoute
            path="/transactions"
            component={Transactions}
            cookies={cookies}
          />
          <AuthRoute
            path="/commercial-ads"
            component={CommercialAds}
            cookies={cookies}
          />
          <AuthRoute path="/bundles" component={Bundles} cookies={cookies} />
          <AuthRoute
            path="/campaigns"
            component={Campaigns}
            cookies={cookies}
          />
          <AuthRoute
            path="/payment-methods"
            component={PaymentMethods}
            cookies={cookies}
          />
          <AuthRoute
            path="/ordered-campaigns"
            component={OrderedCampaigns}
            cookies={cookies}
          />
          <AuthRoute path="/abuses" component={Abuses} cookies={cookies} />
          <AuthRoute
            path="/homePage"
            component={HomePageView}
            cookies={cookies}
          />
          <AuthRoute
            path="/user/profile/:id"
            component={UserProfile}
            cookies={cookies}
          />
          <AuthRoute
            path="/listing/edit/:id"
            component={EditListing}
            cookies={cookies}
            {...this.props}
          />
          <AuthRoute
            path="/commercial-ad/edit/:id"
            component={EditCommercialAd}
            cookies={cookies}
            {...this.props}
          />
          <AuthRoute
            path="/process"
            component={GenerateGeo}
            cookies={cookies}
          />
          <AuthRoute
            path="/listings/export"
            component={ExportListings}
            cookies={cookies}
          />
          <AuthRoute
            path="/locations"
            component={newLocationView}
            cookies={cookies}
          />

          <AuthRoute
            path="/notifications"
            component={Notifications}
            cookies={cookies}
          />

          <AuthRoute
            path="/notifications-details"
            component={NotificationsDetails}
            cookies={cookies}
          />

          <AuthRoute
            path="/notification/details/:id"
            component={ShowDetails}
            cookies={cookies}
          />

          <AuthRoute
            path="/banner-ads"
            component={BannerAds}
            cookies={cookies}
            {...this.props}
          />

          <AuthRoute
            path="/system-config"
            component={SystemConfig}
            cookies={cookies}
          />
        </Switch>
      </Router>
    );
  }
}

export default AppRoute;
