import React from 'react';
export default function ElectricShade({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0v.74h1.116v.738H0v.74h1.116v.739H0v.739h1.116v.739H0v.739h1.116v.739H0v.74H1.86V0H0zm2.232 0v.74h12.645v.738H2.232v.74h12.645v.739H2.232v.739h12.645v.739H2.232v.739h12.645v.739H2.232v.74H15.62V0H2.232zm13.76 0v.74h1.116V0h-1.115zm0 1.478v.74h1.116v-.74h-1.115zm0 1.479v.739h1.116v-.74h-1.115zm0 1.478v.739h1.116v-.74h-1.115zm0 1.478v.74h1.116v-.74h-1.115zM2.233 7.391v7.392c0 .204.166.37.371.37h11.902c.205 0 .372-.166.372-.37V7.39h-.744v7.022H2.975V7.391h-.743zm14.132 0c-.205 0-.371.166-.371.37s.166.37.371.37c.206 0 .372-.166.372-.37s-.166-.37-.372-.37zm-8.182.74v3.542l-1.224-1.217-.526.523 2.122 2.109 2.123-2.11-.526-.522-1.225 1.217V8.13h-.744zm8.182.369c-.205 0-.371.165-.371.37 0 .204.166.37.371.37.206 0 .372-.166.372-.37 0-.205-.166-.37-.372-.37zm0 1.109c-.205 0-.371.165-.371.37 0 .203.166.369.371.369.206 0 .372-.166.372-.37s-.166-.37-.372-.37zm0 1.108c-.205 0-.371.166-.371.37s.166.37.371.37c.206 0 .372-.166.372-.37s-.166-.37-.372-.37zm0 1.11c-.205 0-.371.165-.371.369s.166.37.371.37c.206 0 .372-.166.372-.37s-.166-.37-.372-.37zm0 1.108c-.409 0-.743.332-.743.739 0 .406.334.739.743.739.41 0 .744-.333.744-.74 0-.406-.334-.738-.744-.738z"
                    transform="translate(-150 -1298) translate(0 1078) translate(20 210) translate(130.828 10) translate(0 1.109)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
