import React from 'react';
import AdRejectEdit from './rejectReasonMenu';
import { Card, Row, Col } from 'antd';

export default class RejectListing extends React.Component {
  state = {
    status: this.props.data.status,
    message: this.props.data.disapproveMessage || '',
    type: this.props.data.disapproveType
      ? Array.isArray(this.props.data.disapproveType)
        ? this.props.data.disapproveType
        : this.props.data.disapproveType.split(',')
      : [],
  };
  reRender = (data) => {
    this.setState({
      message: data.message,
      type: Array.isArray(data.type) ? data.type : data.type.split(','),
      status: data.status,
    });
  };
  render() {
    const { data } = this.props;
    return (
      (this.state.status === 'REJECTED' ||
        this.state.status === 'REJECTEDUPDATED' ||
        this.state.status === 'PENDING') && (
        <Card
          title="Rejection Reasons"
          style={{ width: 577, textAlign: 'center' }}
        >
          <Row gutter="24">
            <Col span="15">
              <h3 style={{ textAlign: 'end', width: '100%' }}>:رسالة الرفض</h3>

              <div
                style={{
                  borderRadius: 2,
                  border: '1px solid rgba(0, 0, 0, 0.15)',
                  padding: '2px 3px',
                  marginBottom: 20,
                }}
              >
                <h3 style={{ textAlign: 'end', width: '100%', margin: 0 }}>
                  {this.state.message
                    ? this.state.message
                    : 'No Rejection Message Given'}
                </h3>
              </div>
            </Col>
            <Col span="9">
              <ol style={{ textAlign: 'end' }}>
                {this.state.type.map((reason, index) => (
                  <li key={index} style={{ direction: 'rtl' }}>
                    <h3 style={{ direction: 'rtl' }}>
                      {reason === 'REPEATED'
                        ? 'مكرر'
                        : reason === 'PHOTO'
                        ? 'صور غير دقيقة'
                        : reason === 'LOCATION'
                        ? 'موقع غير دقيق'
                        : reason === 'INFORMATION'
                        ? 'معلومات غير دقيقة'
                        : reason === 'OTHER'
                        ? 'اسباب اخرى'
                        : ''}
                    </h3>
                  </li>
                ))}
              </ol>
            </Col>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <AdRejectEdit
                callBack={this.reRender}
                message={this.state.message}
                type={this.state.type}
                listing={data}
              ></AdRejectEdit>
            </div>
          </Row>
        </Card>
      )
    );
  }
}
