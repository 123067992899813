import React, { useState } from 'react';
import { jsx, css } from '@emotion/react';
export const InputField = ({
  placeholder,
  value,
  onChange,
  label,
  padding,
}) => {
  return (
    <label
      css={{
        padding: padding ? '0 10px' : '0',
      }}
    >
      {label}
      <input
        css={{
          width: '100%',
          height: 28,
        }}
        placeholder={placeholder}
        name={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </label>
  );
};

export const Button = ({ text, onClick }) => {
  return (
    <div
      onClick={onClick}
      css={{
        display: 'inline-block',
        textAlign: 'center',
        padding: '4px 10px',
        borderRadius: '3px',
        margin: '0px 4px',
        position: 'relative',
        top: '23px',
        height: 28,
        backgroundColor: '#40a9ff',
        cursor: 'pointer',
      }}
    >
      <p
        css={{
          color: 'white',
        }}
      >
        {text}
      </p>
    </div>
  );
};

export const DropDown = ({ cities, area, callBack }) => {
  const [chosenCity, setChosenCity] = useState(
    area ? (area.city ? area.city.name : area.name) : cities[0].name
  );

  return (
    <label
      css={{
        padding: '0 10px',
      }}
    >
      City
      <select
        css={{
          width: '100%',
          height: 28,
        }}
        placeholder={chosenCity}
        value={chosenCity}
        onChange={(value) => {
          const res = cities.find((e) => e.name === value.target.value);
          setChosenCity(res.name);
          callBack(res);
        }}
      >
        {cities.map((city) => (
          <option key={city.id} value={city.name}>
            {city.name}
          </option>
        ))}
      </select>
    </label>
  );
};

export const ExpandableRow = ({ area, callBack, cities }) => {
  const [name, setName] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [keyName, setKeyName] = useState('');
  const [radius, setRadius] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [avgLandPrice, setAvgLandPrice] = useState('');
  const [avgLandSD, setAvgLandSD] = useState('');
  const [zoom, setZoom] = useState('');
  const [pickedCity, setPickedCity] = useState(null);
  const Filler = () => {
    setName(area.name);
    setSearchKey(area.searchName);
    setKeyName(area.keyName);
    setRadius(area.radius);
    setLatitude(area.lat);
    setLongitude(area.lng);
    setZoom(area.zoom);
    cities && setAvgLandSD(area.avgLandSD);
    cities && setAvgLandPrice(area.avgLandPrice);
  };

  const Clear = () => {
    setName('');
    setSearchKey('');
    setKeyName('');
    setRadius('');
    setLatitude('');
    setLongitude('');
    setZoom('');
    cities && setAvgLandSD('');
    cities && setAvgLandPrice('');
  };
  const inputFieldsValues = [
    {
      value: name,
      stateSetter: setName,
      label: 'Name',
      placeholder: area.name,
    },
    {
      value: searchKey,
      stateSetter: setSearchKey,
      label: 'Search Key',
      placeholder: area.searchName,
    },
    {
      value: keyName,
      stateSetter: setKeyName,
      label: 'Key Name',
      placeholder: area.keyName,
    },
    {
      value: radius,
      stateSetter: setRadius,
      label: 'Radius',
      placeholder: area.radius,
    },
    {
      value: latitude,
      stateSetter: setLatitude,
      label: 'Latitude',
      placeholder: area.lat,
    },
    {
      value: longitude,
      stateSetter: setLongitude,
      label: 'Longitude',
      placeholder: area.lng,
    },
    {
      value: zoom,
      stateSetter: setZoom,
      label: 'Zoom',
      placeholder: area.zoom,
    },
    {
      value: area.areaPolygon ? true : false,
      label: 'polygon',
      placeholder: area.areaPolygon ? true : false,
    },
  ];
  cities &&
    inputFieldsValues.push({
      value: avgLandPrice,
      stateSetter: setAvgLandPrice,
      label: 'Avg Land Price',
      placeholder: area.avgLandPrice || 'Enter the price average',
    });
  cities &&
    inputFieldsValues.push({
      value: avgLandSD,
      stateSetter: setAvgLandSD,
      label: 'Avg Land SD',
      placeholder: area.avgLandSD || 'Enter the SD average',
    });
  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
      }}
    >
      <Button text={'Auto Fill'} onClick={() => Filler()} />
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {inputFieldsValues.map((element, index) => (
          <InputField
            key={index}
            padding
            placeholder={element.placeholder}
            label={element.label}
            value={element.value}
            onChange={element.stateSetter}
          />
        ))}
        {cities ? (
          <DropDown cities={cities} area={area} callBack={setPickedCity} />
        ) : null}
      </div>
      <Button
        text={'Save'}
        onClick={() => {
          const city = pickedCity
            ? pickedCity
            : area.city
            ? area.city
            : undefined;
          callBack({
            id: area.id,
            searchName: searchKey,
            name,
            lat: latitude,
            lng: longitude,
            radius,
            keyName,
            city: city,
            avgLandPrice,
            zoom,
            avgLandSD,
          });
        }}
      />
      <Button text={'Clear'} onClick={() => Clear()} />
    </div>
  );
};

export const AreaModal = ({ callBack, cities }) => {
  const [name, setName] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const [keyName, setKeyName] = useState('');
  const [radius, setRadius] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const [pickedCity, setPickedCity] = useState(
    cities.find((city) => city.name === 'عمان')
  );

  const Clear = () => {
    setName('');
    setSearchKey('');
    setKeyName('');
    setRadius('');
    setLatitude('');
    setLongitude('');
  };
  const inputFieldsValues = [
    {
      value: name,
      stateSetter: setName,
      label: 'Name',
      placeholder: 'Area Name',
    },
    {
      value: searchKey,
      stateSetter: setSearchKey,
      label: 'Search Key',
      placeholder: 'SearchName',
    },
    {
      value: keyName,
      stateSetter: setKeyName,
      label: 'Key Name',
      placeholder: 'KeyName',
    },
    {
      value: radius,
      stateSetter: setRadius,
      label: 'Radius',
      placeholder: 'Radius',
    },
    {
      value: latitude,
      stateSetter: setLatitude,
      label: 'Latitude',
      placeholder: 'Latitude',
    },
    {
      value: longitude,
      stateSetter: setLongitude,
      label: 'Longitude',
      placeholder: 'Longitude',
    },
  ];
  return (
    <div
      css={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        {inputFieldsValues.map((element, index) => (
          <div
            key={index}
            css={{
              padding: '5px',
            }}
          >
            <InputField
              placeholder={element.placeholder}
              label={element.label}
              value={element.value}
              onChange={element.stateSetter}
            />
          </div>
        ))}
        {cities ? (
          <DropDown
            cities={cities}
            area={pickedCity}
            callBack={setPickedCity}
          />
        ) : null}
      </div>
      <div
        css={{
          width: '100%',
          margin: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          text={'Add Area'}
          onClick={() => {
            const city = pickedCity ? pickedCity : undefined;
            callBack({
              searchName: searchKey,
              name,
              lat: latitude,
              lng: longitude,
              radius,
              keyName,
              city: city,
            });
          }}
        />
        <Button text={'CLEAR'} onClick={() => Clear()} />
      </div>
    </div>
  );
};
