import React from 'react';
export default function Area({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M.352.069C.152.087 0 .25 0 .447v2.266c0 .209.174.378.389.378h.972v3.778H.389c-.215 0-.389.169-.389.377v2.267c0 .209.174.378.389.378h.972v3.777H.389c-.215 0-.389.17-.389.378v2.267c0 .208.174.378.389.378h2.333c.215 0 .389-.17.389-.378v-.756h3.888v.756c0 .208.174.378.389.378H9.72c.214 0 .388-.17.388-.378v-.756h3.889v.756c0 .208.174.378.389.378h2.332c.215 0 .39-.17.39-.378v-2.267c0-.208-.175-.377-.39-.378h-.972V9.891h.972c.215 0 .39-.17.39-.378V7.246c0-.208-.175-.377-.39-.377h-.972V3.09h.972c.215 0 .39-.17.39-.378V.447c0-.196-.153-.36-.353-.378H14.387c-.215 0-.39.169-.39.378v.755H10.11V.447c0-.209-.174-.378-.388-.378H7.35c-.2.018-.353.182-.352.378v.755H3.11V.447c0-.209-.174-.378-.39-.378H.353zm.426.755h1.555v1.511H.778V.825zm6.998 0h1.556v1.511H7.776V.825zm7 0h1.555v1.511h-1.556V.825zM3.11 1.958h3.888v.755c0 .209.174.378.389.378H9.72c.214 0 .388-.17.388-.378v-.755h3.889v.755c0 .209.174.378.389.378h.583v3.778h-.583c-.215 0-.39.169-.39.377v2.267c0 .209.175.378.39.378h.583v3.777h-.583c-.215 0-.39.17-.39.378v.756H10.11v-.756c0-.208-.174-.377-.388-.378H7.388c-.215 0-.39.17-.39.378v.756H3.112v-.756c0-.208-.174-.377-.39-.378H2.14V9.891h.583c.215 0 .389-.17.389-.378V7.246c0-.208-.174-.377-.39-.377H2.14V3.09h.583c.215 0 .389-.17.389-.378v-.755zM.778 7.624h1.555v1.511H.778v-1.51zm13.997 0h1.556v1.511h-1.556v-1.51zm-13.997 6.8h1.555v1.511H.778v-1.511zm6.998 0h1.556v1.511H7.776v-1.511zm7 0h1.555v1.511h-1.556v-1.511z"
                    transform="translate(-150 -1256) translate(0 1078) translate(20 168) translate(130.828 10) translate(0 .12)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
