import React from 'react';
import { Button, Modal } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import {
  PauseCircleOutlined,
  CheckSquareTwoTone,
  StopTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons';
import DisApprovalMenu from '../../components/DisApprovalMenu';
import { Admin } from '../../queries';

export default class listingStatusCtrl extends React.Component {
  state = {
    [`DELETED_${this.props.listing.id}_loading`]: false,
    [`REJECTED_${this.props.listing.id}_loading`]: false,
    [`STOPPED_${this.props.listing.id}_loading`]: false,
    [`ACTIVE_${this.props.listing.id}_loading`]: false,
  };

  componentDidMount() {
    const activeOrderedCampaignsCount =
      this.props.listing.orderedCampaigns.filter(
        (c) => c.status === 'ACTIVE'
      ).length;
    const pendingOrderedCampaignsCount =
      this.props.listing.orderedCampaigns.filter((c) =>
        [
          'PENDING_PAYMENT',
          'PENDING_BY_ADMIN',
          'PENDING_BY_CLIENT',
          'PENDING_FROM_PROVIDER',
          'PENDING_PAYMENT_REVIEW',
          'CHECKING_STATUS',
        ].includes(c.status)
      ).length;

    this.setState({
      shouldDisableActivate:
        pendingOrderedCampaignsCount > 0 || activeOrderedCampaignsCount === 0,
    });
  }

  listingStatusChangeHandler = (listing, status) => {
    const that = this;
    const { changeListing } = this.props;
    const { id } = listing;
    let result;
    if (status === 'ACTIVE') {
      that
        .changeListingStatus({
          listingId: id,
          status,
          variables: {
            where: {
              id,
            },
            data: {
              status: { set: status },
              approvedAt: { set: new Date() },
              disapproveMessage: { set: '' },
              disapproveType: {
                set: null,
              },
            },
          },
        })
        .then((res) => {
          if (changeListing) {
            changeListing(res.data.updateListing);
          }
        });
    } else if (status === 'REJECTED') {
      Modal.confirm({
        title: 'هل تريد تقديم تقرير لهذا الاعلان قبل رفضه ؟',
        content: (
          <DisApprovalMenu getData={(response) => (result = response)} />
        ),
        width: 600,
        onOk() {
          that
            .changeListingStatus({
              listingId: id,
              status,
              variables: {
                where: {
                  id,
                },
                data: {
                  status: { set: status },
                  disapproveType: {
                    set: Array.isArray(result.types)
                      ? result.types.join(',')
                      : result.types,
                  },
                  disapproveMessage: { set: result.message || '' },
                },
              },
            })
            .then((res) => {
              if (changeListing) {
                console.log(res.data.updateListing);
                changeListing(res.data.updateListing);
              }
            });
        },
        onCancel() {},
      });
    } else {
      Modal.confirm({
        title: 'هل تريد تغيير حالة هذا الاعلان ؟',
        content: '',
        width: 600,
        onOk() {
          that
            .changeListingStatus({
              listingId: id,
              status,
              variables: {
                where: {
                  id,
                },
                data: {
                  status: { set: status },
                },
              },
            })
            .then((res) => {
              if (changeListing) {
                console.log(res.data.updateListing);
                changeListing(res.data.updateListing);
              }
            });
        },
        onCancel() {},
      });
    }
  };

  getStatusMap(status) {
    const statusMap = {
      DELETED: 'DELETED',
      REJECTED: 'REJECTED',
      REJECTEDUPDATED: 'REJECTED',
      STOPPED: 'STOPPED',
      ACTIVE: 'ACTIVE',
      ACTIVEUPDATED: 'ACTIVE',
    };
    return statusMap[status];
  }

  async changeListingStatus({ variables, status, listingId }) {
    const loadingStatus = this.getStatusMap(status);
    this.setState({
      [`${loadingStatus}_${listingId}_loading`]: true,
    });
    const result = await API.graphql(
      graphqlOperation(Admin.Listing.CHANGE_LISTING_STATUS, variables)
    );
    this.setState({
      [`${loadingStatus}_${listingId}_loading`]: false,
    });
    return result;
  }

  _getAddress = (address, scope, short) => {
    const findType = (type) =>
      type.types.indexOf(scope) !== -1 ? scope : false;
    const location = address.map((obj) => obj);
    if (findType) {
      const rr = location.filter(findType)[0];
      let newAddress = rr ? (short ? rr.short_name : rr.long_name) : '';
      newAddress = newAddress.replace('منطقة ', '');
      return newAddress;
    }
    return '';
  };

  render() {
    const { listing } = this.props;
    return (
      <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <Button
          size="large"
          type="danger"
          icon={<DeleteTwoTone twoToneColor="white" />}
          style={{ margin: '0 2px', borderRadius: 5 }}
          disabled={listing.status === 'DELETED'}
          loading={this.state[`DELETED_${this.props.listing.id}_loading`]}
          onClick={() => this.listingStatusChangeHandler(listing, 'DELETED')}
        >
          حذف
        </Button>
        {this.props.page ? (
          <Button
            size="large"
            type="ghost"
            icon={<CloseCircleTwoTone twoToneColor="red" />}
            style={{ margin: '0 2px', borderRadius: 5 }}
            disabled={listing.status === 'REJECTED'}
            loading={this.state[`REJECTED_${this.props.listing.id}_loading`]}
            onClick={() =>
              this.listingStatusChangeHandler(listing, 'REJECTED', {
                types: listing.disapproveType,
                message: listing.disapproveMessage || '',
              })
            }
          >
            رفض
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          size="large"
          type="dashed"
          icon={<StopTwoTone twoToneColor="#99A3A4" />}
          style={{ margin: '0 2px', borderRadius: 5 }}
          disabled={listing.status === 'STOPPED'}
          loading={this.state[`STOPPED_${this.props.listing.id}_loading`]}
          onClick={() => this.listingStatusChangeHandler(listing, 'STOPPED')}
        >
          ايقاف
        </Button>
        <Button
          size="large"
          type={listing.status === 'ACTIVE' ? 'default' : 'primary'}
          disabled={
            listing.isHousing &&
            listing.status !== 'ACTIVE' &&
            listing.status !== 'ACTIVEUPDATED' &&
            this.state.shouldDisableActivate
          }
          icon={
            listing.status === 'ACTIVE' ? (
              <PauseCircleOutlined />
            ) : (
              <CheckSquareTwoTone />
            )
          }
          style={{ margin: '0 2px', borderRadius: 5 }}
          loading={this.state[`ACTIVE_${this.props.listing.id}_loading`]}
          onClick={() =>
            this.listingStatusChangeHandler(
              listing,
              listing.status === 'ACTIVE' ? 'PENDING' : 'ACTIVE'
            )
          }
        >
          {listing.status === 'ACTIVE' ? 'تعليق' : 'تفعيل'}
        </Button>
      </div>
    );
  }
}
