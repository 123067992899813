import React from 'react';

export default function AreaMeters({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <defs>
        <path
          id="174fw2qxka"
          d="M9.025 7.787l3.017 2.979 1.173-1.159v3.443H9.73l1.164-1.151-3.018-2.978 1.148-1.134zM9.722.208l3.485.001v3.44L12.04 2.5l-9.516 9.4 1.165 1.15H.205V9.608l1.172 1.159 9.517-9.4L9.722.208zM3.699.207l-1.174 1.16 3.013 2.976-1.147 1.134L1.378 2.5.214 3.65V.207L3.7.207z"
        />
        <path
          id="uvwfr7l4wc"
          d="M0.262 0.186L1.058 0.186 1.058 15.574 0.262 15.574z"
        />
        <path id="r1br6jjafe" d="M0 0L0.796 0 0.796 15.397 0 15.397z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-317 -161) translate(0 150.146) translate(317 10.866) translate(4.033 6.52)">
                <mask id="lmngx2en0b" fill="#fff">
                  <use xlinkHref="#174fw2qxka" />
                </mask>
                <use fill="#EB4672" xlinkHref="#174fw2qxka" />
                <g fill="#141414" fillRule="nonzero" mask="url(#lmngx2en0b)">
                  <path
                    d="M0 0H30.8V31.149H0z"
                    transform="translate(-7.7 -11.228)"
                  />
                </g>
              </g>
              <g>
                <path
                  fill="#010101"
                  d="M20.506 21.732H.76c-.217 0-.394-.162-.394-.362 0-.2.177-.363.394-.363h19.745c.217 0 .394.163.394.363 0 .2-.177.362-.394.362"
                  transform="translate(-317 -161) translate(0 150.146) translate(317 10.866)"
                />
                <g transform="translate(-317 -161) translate(0 150.146) translate(317 10.866) translate(1.1 5.795)">
                  <mask id="6dc545v3ud" fill="#fff">
                    <use xlinkHref="#uvwfr7l4wc" />
                  </mask>
                  <path
                    fill="#010101"
                    d="M1.058.588v14.584c0 .222-.178.403-.398.403-.22 0-.398-.181-.398-.403V.588C.262.366.44.186.66.186c.22 0 .398.18.398.402"
                    mask="url(#6dc545v3ud)"
                  />
                </g>
                <path
                  fill="#010101"
                  d="M10.823.755L.533 7.552c-.17.112-.387.045-.485-.147-.098-.194-.04-.442.129-.554L10.467.054c.169-.112.387-.045.485.148.098.193.04.441-.13.553"
                  transform="translate(-317 -161) translate(0 150.146) translate(317 10.866)"
                />
                <g>
                  <g transform="translate(-317 -161) translate(0 150.146) translate(317 10.866) translate(19.433 5.795) translate(.016 .186)">
                    <mask id="ksqecdngif" fill="#fff">
                      <use xlinkHref="#r1br6jjafe" />
                    </mask>
                    <path
                      fill="#010101"
                      d="M.796.403v14.591c0 .222-.178.403-.398.403-.22 0-.398-.181-.398-.403V.403C0 .18.179 0 .398 0c.22 0 .398.18.398.403"
                      mask="url(#ksqecdngif)"
                    />
                  </g>
                </g>
                <path
                  fill="#010101"
                  d="M10.816.054L21.45 6.846c.175.112.235.361.135.556-.1.194-.325.262-.5.15L10.45.76c-.175-.112-.235-.361-.134-.556.1-.195.324-.262.5-.15"
                  transform="translate(-317 -161) translate(0 150.146) translate(317 10.866)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
