import React from 'react';
import './tiles.css';
import { Button } from 'antd';
import {
  FireTwoTone,
  PlusOutlined,
  DeleteTwoTone,
  CloseCircleFilled,
} from '@ant-design/icons';
import ImageEditor from './image-editor';
export default class Tiles extends React.Component {
  render() {
    return (
      <div className="tiles">
        {this.props.originalImages.map((data) => {
          return (
            <Tile
              uploadImagefromEditor={this.props.methods.uploadImagefromEditor}
              setImgAsMain={this.props.methods.setImgAsMain}
              deleteImage={this.props.methods.deleteImage}
              data={{
                imageUrl: data.fileType.includes('image')
                  ? data.url
                  : `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/full/${data.s3Prefix}/${data.fileName}`,
                name: data.fileName,
                id: data.id,
                main: data.main,
                commercialAdId: this.props.commercialAdId,
                listingId: this.props.listingId,
                type: data.fileType.includes('image') ? 'image' : 'video',
              }}
              s3Prefix={this.props.s3Prefix}
              key={data.id}
              keys={data.id}
            />
          );
        })}
      </div>
    );
  }
}
class Tile extends React.Component {
  state = {
    open: false,
    mouseOver: false,
  };

  _mouseEnter(e) {
    e.preventDefault();
    if (this.state.mouseOver === false) {
      this.setState({
        mouseOver: true,
      });
    }
  }
  _mouseLeave(e) {
    e.preventDefault();
    if (this.state.mouseOver === true) {
      this.setState({
        mouseOver: false,
      });
    }
  }
  _clickHandler(e) {
    e.preventDefault();
    if (this.props.data.type === 'video' && this.state.open === true) return;
    if (this.state.open === false) {
      this.setState({
        open: true,
      });
    } else {
      this.setState({
        open: false,
      });
    }
  }

  render() {
    const isMain = this.props.data.main;
    const isVideo = this.props.data.type === 'video';
    let tileStyle = {};

    if (this.state.open) {
      tileStyle = {
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 999,
        margin: 'auto',
        backgroundColor: '#000',
        boxShadow: '0 0 40px 5px rgba(0, 0, 0, 0.3)',
        transform: 'none',
      };
    } else {
      tileStyle = {
        width: '18vw',
        height: '35vh',
        border: isMain ? '10px solid red' : '',
      };
    }

    return (
      <div>
        <div className="tile">
          {isVideo ? (
            <>
              {this.state.open && (
                <Button
                  key={this.props.keys}
                  onClick={() => {
                    this.setState({
                      open: false,
                    });
                  }}
                  style={{
                    position: 'fixed',
                    zIndex: '9999999',
                    right: 10,
                    top: 20,
                  }}
                  icon={
                    <CloseCircleFilled style={{ fontSize: 25, color: 'red' }} />
                  }
                />
              )}
              <video
                onMouseEnter={this._mouseEnter.bind(this)}
                onMouseLeave={this._mouseLeave.bind(this)}
                onClick={this._clickHandler.bind(this)}
                style={tileStyle}
                controls
                src={this.props.data.imageUrl}
              />
            </>
          ) : (
            <img
              onMouseEnter={this._mouseEnter.bind(this)}
              onMouseLeave={this._mouseLeave.bind(this)}
              onClick={this._clickHandler.bind(this)}
              src={this.props.data.imageUrl}
              alt={this.props.data.name}
              style={tileStyle}
              crossOrigin="anonymous"
            />
          )}
        </div>
        <div
          style={{
            position: 'relative',
            width: '90%',
            height: '35px',
            display: 'flex',
            marginTop: '5px',
            marginBottom: '10px',
          }}
        >
          {!isVideo && (
            <>
              {' '}
              <div style={{ flex: 1, textAlign: 'center' }}>
                <Button
                  key={this.props.keys + 1}
                  onClick={async () => {
                    await this.props.setImgAsMain({
                      newImage: this.props.data,
                    });
                  }}
                  disabled={isMain}
                  icon={
                    isMain ? (
                      <FireTwoTone
                        twoToneColor="red"
                        style={{ fontSize: 25 }}
                      />
                    ) : (
                      <PlusOutlined style={{ fontSize: 25, color: 'red' }} />
                    )
                  }
                />
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <ImageEditor
                  delete={this.props.deleteImage}
                  upload={this.props.uploadImagefromEditor}
                  image={{
                    ...this.props.data,
                    s3Prefix: this.props.s3Prefix,
                  }}
                />
              </div>
            </>
          )}
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Button
              key={this.props.keys + 3}
              type="danger"
              shape="circle"
              icon={<DeleteTwoTone twoToneColor="#fff" />}
              onClick={async () => {
                await this.props.deleteImage({
                  imageData: this.props.data,
                  s3Prefix: this.props.s3Prefix,
                });
              }}
            ></Button>
          </div>
        </div>
      </div>
    );
  }
}
