import React from 'react';
import { Title } from '../../components/ui/Styled';
import { Input } from 'antd';
const { TextArea } = Input;
export default function EditAgencyTyps({ state, setState }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderTop: 'solid 1px',
        marginTop: '20px',
        paddingTop: '10px',
      }}
    >
      <h2>Agancy Account options</h2>
      <Title>الرقم المرجعي</Title>
      <Input
        name="referenceNumber"
        type="text"
        style={{
          width: 200,
          direction: 'rtl',
        }}
        placeholder="اسم المالك"
        onChange={async (value) => {
          await setState({
            referenceNumber: value.target.value,
          });
        }}
        value={state.referenceNumber}
      />
      <Title>اسم المالك</Title>
      <Input
        name="landArea"
        type="text"
        style={{
          width: 200,
          direction: 'rtl',
        }}
        placeholder="اسم المالك"
        onChange={async (value) => {
          await setState({
            ownerName: value.target.value,
          });
        }}
        value={state.ownerName}
      />
      <Title>رقم مالك العقار</Title>
      <Input
        name="landArea"
        type="text"
        style={{
          width: 200,
          direction: 'rtl',
        }}
        placeholder="اسم المالك"
        onChange={async (value) => {
          await setState({
            ownerPhoneNumber: value.target.value,
          });
        }}
        value={state.ownerPhoneNumber}
      />
      <Title>رقم واتساب مالك العقار</Title>
      <Input
        name="landArea"
        type="text"
        style={{
          width: 200,
          direction: 'rtl',
        }}
        placeholder="اسم المالك"
        onChange={async (value) => {
          await setState({
            ownerWhatsappNumber: value.target.value,
          });
        }}
        value={state.ownerWhatsappNumber}
      />
      <Title>ملاحظات</Title>
      <div
        style={{
          textAlign: 'end',
          border: `1px solid black`,
        }}
      >
        <TextArea
          name="title"
          placeholder="ملاحظات"
          required
          style={{
            direction: 'rtl',
            width: '100%',
          }}
          rows="2"
          value={state.note}
          onChange={async (value) => {
            await setState({
              note: value.target.value,
            });
          }}
        ></TextArea>
      </div>
    </div>
  );
}
