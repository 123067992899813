import React from 'react';
import Kitchen from './features-icons/kitchen';
import KitchenAluminium from './features-icons/kitchen-aluminium';
import KitchenWooden from './features-icons/kitchen-wooden';
import Parking from './features-icons/parking';
import PlayGround from './features-icons/playground';
import Area from './features-icons/area';
import Pool from './features-icons/pool';
import FirePlace from './features-icons/fireplace';
import StorageRoom from './features-icons/storage-room';
import Radiator from './features-icons/radiator';
import SunHeater from './features-icons/sun-heater';
import Grill from './features-icons/grill';
import LaundryRoom from './features-icons/laundry-room';
import WaterHeater from './features-icons/water-heater';
import AirConditioning from './features-icons/air-conditioning';
import DoubleGlaze from './features-icons/double-glaze';
import ElectricShade from './features-icons/electric-shade';
import WaterWell from './features-icons/water-well';
import CentralHeating from './features-icons/central-heating';
import { css } from '@emotion/react';
import Age from './key-features-icons/age';
import State from './key-features-icons/state';
import AreaMeters from './key-features-icons/area';
import Floor from './key-features-icons/floor';
import Bathroom from './key-features-icons/bathroom';
import Bedroom from './key-features-icons/bedroom';
const keyFeatures = {
  buildingAge: { name: 'عمر البناء', icon: Age, numeral: 'سنة' },
  totalApartments: { name: 'عدد الشقق' },
  terraceArea: { name: 'مساحة الحديقة', icon: AreaMeters, numeral: 'م' },
  withTerrace: { name: 'مع حديقة' },
  withRoof: { name: 'مع روف' },
  isNew: { name: 'جديد', icon: State },
  isFurnished: { name: 'مفروش' },
  bedRooms: { name: 'غرف النوم', icon: Bedroom },
  bathRooms: { name: 'عدد الحمامات', icon: Bathroom },
  totalFloors: { name: 'عدد الطوابق', icon: Floor },
  totalUnits: { name: 'عدد الوحدات' },
  floor: { name: 'الطابق', icon: Floor },
  roofArea: { name: 'مساحة الروف', icon: AreaMeters, numeral: 'م' },
  withBuilding: { name: 'مع مبنى' },
  landArea: { name: 'مساحة الارض', icon: AreaMeters, numeral: 'م' },
  streetFront: { name: 'واجهة الشارع', icon: AreaMeters, numeral: 'م' },
  isResidential: { name: 'سكني' },
  isCommercial: { name: 'تجاري' },
  zone: { name: 'ميزات المبنى' },
  incline: { name: 'المنسوب' },
};
const featuresValues = {
  kitchenWooden: { name: 'مطبخ خشب', icon: KitchenWooden },
  kitchenAluminium: { name: 'مطبخ ألمونيوم', icon: KitchenAluminium },
  kitchen: { name: 'مطبخ هاي غلوس', icon: Kitchen },
  barbecueArea: { name: 'فيربليس', icon: FirePlace },
  centralHeating: { name: 'تدفئة راكبة', icon: CentralHeating },
  developmentHeating: { name: 'تأسيس تدفأة', icon: Radiator },
  centralConditioning: { name: 'مكيفات راكبة', icon: AirConditioning },
  developmentConditioning: { name: 'تأسيس مكيفات', icon: Area },
  doubleGlaze: { name: 'زجاج عازل', icon: DoubleGlaze },
  electricShades: { name: 'اباجورت كهرباء', icon: ElectricShade },
  heating: { name: 'سخان شمسي', icon: WaterHeater },
  sunHeater: { name: 'خلايا شمسية', icon: SunHeater },
  waterWell: { name: 'بئر ماء منفصل', icon: WaterWell },
  houseStore: { name: 'مخزن مستقل', icon: StorageRoom },
  washRoom: { name: 'غرفة غسيل/خادمة', icon: LaundryRoom },
  barbeque: { name: 'منطقة للشوي', icon: Grill },
  parking: { name: 'موقف سيارة', icon: Parking },
  parking2: { name: 'موقفين سيارة', icon: Parking },
  swimmingPool: { name: 'مسبح', icon: Pool },
  playArea: { name: 'منطقة للألعاب', icon: PlayGround },
};

const BlocksContainer = ({
  features,
  featuresValues,
  values,
  type,
  border,
}) => (
  <div
    css={css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      padding: 15px 0;
      ${border ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.05);' : ''}
    `}
  >
    {features.map((feature, index) =>
      featuresValues[feature] ? (
        <Block
          key={index}
          type={type}
          iconText={featuresValues[feature].name}
          value={type === 'key' && values[feature]}
          icon={featuresValues[feature].icon && featuresValues[feature].icon}
          numeral={
            featuresValues[feature].numeral && featuresValues[feature].numeral
          }
        />
      ) : (
        ''
      )
    )}
  </div>
);
const Block = ({ iconText, value, type, icon, numeral }) => {
  const boolValue = typeof value === 'boolean';
  const Icon = icon;
  const IconComponent = () => (
    <div
      css={css`
        position: relative;
        height: 20px;
        top: 2px;
        margin: 0px 3px;
      `}
    >
      <Icon color={'#000'} />
    </div>
  );
  if (type === 'key') {
    return (
      <div
        css={css`
          flex: 0 0 32%;
          display: flex;
          flex-direction: row-reverse;
          justify-content: ${boolValue && Icon ? 'center' : 'space-around'};
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          height: 28px;
          padding: 2px;
          margin: 3px 2px;
        `}
      >
        {Icon && <IconComponent color={'#000'} />}
        <IconParagraph text={iconText} />
        {!boolValue ? <IconParagraph text={':'} /> : null}
        {!boolValue ? <IconParagraph text={value} /> : null}
        {numeral ? <IconParagraph text={numeral} /> : null}
      </div>
    );
  } else {
    return (
      <div
        css={css`
          flex: 0 0 23%;
          display: flex;
          flex-direction: row-reverse;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 3px;
          height: 28px;
          justify-content: flex-start;
          padding: 2px;
          margin: 3px 2px;
        `}
      >
        <IconComponent color={'#000'} />

        <IconParagraph text={iconText} />
      </div>
    );
  }
};

const IconParagraph = ({ text }) => (
  <p
    css={css`
      font-family: Noto Kufi Arabic;
      font-size: 14px;
      text-align: center;
    `}
  >
    {text}
  </p>
);
const Header = ({ text }) => (
  <div
    css={css`
      display: relative;
      height: auto;
      width: 100%;
      padding: 16px 0;
      text-align: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    `}
  >
    <p
      css={css`
        font-size: 16px;
        margin: 0;
        font-weight: 500;
      `}
    >
      {text}
    </p>
  </div>
);

export const ListingFeaturesBlocks = ({ features, values, views }) => {
  const featuresFiltered = features.filter(
    (feat) => featuresValues[feat] !== undefined
  );
  const KeyFeaturesFiltered = features.filter(
    (feat) => keyFeatures[feat] !== undefined
  );
  const NoneComponent = () => (
    <div
      css={css`
        position: relative;
        margin: 15px;
      `}
    >
      <IconParagraph text={'None'} />
    </div>
  );
  return (
    <div
      css={css`
        position: relative;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.05);
      `}
    >
      <Header text={'Key Features'} />
      {KeyFeaturesFiltered.length > 0 ? (
        <BlocksContainer
          type={'key'}
          features={features}
          featuresValues={keyFeatures}
          values={values}
          border
        ></BlocksContainer>
      ) : (
        <NoneComponent />
      )}
      <Header text={'Additional Features'} />
      {featuresFiltered.length > 0 ? (
        <BlocksContainer
          type={'icon'}
          features={features}
          featuresValues={featuresValues}
        ></BlocksContainer>
      ) : (
        <div
          css={css`
            position: relative;
            margin: 15px;
          `}
        >
          <NoneComponent />
        </div>
      )}
    </div>
  );
};
