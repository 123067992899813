import React from 'react';

export default function Bedroom({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M2.2 0C1.476 0 .88.678.88 1.5v7.59c.002.032.007.063.014.094V9.2c-.198.353-.36.73-.482 1.14-.273.916-.39 1.966-.412 3.155V19.993h3.08v-2c0-.415.09-.632.206-.765.117-.132.308-.234.674-.234h14.08c.366 0 .557.102.674.234.117.133.206.35.206.766v2H22v-5.92c.002-.026.002-.053 0-.079 0-.172.003-.334 0-.5-.022-1.189-.14-2.24-.413-3.155-.122-.406-.285-.775-.48-1.124.008-.041.013-.082.013-.125V1.499C21.12.678 20.524 0 19.8 0H2.2zm0 1h17.6c.247 0 .44.218.44.5v6.575c-.108-.103-.223-.203-.344-.296-.294-.23-.613-.446-.976-.625V4.998c0-.351-.203-.624-.426-.796-.224-.172-.497-.291-.839-.39-.684-.2-1.645-.313-2.915-.313s-2.23.113-2.915.312c-.333.098-.605.21-.825.375-.22-.166-.492-.277-.825-.375C9.491 3.612 8.53 3.5 7.26 3.5s-2.23.113-2.915.312c-.342.1-.615.219-.839.39-.223.173-.426.446-.426.797v2.156c-.363.18-.682.394-.976.625-.12.093-.236.193-.344.296V1.5c0-.28.192-.5.44-.5zm5.06 3.498c1.224 0 2.131.118 2.695.282.282.082.476.181.564.25.036.027.038.03.041.03v.938c-2.893.021-5.034.267-6.6.812V5.06c.003 0 .005-.003.041-.03.088-.069.282-.168.564-.25.564-.164 1.471-.282 2.695-.282zm7.48 0c1.224 0 2.131.118 2.695.282.282.082.476.181.564.25.036.027.038.03.041.03v1.75c-1.566-.545-3.707-.79-6.6-.812v-.937c.003 0 .005-.004.041-.031.088-.069.282-.168.564-.25.564-.164 1.471-.282 2.695-.282zm-3.822 2.5c.046.007.09.007.137 0h.041c4.404.007 6.947.53 8.305 1.593.684.535 1.099 1.199 1.361 2.077.23.766.322 1.709.344 2.827H.894c.022-1.118.115-2.061.344-2.827.262-.878.677-1.542 1.36-2.077 1.359-1.064 3.907-1.588 8.32-1.593zM.88 14.495h20.24v4.499H19.8v-1c0-.584-.13-1.117-.454-1.484-.323-.367-.792-.515-1.306-.515H3.96c-.514 0-.983.148-1.306.515-.323.367-.454.9-.454 1.484v1H.88v-4.499z"
                  transform="translate(-317 -74) translate(0 63.22) translate(317 10.866) translate(0 .87)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
