import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import './button-css.css';
import { Admin } from '../../queries';
import { API, graphqlOperation } from 'aws-amplify';
const TopButtonElement = ({
  QueryType,
  text,
  color,
  where,
  whereSetter,
  setCount,
  datGridFunction,
  paginationCallback,
  isOrderedCampaign,
  isAdditionalObject,
  isAdditional,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [orderedCampaignsCount, setOrderedCampaignsCount] = useState(null);
  const getListingsCount = async () => {
    try {
      const listingsCountResponse = await API.graphql(
        graphqlOperation(Admin.Listing.GET_LISTINGS_COUNT, {
          whereListing: { status: { in: [`${QueryType}`] } },
        })
      );
      setData(listingsCountResponse);
    } catch (error) {
      console.log(
        '🚀 ~ file: table-header-buttons.js ~ line 24 ~ getListingsCount ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const getOrderedCampaignsCount = async () => {
    try {
      const orderedCampaignsCountResponse = await API.graphql(
        graphqlOperation(Admin.OrderedCampaign.GET_ORDERED_CAMPAIGNS_COUNT, {
          where: {
            status: { in: [`${QueryType}`] },
            isAdditional: isAdditionalObject,
          },
        })
      );
      setOrderedCampaignsCount(orderedCampaignsCountResponse);
    } catch (error) {
      console.log(
        '🚀 ~ file: table-header-buttons.js ~ line 47 ~ getOrderedCampaignsCount ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isOrderedCampaign) {
      getOrderedCampaignsCount();
    } else {
      getListingsCount();
    }
  }, []);
  return (
    <Button
      className="header-buttons"
      style={{
        margin: '10px 10px 0 0',
        borderRadius: 8,
        opacity: 0.8,
        backgroundColor: color
          ? color
          : (data && data.data.listingsConnection._count.id > 0) ||
            (orderedCampaignsCount &&
              orderedCampaignsCount.data.orderedCampaignsConnection._count.id >
                0)
          ? '#F08D63'
          : '#9FF063',
      }}
      onClick={async () => {
        if (!isOrderedCampaign) {
          whereSetter({
            first: 50,
            orderBy: { updatedAt: 'desc' },
            skip: 0,
            where: {
              ...where,
              status: { in: [`${QueryType}`] },
            },
          });
          setCount(data.data.listingsConnection._count.id);
          datGridFunction([QueryType]);
          paginationCallback(QueryType);
        } else {
          window.open(
            `${process.env.REACT_APP_DASHBOARD_URL}/ordered-campaigns`,
            '_blank'
          );
        }
      }}
    >
      {text}
      <div
        style={{
          fontSize: 14,
          display: 'inline',
        }}
      >
        {' '}
        {loading && <Spin size="small" />}
        {data && data.data.listingsConnection._count.id}
        {orderedCampaignsCount &&
          orderedCampaignsCount.data.orderedCampaignsConnection._count.id}
      </div>
    </Button>
  );
};

export default function TableHeader({
  clearAll,
  where = {},
  whereSetter,
  setCount,
  datGridFunction,
  paginationCallback,
}) {
  const statusArray = [
    {
      QueryType: 'PENDING',
      text: 'Pending',
      color: 'red',
    },
    {
      QueryType: 'PENDING_BY_ADMIN',
      text: 'Pending Ordered Campaigns',
      color: 'red',
      isOrderedCampaign: true,
    },
    {
      QueryType: 'REJECTED',
      text: 'Rejected',
      color: 'skyblue',
    },
    {
      QueryType: 'REJECTEDUPDATED',
      text: 'Rejected-Updated',
      color: 'orange',
    },
    {
      QueryType: 'ACTIVEUPDATED',
      text: 'Active-Updated',
      color: 'yellow',
    },
    {
      QueryType: 'ACTIVE',
      text: 'Active',
      color: 'lime',
    },
    {
      QueryType: 'ACTIVE',
      text: 'Active Ordered Campaigns',
      isAdditionalObject: {
        not: {
          equals: true,
        },
      },
      isAdditional: false,
      color: 'lime',
      isOrderedCampaign: true,
    },
    {
      QueryType: 'STOPPED',
      text: 'Stopped',
      color: 'grey',
    },
    {
      QueryType: 'DELETED',
      text: 'Deleted',
      color: 'crimson',
    },
  ];
  return (
    <div className="table-operations">
      <Button onClick={clearAll}>Reset Filters and Sorting</Button>
      <div>
        {statusArray.map((props, index) => (
          <div key={index} style={{ display: 'inline', textAlign: 'center' }}>
            {TopButtonElement({
              ...props,
              where,
              whereSetter,
              setCount,
              datGridFunction,
              paginationCallback,
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
