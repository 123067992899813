import React from 'react';
import PropTypes from 'prop-types';
import './EditContactMethod.css';
import { Col, Input, Radio, Row, Space } from 'antd';
import { Title } from '../../components/ui/Styled';

const EditContactMethod = ({ state, setState }) => {
  return (
    <div className="EditContactMethod">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
        }}
      >
        <h2 style={{ paddingTop: '10px' }}>طريقة الاتصال</h2>
        <Title>نوع الاتصال</Title>
        <Radio.Group
          onChange={(e) => {
            const value = e.target.value;
            setState({
              ...state,
              contactMethod: {
                ...state.contactMethod,
                type: value,
              },
            });
          }}
          value={state.contactMethod.type}
          style={{ direction: 'rtl' }}
        >
          <Space direction="vertical">
            <Row>
              <Col>
                <Radio value={'PHONE'}>
                  <div>موبايل</div>
                </Radio>
              </Col>
              <Col>
                <Radio value={'WHATSAPP'}>
                  <div>واتساب</div>
                  <div></div>
                </Radio>
              </Col>
            </Row>
          </Space>
        </Radio.Group>
        <Title>رقم الهاتف</Title>
        <Input
          style={{
            width: 200,
            direction: 'ltr',
          }}
          name="phone"
          placeholder="+962000000000"
          type="tel"
          onChange={(e) => {
            const value = e.target.value;
            setState({
              ...state,
              contactMethod: {
                ...state.contactMethod,
                phone: value,
              },
            });
          }}
          value={state.contactMethod.phone}
        />
        <Title>رقم الواتس</Title>
        <Input
          style={{
            width: 200,
            direction: 'ltr',
          }}
          name="whatsapp"
          placeholder="+962000000000"
          type="tel"
          onChange={(e) => {
            const value = e.target.value;
            setState({
              ...state,
              contactMethod: {
                ...state.contactMethod,
                whatsApp: value,
              },
            });
          }}
          value={state.contactMethod.whatsApp}
        />
        <Title>الرسالة التشجيعية</Title>

        <Input
          style={{
            width: 200,
            textAlign: 'end',
          }}
          name="whatsappMessage"
          placeholder="رسالة تشجيعية"
          type="text"
          onChange={(e) => {
            const value = e.target.value;
            setState({
              ...state,
              contactMethod: {
                ...state.contactMethod,
                message: value,
              },
            });
          }}
          value={state.contactMethod.message}
        />
      </div>
    </div>
  );
};

EditContactMethod.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func,
};

EditContactMethod.defaultProps = {
  state: {
    contactMethod: {},
  },
  setState: () => {},
};

export default EditContactMethod;
