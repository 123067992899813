import React from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditorComponent from '@toast-ui/react-image-editor';
import { Button, Modal } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import { generateImageName } from '../utils';
const icona = require('tui-image-editor/dist/svg/icon-a.svg');
const iconb = require('tui-image-editor/dist/svg/icon-b.svg');
const iconc = require('tui-image-editor/dist/svg/icon-c.svg');
const icond = require('tui-image-editor/dist/svg/icon-d.svg');
var whiteTheme = {
  'menu.normalIcon.path': icond,
  'menu.activeIcon.path': iconb,
  'menu.disabledIcon.path': icona,
  'menu.hoverIcon.path': iconc,
  'submenu.normalIcon.path': icond,
  'submenu.activeIcon.path': iconb,
};

export default class ImageEditor extends React.Component {
  state = {
    image: {},

    visible: false,
  };

  initEditor() {
    this.setState({ image: this.props.image });
  }

  async componentDidMount() {
    await this.initEditor();
  }
  editorRef = React.createRef();

  render() {
    const EditorModal = () => {
      return (
        <Modal
          title="Image Editor"
          visible={this.state.visible}
          style={{ top: 20 }}
          onOk={async () => {
            const editorInstance = this.editorRef.current.getInstance();
            const dataUrl = editorInstance.toDataURL();
            const blob = await (await fetch(dataUrl)).blob();
            const newFileName = generateImageName();
            const file = new File([blob], newFileName, {
              type: blob.type,
              lastModified: new Date(),
            });

            await this.props.upload({
              originalImageObj: this.props.image,
              newImageFile: file,
              newFileName,
            });
          }}
          onCancel={() => {
            this.setState({ visible: false });
          }}
          width={'90%'}
        >
          <ImageEditorComponent
            includeUI={{
              loadImage: {
                path: this.props.image.imageUrl,
                name: this.props.image.name,
              },
              menu: [
                'crop',
                'rotate',
                'flip',
                'draw',
                'shape',
                'text',
                'filter',
                'icon',
                'mask',
              ],
              initMenu: 'crop',
              theme: whiteTheme,
              uiSize: {
                height: `80vh`,
              },

              menuBarPosition: 'left',
            }}
            cssMaxHeight={window.innerHeight}
            cssMaxWidth={window.innerWidth}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
            ref={this.editorRef}
          />
        </Modal>
      );
    };
    return (
      <div>
        <div>{this.state.visible && <EditorModal />}</div>
        <div>
          <Button
            type="primary"
            style={{ borderRadius: 5 }}
            icon={<EditTwoTone twoToneColor="#fff" />}
            onClick={() => {
              this.setState({
                visible: true,
              });
            }}
          ></Button>
        </div>
      </div>
    );
  }
}
