import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { NoSql } from '../queries';

export const setNotificationIsReaded = async ({ notificationId }) => {
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL_NO_SQL,
    },
  });
  const result = await API.graphql(
    graphqlOperation(NoSql.Notifications.updateNotifications(), {
      data: {
        isReaded: { set: true },
      },
      where: {
        id: notificationId,
      },
    })
  )
    .then((result) => result)
    .catch((e) => {
      console.log(
        '🚀 ~ file: NoSqlUtils.js:248 ~ getManyNotifications ~ e:',
        e
      );
      return e;
    });
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL,
    },
  });
  return result;
};

export const getCountOfNotifications = async ({ where }) => {
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL_NO_SQL,
    },
  });
  const result = await API.graphql(
    graphqlOperation(NoSql.Notifications.notificationsCount(), {
      where,
    })
  )
    .then((result) => result)
    .catch((e) => {
      console.log(
        '🚀 ~ file: NoSqlUtils.js:248 ~ getManyNotifications ~ e:',
        e
      );
      return e;
    });
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL,
    },
  });
  return result;
};

export const getManyNotifications = async ({ where, take, skip }) => {
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL_NO_SQL,
    },
  });
  const result = await API.graphql(
    graphqlOperation(
      NoSql.Notifications.findManyNotificationReceiversByUserId(),
      {
        where,
        take,
        skip,
        orderBy: {
          id: 'desc',
        },
      }
    )
  )
    .then((result) => result)
    .catch((e) => {
      console.log(
        '🚀 ~ file: NoSqlUtils.js:248 ~ getManyNotifications ~ e:',
        e
      );
      return e;
    });
  Amplify.configure({
    API: {
      graphql_endpoint: process.env.REACT_APP_API_URL,
    },
  });
  return result;
};
