import React, { useState } from 'react';
import { Row, Col, Card, Switch, Input, Form } from 'antd';

export default function DisApprovalMenu({ data, getData, message, type }) {
  const [report, setReport] = useState({
    types: type ? type : [],
    message: message,
  });

  getData(report);
  const onChange = (value, checked) => {
    const types = Array.isArray(report.types)
      ? report.types
      : report.types.split(',');
    if (checked) types.push(value);
    else types.splice(types.indexOf(value), 1);
    let editedResult = types.join(',');
    if (editedResult[0] === ',') {
      editedResult = editedResult.slice(1);
    } else if (editedResult[-1] === ',') {
      editedResult = editedResult.slice(0, -1);
    }
    setReport({ ...report, types: editedResult });
  };

  const onChangeText = (e) => {
    setReport({ ...report, message: e.target.value });
  };

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <h4>تقرير الرفض</h4>
        <Row gutter={0}>
          <Col span={12}>
            <Card>
              <Switch
                checked={report.types.indexOf('PHOTO') > -1}
                onChange={(checked) => onChange('PHOTO', checked)}
              />{' '}
              صور غير دقيقة
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Switch
                checked={report.types.indexOf('INFORMATION') > -1}
                onChange={(checked) => onChange('INFORMATION', checked)}
              />{' '}
              معلومات غير دقيقة
            </Card>
          </Col>
        </Row>
        <Row gutter={0}>
          <Col span={12}>
            <Card>
              <Switch
                checked={report.types.indexOf('LOCATION') > -1}
                onChange={(checked) => onChange('LOCATION', checked)}
              />{' '}
              موقع غير دقيق
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Switch
                checked={report.types.indexOf('OTHER') > -1}
                onChange={(checked) => onChange('OTHER', checked)}
              />{' '}
              اسباب اخرى
            </Card>
          </Col>
        </Row>
        <Row gutter={0}>
          <Col span={12}>
            <Card>
              <Switch
                checked={report.types.indexOf('REPEATED') > -1}
                onChange={(checked) => onChange('REPEATED', checked)}
              />{' '}
              مكرر
            </Card>
          </Col>
        </Row>
      </div>
      <div>
        <h4>رسالة الرفض</h4>
        <Form.Item>
          <Input.TextArea
            rows={4}
            onChange={onChangeText}
            value={report.message}
          />
        </Form.Item>
      </div>
    </div>
  );
}
