import React from 'react';
export default function LaundryRoom({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M9.089 5.313H7.485V8.5h1.604c.89 0 1.604-.708 1.604-1.594 0-.885-.713-1.593-1.604-1.593zm0 2.656h-1.07V5.844h1.07c.588 0 1.069.478 1.069 1.062 0 .585-.481 1.063-1.07 1.063z"
                  transform="translate(-313 -1465) translate(0 1078) translate(183 377) translate(130.828 10)"
                />
                <path
                  d="M14.97 0H2.139C.962 0 0 .956 0 2.125v12.75C0 16.044.962 17 2.139 17h12.83c1.177 0 2.14-.956 2.14-2.125V2.125C17.108.956 16.145 0 14.968 0zm1.604 6.375v8.5c0 .885-.713 1.594-1.604 1.594H2.139c-.892 0-1.604-.709-1.604-1.594V2.125c0-.885.712-1.594 1.604-1.594h12.83c.892 0 1.605.709 1.605 1.594v4.25z"
                  transform="translate(-313 -1465) translate(0 1078) translate(183 377) translate(130.828 10)"
                />
                <path
                  d="M9.623 3.719H5.614c-.143 0-.268.124-.268.265v9.032c0 .141.125.265.268.265h2.138c.143 0 .268-.124.268-.265v-2.922h1.603c1.765 0 3.208-1.435 3.208-3.188 0-1.753-1.443-3.187-3.208-3.187zm0 5.844H7.485v3.187H5.88v-8.5h3.742c1.48 0 2.674 1.186 2.674 2.656 0 1.47-1.194 2.657-2.674 2.657z"
                  transform="translate(-313 -1465) translate(0 1078) translate(183 377) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
