import React from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

import { Row, Col, Card, Spin, Divider } from 'antd';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as moment from 'moment';
import { Admin } from '../../queries';

class UserChart extends React.PureComponent {
  state = {
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    allListings: 1,
    deletedListings: 1,
    pendingListings: 1,
    activeListings: 1,
    loading: false,
  };

  async componentDidMount() {
    const { token } = this.props;
    Amplify.configure({
      API: {
        graphql_headers: () => {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    });

    let listingsCount = (a, b) => {
      return API.graphql(
        graphqlOperation(Admin.User.GET_USERS_COUNT, {
          where: {
            AND: [
              {
                createdAt: {
                  lte: a,
                },
              },
              {
                createdAt: {
                  gte: b,
                },
              },
            ],
          },
        })
      ).then((r) => {
        return r.data.usersConnection._count.id;
      });
    };

    let usercount = (where) => {
      return API.graphql(
        graphqlOperation(Admin.User.GET_USERS_COUNT, {
          where: {
            ...where,
          },
        })
      ).then((r) => {
        return r.data.usersConnection._count.id;
      });
    };

    let listingsStatusCount = (a) => {
      if (!a) {
        return API.graphql(graphqlOperation(Admin.User.GET_USERS_COUNT)).then(
          (r) => {
            return r.data.usersConnection._count.id;
          }
        );
      } else {
        return API.graphql(
          graphqlOperation(Admin.User.GET_USERS_COUNT, {
            where: {
              status: { equals: a },
            },
          })
        ).then((r) => {
          return r.data.usersConnection._count.id;
        });
      }
    };

    let data1 = [
      {
        name: moment().subtract(6, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().subtract(5, 'days').format('YYYY-MM-DD'),
            moment().subtract(6, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: moment().subtract(5, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().subtract(4, 'days').format('YYYY-MM-DD'),
            moment().subtract(5, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: moment().subtract(4, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().subtract(3, 'days').format('YYYY-MM-DD'),
            moment().subtract(4, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: moment().subtract(3, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().subtract(2, 'days').format('YYYY-MM-DD'),
            moment().subtract(3, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: moment().subtract(2, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().subtract(1, 'days').format('YYYY-MM-DD'),
            moment().subtract(2, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: moment().subtract(1, 'days').format('dddd'),
        user:
          (await listingsCount(
            moment().format('YYYY-MM-DD'),
            moment().subtract(1, 'days').format('YYYY-MM-DD')
          )) + 1,
      },
      {
        name: 'Today',
        user:
          (await listingsCount(
            moment().add(1, 'days').format('YYYY-MM-DD'),
            moment().format('YYYY-MM-DD')
          )) + 1,
      },
    ];
    let data2 = [
      {
        name: 'last 90 days',
        users: await listingsCount(
          moment().subtract(60, 'days').format('YYYY-MM-DD'),
          moment().subtract(90, 'days').format('YYYY-MM-DD')
        ),
        cumulative: await listingsCount(
          moment().format('YYYY-MM-DD'),
          moment().subtract(90, 'days').format('YYYY-MM-DD')
        ),
      },
      {
        name: 'last 60 days',
        users: await listingsCount(
          moment().subtract(30, 'days').format('YYYY-MM-DD'),
          moment().subtract(60, 'days').format('YYYY-MM-DD')
        ),
        cumulative: await listingsCount(
          moment().format('YYYY-MM-DD'),
          moment().subtract(60, 'days').format('YYYY-MM-DD')
        ),
      },
      {
        name: 'last 30 days',
        users: await listingsCount(
          moment().format('YYYY-MM-DD'),
          moment().subtract(30, 'days').format('YYYY-MM-DD')
        ),
        cumulative: await listingsCount(
          moment().format('YYYY-MM-DD'),
          moment().subtract(30, 'days').format('YYYY-MM-DD')
        ),
      },
    ];
    let data3 = [
      {
        name: 1,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-02-01',
          moment().format('YYYY') + '-01-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-02-01',
          moment().subtract(1, 'years').format('YYYY') + '-01-01'
        ),
      },
      {
        name: 2,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-03-01',
          moment().format('YYYY') + '-02-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-03-01',
          moment().subtract(1, 'years').format('YYYY') + '-02-01'
        ),
      },
      {
        name: 3,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-04-01',
          moment().format('YYYY') + '-03-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-04-01',
          moment().subtract(1, 'years').format('YYYY') + '-03-01'
        ),
      },
      {
        name: 4,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-05-01',
          moment().format('YYYY') + '-04-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-05-01',
          moment().subtract(1, 'years').format('YYYY') + '-04-01'
        ),
      },
      {
        name: 5,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-06-01',
          moment().format('YYYY') + '-05-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-06-01',
          moment().subtract(1, 'years').format('YYYY') + '-05-01'
        ),
      },
      {
        name: 6,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-07-01',
          moment().format('YYYY') + '-06-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-07-01',
          moment().subtract(1, 'years').format('YYYY') + '-06-01'
        ),
      },
      {
        name: 7,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-08-01',
          moment().format('YYYY') + '-07-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-08-01',
          moment().subtract(1, 'years').format('YYYY') + '-07-01'
        ),
      },
      {
        name: 8,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-09-01',
          moment().format('YYYY') + '-08-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-09-01',
          moment().subtract(1, 'years').format('YYYY') + '-08-01'
        ),
      },
      {
        name: 9,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-10-01',
          moment().format('YYYY') + '-09-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-10-01',
          moment().subtract(1, 'years').format('YYYY') + '-09-01'
        ),
      },
      {
        name: 10,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-11-01',
          moment().format('YYYY') + '-10-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-11-01',
          moment().subtract(1, 'years').format('YYYY') + '-10-01'
        ),
      },
      {
        name: 11,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-12-01',
          moment().format('YYYY') + '-11-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-12-01',
          moment().subtract(1, 'years').format('YYYY') + '-11-01'
        ),
      },
      {
        name: 12,
        thisYear: await listingsCount(
          moment().format('YYYY') + '-12-30',
          moment().format('YYYY') + '-12-01'
        ),
        lastYear: await listingsCount(
          moment().subtract(1, 'years').format('YYYY') + '-12-30',
          moment().subtract(1, 'years').format('YYYY') + '-12-01'
        ),
      },
    ];

    this.setState({ data1: data1 });
    this.setState({ data2: data2 });
    this.setState({ data3: data3 });

    let allListingsCount = await listingsStatusCount();
    this.setState({ allListings: allListingsCount });

    let activeListings = await listingsStatusCount('ACTIVE');
    this.setState({ activeListings: activeListings });

    let pendingListings = await listingsStatusCount('PENDING');
    this.setState({ pendingListings: pendingListings });

    let deletedListings = await listingsStatusCount('DELETED');
    this.setState({ deletedListings: deletedListings });

    let fbcount = await usercount({
      facebookId: { not: { equals: null } },
    });

    let google = await usercount({
      googleId: { not: { equals: null } },
    });
    let Apple = await usercount({
      appleId: { not: { equals: null } },
    });
    let data4 = [
      { name: 'FaceBook', value: fbcount },
      { name: 'Google', value: google },
      { name: 'Apple', value: Apple },
      { name: 'Phone', value: allListingsCount - fbcount - google - Apple },
    ];
    this.setState({ data4: data4 });
    this.setState({ loading: true });
  }

  COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% ${this.state.data4[index].name} `}
      </text>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <div>
          <Row gutter={16}>
            <Col span={15}>
              <Divider orientation="right">
                عدد المستخدمين الجدد لاخر اسبوع{' '}
              </Divider>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    width={750}
                    height={300}
                    data={this.state.data1}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="user" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
            <Col span={9}>
              <Divider orientation="right">
                عدد المستخدمين الجدد لاخر 60,90,30 يوم{' '}
              </Divider>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={this.state.data2}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="cumulative" fill="#8884d8" />

                    <Bar dataKey="users" fill="#82ca9d" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <br></br>
          <br></br>
          <Divider>عدد المستخدمين مقارنة بالعام الماضي</Divider>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
              <BarChart
                data={this.state.data3}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  type="monotone"
                  dataKey="thisYear"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                />
                <Bar
                  type="monotone"
                  dataKey="lastYear"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <br></br>
          <br></br>
          <div></div>

          <Row>
            <Col span={11}>
              <div style={{ width: '100%', height: 400 }}>
                <Divider> طريقة التسجيل </Divider>

                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={this.state.data4}
                      cx={300}
                      cy={180}
                      labelLine={false}
                      label={this.renderCustomizedLabel}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {this.state.data4.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={this.COLORS[index % this.COLORS.length]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Row gutter={16}>
                <Row>
                  <br></br>
                  <br></br>
                </Row>
                <Row>
                  <Col span={20}>
                    <Card
                      size="small"
                      title={<p>عدد المستخدمين الكلي</p>}
                      bordered={true}
                    >
                      <h2> {this.state.allListings}</h2>
                    </Card>
                    <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Card
                      size="small"
                      title={<p>عدد المستخدمين الفعالين</p>}
                      bordered={true}
                    >
                      <h2> {this.state.activeListings} </h2>{' '}
                    </Card>
                    <br></br>
                  </Col>
                </Row>
                <Row>
                  <Col span={20}>
                    <Card
                      size="small"
                      title={<p>عدد المستخدمين لم يتم تفعيلهم</p>}
                      bordered={true}
                    >
                      <h2>{this.state.pendingListings} </h2>{' '}
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <Spin size="large" tip="Loading . . ." style={{ margin: '20% 45%' }} />
      );
    }
  }
}

export default UserChart;
