import React, { useState } from 'react';
import { AreaModal } from './LocationViewUpdate';
import { Admin } from '../../queries';
import { Modal, message, Button } from 'antd';

export const NewAreaModal = ({ ButtonText, props, cities, client }) => {
  const [visible, setVisible] = useState(false);
  const saveArea = async (args) => {
    // const { client } = props;
    const { id, searchName, name, lat, lng, radius, keyName, city } = args;
    let variables = {
      data: {
        searchName,
        name,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        radius: parseFloat(radius),
        keyName,
        version: 3,
        city: { connect: { id: city.id } },
      },
    };
    if (id) {
      variables = {
        ...variables,
      };
    }
    try {
      const response = await client.mutate({
        mutation: Admin.Area.CREATE_AREA,
        variables,
      });
      if (response) {
        console.log(response);
        message.success(`Area Added to ${city.name} City`);
      }
    } catch (e) {
      message.error(
        'problem adding area, have you filled all the fields correctly?'
      );
    }
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  return (
    <>
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
        onClick={() => {
          showModal();
        }}
      >
        {ButtonText}
      </Button>
      <Modal
        title="Create Area"
        visible={visible}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ disabled: false }}
      >
        <AreaModal cities={cities} callBack={saveArea} />
      </Modal>
    </>
  );
};
