import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Spin, Table } from 'antd';
import { MainLayout } from '../../components/Layout';
import CustomNotification from './Customnotification';
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default class Notifications extends React.Component {
  state = {
    loading: true,
    topics: [],
    current: 1,
    total: 0,

    currentPage: 0,
    limit: 10,
    prev: [],
    next: 0,
  };

  async handlePagination(v) {
    if (v !== undefined) {
      this.setState({ limit: v.pageSize });
    }
    this.setState({ loading: true });
    let first = db
      .collection('topics')
      .orderBy('title')
      .limit(this.state.limit);

    if (v !== undefined && v.current < this.state.current) {
      this.setState({ current: v.current });
      first = this.state.prev[this.state.prev.length - 1];
      let arr = this.state.prev;
      arr.pop();
      this.setState({ prev: arr });
    } else if (v !== undefined && v.current > this.state.current) {
      this.setState({ isNext: true });
      this.setState({ current: v.current });
      first = this.state.next;
      const arr = this.state.prev;
      arr.push(this.state.currentPage);
      await this.setState({
        prev: arr,
      });
    }

    const data = [];

    const snapshot = await first.get();

    const last = snapshot.docs[snapshot.docs.length - 1];
    let iterable = await first.get();
    this.setState({ currentPage: first });
    const next = db
      .collection('topics')
      .orderBy('title')
      .startAfter(last)
      .limit(this.state.limit);
    this.setState({ next: next });
    //

    for (let doc of iterable.docs) {
      const topic = doc.data();
      if (
        topic &&
        typeof topic === 'object' &&
        Object.keys(topic).length &&
        topic.id
      ) {
        data.push(topic);
        topic.users = [];
        const users = await db
          .collection('topics')
          .doc(topic.id)
          .collection('users')
          .get();
        for (let doc of users.docs) {
          const user = doc.data();
          topic.users.push(user);
          user.listings = [];
          const listings = await db
            .collection('topics')
            .doc(topic.id)
            .collection('users')
            .doc(user.id)
            .collection('listings')
            .get();
          for (let doc of listings.docs) {
            const listing = doc.data();
            user.listings.push(listing);
          }
        }
      }
    }
    const dataGen = data.map((topic) => {
      return {
        key: topic.id,
        title: topic.title,
        users: topic.users.length,
        listings: topic.users
          .map((user) => user.listings)
          .map((listings) => listings.length)
          .reduce((sum, count) => sum + count, 0),
        readed: topic.users
          .map((user) => user.listings)
          .map(
            (listings) => listings.filter((listing) => listing.isReaded).length
          )
          .reduce((sum, count) => sum + count, 0),
      };
    });

    await this.setState({ topics: dataGen });
    this.setState({ loading: false });
    return dataGen;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.handlePagination();
    let size = 0;
    db.collection('topics')
      .get()
      .then(async (snap) => {
        size = snap.size;
        await this.setState({ total: size });
        // will return the collection size
      });
    this.setState({ loading: false });
  }

  render() {
    const columns = [
      {
        title: 'Topic',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Users',
        dataIndex: 'users',
        key: 'users',
      },
      {
        title: 'Listings',
        dataIndex: 'listings',
        key: 'listings',
      },
      {
        title: 'Readed',
        dataIndex: 'readed',
        key: 'readed',
      },
    ];
    function itemRender(current, type, originalElement) {
      if (type === 'prev') {
        return <a>Previous</a>;
      }
      if (type === 'next') {
        return <a>Next</a>;
      }
      return originalElement;
    }
    return (
      <MainLayout title="Notifications">
        <CustomNotification props={this.props} />
        {this.state.loading ? (
          <Spin>loading</Spin>
        ) : (
          <Table
            dataSource={this.state.topics}
            columns={columns}
            onChange={this.handlePagination.bind(this)}
            loading={this.state.loading}
            pagination={{
              simple: true,
              showQuickJumper: false,
              pageSizeOptions: [10, 50, 100],
              current: this.state.current,
              total: this.state.total,
              itemRender: itemRender,
            }}
          />
        )}
      </MainLayout>
    );
  }
}
