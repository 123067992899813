import React from 'react';
import { Input, Button, Col, Form, Modal, message } from 'antd';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../queries';
////send to one chosen user, send to all in the list - task // issue - unable to fullfill js promise when querying users
class CustomNotification extends React.Component {
  state = {
    testUsers: [
      'ck73jmzv320ku0954aofjgi9t',
      'ck52j4gyacg2y0842dqxc7uet',
      'cjt05k3kj0000aap4fbtp9pay',
      'cjt4n4g5f00f90847auidweic',
    ],
    testUser: { id: 'cjt05k3kj0000aap4fbtp9pay', name: 'نضال نواصرة' },
    testUserValues: [],
    testUserField: '',
    active: false,
    inputValue: '',
    headingsValue: '',
    SubMessageValue: '',
    dataIdValue: '',
    dataUrlValue: '',
    recieverIdValue: 0,
    visible: false,
    type: 'NEW_AD',
    imageValue: '',
    images: [
      'https://is5-ssl.mzstatic.com/image/thumb/Purple124/v4/78/61/c0/7861c0f4-8700-3064-dadc-d28b76137ec7/source/512x512bb.jpg',
    ],
    imageUrl:
      'https://is5-ssl.mzstatic.com/image/thumb/Purple124/v4/78/61/c0/7861c0f4-8700-3064-dadc-d28b76137ec7/source/512x512bb.jpg',
  };
  //make image uploading possible in this modal and make topic based notifications
  async checkComponent() {
    await this.setState({
      ...this.state,
      props: true,
      recieverIdValue: this.props.data.user,
      type: 'NEW_AD',
      dataIdValue: this.props.data.listing,
      dataUrlValue: `/viewAd/${this.props.data.listing}`,
    });
  }
  async componentDidMount() {
    const { token } = this.props.props;
    Amplify.configure({
      API: {
        graphql_headers: () => {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    });
    await this.AddMainImage();
    await this.checkComponent();
    await this.handleUserNames();
  }
  async AddMainImage() {
    const arr = this.state.images;
    const check =
      this.props.data.images.find((image) => image.main === true) !== undefined;
    if (check === true) {
      const url = `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/${
        this.props.data.s3
      }/${
        this.props.data.images.find((image) => image.main === true).fileName
      }`;
      arr.push(url);
      await this.setState({ imageUrl: url });
    }
    this.setState({ images: arr });
  }
  handleMessage(v) {
    this.setState({ inputValue: v.target.value });
  }
  handleHeadings(v) {
    this.setState({ headingsValue: v.target.value });
  }

  handleSubMessage(v) {
    this.setState({ SubMessageValue: v.target.value });
  }
  handleDataId(v) {
    this.setState({ dataIdValue: v.target.value });
  }
  handleDataUrl(v) {
    this.setState({ dataUrlValue: v.target.value });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleType(v) {
    this.setState({ type: v.target.value });
  }
  async handleClick() {
    message.warning(
      'This feature has been frozen by the Developer, Contact the Development Department'
    );
    return;
    /*
            senduser params

            reserver_id = id for recieving user *required
            data_senderId = id for sending user ^ * required when notification is a user profile // if sender is an admin send "ADMIN" as ID
            data_adId = main id for either listing or a user profile
            headings = main title for the push notification *required when missing take content
            url = https//khareta.com/properties/"add.url" // website url 
            big_picture = main image of listing for push notification // outside app
            data_url = /viewAd/"listing id" // app url
            data_sub_message_in_app = example "click here" * required
            contents = notification main message *required
            data_notification_type = “NEW_AD”,
                                      “REJECT_AD”,
                                      “APROVED_AD”,
                                      “REACHED_100_AD”,
                                      “REACHED_1000_AD”, * required
            data_in_app_img = display the main img in app notification panel * required if missing take big_picture in its place too.
            */
    const Message = this.state.inputValue;
    const header = this.state.headingsValue;
    const subMessage = this.state.SubMessageValue;
    const dataID = this.state.dataIdValue;
    // const author = this.props.author;
    const dataUrl = this.state.dataUrlValue;
    const recID = this.state.recieverIdValue;
    // const input_url = this.state.inputUrl;

    const reserver_id = recID;
    const data_senderId = 'ADMIN';
    const data_adId = dataID; //input;
    const headings = header;

    const data_url = dataUrl; //

    const type = this.state.type;
    const big_picture = this.state.imageUrl;

    const data_sub_message_in_app = subMessage;
    const contents = Message;
    const data_notification_type = type;
    const data_in_app_img = big_picture;
    const insideAppLink = `${
      data_adId.length > 3 ? `&data_adId=${data_adId}` : ``
    }`;
    const check =
      data_sub_message_in_app.length > 3 &&
      contents.length > 3 &&
      reserver_id.length > 10 &&
      headings.length > 3;
    if (check === true) {
      fetch(
        `${
          `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/senduser?resever_id=${reserver_id}&data_senderId=${data_senderId}` +
          insideAppLink +
          `&headings=${headings}` +
          // `&url=${url}` +
          `&big_picture=${big_picture}` +
          `${data_url.length > 3 ? `&data_url=${data_url}` : ``}` +
          `&data_sub_message_in_app=${data_sub_message_in_app}&contents=${contents}&data_notification_type=${data_notification_type}&data_in_app_img=${data_in_app_img}`
        }`
      ).then((res) => console.log(res));
      message.success('Success');
    } else {
      message.error(
        `Make sure required fields are filled : ${
          check === false
            ? ' red stared fields least word length is 4 letters'
            : ''
        }`
      );
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleClicks = (v) => {
    const current = v.key;
    this.setState({ type: current });
  };

  handleImage(v) {
    this.setState({ imageUrl: v });
  }
  handleImageChoice() {
    const Images = this.state.images;
    Images.push(this.state.imageValue);
    this.setState({ images: Images });
  }

  handleImageField(v) {
    this.setState({ imageValue: v.target.value });
  }

  async handleTestClick() {
    const userIds = this.state.testUsers;
    for (let i = 0; i < userIds.length; i++) {
      await this.setState({ recieverIdValue: userIds[i] });
      await this.handleClick();
    }
  }
  async handleTestUserSend() {
    const userIds = this.state.testUser.id;
    await this.setState({ recieverIdValue: userIds });
    await this.handleClick();
  }

  handleUserField(v) {
    this.setState({ testUserField: v.target.value });
  }
  handleTestUser(v) {
    this.setState({ testUser: v });
  }
  async handleUserChoice() {
    const users = this.state.testUsers;
    const found = this.simpleSearch(this.state.testUserField, users);
    if (found !== true) {
      users.push(this.state.testUserField);
    }
    await this.setState({ testUsers: users });
    this.queryUsers(this.state.testUsers[this.state.testUsers.length - 1]); //////
  }

  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].data.users[0].id === nameKey) {
        return true;
      }
    }
  }
  simpleSearch(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i] === nameKey) {
        return true;
      }
    }
  }
  queryUsers(v) {
    API.graphql(
      graphqlOperation(Admin.User.GET_USERS_NAMES, {
        where: {
          id: { equals: v },
        },
      })
    ).then((data) => {
      if (data.data.users.length > 0) {
        const UsersArr = this.state.testUserValues;
        const found = this.search(data.data.users[0].id, UsersArr);
        if (found !== true) {
          UsersArr.push(data);
        }
        this.setState({ testUserValues: UsersArr });
      }
      return data;
    });
  }

  async handleUserNames() {
    const arr = this.state.testUsers;
    for (let i = 0; i < arr.length; i++) {
      this.queryUsers(arr[i]);
    }
  }
  render() {
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <div>
        {/* <div style={{ textAlign: 'center', marginTop: 15 }}>
          <Button type="primary" onClick={this.showModal}>
            Custom Message
          </Button>
        </div> */}

        <Modal
          width={600}
          title="Basic Modal"
          onCancel={this.handleCancel}
          visible={this.state.visible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              عودة
            </Button>,
          ]}
        >
          <Col>
            <h3 style={{ color: 'green', textAlign: 'center' }}>
              Send Custom Messages
            </h3>
            <Form {...layout} name="basic">
              <Form.Item
                label="Heading"
                name="Heading"
                rules={[{ required: true, message: 'Heading' }]}
              >
                <Input onChange={this.handleHeadings.bind(this)} />
              </Form.Item>
              <Form.Item
                label="App Text"
                name="Sub Message"
                rules={[{ required: true, message: 'Sub Message' }]}
              >
                <Input.TextArea onChange={this.handleSubMessage.bind(this)} />
              </Form.Item>
              <Form.Item
                label="Push Text"
                name="Message"
                rules={[{ required: true, message: 'Message' }]}
              >
                <Input.TextArea
                  value={''}
                  onChange={this.handleMessage.bind(this)}
                />
              </Form.Item>

              <div>
                <div style={{ display: 'inline' }}>
                  <Form.Item
                    label="Add an Image"
                    name="Add an Image"
                    rules={[{ message: 'Add an Image' }]}
                  >
                    <Input
                      style={{ display: 'inline', width: '75%' }}
                      onChange={this.handleImageField.bind(this)}
                    />
                    <Button
                      style={{ display: 'inline' }}
                      onClick={this.handleImageChoice.bind(this)}
                    >
                      Add Image
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>

            <div style={{ textAlign: 'center' }}>
              {this.state.images.map((e) => (
                <img
                  style={
                    this.state.imageUrl === e
                      ? { border: '10px solid green', margin: 10 }
                      : { border: '10px solid grey', margin: 10, width: 100 }
                  }
                  key={e}
                  alt=""
                  width={100}
                  onClick={() => this.handleImage(e)}
                  src={e}
                />
              ))}
            </div>
            <div style={{ marginBottom: 20, textAlign: 'center' }}>
              <Button
                type="primary"
                disabled={this.state.active}
                onClick={async (e) => {
                  await this.setState({
                    recieverIdValue: this.props.data.user,
                  });
                  this.handleClick(e);
                  await this.setState({ active: true });

                  setTimeout(() => {
                    this.setState({ active: false });
                  }, 3000);
                }}
              >
                Send
              </Button>
            </div>
            <div>
              <div style={{ textAlign: 'center' }}>
                <h3>Test Users Block</h3>
              </div>

              <div>
                <div style={{ display: 'inline', textAlign: 'center' }}>
                  <Form {...layout} name="basic">
                    <Form.Item
                      label="Test User"
                      name="Test User"
                      rules={[{ message: 'Test User' }]}
                    >
                      <Input
                        style={{ display: 'inline', width: '60%' }}
                        onChange={this.handleUserField.bind(this)}
                      />
                      <Button
                        style={{ display: 'inline' }}
                        onClick={this.handleUserChoice.bind(this)}
                      >
                        Add a test user
                      </Button>
                    </Form.Item>
                  </Form>
                </div>

                <div style={{ display: 'flex', textAlign: 'center' }}>
                  {this.state.testUserValues.map((user) =>
                    user.data.users.length > 0 ? (
                      <div
                        onClick={() => this.handleTestUser(user.data.users[0])}
                        key={user.data.users[0].id}
                        style={{
                          flex: 1,
                          width: '18%',
                          border: '1px solid grey',
                          margin: 3,
                          textAlign: 'center',
                        }}
                      >
                        <h3>{user.data.users[0].name}</h3>
                      </div>
                    ) : (
                      <div
                        style={{
                          flex: 1,
                          width: '18%',
                          border: '1px solid grey',
                          margin: 3,
                        }}
                      >
                        `User Doesn't Exist`
                      </div>
                    )
                  )}
                </div>
                <br></br>
                <div style={{ textAlign: 'center' }}>
                  Picked user : {this.state.testUser.name}
                </div>
                <br></br>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button onClick={this.handleTestUserSend.bind(this)}>
                  Send to Picked Test User
                </Button>
                <Button
                  type="primary"
                  disabled={this.state.active1}
                  onClick={async (e) => {
                    this.handleTestClick(e);
                    await this.setState({ active1: true });

                    setTimeout(() => {
                      this.setState({ active1: false });
                    }, 3000);
                  }}
                >
                  Send to test users
                </Button>
              </div>
            </div>
          </Col>
        </Modal>
      </div>
    );
  }
}
export default CustomNotification;
