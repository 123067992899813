import React, { useEffect, useState } from 'react';
import { Table, Row, Switch, Popconfirm } from 'antd';
import * as moment from 'moment';
import { MainLayout } from '../../components/Layout';
import CreatePaymentMethod from './modals/create-payment-method.modal';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

const PAYMENT_METHODS_TYPES = {
  HYPER_PAY: 'Hyperpay',
};

let client = null;

export default function PaymentMethods(props) {
  const [isLoading, setLoading] = useState(false);
  const [PaymentMethods, setPaymentMethods] = useState([]);
  const [isDisabledCreate, setIsDisableCreate] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState({});
  const [paymentMethodStatusValue, setPaymentMethodStatusValue] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });

  const getPaymentMethods = async ({
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination,
  }) => {
    try {
      setLoading(true);
      const paymentMethodsResponse = await client.query({
        query: Admin.PaymentMethod.GET_PAYMENT_METHODS,
        variables: {
          where,
          orderBy,
          skip,
          take,
        },
      });

      const paymentMethodsCount = await client.query({
        query: Admin.PaymentMethod.GET_PAYMENT_METHODS_COUNT,
        variables: {
          where,
        },
      });

      setPagination({
        ...newPagination,
        total: paymentMethodsCount.data.paymentMethodsConnection._count.id,
      });

      setPaymentMethods(paymentMethodsResponse.data.paymentMethods);
      const activeCount = paymentMethodsResponse.data.paymentMethods.filter(
        (pm) => pm.isActive
      ).length;
      if (activeCount > 0) {
        setIsDisableCreate(true);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: PaymentMethods.js ~ line 63 ~ getPaymentMethods ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getPaymentMethods({ where, orderBy, skip, newPagination });
  };

  const updatePaymentMethodStatus = async ({ id, value }) => {
    try {
      await client.query({
        query: Admin.PaymentMethod.UPDATE_PAYMENT_METHOD,
        variables: {
          where: {
            id,
          },
          data: {
            isActive: { set: value },
          },
        },
      });
      getPaymentMethods({});
    } catch (error) {
      console.log(
        '🚀 ~ file: payment-methods.js ~ line 60 ~ onChange={ ~ error',
        error
      );
    }
  };

  const getConfirmMessage = () => {
    return (
      <>
        <p>
          Please be aware that disabling the active method could lead to
          unexpected errors (especially on mobile).
        </p>
        <p>
          <b>
            This is not meant to be the way to disable payment on the system; it
            just disables payment methods
          </b>
        </p>
      </>
    );
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getPaymentMethods({});
  }, []);
  return (
    <MainLayout title="PaymentMethods">
      {!isDisabledCreate && (
        <Row>
          <CreatePaymentMethod
            getPaymentMethods={() => getPaymentMethods({})}
            {...props}
          ></CreatePaymentMethod>
        </Row>
      )}
      <Table
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => PAYMENT_METHODS_TYPES[type],
            sorter: (a, b) => a.type.length - b.type.length,
            filters: [
              {
                text: 'Hyperpay',
                value: 'HYPER_PAY',
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.type.indexOf(value) === 0,
          },
          {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive, row) => (
              <Popconfirm
                title={getConfirmMessage()}
                visible={isVisibleConfirm[row.id]}
                onConfirm={async () => {
                  try {
                    await updatePaymentMethodStatus({
                      id: row.id,
                      value: paymentMethodStatusValue[row.id],
                    });
                    setIsVisibleConfirm({
                      ...isVisibleConfirm,
                      [`${row.id}`]: false,
                    });
                  } catch (error) {
                    console.log(
                      '🚀 ~ file: campaigns.js ~ line 238 ~ onConfirm={ ~ error',
                      error
                    );
                  }
                }}
                onCancel={() => {
                  setIsVisibleConfirm({
                    ...isVisibleConfirm,
                    [`${row.id}`]: false,
                  });
                }}
                okText={
                  paymentMethodStatusValue[row.id] === true
                    ? 'Activate'
                    : 'Disable Anyway'
                }
                okType={paymentMethodStatusValue[row.id] ? 'primary' : 'danger'}
                cancelText="Cancel"
                placement="rightTop"
              >
                <Switch
                  checked={isActive}
                  checkedChildren="Enabled"
                  unCheckedChildren="Disabled"
                  onChange={async (value) => {
                    try {
                      if (value === true) {
                        setPaymentMethodStatusValue({
                          ...paymentMethodStatusValue,
                          [`${row.id}`]: value,
                        });
                        await updatePaymentMethodStatus({
                          id: row.id,
                          value,
                        });
                      } else {
                        setPaymentMethodStatusValue({
                          ...paymentMethodStatusValue,
                          [`${row.id}`]: value,
                        });
                        setIsVisibleConfirm({
                          ...isVisibleConfirm,
                          [`${row.id}`]: true,
                        });
                      }
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: campaigns.js ~ line 242 ~ onChange={ ~ error',
                        error
                      );
                    }
                  }}
                ></Switch>
              </Popconfirm>
            ),
            sorter: (a, b) => a.isActive - b.isActive,
            filters: [
              {
                text: 'Enabled',
                value: true,
              },
              {
                text: 'Disabled',
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.isActive === value,
          },
          {
            title: 'Times Used',
            key: 'timesUsed',
            render: (value, row) => `${row._count.orders} times`,
            sorter: (a, b) => a._count.orders - b._count.orders,
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={PaymentMethods}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
