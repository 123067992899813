import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './store';
import { ConfigProvider } from 'antd';
import en_US from 'antd/lib/locale-provider/en_US';
import { CookiesProvider } from 'react-cookie';

const store = configureStore();

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ConfigProvider locale={en_US}>
        <App />
      </ConfigProvider>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
