import React, { useEffect, useState } from 'react';
import { Table, Row, Switch } from 'antd';
import * as moment from 'moment';
import { MainLayout } from '../../components/Layout';
import CreateBundle from './modals/create-bundle.modal';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

let client = null;

export default function Bundles(props) {
  const [isLoading, setLoading] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });
  const getBundles = async (
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination
  ) => {
    try {
      setLoading(true);
      const bundlesResponse = await client.query({
        query: Admin.Bundle.GET_BUNDLES,
        variables: {
          where,
          orderBy,
          skip,
          take,
        },
      });
      const bundlesCount = await client.query({
        query: Admin.Bundle.GET_BUNDLES_COUNT,
        variables: { where },
      });

      setPagination({
        ...newPagination,
        total: bundlesCount.data.bundlesConnection._count.id,
      });
      setBundles(bundlesResponse.data.bundles);
    } catch (error) {
      console.log(
        '🚀 ~ file: bundles.js ~ line 63 ~ getBundles ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getBundles({ where, orderBy, skip, newPagination });
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getBundles({});
  }, []);
  return (
    <MainLayout title="Bundles">
      <Row>
        <CreateBundle
          getBundles={() => getBundles({})}
          {...props}
        ></CreateBundle>
      </Row>
      <Table
        columns={[
          {
            title: 'Create Limit',
            dataIndex: 'listingsCreateLimit',
            key: 'listingsCreateLimit',
            sorter: (a, b) => a.listingsCreateLimit - b.listingsCreateLimit,
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => `JOD${price}`,
            sorter: (a, b) => a.price - b.price,
          },
          {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive, row) => (
              <Switch
                checked={isActive}
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={async (value) => {
                  try {
                    setLoading(true);
                    await client.query({
                      query: Admin.Bundle.ENABLE_OR_DISABLE_BUNDLE,
                      variables: {
                        where: {
                          id: row.id,
                        },
                        data: {
                          isActive: { set: value },
                        },
                      },
                    });
                    getBundles({});
                  } catch (error) {
                    console.log(
                      '🚀 ~ file: bundles.js ~ line 93 ~ onChange={ ~ error',
                      error
                    );
                  } finally {
                    setLoading(false);
                  }
                }}
              ></Switch>
            ),
            sorter: (a, b) => a.isActive - b.isActive,
            filters: [
              {
                text: 'Enabled',
                value: true,
              },
              {
                text: 'Disabled',
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.isActive === value,
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={bundles}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
