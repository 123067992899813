import React from 'react';

export default function Age({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000">
          <g>
            <g>
              <g>
                <path
                  d="M13.19 20.733h2.422v-5.34h-2.421v5.34zm3.123 0h2.422v-5.34h-2.422v5.34zm-15.58.031H12.49v-8.827H6.978v3.126c0 .179-.168.33-.367.33H.733v5.371zm18.368.691H.366c-.208 0-.366-.155-.366-.36V9c0-.206.158-.361.366-.361.21 0 .367.155.367.361v5.702h5.512V6.518c0-.205.157-.36.366-.36.21 0 .367.155.367.36v4.728h5.48V9c0-.206.158-.361.366-.361.21 0 .367.155.367.361v5.702h2.421V3.142H3.744c-.21 0-.366-.156-.366-.362s.157-.361.366-.361H15.58V.36c0-.206.158-.361.367-.361s.366.155.366.361V2.42h2.756c.21 0 .366.155.366.361s-.157.362-.366.362h-2.756v11.56h2.422v-3.126c0-.206.157-.361.366-.361.177 0 .338.16.366.366v9.513c0 .206-.157.361-.366.361z"
                  transform="translate(-317 -291) translate(0 280.537) translate(317 10.866) translate(1.1)"
                />
                <path
                  d="M3.744 19.068h-.701c-.209 0-.367-.155-.367-.361v-1.163c0-.205.158-.36.367-.36h.7c.21 0 .367.155.367.36v1.163c0 .206-.157.361-.366.361M10.052 19.036h-.7c-.21 0-.367-.155-.367-.36v-1.163c0-.206.157-.36.366-.36h.701c.21 0 .367.155.367.36v1.162c0 .206-.158.361-.367.361M10.052 15.581h-.7c-.21 0-.367-.155-.367-.36v-1.164c0-.205.157-.36.366-.36h.701c.21 0 .367.155.367.36v1.163c0 .206-.158.361-.367.361M6.898 19.068h-.7c-.21 0-.367-.155-.367-.361v-1.163c0-.205.157-.36.366-.36h.701c.209 0 .367.155.367.36v1.163c0 .206-.158.361-.367.361"
                  transform="translate(-317 -291) translate(0 280.537) translate(317 10.866) translate(1.1)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
