import React from 'react';
import { Input, Button, Col, Form, Modal, message, Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Amplify from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../queries';
//////validation and field selection based on initial input,all topics send//current tasks
class CustomNotification extends React.Component {
  state = {
    active: false,
    active1: false,
    inputValue: '',
    headingsValue: '',

    SubMessageValue: '',
    dataIdValue: '',
    url: 'https://khareta.com',
    dataUrlValue: '',
    visible: false,
    types: ['NEW_AD', 'USER_PAGE', 'OTHER'],
    pushlinks: ['/user_page/edit/', '/search/', '/profile', '/notifications'],
    topics: ['TOPIC', 'ALL'],
    topicslist: ['zaid'],
    topiclistitem: 'zaid',
    topic: 'ALL',
    topicField: '',
    typeField: '',
    type: 'NEW_AD',
    imageValue: '',
    images: [
      'https://is5-ssl.mzstatic.com/image/thumb/Purple124/v4/78/61/c0/7861c0f4-8700-3064-dadc-d28b76137ec7/source/512x512bb.jpg',
      '',
    ],
    imageUrl:
      'https://is5-ssl.mzstatic.com/image/thumb/Purple124/v4/78/61/c0/7861c0f4-8700-3064-dadc-d28b76137ec7/source/512x512bb.jpg',
  };
  async componentDidMount() {
    const { token } = this.props.props;

    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
  }
  handleMessage(v) {
    this.setState({ inputValue: v.target.value });
  }
  handleHeadings(v) {
    this.setState({ headingsValue: v.target.value });
  }

  handleSubMessage(v) {
    this.setState({ SubMessageValue: v.target.value });
  }
  handleDataId(v) {
    if (this.state.type === 'NEW_AD') {
      this.queryListings(v.target.value);
    }
    this.setState({ dataIdValue: v.target.value });
  }
  handleDataUrl(v) {
    this.setState({ dataUrlValue: v.target.value });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  handleType(v) {
    this.setState({ type: v.target.value });
  }

  queryListings(v) {
    API.graphql(
      graphqlOperation(Admin.Listing.GET_LISTING_IMAGES_AND_URL_AND_S3PREFIX, {
        where: {
          id: v,
        },
      })
    ).then(async (data) => {
      if (data.data.listing !== undefined) {
        const arr = this.state.images;
        const checks =
          data.data.listing.images.find((image) => image.main === true) !==
          undefined;
        if (checks === true) {
          const url = `${process.env.REACT_APP_IMAGES_BASE_URL}/full/${
            data.data.listing.s3Prefix
          }/${
            data.data.listing.images.find((image) => image.main === true)
              .fileName
          }`;
          arr.push(url);
          await this.setState({ imageUrl: url });
        }
        this.setState({ images: arr });
      }
      return data;
    });
  }

  async handleClick() {
    message.warning(
      'This feature has been frozen by the Developer, Contact the Development Department'
    );
    return;
    /*
            senduser params

            reserver_id = id for recieving user *required
            data_senderId = id for sending user ^ * required when notification is a user profile // if sender is an admin send "ADMIN" as ID
            data_adId = main id for either listing or a user profile
            headings = main title for the push notification *required when missing take content
            url = https//khareta.com/properties/"add.url" // website url 
            big_picture = main image of listing for push notification // outside app
            data_url = /viewAd/"listing id" // app url
            data_sub_message_in_app = example "click here" * required
            contents = notification main message *required
            data_notification_type = “NEW_AD”,
                                      “REJECT_AD”,
                                      “APROVED_AD”,
                                      “REACHED_100_AD”,
                                      “REACHED_1000_AD”, * required
            data_in_app_img = display the main img in app notification panel * required if missing take big_picture in its place too.
            */
    const Message = this.state.inputValue;
    const header = this.state.headingsValue;
    const subMessage = this.state.SubMessageValue;
    const dataID = this.state.dataIdValue;
    // const author = this.props.author;
    const dataUrl = this.state.dataUrlValue;
    // const input_url = this.state.inputUrl;

    const data_adId = dataID; //input;
    const headings = header;

    const data_url = dataUrl; //
    const url = this.state.url;

    const type = this.state.type;
    const big_picture = this.state.imageUrl;
    const fillDataId =
      type === 'NEW_AD'
        ? `/viewAd/${data_adId}`
        : type === 'USER_PAGE'
        ? `/user_page/profile_preview/${data_adId}`
        : data_url;

    const data_sub_message_in_app = subMessage;
    const contents = Message;
    const data_notification_type = type;
    const data_in_app_img = big_picture;
    const insideAppLink = `${
      data_adId.length > 3 ? `&data_adId=${data_adId}` : ``
    }`;
    const topic = this.state.topic;
    const all = topic === 'ALL' ? true : false;
    const topictype = all === true ? 'ALL' : 'TOPIC';
    const topicname = topictype === 'TOPIC' && this.state.topiclistitem;
    const check =
      data_sub_message_in_app.length > 3 &&
      contents.length > 3 &&
      headings.length > 3;
    const check2 =
      this.state.type === 'NEW_AD' || this.state.type === 'USER_PAGE'
        ? data_adId.length > 8
        : true;
    ///add check for push link
    if (check === true && check2 === true) {
      fetch(
        `${
          `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/send?&type=${topictype}` +
          (topictype === 'TOPIC' ? `&topic=${topicname}` : ``) +
          insideAppLink +
          `&headings=${headings}` +
          `&url=${url}` +
          `&big_picture=${big_picture}` +
          `${data_url.length > 3 ? `&data_url=${fillDataId}` : ``}` +
          `&data_sub_message_in_app=${data_sub_message_in_app}&contents=${contents}&data_notification_type=${data_notification_type}&data_in_app_img=${data_in_app_img}`
        }`
      ).then((res) => console.log(res));
      message.success('Success');
    } else {
      message.error(
        `Make sure required fields are filled :${
          check2 === false ? ' Data ID ,' : ''
        } ${check === false ? ' red stared fields' : ''} `
      );
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleClicks = (v) => {
    const current = v.key;
    this.setState({ type: current });
  };
  handleTopics = (v) => {
    const current = v.key;
    this.setState({ topic: current });
  };
  handleTopicsList(v) {
    const current = v.key;
    this.setState({ topiclistitem: current });
  }
  handleImage(v) {
    this.setState({ imageUrl: v });
  }
  handleImageChoice() {
    const Images = this.state.images;
    Images.push(this.state.imageValue);
    this.setState({ images: Images });
  }

  handleImageField(v) {
    this.setState({ imageValue: v.target.value });
  }

  handleTypeChoice() {
    const types = this.state.types;
    types.push(this.state.typeField);
    this.setState({ types: types });
  }
  handleTopicChoice() {
    const topics = this.state.topicslist;
    topics.push(this.state.topicField);
    this.setState({ topicslist: topics });
    this.setState({ topiclistitem: this.state.topicField });
  }
  handleTypeField(v) {
    this.setState({ typeField: v.target.value });
  }
  handleTopicField(v) {
    this.setState({ topicField: v.target.value });
  }
  handleUrl(v) {
    this.setState({ Url: v.target.value });
  }
  handleDataUrlChoice() {
    const links = this.state.pushlinks;
    links.push(this.state.dataUrlValue);
    this.setState({ pushlinks: links });
  }
  handleLinks = (v) => {
    const current = v.key;
    this.setState({ dataUrlValue: current });
  };
  render() {
    const PushMenu = (props) => {
      return (
        <Menu onClick={this.handleLinks.bind(this)}>
          {this.state.pushlinks.map((link) => (
            <Menu.Item {...props} key={link} value={link}>
              {link}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    const pushmenu = <PushMenu />;

    const TypeMenu = (props) => {
      return (
        <Menu onClick={this.handleClicks.bind(this)}>
          {this.state.types.map((type) => (
            <Menu.Item {...props} key={type} value={type}>
              {type}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    const TopicMenu = (props) => {
      return (
        <Menu onClick={this.handleTopics.bind(this)}>
          {this.state.topics.map((topic) => (
            <Menu.Item {...props} key={topic} value={topic}>
              {topic}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    const TopicListMenu = (props) => {
      return (
        <Menu onClick={this.handleTopicsList.bind(this)}>
          {this.state.topicslist.map((topic) => (
            <Menu.Item {...props} key={topic} value={topic}>
              {topic}
            </Menu.Item>
          ))}
        </Menu>
      );
    };
    const topiclistmenu = <TopicListMenu />;
    const topicmenu = <TopicMenu />;
    const menu = <TypeMenu />;
    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
    };

    return (
      <div>
        {/* <Button type="primary" onClick={this.showModal}>
          Custom Message
        </Button> */}
        <Modal
          width={700}
          title="Basic Modal"
          onCancel={this.handleCancel}
          visible={this.state.visible}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              عودة
            </Button>,
          ]}
        >
          <Col>
            <h3 style={{ color: 'green', textAlign: 'center' }}>
              Send Custom Messages
            </h3>
            <Form {...layout} name="basic">
              <Form.Item
                label="Heading"
                name="Heading"
                rules={[{ required: true, message: 'Heading' }]}
              >
                <Input onChange={this.handleHeadings.bind(this)} />
              </Form.Item>
              <Form.Item
                label="App Text"
                name="Sub Message"
                rules={[{ required: true, message: 'Sub Message' }]}
              >
                <Input.TextArea onChange={this.handleSubMessage.bind(this)} />
              </Form.Item>
              <Form.Item
                label="Push Text"
                name="Message"
                rules={[{ required: true, message: 'Message' }]}
              >
                <Input.TextArea
                  value={''}
                  onChange={this.handleMessage.bind(this)}
                />
              </Form.Item>

              <div>
                <Form.Item
                  style={{ textAlign: 'start' }}
                  label="Type"
                  name="Notification Type"
                  rules={[{ required: true }]}
                >
                  <Input
                    style={{
                      marginLeft: 20,
                      display: 'inline',
                      width: '30%',
                    }}
                    onChange={this.handleTypeField.bind(this)}
                  />
                  <div
                    style={{
                      marginLeft: 20,
                      display: 'inline',
                      width: '30%',
                    }}
                  >
                    <Button
                      style={{ display: 'inline' }}
                      onClick={this.handleTypeChoice.bind(this)}
                    >
                      Add type
                    </Button>
                  </div>
                  <div
                    style={{
                      marginLeft: 20,
                      display: 'inline',
                      width: '30%',
                    }}
                  >
                    <Dropdown overlay={menu} trigger={['click']}>
                      <Button onClick={(e) => e.preventDefault()}>
                        {this.state.type}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </Form.Item>
                <div style={{ textAlign: 'center', marginBottom: 30 }}>
                  <div
                    style={{
                      marginLeft: 20,
                      display: 'inline',
                      width: '30%',
                    }}
                  >
                    <Dropdown overlay={topicmenu} trigger={['click']}>
                      <Button onClick={(e) => e.preventDefault()}>
                        {this.state.topic}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </div>
                {this.state.topic === 'TOPIC' && (
                  <Form.Item
                    style={{ textAlign: 'start' }}
                    label="Topic"
                    name="Notification Topic"
                    rules={[{ required: true }]}
                  >
                    <Input
                      style={{
                        marginLeft: 20,
                        display: 'inline',
                        width: '30%',
                      }}
                      onChange={this.handleTopicField.bind(this)}
                    />
                    <div
                      style={{
                        marginLeft: 20,
                        display: 'inline',
                        width: '30%',
                      }}
                    >
                      <Button
                        style={{ display: 'inline' }}
                        onClick={this.handleTopicChoice.bind(this)}
                      >
                        Add Topic
                      </Button>
                    </div>
                    <div
                      style={{
                        marginLeft: 20,
                        display: 'inline',
                        width: '30%',
                        textAlign: 'center',
                      }}
                    >
                      <Dropdown overlay={topiclistmenu} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                          {this.state.topiclistitem}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  </Form.Item>
                )}

                <Form.Item label="Url" name="Url" rules={[{ message: 'Url' }]}>
                  <Input
                    placeholder="http//khareta.com"
                    onChange={this.handleUrl.bind(this)}
                  />
                </Form.Item>
                {this.state.type !== 'OTHER' && (
                  <Form.Item
                    label="Data ID"
                    name="Data ID"
                    rules={[
                      {
                        required:
                          this.state.type === 'NEW_AD' ||
                          this.state.type === 'USER_PAGE'
                            ? true
                            : false,
                        message: 'Data ID',
                      },
                    ]}
                  >
                    <Input onChange={this.handleDataId.bind(this)} />
                  </Form.Item>
                )}
                {!(
                  this.state.type === 'NEW_AD' ||
                  this.state.type === 'USER_PAGE'
                ) && (
                  <div style={{ display: 'inline' }}>
                    <Form.Item
                      label="Push Link"
                      name="Data Url"
                      rules={[{ message: 'Data Url' }]}
                    >
                      <Input
                        placeholder={this.state.dataUrl}
                        style={{ display: 'inline', width: '40%' }}
                        value={this.state.dataUrlValue}
                        onChange={this.handleDataUrl.bind(this)}
                      />
                      <Button
                        style={{ display: 'inline' }}
                        onClick={this.handleDataUrlChoice.bind(this)}
                      >
                        Add Push Link
                      </Button>
                      <Dropdown overlay={pushmenu} trigger={['click']}>
                        <Button onClick={(e) => e.preventDefault()}>
                          {this.state.dataUrlValue}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Form.Item>
                  </div>
                )}
                <div style={{ display: 'inline' }}>
                  <Form.Item
                    label="Add an Image"
                    name="Add an Image"
                    rules={[{ message: 'Add an Image' }]}
                  >
                    <Input
                      style={{ display: 'inline', width: '55%' }}
                      onChange={this.handleImageField.bind(this)}
                    />
                    <Button
                      style={{ display: 'inline' }}
                      onClick={this.handleImageChoice.bind(this)}
                    >
                      Add Image
                    </Button>
                    <Button
                      style={{ display: 'inline' }}
                      onClick={() => this.setState({ imageUrl: '' })}
                    >
                      Empty Image
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>

            <div style={{ textAlign: 'center' }}>
              {this.state.images.map((e) => (
                <img
                  style={
                    this.state.imageUrl === e
                      ? { border: '10px solid green', margin: 10 }
                      : { border: '10px solid grey', margin: 10, width: 100 }
                  }
                  key={e}
                  alt=""
                  width={100}
                  onClick={() => this.handleImage(e)}
                  src={e}
                />
              ))}
            </div>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <div style={{ marginBottom: 20 }}>
                <Button
                  type="primary"
                  disabled={this.state.active}
                  onClick={async (e) => {
                    this.handleClick(e);
                    await this.setState({ active: true });

                    setTimeout(() => {
                      this.setState({ active: false });
                    }, 3000);
                  }}
                >
                  Send
                </Button>
              </div>
              <div style={{ marginBottom: 20 }}>
                <Button
                  type="primary"
                  disabled={this.state.active}
                  onClick={async (e) => {
                    await this.setState({
                      topic: 'TOPIC',
                      topiclistitem: 'zaid',
                    });
                    this.handleClick(e);
                    await this.setState({ active: true });

                    setTimeout(() => {
                      this.setState({ active: false });
                    }, 3000);
                  }}
                >
                  Send to Test Topic
                </Button>
              </div>
            </div>
          </Col>
        </Modal>
      </div>
    );
  }
}
export default CustomNotification;
