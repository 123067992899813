import React, { useState, useEffect } from 'react';
import Highlighter from 'react-highlight-words';
import { MainLayout } from '../../components/Layout';
import {
  Tabs,
  Table,
  Spin,
  Row,
  Input,
  Button,
  Menu,
  Dropdown,
  message,
  Space,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import Amplify, { API, graphqlOperation } from 'aws-amplify';

import { NewAreaModal } from './AreaModalUpdate';
import { ExpandableRow } from './LocationViewUpdate';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';
const { TabPane } = Tabs;

const columns = [
  { title: 'City name', dataIndex: 'name', key: 'name' },
  { title: 'Search key', dataIndex: 'searchName', key: 'searchName' },
  { title: 'Key name', dataIndex: 'keyName', key: 'keyName' },
  { title: 'Radius', dataIndex: 'radius', key: 'radius' },
  { title: 'Latitude', dataIndex: 'lat', key: 'lat' },
  { title: 'Longitude', dataIndex: 'lng', key: 'lng' },
];

let client = null;

const NewLocationView = (props) => {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [Tab1Load, setTab1Load] = useState(true);
  const [citiesMenu, setCitiesMenu] = useState([]);
  const [currentCity, setCurrentCity] = useState(null);

  const getCities = async () => {
    const cities = await API.graphql(
      graphqlOperation(Admin.City.GET_V3_CITIES)
    );
    const areas = await API.graphql(graphqlOperation(Admin.Area.GET_V3_AREAS));
    setCities(cities.data.cities);
    setAreas(areas.data.areas);
    setCurrentCity(cities.data.cities.find((e) => e.name === 'عمان'));
    setTab1Load(false);
    const citiesArr = cities.data.cities.map((city) => {
      return { name: city.name, id: city.id, areas: city.areas };
    });
    setCitiesMenu(citiesArr);
  };

  const saveCity = async (args) => {
    const { id, searchName, name, lat, lng, radius, keyName } = args;
    if (!lat || !lng || !radius || !radius || !name || !searchName) {
      message.error('Either click auto fill, or manually enter the values', 3);
      return;
    }
    let variables = {
      data: {
        searchName: { set: searchName },
        name: { set: name },
        lat: { set: parseFloat(lat) },
        lng: { set: parseFloat(lng) },
        radius: { set: parseFloat(radius) },
        keyName: { set: keyName },
        version: { set: 3 },
      },
    };
    if (id) {
      console.log(id, 'id', variables);
      variables = {
        ...variables,
        where: { id },
      };
    }
    try {
      const response = await client.mutate({
        mutation: Admin.City.UPDATE_CITY,
        variables,
      });
      if (response) {
        message.success(`City ${name} has been Updated `);
      }
    } catch (error) {
      console.log(
        '🚀 ~ file: newLocationView.js ~ line 87 ~ saveCity ~ error',
        error
      );
      message.error(
        'problem editing city, have you filled all the fields correctly?'
      );
    }
  };
  const saveArea = async (args) => {
    const {
      id,
      searchName,
      name,
      lat,
      lng,
      radius,
      keyName,
      city,
      avgLandPrice,
      avgLandSD,
      zoom,
    } = args;
    if (!lat || !lng || !radius || !radius || !name || !searchName) {
      message.error('Either click auto fill, or manually enter the values', 3);
      return;
    }
    let variables = {
      data: {
        searchName: { set: searchName },
        name: { set: name },
        lat: { set: parseFloat(lat) },
        lng: { set: parseFloat(lng) },
        radius: { set: parseFloat(radius) },
        keyName: { set: keyName },
        version: { set: 3 },
        avgLandPrice: { set: parseFloat(avgLandPrice) },
        avgLandSD: { set: parseFloat(avgLandSD) },
        zoom: {
          set:
            zoom.length > 8 ? parseFloat(zoom.slice(0, 8)) : parseFloat(zoom),
        },
        city: { connect: { id: city.id } },
      },
    };
    if (id) {
      variables = {
        ...variables,
        where: { id },
      };
    }
    try {
      const response = await client.mutate({
        mutation: Admin.Area.UPDATE_AREA,
        variables,
      });
      if (response) {
        console.log(response);
        message.success(`Area ${name} has been Updated for ${city.name} City`);
      }
    } catch {
      message.error(
        'problem editing area, have you filled all the fields correctly?'
      );
    }
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });

    getCities();
  }, []);
  const handleClick = (v) => {
    const current = cities.find((e) => e.id === v.key);
    setCurrentCity(current);
  };
  let searchInput = '';
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const columnsArea = [
    {
      title: 'Area name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
    },
    { title: 'Search key', dataIndex: 'searchName', key: 'searchName' },
    { title: 'Key name', dataIndex: 'keyName', key: 'keyName' },
    { title: 'Radius', dataIndex: 'radius', key: 'radius' },
    { title: 'Latitude', dataIndex: 'lat', key: 'lat' },
    { title: 'Longitude', dataIndex: 'lng', key: 'lng' },
    { title: 'Avg Land Price', dataIndex: 'avgLandPrice', key: 'avgLandPrice' },
    { title: 'Avg Land SD', dataIndex: 'avgLandSD', key: 'avgLandSD' },
    { title: 'Zoom', dataIndex: 'zoom', key: 'zoom' },
  ];
  const MenuX = (props) => {
    return (
      <Menu onClick={handleClick}>
        {citiesMenu.map((item) => (
          <Menu.Item {...props} key={item.id}>
            {item.name}
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const menu = <MenuX />;
  return (
    <MainLayout>
      {Tab1Load ? (
        <Spin>Loading</Spin>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Tabs defaultActiveKey="1" centered style={{ textAlign: 'center' }}>
            <TabPane tab="المدن" key="1">
              {Tab1Load ? (
                <Spin>Loading</Spin>
              ) : (
                <Table
                  columns={columns}
                  expandable={{
                    expandedRowRender: (city) => (
                      <ExpandableRow area={city} callBack={saveCity} />
                    ),
                  }}
                  rowKey="id"
                  pagination={false}
                  dataSource={cities}
                />
              )}
            </TabPane>
            <TabPane tab="المناطق حسب المدن" key="2">
              {Tab1Load ? (
                <Spin>Loading</Spin>
              ) : (
                <Dropdown overlay={menu}>
                  <Button onClick={(e) => e.preventDefault()}>
                    {currentCity.name}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )}
              {currentCity.name !== 'choose city' ? (
                <div>
                  <Table
                    columns={columnsArea}
                    expandable={{
                      expandedRowRender: (area) => (
                        //edit the city
                        <ExpandableRow area={area} callBack={saveArea} />
                      ),
                    }}
                    rowKey="id"
                    pagination={false}
                    dataSource={currentCity.areas}
                  />
                </div>
              ) : (
                <Spin>Please choose city</Spin>
              )}
            </TabPane>
            <TabPane tab="كل المناطق" key="3">
              {Tab1Load ? (
                <Spin>Loading</Spin>
              ) : (
                <div>
                  <Row>
                    <NewAreaModal
                      ButtonText={'Add a new Area'}
                      props={props}
                      cities={cities}
                      client={client}
                    ></NewAreaModal>
                  </Row>
                  <Table
                    columns={columnsArea}
                    expandable={{
                      expandedRowRender: (area) => (
                        //edit the city
                        <ExpandableRow
                          area={area}
                          callBack={saveArea}
                          cities={cities}
                        />
                      ),
                    }}
                    rowKey="id"
                    pagination={{
                      position: ['topLeft', 'bottomLeft'],
                    }}
                    dataSource={areas}
                  />
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>
      )}
    </MainLayout>
  );
};

export default NewLocationView;
