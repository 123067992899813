import React from 'react';
export default function DoubleGlaze({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M1.738 13.767h13.365V.53H1.738v13.237zM15.37 0H1.47c-.148 0-.268.119-.268.265v13.767c0 .146.12.265.267.265h13.9c.148 0 .268-.119.268-.265V.265c0-.146-.12-.265-.267-.265z"
                    transform="translate(-313 -1298) translate(0 1078) translate(183 210) translate(130.828 10) translate(0 .567)"
                  />
                  <path
                    d="M2.807 12.576H7.35V1.72H2.807v10.855zM7.619 1.19h-5.08c-.147 0-.267.119-.267.265v11.385c0 .146.12.264.268.264h5.079c.147 0 .267-.118.267-.264V1.456c0-.146-.12-.265-.267-.265z"
                    transform="translate(-313 -1298) translate(0 1078) translate(183 210) translate(130.828 10) translate(0 .567)"
                  />
                  <path
                    d="M5.747 6.884H7.62c.147 0 .267.118.267.264 0 .146-.12.265-.267.265H5.747c-.147 0-.267-.119-.267-.265 0-.146.12-.264.267-.264M9.222 6.884h1.872c.147 0 .267.118.267.264 0 .146-.12.265-.267.265H9.222c-.147 0-.267-.119-.267-.265 0-.146.12-.264.267-.264"
                    transform="translate(-313 -1298) translate(0 1078) translate(183 210) translate(130.828 10) translate(0 .567)"
                  />
                  <path
                    d="M9.49 12.576h4.544V1.72H9.49v10.855zM14.3 1.19H9.222c-.147 0-.267.119-.267.265v11.385c0 .146.12.264.267.264h5.08c.147 0 .267-.118.267-.264V1.456c0-.146-.12-.265-.268-.265zM.267 15.54h16.574c.148 0 .267.12.267.266 0 .146-.12.264-.267.264H.267c-.147 0-.267-.118-.267-.264 0-.146.12-.265.267-.265"
                    transform="translate(-313 -1298) translate(0 1078) translate(183 210) translate(130.828 10) translate(0 .567)"
                  />
                  <path
                    d="M16.241 14.838l.789.78c.104.104.104.272 0 .375-.104.103-.274.103-.378 0l-.789-.78c-.104-.104-.104-.272 0-.375.105-.104.274-.104.378 0M1.245 15.212l-.789.781c-.104.103-.273.103-.378 0-.104-.103-.104-.271 0-.375l.789-.78c.104-.104.273-.104.378 0 .104.103.104.27 0 .374M11.296 2.659l1.323 1.31c.105.104.105.271 0 .375-.104.103-.273.103-.378 0l-1.323-1.31c-.104-.104-.104-.272 0-.375.105-.104.274-.104.378 0M11.563 4.512l1.324 1.31c.104.104.104.272 0 .375-.105.103-.274.103-.378 0l-1.324-1.31c-.104-.104-.104-.271 0-.375.105-.103.274-.103.378 0M4.079 8.219l1.323 1.31c.104.104.104.271 0 .375-.105.103-.274.103-.378 0L3.7 8.594c-.105-.104-.105-.272 0-.375.104-.104.273-.104.378 0M4.346 10.072l1.323 1.31c.104.104.104.272 0 .375-.104.103-.274.103-.378 0l-1.323-1.31c-.105-.104-.105-.272 0-.375.104-.103.273-.103.378 0"
                    transform="translate(-313 -1298) translate(0 1078) translate(183 210) translate(130.828 10) translate(0 .567)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
