import React from 'react';

export default function Floor({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M1.598 0C.722 0 0 .59 0 1.304v20.428h20.24V1.304C20.24.589 19.518 0 18.642 0H1.598zm0 .87h17.044c.296 0 .533.193.533.434v19.558h-2.13v-5.215h-5.86v5.215H1.065V1.304c0-.241.237-.435.533-.435zm1.598 1.738v3.477h5.859V2.608h-5.86zm7.99 0v3.477h5.858V2.608h-5.859zm-6.925.87H7.99v1.738H4.261V3.477zm7.99 0h3.728v1.738H12.25V3.477zM3.196 6.953v3.477h5.859V6.954h-5.86zm7.99 0v3.477h5.858V6.954h-5.859zm-6.925.87H7.99v1.738H4.261V7.823zm7.99 0h3.728v1.738H12.25V7.823zM3.196 11.3v3.478h5.859V11.3h-5.86zm7.99 0v3.478h5.858V11.3h-5.859zm-6.925.87H7.99v1.738H4.261V12.17zm7.99 0h3.728v1.738H12.25V12.17zm-9.055 3.477v3.477h5.859v-3.477h-5.86zm1.065.87H7.99v1.738H4.261v-1.739zm7.99 0h3.728v4.345H12.25v-4.346z"
                  transform="translate(-317 -204) translate(0 193.61) translate(317 10.866) translate(.88)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
