import React, { PureComponent } from 'react';
import { FlexList, Button } from './Styled';

export default class MultiSwitch extends PureComponent {
  _onClick = (value) => {
    this.props.onClick(value);
  };

  render() {
    const { labels, name, values, width, align, path, icons, selected, ext } =
      this.props;

    return (
      <FlexList>
        {values &&
          values.map((value, i) => {
            let iconPath = null;
            if (Array.isArray(icons)) {
              if (icons.length > 0 && selected === value) {
                iconPath = `${path}/white/${icons[i]}`;
              } else {
                iconPath = `${path}/black/${icons[i]}`;
              }
              iconPath = ext ? iconPath + ext : iconPath;
            }

            return (
              <Button
                key={i}
                active={selected.indexOf(value) > -1}
                name={name}
                value={value}
                onClick={() => this._onClick(value)}
                width={width}
                align={align}
              >
                {iconPath && <img src={iconPath} alt={labels[i]} />}
                <span>{labels[i]}</span>
              </Button>
            );
          })}
      </FlexList>
    );
  }
}
