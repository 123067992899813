import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function AuthRoute({ component: Component, cookies, allCookies, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        const token = cookies.get('token');
        if (token) {
          return <Component {...props} allCookies={allCookies} token={token} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
}

export default AuthRoute;
