import React from 'react';
export default function PlayGround({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M3.984.333c-.075.008-.146.042-.2.094L.482 3.72c-.018.011-.036.026-.052.041-.008.007-.015.013-.021.021-.008.01-.015.021-.021.031-.013.02-.024.04-.032.063-.011.03-.018.061-.02.094-.003.024-.003.048 0 .073v12.625c0 .183.15.333.335.333h1.006c.185 0 .336-.15.336-.333V11h4.025v.667h-3.69v.666h3.69V13h-3.69v.667h3.69v.666h-3.69V15h3.69v.667h-3.69v.666h3.69v.334c0 .183.15.333.336.333H7.38c.185 0 .335-.15.335-.333v-5.948c.002-.017.002-.035 0-.052V4.052c.002-.017.002-.035 0-.052v-.01-.021-.021c-.002-.014-.006-.029-.01-.042-.005-.018-.013-.035-.021-.052l-.01-.01c-.012-.022-.027-.043-.042-.063v-.01l-.01-.01H7.61c-.007-.008-.013-.015-.021-.021L4.267.427C4.192.354 4.087.32 3.984.333zm.041.813l2.537 2.52H1.49l2.536-2.52zM1.006 4.333h6.039v6H1.006v-6zM4.026 5c-1.29 0-2.349 1.052-2.349 2.333 0 1.282 1.06 2.334 2.348 2.334 1.29 0 2.349-1.052 2.349-2.334C6.374 6.053 5.314 5 4.025 5zm0 .667c.923 0 1.677.748 1.677 1.666C5.703 8.251 4.949 9 4.025 9c-.923 0-1.677-.749-1.677-1.667s.754-1.666 1.677-1.666zM8.05 7v.667c1.254 0 1.968.415 2.505 1.052.538.636.872 1.527 1.195 2.458.324.931.641 1.892 1.227 2.656.586.765 1.478 1.302 2.788 1.302.36 0 .671.364.671.76v.407c-1.85-.044-3.128-.375-3.962-.864-.894-.524-1.367-1.202-1.709-1.917-.342-.715-.528-1.453-.87-2.073-.342-.62-.928-1.115-1.845-1.115V11c.71 0 .97.255 1.247.76.278.506.485 1.267.86 2.053.375.785.945 1.606 1.97 2.208 1.027.601 2.492.979 4.645.979.185 0 .335-.15.335-.333v-.771c0-.737-.56-1.427-1.342-1.427-1.138 0-1.766-.406-2.253-1.042-.488-.635-.796-1.532-1.122-2.469-.326-.936-.676-1.912-1.32-2.677C10.424 7.517 9.463 7 8.05 7zm-7.045 4h.336v5.333h-.336V11zm5.703 0h.336v5.333h-.336V11z"
                  transform="translate(-150 -1507) translate(0 1078) translate(20 419) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
