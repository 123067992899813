import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, InputNumber, Switch } from 'antd';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../../queries';
import './create-bundle.css';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function CreateBundle(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    const { token } = props;
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
  }, []);
  return (
    <>
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Create New Bundle
      </Button>
      <Modal
        title="Create New Bundle"
        visible={isModalOpen}
        centered={true}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              try {
                await API.graphql(
                  graphqlOperation(Admin.Bundle.CREATE_BUNDLE, {
                    data: {
                      ...values,
                    },
                  })
                );
                const { getBundles } = props;
                getBundles();
                setModalOpen(false);
              } catch (error) {
                console.log(
                  '🚀 ~ file: create-bundle.modal.js ~ line 62 ~ .then ~ error',
                  error
                );
              }
            })
            .catch((errors) => console.error('errors: ', errors));
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form {...layout} form={form} name="create-bundle">
          <Form.Item
            name="listingsCreateLimit"
            label="Listings Create Limit"
            rules={[
              {
                required: true,
                message: 'Please input create limit!',
              },
            ]}
          >
            <InputNumber size="middle" min={1} max={1000} />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: 'Please input price!',
              },
            ]}
          >
            <InputNumber size="middle" min={1.0} step={1.0} />
          </Form.Item>
          <Form.Item name="isActive" label="Status">
            <div>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={(value) => {
                  form.setFieldsValue({ isActive: value });
                }}
              ></Switch>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
