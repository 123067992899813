import React from 'react';
import { Button, Modal } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import DisApprovalMenu from '../../../components/DisApprovalMenu';
import { Admin } from '../../../queries';

export default class AdRejectEdit extends React.Component {
  state = {
    loading: false,
  };

  adDisapprovalHandler = (listing, data) => {
    const { id } = listing;
    let result = data;
    const { getListingAfterChange } = this.props;
    const callBack = this.props.callBack;
    const message = this.props.message;
    const type = this.props.type;
    const that = this;
    Modal.confirm({
      title: 'هل تريد تعديل اسباب الرفض ؟',
      content: (
        <DisApprovalMenu
          data={data}
          getData={(response) => (result = response)}
          message={message}
          type={type}
        />
      ),
      width: 600,
      onOk() {
        let editedResult = result.types;
        if (editedResult[0] === ',') {
          editedResult = editedResult.slice(1);
        } else if (editedResult[-1] === ',') {
          editedResult = editedResult.slice(0, -1);
        }
        that
          .changeAdDisapprovalReasons({
            variables: {
              where: {
                id,
              },
              data: {
                disapproveType: {
                  set: editedResult,
                },
                disapproveMessage: { set: result.message || '' },
              },
            },
          })
          .then(() => {
            if (getListingAfterChange) {
              getListingAfterChange();
            }
          });
        callBack({
          message: result.message,
          type: editedResult,
          status: 'REJECTED',
        });
      },
      onCancel() {},
    });
  };

  async changeAdDisapprovalReasons({ variables }) {
    this.setState({
      loading: true,
    });
    await API.graphql(
      graphqlOperation(Admin.Listing.CHANGE_LISTING_STATUS, variables)
    );
    this.setState({
      loading: false,
    });
  }

  render() {
    const { listing } = this.props;

    return (
      <Button
        loading={this.state.loading}
        onClick={() => {
          this.adDisapprovalHandler(listing, {
            types: listing.disapproveType,
            message: listing.disapproveMessage || '',
          });
        }}
      >
        عدل اسباب الرفض
      </Button>
    );
  }
}
