import React, { useState } from 'react';
import kharetaLogo from '../../assets/images/logo.png';
import ListingStatusCtrl from '../Users/listingStatusCtrl';
import { MainLayout } from '../../components/Layout';
import InfiniteListExample from './scroll_list';
import {
  Button,
  Row,
  Col,
  Card,
  Spin,
  Tag,
  List,
  message,
  Input,
  Form,
  Tabs,
  Select,
} from 'antd';
import {
  EyeTwoTone,
  EditTwoTone,
  PhoneOutlined,
  FacebookOutlined,
  GoogleOutlined,
  AppleOutlined,
} from '@ant-design/icons';
import { withCookies } from 'react-cookie';
import profilePic from '../../assets/images/profile.jpg';
import { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../queries';

const { Option } = Select;

const categories = [
  { text: 'مطابخ', value: 'KITCHENS' },
  { text: 'مفروشات', value: 'FURNITURE' },
  { text: 'أثاث مكتبي', value: 'OFFICE_FURNITURE' },
  { text: 'كهربائيات منزلية', value: 'ELECTRICAL_APPLIANCES' },
  { text: 'حدائق ومشاتل', value: 'GARDENS_AND_PLANTATIONS' },
  { text: 'صيانة وتشطيبات', value: 'MAINTENANCE_AND_FINISHES' },
  { text: 'ديكورات وتصميم', value: 'DECORATIONS_AND_DESIGN' },
  { text: 'مقاولات', value: 'CONTRACTING' },
  { text: 'هندسة معمارية', value: 'ARCHITECTURAL_ENGINEERING' },
  { text: 'أخرى', value: 'OTHER' },
];

const { Meta } = Card;

class UserProfile extends React.Component {
  state = {
    id: this.props.match.params.id,
    userData: {
      email: 'loading ...',
      name: 'loading ...',
      mobile: 'loading ...',
      about: 'loading ...',
      company: 'loading ...',
      businessPhone: 'loading ...',
      businessAddress: 'loading ...',
      whatsApp: 'loading ...',
      phone: 'loading ...',
    },
    remainingListingsCreateCount: 0,
    data: {},
    selectedListing: [],
    selectAll: true,
    loading: true,
    loading_activ: false,
    loading_pass: false,
    loading_sus: false,
  };

  async componentDidMount() {
    this.getUserById();
  }

  getUserById = async () => {
    API.graphql(
      graphqlOperation(Admin.User.GET_USER_WITH_LISTINGS_BY_IMPRESSION, {
        where: { id: this.state.id },
      })
    )
      .then((userData) => {
        this.setState({
          data: userData.data.userWithListingsByImpression,
        });
        this.setState({
          remainingListingsCreateCount:
            userData.data.userWithListingsByImpression
              .remainingListingsCreateCount,
        });
        this.setState({
          userData: {
            email: userData.data.userWithListingsByImpression.email,
            name: userData.data.userWithListingsByImpression.name,
            mobile: userData.data.userWithListingsByImpression.mobile,
            about: userData.data.userWithListingsByImpression.about,
            company: userData.data.userWithListingsByImpression.company,
            businessPhone:
              userData.data.userWithListingsByImpression.businessPhone,
            businessAddress:
              userData.data.userWithListingsByImpression.businessAddress,
            whatsApp: userData.data.userWithListingsByImpression.whatsApp,
            phone: userData.data.userWithListingsByImpression.phone,
            role: userData.data.userWithListingsByImpression.role,
          },
        });
        this.setState({
          loading: false,
        });
      })
      .catch((e) => {
        this.setState({
          loading: false,
          error: e,
        });
      });
  };

  resetUserPassword = async (variables) => {
    this.setState({
      loading_pass: true,
    });

    API.graphql(graphqlOperation(Admin.Auth.AUTH_RESET_PASSWORD, variables))
      .then(() => {
        this.getUserById();
      })
      .catch((e) => {
        message.error('حدث خطء اثناء تغير كلمة السر');
        console.log(e);
        this.setState({
          loading_pass: false,
        });
      });
  };

  activateUserAccount = async (variables) => {
    this.setState({
      loading_activ: true,
    });

    API.graphql(
      graphqlOperation(Admin.User.CHANGE_USER_STATUS, variables.variables)
    )
      .then(() => {
        message.success('تم تفعيل المستخدم بنجاح');
        this.getUserById();
        this.setState({
          loading_activ: false,
        });
      })
      .catch((e) => {
        message.error('حدث خطء اثناء تفعيل المستخدم ');
        console.log(e);
        this.setState({
          loading_activ: false,
        });
      });
  };

  suspendUser = async (variables) => {
    this.setState({
      loading_sus: true,
    });

    API.graphql(graphqlOperation(Admin.User.SUSPEND_USER, variables.variables))
      .then(() => {
        message.success('تم حظر المستخدم بنجاح');
        this.getUserById();
        this.setState({
          loading_sus: false,
        });
      })
      .catch((e) => {
        message.error('حدث خطء اثناء حظر المستخدم ');
        console.log(e);
        this.setState({
          loading_sus: false,
        });
      });
  };

  deleteUser = async (variables) => {
    API.graphql(graphqlOperation(Admin.User.DELETE_USER, variables.variables))
      .then(() => {
        message.success('تم حذف المستخدم بنجاح');
        this.props.history.push(`/users`);
      })
      .catch((e) => {
        message.error('حدث خطء اثناء حذف المستخدم ');
        console.log(e);
      });
  };

  updateUser = async (variables) => {
    API.graphql(
      graphqlOperation(Admin.User.UPDATE_USER_INFO, variables.variables)
    )
      .then(({ data }) => {
        let obj1 = {
          email: data.updateUser.email,
          name: data.updateUser.name,
          mobile: data.updateUser.mobile,
          about: data.updateUser.about,
          company: data.updateUser.company,
          businessPhone: data.updateUser.businessPhone,
          businessAddress: data.updateUser.businessAddress,
          whatsApp: data.updateUser.whatsApp,
          phone: data.updateUser.phone,
          role: data.updateUser.role,
        };

        let obj2 = { ...this.state.userData };

        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
          message.success('تم تحديث بيانات المستخدم بنجاح');
          this.getUserById();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  itemCard = (item, isCommercial) => {
    const { history } = this.props;
    if (item.status === 'ACTIVE' || item.status === 'ACTIVEUPDATED') {
      return [
        <EditTwoTone
          onClick={() => {
            history.push(
              `/${!isCommercial ? 'listing' : 'commercial-ad'}/edit/${item.id}`
            );
          }}
        />,
        isCommercial ? (
          <></>
        ) : (
          <EyeTwoTone
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_HOST_NAME}/properties/${item.url}`,
                '_blank'
              )
            }
          />
        ),
      ];
    } else {
      return [
        <EditTwoTone
          onClick={() => {
            history.push(
              `/${!isCommercial ? 'listing' : 'commercial-ad'}/edit/${item.id}`
            );
          }}
        />,
      ];
    }
  };
  render() {
    const { TabPane } = Tabs;
    const { data, loading, error } = this.state;
    const profile = { data, loading, error };
    if (profile.loading || this.state.loading) {
      return (
        <Spin size="large" tip="Loading . . ." style={{ margin: '20% 45%' }} />
      );
    }
    if (profile.error) {
      return <div> حدث خطأ </div>;
    }
    if (!profile.data.user) {
      profile.data.user = this.state.data;
    }
    return (
      <MainLayout title="ملف المستخدم">
        <>
          <div>
            <div>
              <Row>
                <Col span={12}>
                  <Row>
                    <Col span={12} offset={6}>
                      <br></br> <br></br>
                      <img
                        style={{ marginBottom: 20 }}
                        alt="example"
                        width="200"
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}/users/${this.state.id}/${profile.data.user.photo}`} //was 150x150
                        onError={(i) => (i.target.src = profilePic)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={15} offset={4}>
                      <br></br> <br></br>
                      <Tag color="green">{profile.data.user.role}</Tag>
                      <Tag color="green">
                        {profile.data.user.createdAt
                          .split('')
                          .splice(0, 10)
                          .join('')}
                      </Tag>
                      <Tag
                        color={
                          profile.data.user.status === 'ACTIVE'
                            ? 'green'
                            : 'red'
                        }
                      >
                        {profile.data.user.status}
                      </Tag>
                      <Tag
                        color={
                          profile.data.user.mobileVerificationCode
                            ? 'green'
                            : 'red'
                        }
                      >
                        {profile.data.user.mobileVerificationCode || 'NO VCODE'}
                      </Tag>
                      <Tag color="green">
                        {profile.data.user.facebookId ? (
                          <FacebookOutlined />
                        ) : profile.data.user.googleId ? (
                          <GoogleOutlined />
                        ) : profile.data.user.appleId ? (
                          <AppleOutlined />
                        ) : (
                          <PhoneOutlined />
                        )}
                      </Tag>
                    </Col>
                  </Row>
                  <br></br> <br></br>
                  <Row>
                    <Col span={12} offset={4} style={{ textAlign: 'center' }}>
                      <h2>{profile.data.user.name}</h2>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      {profile.data.user.password ? (
                        <div>
                          <div>
                            <br></br>
                            <br></br>
                            <Form
                              name="basic"
                              id="NewPasswordForm"
                              layout="inline"
                              onFinish={(e) => {
                                let newPassword = e.password;
                                e.password = '';

                                if (newPassword.length < 6) {
                                  message.error(
                                    'كلمة السر يجب ان تكون 6 حروف واكثر'
                                  );
                                } else if (newPassword.length > 12) {
                                  message.error(
                                    'كلمة السر يجب ان تكون اقل من 12 حرف'
                                  );
                                } else {
                                  this.resetUserPassword({
                                    variables: {
                                      mobile: profile.data.user.mobile,
                                      code: profile.data.user
                                        .mobileVerificationCode,
                                      password: newPassword,
                                    },
                                  });
                                }
                              }}
                            >
                              <Form.Item name="password">
                                <Input
                                  id="password"
                                  label="Password"
                                  name="password"
                                  rules={[
                                    {
                                      message: 'Please input your password!',
                                    },
                                  ]}
                                  placeholder="New Password"
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  form="NewPasswordForm"
                                  type="primary"
                                  loading={this.state.loading_pass}
                                  htmlType="submit"
                                >
                                  تحديث كلمة السر
                                </Button>
                              </Form.Item>
                            </Form>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </Row>
                  <br></br>
                  <br></br>
                  <Row>
                    <Col span={7}>
                      {profile.data.user.status !== 'ACTIVE' ? (
                        <div>
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loading_activ}
                            onClick={() => {
                              this.activateUserAccount({
                                variables: {
                                  where: {
                                    id: profile.data.user.id,
                                  },
                                  data: {
                                    mobileVerified: { set: true },
                                    status: { set: 'ACTIVE' },
                                  },
                                },
                              });
                            }}
                          >
                            تأكيد المستخدم
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button disabled type="primary">
                            تأكيد المستخدم
                          </Button>
                        </div>
                      )}
                    </Col>

                    <Col span={7}>
                      {profile.data.user.status === 'ACTIVE' ? (
                        <div>
                          <Button
                            type="danger"
                            htmlType="submit"
                            loading={this.state.loading_sus}
                            onClick={() => {
                              this.suspendUser({
                                variables: {
                                  id: profile.data.user.id,
                                },
                              });
                            }}
                          >
                            فصل المستخدم
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button disabled type="primary">
                            فصل المستخدم
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col span={7}>
                      <div>
                        <Button
                          type="danger"
                          htmlType="submit"
                          onClick={() => {
                            if (profile.data.user.listings.length === 0) {
                              this.deleteUser({
                                variables: {
                                  id: profile.data.user.id,
                                },
                              });
                            } else {
                              message.error(
                                'يجب حذف جميع اعلانات المستخدم قبل حذف المستخدم'
                              );
                            }
                          }}
                        >
                          حذف المستخدم
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={12} style={{ borderLeft: '5 solid ' }}>
                  <div>
                    <h2
                      style={{
                        textAlign: 'center',
                        marginBottom: '30px',
                        marginTop: '25px',
                      }}
                    >
                      تحديث بيانات المستخدم
                    </h2>
                    <Form>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3> البريد الالكتروني : </h3>
                          </Col>

                          <Col span={18}>
                            <Input
                              id="email"
                              allowClear
                              value={this.state.userData.email}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    email: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>الاسم :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="name"
                              allowClear
                              value={this.state.userData.name}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    name: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>رقم التسجيل :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="mobile"
                              allowClear
                              value={this.state.userData.mobile}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    mobile: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>عن :</h3>
                          </Col>
                          <Col span={18}>
                            <Input.TextArea
                              id="about"
                              value={this.state.userData.about}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    about: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>اسم العمل \ الشركة</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="company"
                              allowClear
                              value={this.state.userData.company}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    company: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>رقم العمل :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="businessPhone"
                              allowClear
                              value={this.state.userData.businessPhone}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    businessPhone: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>مكان العمل :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="businessAddress"
                              allowClear
                              value={this.state.userData.businessAddress}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    businessAddress: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>

                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>واتساب :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="whatsApp"
                              allowClear
                              value={this.state.userData.whatsApp}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    whatsApp: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>رقم التواصل :</h3>
                          </Col>
                          <Col span={18}>
                            <Input
                              id="phone"
                              allowClear
                              value={this.state.userData.phone}
                              onChange={(e) => {
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    phone: e.target.value,
                                  },
                                });
                              }}
                            />
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row>
                          <Col span={6}>
                            <h3>نوع الحساب :</h3>
                          </Col>
                          <Col span={18}>
                            <Select
                              defaultValue={this.state.userData.role}
                              style={{ width: '100%' }}
                              onChange={(value) => {
                                let role = 'USER';
                                if (value === '0') {
                                  role = 'USER';
                                } else if (value === '1') {
                                  role = 'AGENCY';
                                }
                                this.setState({
                                  userData: {
                                    ...this.state.userData,
                                    role,
                                  },
                                });
                              }}
                            >
                              <Option key={0}>USER</Option>
                              <Option key={1}>AGENCY</Option>
                            </Select>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row
                          style={{
                            border: 'solid 1px red',
                            padding: '10px',
                            borderRadius: '4px',
                          }}
                        >
                          <Col className="gutter-row" span={6}>
                            <div style={{ padding: '8px 0' }}>
                              <span
                                style={{
                                  padding: '25px',
                                  fontWeight: 'bolder',
                                }}
                              >
                                حزمة الإعلانات المتبقية
                              </span>
                            </div>
                          </Col>
                          <Col className="gutter-row" span={9}>
                            <div style={{ padding: '8px 0' }}>
                              <Row gutter={6}>
                                <Col>
                                  <Input
                                    type="number"
                                    min={0}
                                    onChange={(e) => {
                                      this.setState({
                                        remainingListingsCreateCount:
                                          e.target.value,
                                      });
                                    }}
                                    value={parseInt(
                                      this.state.remainingListingsCreateCount
                                    )}
                                  />
                                </Col>
                                <Col>
                                  <Button
                                    type="primary"
                                    onClick={async (value) => {
                                      try {
                                        await API.graphql(
                                          graphqlOperation(
                                            Admin.User.UPDATE_USER_INFO,
                                            {
                                              where: {
                                                id: profile.data.user.id,
                                              },
                                              data: {
                                                remainingListingsCreateCount: {
                                                  set: parseInt(
                                                    this.state
                                                      .remainingListingsCreateCount
                                                  ),
                                                },
                                              },
                                            }
                                          )
                                        );
                                        message.success(
                                          'تم تعديل الحزمة بنجاح'
                                        );
                                        this.getUserById();
                                      } catch (error) {
                                        console.log(
                                          '🚀 ~ file: system-config.js ~ line 218 ~ onClick={ ~ error',
                                          error
                                        );
                                      }
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col className="gutter-row" span={9}>
                            <div style={{ padding: '8px 0', textAlign: 'end' }}>
                              <span
                                style={{
                                  direction: 'rtl',
                                  color: 'red',
                                }}
                              >
                                تأكد من انه تم دفع كلفة الحزمة قبل التعديل
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            style={{ width: '50%' }}
                            type="primary"
                            onClick={(e) => {
                              this.updateUser({
                                variables: {
                                  where: {
                                    id: profile.data.user.id,
                                  },
                                  data: Object.keys(this.state.userData).reduce(
                                    (result, current) => {
                                      result[current] = {
                                        set: this.state.userData[current],
                                      };
                                      return result;
                                    },
                                    {}
                                  ),
                                },
                              });
                            }}
                          >
                            حدث البيانات
                          </Button>
                        </div>
                      </Form.Item>
                    </Form>
                  </div>
                </Col>
              </Row>
            </div>
            <div style={{ width: '100%' }}>
              <Tabs defaultActiveKey="1" centered>
                <TabPane tab="اعلانات المستخدم العقارية والاسكانية" key="1">
                  <hr></hr>
                  {/* <input
                    type="checkbox"
                    id="myCheck"
                    checked={this.state.selectAll}
                    onclick="myFunction()"
                  /> */}
                  <InfiniteListExample
                    data={profile.data.user.listings}
                    component={
                      <Row>
                        <List
                          grid={{
                            gutter: 24,
                            Col: 6,
                          }}
                          dataSource={profile.data.user.listings}
                          renderItem={(item) => {
                            console.log('selectAll', this.state.selectAll);
                            const [itemToEdit, setItemToEdit] = useState(item);
                            const changeListing = (listing) => {
                              setItemToEdit(listing);
                            };
                            return (
                              <List.Item>
                                <Card
                                  style={{
                                    width: 380,
                                    height: 400,
                                  }}
                                  cover={
                                    <>
                                      {/* <div
                                        style={{
                                          position: 'absolute',
                                          zIndex: '999',
                                          padding: '2px',
                                          backgroundColor: '#fff',
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          id="myCheck"
                                          checked={this.state.selectAll}
                                          onclick="myFunction()"
                                        />
                                      </div> */}
                                      <img
                                        style={{ width: '100%', height: 400 }}
                                        src={
                                          item.images.length > 0
                                            ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${item.s3Prefix}/${item.images[0].fileName}`
                                            : kharetaLogo
                                        }
                                        alt={''}
                                      />
                                    </>
                                  }
                                  actions={this.itemCard(itemToEdit)}
                                  bodyStyle={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Meta
                                    title={itemToEdit.title}
                                    description={itemToEdit.description}
                                    style={{ direction: 'rtl' }}
                                  />
                                  <br></br>
                                  <Tag
                                    color={
                                      itemToEdit.status === 'PENDING' ||
                                      itemToEdit.status === 'DELETED'
                                        ? 'red'
                                        : 'green'
                                    }
                                  >
                                    {itemToEdit.status}
                                  </Tag>
                                  <Tag color="green">
                                    {itemToEdit.views || '0'} عدد المشاهدات
                                  </Tag>
                                  <Tag color="green">
                                    {itemToEdit.isHousing ? 'اسكان' : 'عقار'}
                                  </Tag>
                                  <div style={{ marginTop: 20, width: '100%' }}>
                                    <ListingStatusCtrl
                                      listing={itemToEdit}
                                      changeListing={changeListing}
                                      page={'user'}
                                    />
                                  </div>
                                </Card>
                              </List.Item>
                            );
                          }}
                        />
                      </Row>
                    }
                  ></InfiniteListExample>
                </TabPane>
                <TabPane tab="اعلاناته المفضلة" key="2">
                  <InfiniteListExample
                    data={profile.data.user && profile.data.user['FAV']}
                    component={
                      profile.data.user &&
                      profile.data.user['FAV'] &&
                      profile.data.user['FAV'].length !== 0 ? (
                        <Row>
                          <List
                            grid={{
                              gutter: 24,
                              Col: 6,
                            }}
                            dataSource={
                              profile.data.user && profile.data.user['FAV']
                            }
                            renderItem={(item) => (
                              <List.Item>
                                <Card
                                  style={{
                                    width: 380,
                                    height: 400,
                                  }}
                                  cover={
                                    <img
                                      style={{ width: '100%', height: 400 }}
                                      src={
                                        item.images.length > 0
                                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${item.s3Prefix}/${item.images[0].fileName}`
                                          : kharetaLogo
                                      }
                                      alt={''}
                                    />
                                  }
                                  actions={this.itemCard(item)}
                                  bodyStyle={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Meta
                                    title={item.title}
                                    style={{ direction: 'rtl' }}
                                  />
                                  <br></br>
                                  <Tag
                                    color={
                                      item.status === 'PENDING' ||
                                      item.status === 'DELETED'
                                        ? 'red'
                                        : 'green'
                                    }
                                  >
                                    {item.status}
                                  </Tag>
                                  <Tag color="green">
                                    {' '}
                                    {item.views || '0'} عدد المشاهدات
                                  </Tag>
                                </Card>{' '}
                              </List.Item>
                            )}
                          />
                        </Row>
                      ) : (
                        <div></div>
                      )
                    }
                  ></InfiniteListExample>
                </TabPane>

                <TabPane tab="زياراته" key="3">
                  <InfiniteListExample
                    data={profile.data.user && profile.data.user['VISIT']}
                    component={
                      profile.data.user &&
                      profile.data.user['VISIT'] &&
                      profile.data.user['VISIT'].length !== 0 ? (
                        <Row>
                          <hr></hr>
                          <List
                            grid={{
                              gutter: 24,
                              col: 6,
                            }}
                            dataSource={
                              profile.data.user && profile.data.user['VISIT']
                            }
                            renderItem={(item) => (
                              <List.Item>
                                <Card
                                  style={{
                                    width: 380,
                                    height: 400,
                                  }}
                                  cover={
                                    <img
                                      style={{ width: '100%', height: 400 }}
                                      src={
                                        item.images.length > 0
                                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${item.s3Prefix}/${item.images[0].fileName}`
                                          : kharetaLogo
                                      }
                                      alt={''}
                                    />
                                  }
                                  actions={this.itemCard(item)}
                                  bodyStyle={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Meta
                                    title={item.title}
                                    style={{ direction: 'rtl' }}
                                  />
                                  <br></br>
                                  <Tag
                                    color={
                                      item.status === 'PENDING' ||
                                      item.status === 'DELETED'
                                        ? 'red'
                                        : 'green'
                                    }
                                  >
                                    {item.status}
                                  </Tag>
                                  <Tag color="green">
                                    {' '}
                                    {item.views || '0'} عدد المشاهدات
                                  </Tag>
                                </Card>{' '}
                              </List.Item>
                            )}
                          />
                        </Row>
                      ) : (
                        <div></div>
                      )
                    }
                  ></InfiniteListExample>
                </TabPane>

                <TabPane tab="المتصل بها" key="4">
                  <InfiniteListExample
                    data={profile.data.user && profile.data.user['CALL']}
                    component={
                      profile.data.user &&
                      profile.data.user['CALL'] &&
                      profile.data.user['CALL'].length !== 0 ? (
                        <Row>
                          <hr></hr>
                          <List
                            grid={{
                              gutter: 24,
                              col: 6,
                            }}
                            dataSource={
                              profile.data.user && profile.data.user['CALL']
                            }
                            renderItem={(item) => (
                              <List.Item>
                                <Card
                                  style={{
                                    width: 380,
                                    height: 400,
                                  }}
                                  cover={
                                    <img
                                      style={{ width: '100%', height: 400 }}
                                      src={
                                        item.images.length > 0
                                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${item.s3Prefix}/${item.images[0].fileName}`
                                          : kharetaLogo
                                      }
                                      alt={''}
                                    />
                                  }
                                  actions={this.itemCard(item)}
                                  bodyStyle={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Meta
                                    title={item.title}
                                    style={{ direction: 'rtl' }}
                                  />
                                  <br></br>
                                  <Tag
                                    color={
                                      item.status === 'PENDING' ||
                                      item.status === 'DELETED'
                                        ? 'red'
                                        : 'green'
                                    }
                                  >
                                    {item.status}
                                  </Tag>
                                  <Tag color="green">
                                    {' '}
                                    {item.views || '0'} عدد المشاهدات
                                  </Tag>
                                </Card>{' '}
                              </List.Item>
                            )}
                          />
                        </Row>
                      ) : (
                        <div></div>
                      )
                    }
                  ></InfiniteListExample>
                </TabPane>

                {/* eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee */}

                <TabPane tab="الاعلانات التجارية" key="5">
                  <InfiniteListExample
                    data={profile.data.user.commercialAds}
                    component={
                      profile.data.user.commercialAds.length !== 0 ? (
                        <Row>
                          <hr></hr>
                          <List
                            grid={{
                              gutter: 24,
                              col: 6,
                            }}
                            dataSource={profile.data.user.commercialAds}
                            renderItem={(item) => (
                              <List.Item>
                                <Card
                                  style={{
                                    width: 380,
                                    height: 400,
                                  }}
                                  cover={
                                    <img
                                      style={{ width: '100%', height: 400 }}
                                      src={
                                        item.images.length > 0
                                          ? `${process.env.REACT_APP_IMAGES_BASE_URL}/listings/200x200/${item.s3Prefix}/${item.images[0].fileName}`
                                          : kharetaLogo
                                      }
                                      alt={''}
                                    />
                                  }
                                  actions={this.itemCard(item, true)}
                                  bodyStyle={{
                                    paddingRight: '0px',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  <Meta
                                    title={
                                      categories.find(
                                        (cat) => cat.value === item.category
                                      ).text
                                    }
                                    style={{ direction: 'rtl' }}
                                  />
                                  <br></br>
                                  <Tag
                                    color={
                                      item.status === 'PENDING' ||
                                      item.status === 'DELETED'
                                        ? 'red'
                                        : 'green'
                                    }
                                  >
                                    {item.status}
                                  </Tag>
                                  <Tag color="green">
                                    {' '}
                                    {item.views || '0'} عدد المشاهدات
                                  </Tag>
                                </Card>{' '}
                              </List.Item>
                            )}
                          />
                        </Row>
                      ) : (
                        <div></div>
                      )
                    }
                  ></InfiniteListExample>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </>
      </MainLayout>
    );
  }
}

export default withCookies(UserProfile);
