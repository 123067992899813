import React from 'react';
import { Modal, Descriptions } from 'antd';

const INSIGHT_TYPES = [
  { key: 'views', label: 'Views' },
  { key: 'visits', label: 'Visits' },
  { key: 'calls', label: 'Calls' },
  { key: 'whatsappClicks', label: 'Whatsapp Clicks ' },
  { key: 'goToPropertyMapClicks', label: 'Go To Location Clicks' },
  { key: 'addedToFavClicks', label: 'Added To Favorite Clicks' },
  { key: 'shareToFacebookClicks', label: 'Shared To Facebook' },
  { key: 'shareToWhatsappClicks', label: 'Shared To Whatsapp' },
  { key: 'shareToEmailClicks', label: 'Shared To Email' },
];

export default function Insights(props) {
  const { listing, isModalOpen, closeModal } = props;
  const insights = INSIGHT_TYPES.map((insight) => ({
    ...insight,
    count: listing[insight.key],
  }));
  return (
    <>
      <Modal
        title={
          <span>
            Insights for listing with number: <i>{listing.no}</i>
          </span>
        }
        visible={isModalOpen}
        centered={true}
        footer={null}
        onCancel={() => {
          closeModal();
        }}
        width="80%"
      >
        {
          <Descriptions title="" bordered size="small" span={4}>
            {insights.map(({ key, count, label }) => {
              return (
                <Descriptions.Item key={key} label={label} span={2}>
                  {count}
                </Descriptions.Item>
              );
            })}
          </Descriptions>
        }
      </Modal>
    </>
  );
}
