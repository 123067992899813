import React from 'react';
import { Input, Divider } from 'antd';
import { Title } from '../../components/ui/Styled';
import Switch from '../../components/ui/Switch';
import MultiSwitch from '../../components/ui/MultiSwitch';
import Feature from '../../models/Feature';
import EditContactMethod from '../../components/EditContactMethod/EditContactMethod';
import EditAgencyTyps from '../../components/EditAgencyTyps';
import {
  ElementPriorityForCommercialProperty,
  zonesByAdType,
  commercialTypeByADType,
} from '../../utils/commercialPropertyUtils';
const {
  isNewBtnPriority,
  landAreaFieldPriority,
  buildingAreaFieldPriority,
  creationYearFieldPriority,
  bedRoomsPriority,
  bathRoomsPriority,
  floorPriority,
  CommercialComplexZonePriority,
  isRentedPriority,
  HotelZonePriority,
  EducationalZonePriority,
  HospitalZonePriority,
  UnitsFieldPriority,
} = ElementPriorityForCommercialProperty;
const { zoneValues, zoneLabels } = zonesByAdType;
const { commercialTypeValues, commercialTypeLabels } = commercialTypeByADType;

const otherFeatures = Feature.getOthers();
const farmOtherFeaturesKeys = Object.keys(otherFeatures).filter(
  (key) =>
    [
      'swimmingPool',
      'swimmingPool2',
      'parkings',
      'barbecueArea',
      'centralConditioning',
      'playArea',
    ].indexOf(key) > -1
);
const farmOtherFeaturesValues = Object.values(otherFeatures).filter(
  (value) =>
    [
      otherFeatures['swimmingPool'],
      otherFeatures['swimmingPool2'],
      otherFeatures['parkings'],
      otherFeatures['barbecueArea'],
      otherFeatures['centralConditioning'],
      otherFeatures['playArea'],
    ].indexOf(value) > -1
);
delete otherFeatures.parkings;

const farmCategory = {
  id: '',
  landArea: 0,
  bedRooms: [],
  bathRooms: [],
  buildingArea: null,
  centralConditioning: null,
  withBuilding: false,
  playArea: null,
  barbecueArea: null,
  parkings: null,
  swimmingPool: null,
  swimmingPool2: null,
};

const apartmentCategory = {
  id: '',
  totalApartments: 0,
  isNew: null,
  isFurnished: null,
  bedRooms: [],
  bathRooms: [],
  totalFloors: 0,
  totalUnits: 0,
  buildingAge: 0,
  floor: 0,
  withTerrace: false,
  terraceArea: 0,
  withRoof: false,
  roofArea: 0,
  kitchenWooden: false,
  kitchenAluminium: false,
  kitchen: false,
  barbeque: false,
  centralHeating: false,
  developmentHeating: false,
  centralConditioning: false,
  developmentConditioning: false,
  doubleGlaze: false,
  electricShades: false,
  parking: false,
  parking2: false,
  swimmingPool: false,
  playArea: false,
  heating: false,
  sunHeater: false,
  waterWell: false,
  houseStore: false,
  washRoom: false,
  barbecueArea: false,
};

const vellaOrHouseCategory = {
  id: '',
  isNew: null,
  isFurnished: null,
  bedRooms: [],
  bathRooms: [],
  buildingAge: 0,
  buildingArea: 0,
  withRoof: false,
  roofArea: 0,
  kitchenWooden: false,
  kitchenAluminium: false,
  kitchen: false,
  barbeque: false,
  centralHeating: false,
  developmentHeating: false,
  centralConditioning: false,
  developmentConditioning: false,
  doubleGlaze: false,
  electricShades: false,
  parking: false,
  parking2: false,
  swimmingPool: false,
  playArea: false,
  heating: false,
  sunHeater: false,
  waterWell: false,
  houseStore: false,
  washRoom: false,
  barbecueArea: false,
};

const landCategory = {
  id: '',
  landArea: 0,
  streetFront: 0,
  incline: 0,
  zone: '',
};

const buildingCategory = {
  id: '',
  totalFloors: 0,
  totalUnits: 0,
  buildingArea: 0,
  buildingAge: 0,
  landArea: 0,
  isResidential: false,
  isCommercial: false,
};

const commercialCategory = {
  id: '',
  commercialType: '',
  buildingArea: 0,
  buildingAge: 0,
  creationYear: 0,
};

const features = {
  id: '',
  isNew: null,
  isFurnished: null,
  bedRooms: null,
  bathRooms: null,
  buildingArea: null,
  buildingAreaFrom: null,
  buildingAreaTo: null,
  buildingAge: null,
  creationYear: null,
  totalApartments: null,
  floorApartments: null,
  totalFloors: null,
  totalUnits: null,
  floor: null,
  withTerrace: null,
  terraceArea: null,
  withRoof: null,
  roofArea: null,
  withBuilding: null,
  landArea: null,
  streetFront: null,
  commercialType: null,
  isResidential: null,
  isCommercial: null,
  isRented: null,
  zone: null,
  incline: null,
  expectedFinish: null,
  withPool: null,
  kitchenWooden: null,
  kitchenAluminium: null,
  kitchen: null,
  barbeque: null,
  centralHeating: null,
  developmentHeating: null,
  centralConditioning: null,
  developmentConditioning: null,
  doubleGlaze: null,
  electricShades: null,
  heating: null,
  sunHeater: null,
  waterWell: null,
  houseStore: null,
  washRoom: null,
  barbecueArea: null,
  parking: null,
  parking2: null,
  parkings: null,
  swimmingPool: null,
  swimmingPool2: null,
  playArea: null,
};

export default class ListingProps extends React.Component {
  state = {
    id: this.props.features.id,
    category: this.props.category,
    features: this.props.features,
    isHousing: this.props.isHousing,
  };

  currentCategorySetAssignment = (cat) => {
    const form = {
      APARTMENT: apartmentCategory,
      HOUSE: vellaOrHouseCategory,
      LAND: landCategory,
      BUILDING: buildingCategory,
      FARM: farmCategory,
      COMMERCIAL_PROPERTY: commercialCategory,
    };
    return form[cat];
  };
  async formFeaturesBasedOnCategory() {
    //on init/mount

    const feats = this.currentCategorySetAssignment(this.props.category);
    const result = { ...feats, ...this.props.features };
    result.id = this.state.id;
    await this.setState({ features: result });
  }

  async resetValuesToCategory() {
    //on change category
    await this.setState({ features: {} });
    const feats = this.currentCategorySetAssignment(this.props.category);
    const result = await this.formation(this.state.category, feats);

    const the = this.state.id;
    result.id = the;
    await this.setState({ features: result });
  }

  async formation(c, f) {
    const categorySet = this.currentCategorySetAssignment(c);

    const currentFeatures = f;

    const catKeys = Object.keys(categorySet);

    var obj = {};
    await catKeys.forEach((e) => (obj[[e]] = currentFeatures[[e]]));

    const objj = Object.assign(f, obj);
    objj.id = this.state.id;

    return { ...f, ...objj };
  }
  async finalFormation() {
    //farm

    //all issues here fix then do validation from main componenet

    if (
      this.state.category === 'FARM' &&
      this.state.features.withBuilding === false
    ) {
      const farmSpecial = {
        ...features,
        landArea: this.state.features.landArea,
        withBuilding: null,
        id: this.state.id,
      };
      const lastSpecial = { ...features, ...farmSpecial };
      const finalSpecial = await this.formation(
        this.state.category,
        lastSpecial
      );
      finalSpecial.id = this.state.id;
      return finalSpecial;
    } else {
      const last = { ...features, ...this.state.features };
      const final = await this.formation(this.state.category, last);
      final.id = this.state.id;
      return final;
    }
  }

  componentDidMount() {
    //function to handle first input
    this.formFeaturesBasedOnCategory();
  }
  async componentDidUpdate(prevProps, prevState) {
    // working
    if (this.props.category !== prevProps.category) {
      await this.setState({ category: this.props.category });
      await this.resetValuesToCategory();
    } else if (this.state.features !== prevState.features) {
      await this.props.callBack(await this.finalFormation());
    }
  }

  render() {
    const { commercialType } = this.state.features;
    return (
      <div>
        {this.state.category === 'FARM' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Title> مساحة المزرعة </Title>

            <Input
              name="landArea"
              type="number"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.landArea === null ||
                  this.state.features.landArea < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="مساحة المزرعة بالمتر المربع"
              onChange={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    landArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.landArea}
            />

            <Title> مع مبنى</Title>
            <Switch
              name="withBuilding"
              values={['yes', 'no']}
              labels={['نعم', 'لا']}
              align={'center'}
              value={this.state.features.withBuilding}
              selected={
                this.state.features.withBuilding != null
                  ? this.state.features.withBuilding
                    ? 'yes'
                    : 'no'
                  : null
              }
              onClick={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    withBuilding: value === 'yes' ? true : false,
                  },
                });
              }}
            />
          </div>
        )}
        {this.state.features.withBuilding && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Title> عدد غرف النوم</Title>
            <Switch
              name="bedRooms"
              values={[0, 1, 2, 3, 4, 5]}
              width={'40px'}
              align={'center'}
              labels={['ستوديو', '1', '2', '3', '4', '+5']}
              selected={this.state.features.bedRooms}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    bedRooms: value,
                  },
                });
              }}
            />

            <Title> عدد الحمامات</Title>
            <Switch
              name="bathRooms"
              values={[1, 2, 3, 4, 5]}
              width={'40px'}
              align={'center'}
              labels={['1', '2', '3', '4', '+5']}
              selected={this.state.features.bathRooms}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    bathRooms: value,
                  },
                });
              }}
            />

            <Title> مساحة البناء </Title>
            <Input
              type="number"
              name="buildingArea"
              placeholder="مساحة البناء بالمتر المربع"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    buildingArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.buildingArea !== null &&
                  this.state.features.buildingArea > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              value={this.state.features.buildingArea}
            />
          </div>
        )}

        {this.state.category === 'COMMERCIAL_PROPERTY' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Title> مواصفات العقار التجاري</Title>
            <Switch
              name="commercialType"
              values={commercialTypeValues[this.props.type]}
              labels={commercialTypeLabels[this.props.type]}
              selected={this.state.features.commercialType}
              onClick={(value) =>
                this.setState({
                  features: {
                    ...this.state.features,
                    commercialType: value,
                  },
                })
              }
            />
            {isNewBtnPriority.includes(commercialType) && (
              <>
                <Title>حالة العقار</Title>
                <Switch
                  name="isNew"
                  values={['used', 'new']}
                  labels={['مستعمل', 'جديد']}
                  selected={
                    this.state.features.isNew != null
                      ? this.state.features.isNew
                        ? 'new'
                        : 'used'
                      : null
                  }
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        isNew: value === 'new' ? true : false,
                      },
                    });
                  }}
                />
              </>
            )}
            {[
              ...CommercialComplexZonePriority,
              ...HotelZonePriority,
              ...HospitalZonePriority,
              ...EducationalZonePriority,
            ].includes(commercialType) && (
              <>
                <Title>التنظيم/التصنيف</Title>
                <Switch
                  name="zone"
                  values={zoneValues[commercialType]}
                  labels={zoneLabels[commercialType]}
                  selected={this.state.features.zone}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        zone: value,
                      },
                    });
                  }}
                />
              </>
            )}

            {UnitsFieldPriority.includes(commercialType) && (
              <>
                <Title>عدد الأَسِرَّة</Title>
                <Input
                  type="number"
                  name="totalUnits"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.totalUnits === null ||
                      this.state.features.totalUnits < 1
                        ? '4px solid red'
                        : ''
                    }`,
                  }}
                  placeholder="عدد الأَسِرَّة في المستشفى"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        totalUnits:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.totalUnits}
                />
              </>
            )}
            {isRentedPriority.includes(commercialType) && (
              <>
                <Title>هل المجمع مؤجر ؟</Title>
                <Switch
                  name="isNew"
                  values={['no', 'yes']}
                  labels={['غير مؤجر', 'مؤجر/جزئي']}
                  selected={
                    this.state.features.isNew != null
                      ? this.state.features.isNew
                        ? 'new'
                        : 'used'
                      : null
                  }
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        isRented: value === 'yes' ? true : false,
                      },
                    });
                  }}
                />
              </>
            )}
            {landAreaFieldPriority.includes(commercialType) && (
              <>
                <Title> مساحة الارض </Title>
                <Input
                  name="landArea"
                  type="number"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.landArea === null ||
                      this.state.features.landArea < 1
                        ? '4px solid red'
                        : ''
                    }`,
                  }}
                  placeholder="مساحة الارض بالمتر المربع"
                  onChange={async (value) => {
                    await this.setState({
                      features: {
                        ...this.state.features,
                        landArea:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.landArea}
                />
              </>
            )}
            {buildingAreaFieldPriority.includes(commercialType) && (
              <>
                <Title> مساحة البناء </Title>
                <Input
                  type="number"
                  name="buildingArea"
                  placeholder="مساحة البناء بالمتر المربع"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingArea:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingArea !== null &&
                      this.state.features.buildingArea > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  value={this.state.features.buildingArea}
                />
              </>
            )}
            {creationYearFieldPriority.includes(commercialType) && (
              <>
                <Title> سنة البناء </Title>
                <Input
                  type="number"
                  name="creationYear"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.creationYear !== null &&
                      this.state.features.creationYear > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  placeholder="عمر البناء بالسنوات"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        creationYear:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.creationYear}
                />
              </>
            )}
            {bedRoomsPriority.includes(commercialType) && (
              <>
                <Title> عدد الغرف</Title>
                <Switch
                  name="bedRooms"
                  values={[1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['1', '2', '3', '4', '+5']}
                  selected={this.state.features.bedRooms}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        bedRooms: value,
                      },
                    });
                  }}
                />
              </>
            )}
            {bathRoomsPriority.includes(commercialType) && (
              <>
                <Title> عدد الحمامات</Title>
                <Switch
                  name="bathRooms"
                  values={[1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['1', '2', '3', '4', '+5']}
                  selected={this.state.features.bathRooms}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        bathRooms: value,
                      },
                    });
                  }}
                />
              </>
            )}
            {floorPriority.includes(commercialType) && (
              <>
                <Title> الطابق</Title>
                <Switch
                  name="floor"
                  values={['3', '4', '5', '6', '7']}
                  width={'64px'}
                  align={'center'}
                  labels={['أرضي', 'الأول', 'الثاني', 'الثالث', '+الرابع']}
                  selected={this.state.features.floor}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        floor: value,
                      },
                    });
                  }}
                />
              </>
            )}
            {this.state.features.isNew !== true ? (
              <div>
                <Title> عمر البناء </Title>
                <Input
                  type="number"
                  name="buildingAge"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAge !== null &&
                      this.state.features.buildingAge > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  placeholder="عمر البناء بالسنوات"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAge:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.buildingAge}
                />
              </div>
            ) : null}
          </div>
        )}

        {this.state.category === 'BUILDING' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Title> عدد طوابق المبنى </Title>
            <Input
              type="number"
              name="totalFloors"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.totalFloors === null ||
                  this.state.features.totalFloors < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="عدد طوابق المبنى"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    totalFloors:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.totalFloors}
            />

            <Title> عدد الوحدات في المبنى </Title>
            <Input
              type="number"
              name="totalUnits"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.totalUnits === null ||
                  this.state.features.totalUnits < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="عدد الوحدات في المبنى"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    totalUnits:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.totalUnits}
            />

            <Title> مساحة البناء </Title>
            <Input
              type="number"
              name="buildingArea"
              placeholder="مساحة البناء بالمتر المربع"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    buildingArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.buildingArea !== null &&
                  this.state.features.buildingArea > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              value={this.state.features.buildingArea}
            />

            {this.state.features.isNew !== true ? (
              <div>
                <Title> عمر البناء </Title>
                <Input
                  type="number"
                  name="buildingAge"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAge !== null &&
                      this.state.features.buildingAge > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  placeholder="عمر البناء بالسنوات"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAge:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.buildingAge}
                />
              </div>
            ) : null}
            <Title> مساحة الارض </Title>

            <Input
              name="landArea"
              type="number"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.landArea === null ||
                  this.state.features.landArea < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="مساحة المزرعة بالمتر المربع"
              onChange={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    landArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.landArea}
            />

            <Title> ملاحظات</Title>
            <Switch
              name="isResidential"
              values={['yes', 'no']}
              labels={['سكني', 'تجاري']}
              align={'center'}
              selected={
                this.state.features.isResidential != null
                  ? this.state.features.isResidential
                    ? 'yes'
                    : 'no'
                  : null
              }
              onClick={(value) => {
                if (value === 'yes') {
                  this.setState({
                    features: {
                      ...this.state.features,
                      isCommercial: false,
                      isResidential: true,
                    },
                  });
                } else {
                  this.setState({
                    features: {
                      ...this.state.features,
                      isCommercial: true,
                      isResidential: false,
                    },
                  });
                }
              }}
            />
          </div>
        )}
        {this.state.category === 'LAND' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            {' '}
            <Title> مساحة الارض </Title>
            <Input
              name="landArea"
              type="number"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.landArea === null ||
                  this.state.features.landArea < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="مساحة الارض بالمتر المربع"
              onChange={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    landArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.landArea}
            />
            <Title> الواجهة على الشارع </Title>
            <Input
              name="streetFront"
              type="number"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.streetFront === null ||
                  this.state.features.streetFront < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="مساحة الواجهة"
              onChange={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    streetFront:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.streetFront}
            />
            <Title> منسوب</Title>
            <Switch
              name="incline"
              values={[0, 1, 2, 3]}
              width={'64px'}
              align={'center'}
              labels={['غير محدد', 'طابق', 'طابقين', '+ثلاث طوابق ']}
              selected={this.state.features.incline}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    incline: value,
                  },
                });
              }}
            />
            <Title> ميزات اخرى </Title>
            <Switch
              name="zone"
              values={zoneValues.LAND}
              labels={zoneLabels.LAND}
              selected={this.state.features.zone}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    zone: value,
                  },
                });
              }}
            />
          </div>
        )}
        {this.state.category === 'HOUSE' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Title> مواصفات المنزل\الفيلا</Title>
            <Switch
              name="isNew"
              values={['used', 'new']}
              labels={['مستعمل', 'جديد']}
              selected={
                this.state.features.isNew != null
                  ? this.state.features.isNew
                    ? 'new'
                    : 'used'
                  : null
              }
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    isNew: value === 'new' ? true : false,
                  },
                });
              }}
            />
            <Switch
              name="isFurnished"
              values={['unfurnished', 'furnished']}
              labels={['غير مفروش', 'مفروش']}
              selected={
                this.state.features.isFurnished != null
                  ? this.state.features.isFurnished === true
                    ? 'furnished'
                    : 'unfurnished'
                  : null
              }
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    isFurnished: value === 'furnished' ? true : false,
                  },
                });
              }}
            />
            <Title> عدد غرف النوم</Title>
            <Switch
              name="bedRooms"
              values={[0, 1, 2, 3, 4, 5]}
              width={'40px'}
              align={'center'}
              labels={['ستوديو', '1', '2', '3', '4', '+5']}
              selected={this.state.features.bedRooms}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    bedRooms: value,
                  },
                });
              }}
            />
            <Title> عدد الحمامات</Title>
            <Switch
              name="bathRooms"
              values={[1, 2, 3, 4, 5]}
              width={'40px'}
              align={'center'}
              labels={['1', '2', '3', '4', '+5']}
              selected={this.state.features.bathRooms}
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    bathRooms: value,
                  },
                });
              }}
            />
            <Title> مساحة البناء </Title>
            <Input
              type="number"
              name="buildingArea"
              placeholder="مساحة البناء بالمتر المربع"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    buildingArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.buildingArea !== null &&
                  this.state.features.buildingArea > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              value={this.state.features.buildingArea}
            />
            {this.state.features.isNew !== true ? (
              <div>
                <Title> عمر البناء </Title>
                <Input
                  type="number"
                  name="buildingAge"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAge !== null &&
                      this.state.features.buildingAge > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  placeholder="عمر البناء بالسنوات"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAge:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.buildingAge}
                />
              </div>
            ) : null}
            <Title> مساحة الارض </Title>

            <Input
              name="landArea"
              type="number"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.landArea === null ||
                  this.state.features.landArea < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="مساحة المزرعة بالمتر المربع"
              onChange={async (value) => {
                await this.setState({
                  features: {
                    ...this.state.features,
                    landArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.landArea}
            />
          </div>
        )}
        {this.state.category === 'APARTMENT' && (
          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Divider>
              {this.state.isHousing ? 'مواصفات الإسكان' : 'مواصفات الشقة'}
            </Divider>
            {!this.state.isHousing && (
              <>
                <Switch
                  name="isNew"
                  values={['used', 'new']}
                  labels={['مستعمل', 'جديد']}
                  selected={
                    this.state.features.isNew != null
                      ? this.state.features.isNew
                        ? 'new'
                        : 'used'
                      : null
                  }
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        isNew: value === 'new' ? true : false,
                      },
                    });
                  }}
                />
                <Switch
                  name="isFurnished"
                  values={['unfurnished', 'furnished']}
                  labels={['غير مفروش', 'مفروش']}
                  selected={
                    this.state.features.isFurnished != null
                      ? this.state.features.isFurnished === true
                        ? 'furnished'
                        : 'unfurnished'
                      : null
                  }
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        isFurnished: value === 'furnished' ? true : false,
                      },
                    });
                  }}
                />
              </>
            )}
            {this.state.isHousing ? (
              <>
                <Title> (من إلى) غرف النوم</Title>
                <Switch
                  name="bedRooms"
                  values={[0, 1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['ستوديو', '1', '2', '3', '4', '+5']}
                  multiple
                  selected={this.state.features.bedroomsRange}
                  onClick={(value) => {
                    const indexOfValue =
                      this.state.features.bedroomsRange.indexOf(value);
                    if (indexOfValue > -1) {
                      const valueArr = this.state.features.bedroomsRange;
                      valueArr.splice(indexOfValue);
                      this.setState({
                        features: {
                          ...this.state.features,
                          bedroomsRange: valueArr,
                        },
                      });
                    } else {
                      this.setState({
                        features: {
                          ...this.state.features,
                          bedroomsRange: [
                            ...this.state.features.bedroomsRange,
                            value,
                          ],
                        },
                      });
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Title> عدد غرف النوم</Title>
                <Switch
                  name="bedRooms"
                  values={[0, 1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['ستوديو', '1', '2', '3', '4', '+5']}
                  selected={this.state.features.bedRooms}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        bedRooms: value,
                      },
                    });
                  }}
                />
              </>
            )}
            {this.state.isHousing ? (
              <>
                <Title> حمامات (من إلى)</Title>
                <Switch
                  name="bathRooms"
                  values={[1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['1', '2', '3', '4', '+5']}
                  multiple
                  selected={this.state.features.bathroomsRange}
                  onClick={(value) => {
                    const indexOfValue =
                      this.state.features.bathroomsRange.indexOf(value);
                    if (indexOfValue > -1) {
                      const valueArr = this.state.features.bathroomsRange;
                      valueArr.splice(indexOfValue);
                      this.setState({
                        features: {
                          ...this.state.features,
                          bathroomsRange: valueArr,
                        },
                      });
                    } else {
                      this.setState({
                        features: {
                          ...this.state.features,
                          bathroomsRange: [
                            ...this.state.features.bathroomsRange,
                            value,
                          ],
                        },
                      });
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Title> عدد الحمامات</Title>
                <Switch
                  name="bathRooms"
                  values={[1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['1', '2', '3', '4', '+5']}
                  selected={this.state.features.bathRooms}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        bathRooms: value,
                      },
                    });
                  }}
                />
              </>
            )}
            {this.state.isHousing ? (
              <>
                <Title>مساحة البناء (متر مربع) من </Title>
                <Input
                  type="number"
                  name="buildingAreaFrom"
                  placeholder="مساحة البناء بالمتر المربع"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAreaFrom:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAreaFrom !== null &&
                      this.state.features.buildingAreaFrom > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  value={this.state.features.buildingAreaFrom}
                />

                <Title>مساحة البناء (متر مربع) إلى </Title>
                <Input
                  type="number"
                  name="buildingAreaTo"
                  placeholder="مساحة البناء بالمتر المربع"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAreaTo:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAreaTo !== null &&
                      this.state.features.buildingAreaTo > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  value={this.state.features.buildingAreaTo}
                />
              </>
            ) : (
              <>
                <Title> مساحة البناء </Title>
                <Input
                  type="number"
                  name="buildingArea"
                  placeholder="مساحة البناء بالمتر المربع"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingArea:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingArea !== null &&
                      this.state.features.buildingArea > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  value={this.state.features.buildingArea}
                />
              </>
            )}
            {this.state.features.isNew !== true && !this.state.isHousing && (
              <div>
                <Title> عمر البناء </Title>
                <Input
                  type="number"
                  name="buildingAge"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.buildingAge !== null &&
                      this.state.features.buildingAge > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  placeholder="عمر البناء بالسنوات"
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        buildingAge:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                  value={this.state.features.buildingAge}
                />
              </div>
            )}

            {this.state.isHousing && (
              <>
                <Title>عدد الشقق في الطابق</Title>
                <Switch
                  name="floorsApartmentsRange"
                  values={[1, 2, 3, 4, 5]}
                  width={'40px'}
                  align={'center'}
                  labels={['1', '2', '3', '4', '+5']}
                  multiple
                  selected={this.state.features.floorsApartmentsRange}
                  onClick={(value) => {
                    const indexOfValue =
                      this.state.features.floorsApartmentsRange.indexOf(value);
                    if (indexOfValue > -1) {
                      const valueArr =
                        this.state.features.floorsApartmentsRange;
                      valueArr.splice(indexOfValue);
                      this.setState({
                        features: {
                          ...this.state.features,
                          floorsApartmentsRange: valueArr,
                        },
                      });
                    } else {
                      this.setState({
                        features: {
                          ...this.state.features,
                          floorsApartmentsRange: [
                            ...this.state.features.floorsApartmentsRange,
                            value,
                          ],
                        },
                      });
                    }
                  }}
                />
              </>
            )}

            <Title> عدد الطوابق </Title>
            <Input
              type="number"
              name="totalFloors"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.totalFloors === null ||
                  this.state.features.totalFloors < 1
                    ? '4px solid red'
                    : ''
                }`,
              }}
              placeholder="عدد طوابق المبنى"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    totalFloors:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.totalFloors}
            />
            <Title> مجموع عدد الشقق في المبنى</Title>
            <Input
              type="number"
              name="totalApartments"
              placeholder="مجموع عدد الشقق في المبنى"
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    totalApartments:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
              value={this.state.features.totalApartments}
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.totalApartments !== null &&
                  this.state.features.totalApartments > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
            />
            {this.state.isHousing ? (
              <>
                <Title> الطابق</Title>
                <Switch
                  name="floorRange"
                  values={['1', '2', '3', '4', '5', '6', '7']}
                  width={'64px'}
                  align={'center'}
                  labels={[
                    'تسوية',
                    'شبه أرضي',
                    'أرضي',
                    'الأول',
                    'الثاني',
                    'الثالث',
                    '+الرابع',
                  ]}
                  multiple
                  selected={this.state.features.floorRange}
                  onClick={(value) => {
                    const indexOfValue =
                      this.state.features.floorRange.indexOf(value);
                    if (indexOfValue > -1) {
                      const valueArr = this.state.features.floorRange;
                      valueArr.splice(indexOfValue);
                      this.setState({
                        features: {
                          ...this.state.features,
                          floorRange: valueArr,
                        },
                      });
                    } else {
                      this.setState({
                        features: {
                          ...this.state.features,
                          floorRange: [
                            ...this.state.features.floorRange,
                            value,
                          ],
                        },
                      });
                    }
                  }}
                />
              </>
            ) : (
              <>
                <Title> الطابق</Title>
                <Switch
                  name="floor"
                  values={[
                    '1',
                    '2',
                    '3',
                    '4',
                    '5',
                    '6',
                    '7',
                    '8',
                    '9',
                    '10',
                    '11',
                  ]}
                  width={'64px'}
                  align={'center'}
                  labels={[
                    'تسوية',
                    'شبه أرضي',
                    'أرضي',
                    'الأول',
                    'الثاني',
                    'الثالث',
                    '+الرابع',
                    'أخير',
                    'أخير مع رووف',
                    'رووف',
                    'دوبلكس',
                  ]}
                  selected={this.state.features.floor}
                  onClick={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        floor: value,
                      },
                    });
                  }}
                />
              </>
            )}
            <Title> مع حديقة</Title>
            <Switch
              name="withTerrace"
              values={['yes', 'no']}
              labels={['نعم', 'لا']}
              align={'center'}
              selected={
                this.state.features.withTerrace != null
                  ? this.state.features.withTerrace
                    ? 'yes'
                    : 'no'
                  : null
              }
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    withTerrace: value === 'yes' ? true : false,
                  },
                });
              }}
            />
            {this.state.features.withTerrace && (
              <div style={{ textAlign: 'end' }}>
                <Title> مساحة الحديقة</Title>
                <Input
                  type="number"
                  name="terraceArea"
                  placeholder="مساحة الحديقة بالمتر المربع"
                  style={{
                    width: 200,
                    direction: 'rtl',
                    border: `${
                      this.state.features.terraceArea !== null &&
                      this.state.features.terraceArea > 0
                        ? ''
                        : '4px solid red'
                    }`,
                  }}
                  value={this.state.features.terraceArea}
                  onChange={(value) => {
                    this.setState({
                      features: {
                        ...this.state.features,
                        terraceArea:
                          value.target.value === ''
                            ? null
                            : parseInt(value.target.value),
                      },
                    });
                  }}
                />
              </div>
            )}

            <Title> مع روف</Title>
            <Switch
              name="withRoof"
              values={['yes', 'no']}
              labels={['نعم', 'لا']}
              align={'center'}
              selected={
                this.state.features.withRoof != null
                  ? this.state.features.withRoof
                    ? 'yes'
                    : 'no'
                  : null
              }
              onClick={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    withRoof: value === 'yes' ? true : false,
                  },
                });
              }}
            />
          </div>
        )}

        {this.state.features.withRoof && (
          <div style={{ textAlign: 'end' }}>
            <Title> مساحة الروف </Title>
            <Input
              type="number"
              name="roofArea"
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.features.roofArea !== null &&
                  this.state.features.roofArea > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              placeholder="مساحة الروف بالمتر المربع"
              value={this.state.features.roofArea}
              onChange={(value) => {
                this.setState({
                  features: {
                    ...this.state.features,
                    roofArea:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  },
                });
              }}
            />
          </div>
        )}

        {((this.state.category === 'FARM' &&
          this.state.features.withBuilding) ||
          this.state.category === 'APARTMENT' ||
          this.state.category === 'HOUSE') && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <div style={{ width: 1000 }}>
              <Title> ميزات اخرى </Title>
              <MultiSwitch
                name=""
                ext=".png"
                path="/static/icons/features"
                icons={
                  this.state.category === 'FARM'
                    ? farmOtherFeaturesKeys
                    : Object.keys(otherFeatures).filter(
                        (key) => key !== 'swimmingPool2'
                      )
                }
                values={
                  this.state.category === 'FARM'
                    ? farmOtherFeaturesKeys
                    : Object.keys(otherFeatures).filter(
                        (key) => key !== 'swimmingPool2'
                      )
                }
                selected={Object.keys(this.state.features).filter(
                  (key) => this.state.features[key] === true
                )}
                labels={
                  this.state.category === 'FARM'
                    ? farmOtherFeaturesValues
                    : Object.values(otherFeatures).filter(
                        (value) => value !== '2مسبح'
                      )
                }
                onClick={async (key) => {
                  let obj = { ...this.state.features };
                  obj[key] = !this.state.features[key];
                  await this.setState({
                    features: { ...this.state.features, [key]: obj[key] },
                  });
                }}
              />
            </div>
          </div>
        )}

        {this.props.state.contactMethod && (
          <EditContactMethod
            state={this.props.state}
            setState={(state) => this.props.setState(state)}
          />
        )}
        {this.props.state.author.role === 'ADMIN' ||
          (this.props.state.author.id === 'cjt05k3ko00agaap487wlc4pa' && (
            <EditAgencyTyps
              state={this.props.state}
              setState={(state) => this.props.setState(state)}
            />
          ))}
      </div>
    );
  }
}
