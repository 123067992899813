import React from 'react';
export default function KitchenWooden({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <defs>
        <path id="b6u453fn5a" d="M0 17L17.108 17 17.108 0 0 0z" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-150 -1130) translate(0 1078) translate(20 42) translate(130.828 10)">
                <path
                  fill={color}
                  d="M.855 6.233c-.157 0-.285.127-.285.284 0 .156.128.283.285.283h15.398c.157 0 .285-.127.285-.283 0-.157-.128-.284-.285-.284H.855zm15.398 1.134H.855c-.471 0-.855-.382-.855-.85 0-.469.384-.85.855-.85h15.398c.471 0 .855.381.855.85 0 .468-.384.85-.855.85z"
                />
                <mask id="oilfvm5uvb" fill="#fff">
                  <use xlinkHref="#b6u453fn5a" />
                </mask>
                <path
                  fill={color}
                  d="M1.14 14.167h14.828v-6.8H1.14v6.8zm15.113.566H.855c-.157 0-.285-.126-.285-.283V7.083c0-.156.128-.283.285-.283h15.398c.157 0 .285.127.285.283v7.367c0 .157-.128.283-.285.283z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M2.281 13.033h5.133V8.5H2.28v4.533zM7.7 13.6H1.996c-.157 0-.285-.127-.285-.283v-5.1c0-.157.128-.284.285-.284h5.703c.157 0 .285.127.285.284v5.1c0 .156-.128.283-.285.283zM9.695 13.033h5.132V8.5H9.695v4.533zm5.417.567H9.41c-.158 0-.286-.127-.286-.283v-5.1c0-.157.128-.284.286-.284h5.702c.158 0 .285.127.285.284v5.1c0 .156-.127.283-.285.283zM14.827 15.3h.57v-.567h-.57v.567zm.856.567h-1.141c-.157 0-.285-.127-.285-.284V14.45c0-.157.128-.283.285-.283h1.14c.158 0 .286.126.286.283v1.133c0 .157-.128.284-.285.284z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M15.112 17c-.157 0-.285-.127-.285-.283v-1.134c0-.156.128-.283.285-.283.158 0 .285.127.285.283v1.134c0 .156-.127.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M15.683 17h-1.141c-.157 0-.285-.127-.285-.283 0-.157.128-.284.285-.284h1.14c.158 0 .286.127.286.284 0 .156-.128.283-.285.283M1.71 15.3h.571v-.567h-.57v.567zm.856.567h-1.14c-.158 0-.285-.127-.285-.284V14.45c0-.157.127-.283.285-.283h1.14c.158 0 .285.126.285.283v1.133c0 .157-.127.284-.285.284z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M1.996 17c-.157 0-.285-.127-.285-.283v-1.134c0-.156.128-.283.285-.283.157 0 .285.127.285.283v1.134c0 .156-.128.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M2.566 17h-1.14c-.158 0-.285-.127-.285-.283 0-.157.127-.284.285-.284h1.14c.158 0 .285.127.285.284 0 .156-.127.283-.285.283M13.401 10.483c-.157 0-.285-.126-.285-.283v-.283h-1.71v.283c0 .157-.128.283-.286.283-.157 0-.285-.126-.285-.283v-.567c0-.156.128-.283.285-.283h2.281c.158 0 .286.127.286.283v.567c0 .157-.128.283-.286.283M2.281 10.483h5.133V8.5H2.28v1.983zm5.418.567H1.996c-.157 0-.285-.127-.285-.283v-2.55c0-.157.128-.284.285-.284h5.703c.157 0 .285.127.285.284v2.55c0 .156-.128.283-.285.283z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M2.281 13.033h5.133V11.05H2.28v1.983zM7.7 13.6H1.996c-.157 0-.285-.127-.285-.283v-2.55c0-.157.128-.284.285-.284h5.703c.157 0 .285.127.285.284v2.55c0 .156-.128.283-.285.283zM5.703 9.35H3.992c-.157 0-.285-.127-.285-.283 0-.157.128-.284.285-.284h1.71c.158 0 .286.127.286.284 0 .156-.128.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M4.847 9.917c-.157 0-.285-.127-.285-.284v-.566c0-.157.128-.284.285-.284.158 0 .285.127.285.284v.566c0 .157-.127.284-.285.284M5.703 11.9H3.992c-.157 0-.285-.127-.285-.283 0-.157.128-.284.285-.284h1.71c.158 0 .286.127.286.284 0 .156-.128.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M4.847 12.467c-.157 0-.285-.127-.285-.284v-.566c0-.157.128-.284.285-.284.158 0 .285.127.285.284v.566c0 .157-.127.284-.285.284M10.608 3.967c.128.569.49 1.7 1.34 1.7h2.337c.838 0 1.206-1.131 1.338-1.7h-5.015zm3.677 2.266h-2.337c-1.631 0-1.953-2.412-1.966-2.515-.01-.08.015-.161.069-.222.054-.061.132-.096.214-.096h5.703c.082 0 .16.035.214.096.054.06.079.142.069.222-.013.103-.335 2.515-1.966 2.515z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M15.397 3.967c-.157 0-.285-.127-.285-.284V1.417c0-.469-.383-.85-.855-.85h-.57c-.315 0-.57.254-.57.566 0 .157-.128.284-.286.284-.157 0-.285-.127-.285-.284 0-.625.512-1.133 1.14-1.133h.57c.787 0 1.427.635 1.427 1.417v2.266c0 .157-.128.284-.286.284"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M15.397 3.967c-.073 0-.146-.028-.201-.083l-.856-.85c-.111-.111-.111-.29 0-.401.112-.11.292-.11.404 0l.855.85c.112.11.112.29 0 .4-.056.056-.129.084-.202.084M6.558 3.967h1.14V3.4h-1.14v.567zm1.426.566H6.273c-.157 0-.285-.126-.285-.283V3.117c0-.157.128-.284.285-.284h1.71c.158 0 .286.127.286.284V4.25c0 .157-.128.283-.285.283z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M8.554 3.4H5.703c-.158 0-.285-.127-.285-.283 0-.157.127-.284.285-.284h2.851c.158 0 .285.127.285.284 0 .156-.127.283-.285.283M2.281 3.967h1.14V3.4h-1.14v.567zm1.426.566H1.996c-.157 0-.285-.126-.285-.283V3.117c0-.157.128-.284.285-.284h1.71c.158 0 .286.127.286.284V4.25c0 .157-.128.283-.285.283z"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M4.277 3.4H1.426c-.158 0-.285-.127-.285-.283 0-.157.127-.284.285-.284h2.851c.157 0 .285.127.285.284 0 .156-.128.283-.285.283M1.426 5.667h7.128v-.763c0-.205-.167-.37-.373-.37H1.798c-.205 0-.372.165-.372.37v.763zm7.413.566H1.141c-.158 0-.286-.126-.286-.283V4.904c0-.517.423-.937.943-.937h6.383c.52 0 .943.42.943.937V5.95c0 .157-.127.283-.285.283zM8.269 1.133H1.426c-.158 0-.285-.126-.285-.283 0-.157.127-.283.285-.283h6.843c.157 0 .285.126.285.283 0 .157-.128.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
                <path
                  fill={color}
                  d="M2.281 1.983c-.157 0-.285-.126-.285-.283V.283c0-.156.128-.283.285-.283.158 0 .285.127.285.283V1.7c0 .157-.127.283-.285.283M3.992 1.983c-.157 0-.285-.126-.285-.283V.283c0-.156.128-.283.285-.283.157 0 .285.127.285.283V1.7c0 .157-.128.283-.285.283M5.703 1.983c-.158 0-.285-.126-.285-.283V.283c0-.156.127-.283.285-.283.157 0 .285.127.285.283V1.7c0 .157-.128.283-.285.283M7.414 1.983c-.158 0-.286-.126-.286-.283V.283c0-.156.128-.283.286-.283.157 0 .285.127.285.283V1.7c0 .157-.128.283-.285.283"
                  mask="url(#oilfvm5uvb)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
