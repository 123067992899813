import React, { useEffect, useState } from 'react';
import { Button, Row, Form, Switch, Select, Spin, Result, message } from 'antd';
import { Admin } from '../queries';
import { getApolloClient } from '../clients/apollo';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 8,
  },
};

let client = null;

export default function ManualPromotion(props) {
  const {
    listingId,
    commercialAdId,
    promotedItemType,
    isCommerical,
    campaignType,
  } = props;
  const [type, setType] = useState(campaignType);
  const [isLoading, setLoading] = useState(false);
  const [status, setStatus] = useState('INIT');
  const [activation, setActivation] = useState(false);
  const [withProPhotography, setWithProPhotography] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedAdditionalCampaign, setSelectedAdditionalCampaign] =
    useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [additionalCampaigns, setAdditionalCampaigns] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [form] = Form.useForm();
  const getCampaigns = async () => {
    try {
      setLoading(true);
      const campaignsResponse = await client.query({
        query: Admin.Campaign.GET_CAMPAIGNS,
        variables: {
          where: {
            type: {
              in: [type, 'ON_FACEBOOK'],
            },
            isActive: {
              equals: true,
            },
          },
          orderBy: {
            createdAt: 'desc',
          },
        },
      });
      setCampaigns(
        campaignsResponse.data.campaigns.filter((c) => c.type === type)
      );
      setAdditionalCampaigns(
        campaignsResponse.data.campaigns.filter((c) => c.type === 'ON_FACEBOOK')
      );
    } catch (error) {
      console.log(
        '🚀 ~ file: promote.js ~ line 57 ~ getCampaigns ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    try {
      setLoading(true);
      const paymentMethodsResponse = await client.query({
        query: Admin.PaymentMethod.GET_PAYMENT_METHODS,
        variables: {
          where: {
            isActive: {
              equals: true,
            },
          },
          orderBy: {
            createdAt: 'desc',
          },
        },
      });
      setPaymentMethods(paymentMethodsResponse.data.paymentMethods);
    } catch (error) {
      console.log(
        '🚀 ~ file: promote.js ~ line 111 ~ getPaymentMethods ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
  }, []);

  useEffect(() => {
    if (type) {
      getCampaigns();
    }
  }, [type]);

  useEffect(() => {
    if (selectedCampaign && additionalCampaigns.length === 0) {
      getPaymentMethods();
    }
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedAdditionalCampaign && additionalCampaigns.length > 0) {
      getPaymentMethods();
    }
  }, [selectedAdditionalCampaign]);

  return (
    <Row justify="center" align="middle" style={{ marginTop: '4rem' }}>
      <Spin spinning={isLoading} tip="Please wait..." />

      {status === 'SUCCESS' && (
        <Result status="success" title="Successfully Promoted!" />
      )}

      {status === 'INIT' && (
        <Form {...layout} form={form} name="create-campaign">
          {!isLoading && (
            <Form.Item
              name="type"
              label="Type"
              rules={[
                {
                  required: true,
                  message: 'Please select campaign type!',
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                disabled={isCommerical}
                defaultValue={type}
                onChange={(value) => setType(value)}
              >
                <Option value="FEATURED">Featured</Option>
                <Option value="PRIME">Prime</Option>
              </Select>
            </Form.Item>
          )}

          {campaigns.length > 0 && !isLoading && (
            <Form.Item
              name="campaign"
              label="Campaign"
              rules={[
                {
                  required: true,
                  message: 'Please select campaign!',
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                onChange={async (campaignId) => {
                  setSelectedCampaign(campaignId);
                }}
              >
                {campaigns.map((campaign) => (
                  <Option key={campaign.id + Date.now()} value={campaign.id}>
                    {campaign.price} JOD =&gt; {campaign.validForDays} Days
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {additionalCampaigns.length > 0 && !isLoading && (
            <Form.Item
              name="additionalCampaign"
              label="Facebook Campaign"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                onChange={async (campaignId) => {
                  if (campaignId === -1) {
                    setSelectedAdditionalCampaign(null);
                  } else {
                    setSelectedAdditionalCampaign(campaignId);
                  }
                }}
              >
                {[{ id: '-1' }, ...additionalCampaigns].map((campaign) => (
                  <Option key={campaign.id + Date.now()} value={campaign.id}>
                    {campaign.id === '-1'
                      ? 'None'
                      : `${campaign.price} JOD => ${campaign.validForDays} Days`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedAdditionalCampaign && !isLoading && (
            <Form.Item
              name="withProPhotography"
              label={'With Pro Photography'}
              style={{
                paddingLeft: '11px',
              }}
            >
              <div>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(value) => {
                    setWithProPhotography(value);
                  }}
                ></Switch>
              </div>
            </Form.Item>
          )}

          {paymentMethods.length > 0 && !isLoading && (
            <Form.Item
              name="paymentMethod"
              label="PaymentMethod"
              rules={[
                {
                  required: true,
                  message: 'Please select payment method!',
                },
              ]}
            >
              <Select
                style={{ width: 200 }}
                onChange={async (paymentMethod) => {
                  setSelectedPaymentMethod(paymentMethod);
                }}
              >
                {paymentMethods.map((pm) => (
                  <Option key={pm.id + Date.now()} value={pm.id}>
                    {pm.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}

          {selectedPaymentMethod && !isLoading && (
            <Form.Item
              name="activation"
              label={'Activate Immediately'}
              style={{
                paddingLeft: '11px',
              }}
            >
              <div>
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  onChange={(value) => {
                    setActivation(value);
                  }}
                ></Switch>
              </div>
            </Form.Item>
          )}

          {selectedPaymentMethod && !isLoading && (
            <Form.Item
              style={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '3rem',
              }}
            >
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    const result = await client.mutate({
                      variables: {
                        ...(promotedItemType === 'REAL_ESTATE' ||
                        promotedItemType === 'HOUSING'
                          ? {
                              listingId,
                            }
                          : {
                              commercialAdId,
                            }),
                        campaignId: selectedCampaign,
                        ...(selectedAdditionalCampaign &&
                          selectedAdditionalCampaign !== '-1' && {
                            additionalCampaignId: selectedAdditionalCampaign,
                          }),
                        paymentMethodId: selectedPaymentMethod,
                        type,
                        activation,
                        withProPhotography,
                      },
                      mutation: Admin.Order.MANUAL_PROMOTION,
                    });

                    setStatus(
                      result.data &&
                        result.data.manualPromotion &&
                        result.data.manualPromotion.id
                        ? 'SUCCESS'
                        : 'FAIL'
                    );
                  } catch (error) {
                    console.log(
                      '🚀 ~ file: promote.js ~ line 313 ~ onClick={ ~ error',
                      error
                    );

                    setStatus('FAIL');
                    message.error(error.message, 5);
                  }
                }}
              >
                Save
              </Button>
            </Form.Item>
          )}
        </Form>
      )}

      {status === 'FAIL' && <Result status="error" title="Promotion Failed" />}
    </Row>
  );
}
