import React, { lazy, Suspense } from 'react';

const LazyEditCommercialAd = lazy(() => import('./EditCommercialAd'));

const EditCommercialAd = (props) => (
  <Suspense fallback={null}>
    <LazyEditCommercialAd {...props} />
  </Suspense>
);

export default EditCommercialAd;
