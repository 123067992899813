import React from 'react';
import { Button, Row } from 'antd';
import { EyeOutlined, EditTwoTone, EyeTwoTone } from '@ant-design/icons';

import * as moment from 'moment';

import Insights from './insights.modal';

import { getColumnSearchProps } from './search-props';
const categories = [
  { text: 'شقة', value: 'APARTMENT' },
  { text: 'فيلا/منزل', value: 'HOUSE' },
  { text: 'مزرعة', value: 'FARM' },
  { text: 'أرض', value: 'LAND' },
  { text: 'عمارة', value: 'BUILDING' },
  { text: 'تجاري', value: 'COMMERCIAL_PROPERTY' },
  { text: 'تطوير', value: 'DEVELOPMENT' },
  { text: 'قيد الإنشاء', value: 'UNDER_CONSTRACTION' },
];
const listingStatus = [
  { text: 'فعال', value: 'ACTIVE' },
  { text: 'معلق', value: 'PENDING' },
  { text: 'متوقف', value: 'STOPPED' },
  { text: 'محذوف', value: 'DELETED' },
  { text: 'مرفوض', value: 'REJECTED' },
  { text: 'فعال-معدل', value: 'ACTIVEUPDATED' },
  { text: 'مرفوض-معدل', value: 'REJECTEDUPDATED' },
];
const types = [
  { text: 'للبيع', value: 'SALE' },
  { text: 'للايجار', value: 'RENT' },
];

const sources = [
  { text: 'الموقع', value: 'WEB' },
  { text: 'التطبيق', value: 'MOBILE' },
];
const owner = [
  { text: 'من المالك', value: true },
  { text: 'وسيط', value: false },
];

const activeCampaignTypes = [
  { text: 'Featured', value: 'FEATURED' },
  { text: 'Prime', value: 'PRIME' },
  { text: 'On Facebook', value: 'ON_FACEBOOK' },
];

const CAMPAIGN_TYPES = {
  FEATURED: 'Featured',
  PRIME: 'Prime',
  ON_FACEBOOK: 'On Facebook',
};

export const columns = ({
  props,
  history,
  dataGridCurrentEdited,
  filteredInfo,
  sortedInfo,
  isInsightsModalOpen,
  selectedListingForInsights,
  setIsInsightsModalOpen,
  setSelectedListingForInsights,
  handleSearch,
  handleReset,
  listingsDataGrid,
}) => [
  {
    title: '',
    dataIndex: '',
    key: 'actions',
    render: (text, record) => (
      <div>
        {(record.status === 'ACTIVE' || record.status === 'ACTIVEUPDATED') && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              type="default"
              style={{ marginRight: 10 }}
              icon={<EyeOutlined />}
              size="small"
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_HOST_NAME}/properties/${record.url}`,
                  '_blank'
                )
              }
            ></Button>

            <Button
              type="primary"
              icon={<EditTwoTone twoToneColor="white" />}
              size="small"
              onClick={() => {
                dataGridCurrentEdited(record.no);
                history.push(`/listing/edit/${record.id}`);
              }}
            ></Button>
          </div>
        )}

        {!['ACTIVE', 'ACTIVEUPDATED'].includes(record.status) && (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="primary"
              icon={<EditTwoTone twoToneColor="white" />}
              size="small"
              onClick={() => {
                dataGridCurrentEdited(record.no);
                history.push(`/listing/edit/${record.id}`);
              }}
            ></Button>
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    ...getColumnSearchProps('no', handleSearch, handleReset, listingsDataGrid),
    render: (no) => <span id={no}>{no}</span>,
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    ...getColumnSearchProps(
      'title',
      handleSearch,
      handleReset,
      listingsDataGrid
    ),
    render: (text, record) => (
      <div
        style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        className="long-text"
      >
        {text}
      </div>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    filters: listingStatus,
    filteredValue: filteredInfo.status || null,
    onFilter: (value, record) => record.status === value,
    render: (status) => {
      const arr = listingStatus.filter((item) => item.value === status);
      return (
        <span
          style={
            ['DELETED'].indexOf(status) > -1
              ? { color: 'red' }
              : ['REJECTED'].indexOf(status) > -1
              ? { color: 'blue' }
              : ['REJECTEDUPDATED'].indexOf(status) > -1
              ? { color: 'orange' }
              : ['ACTIVEUPDATED'].indexOf(status) > -1
              ? { color: 'grey' }
              : ['PENDING'].indexOf(status) > -1
              ? { color: 'red' }
              : ['ACTIVE'].indexOf(status) > -1
              ? { color: 'green' }
              : {}
          }
        >
          {arr[0].text}
        </span>
      );
    },
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    filters: categories,
    filteredValue: filteredInfo.category || null,
    onFilter: (value, record) => record.category === value,
    render: (category) => {
      const arr = categories.filter((item) => item.value === category);
      return arr && arr[0] ? arr[0].text : '';
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    filters: types,
    filteredValue: filteredInfo.type || null,
    onFilter: (value, record) => record.type === value,
    render: (type) => {
      const arr = types.filter((item) => item.value === type);
      return arr && arr.length > 0 ? arr[0].text : null;
    },
  },
  {
    title: 'Housing',
    dataIndex: 'isHousing',
    key: 'isHousing',
    filters: [
      {
        text: 'Yes',
        value: true,
      },
      {
        text: 'No',
        value: false,
      },
    ],
    filteredValue: filteredInfo.isHousing || null,
    onFilter: (value, record) => record.isHousing === value,
    render: (isHousing) => {
      return isHousing ? 'Yes' : 'No';
    },
  },
  {
    title: 'Author Number',
    dataIndex: 'author',
    key: 'mobile',
    ...getColumnSearchProps(
      'mobile',
      handleSearch,
      handleReset,
      listingsDataGrid
    ),
    render: (author) => (author ? author.mobile : ''),
  },
  {
    title: 'Author',
    dataIndex: 'author',
    key: 'name',
    ...getColumnSearchProps(
      'name',
      handleSearch,
      handleReset,
      listingsDataGrid
    ),
    render: (author) => (author ? author.name : ''),
  },

  {
    title: 'Area',
    dataIndex: 'location',
    key: 'area',
    ...getColumnSearchProps(
      'location',
      handleSearch,
      handleReset,
      listingsDataGrid
    ),
    render: (location) => {
      if (location) {
        if (location.listedArea && location.listedArea.name) {
          return location.listedArea.name;
        } else if (location.area) {
          return location.area;
        }
      } else {
        return 'Incorrect Data';
      }
    },
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    sorter: (a, b) => {},
    sortOrder: sortedInfo.columnKey === 'price' && sortedInfo.order,
    render: (price) =>
      price.price
        ? price.price
        : price.daily
        ? price.daily
        : price.yearly
        ? price.yearly
        : '',
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => moment(createdAt).format('HH:mm - DD/MM/YY'),
    sorter: (a, b) => {},
    sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
  },
  {
    title: 'Updated at',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt) => moment(updatedAt).format('HH:mm - DD/MM/YY'),
    sorter: (a, b) => {},
    sortOrder: sortedInfo.columnKey === 'updatedAt' && sortedInfo.order,
  },
  {
    title: 'Active Campaign Types',
    dataIndex: 'activeCampaignType',
    key: 'activeCampaignType',
    filters: activeCampaignTypes,
    onFilter: (value, record) =>
      [
        { status: 'ACTIVE', campaign: { type: record.activeCampaignType } },
        ...record.orderedCampaigns,
      ].find((oc) => oc.status === 'ACTIVE' && oc.campaign.type === value),
    render: (activeCampaignType, row) => {
      const activeCampaignTypes = new Set([activeCampaignType]);
      if (row && row.orderedCampaigns && row.orderedCampaigns.length > 0) {
        row.orderedCampaigns.forEach((oc) => {
          if (oc.status === 'ACTIVE') {
            activeCampaignTypes.add(oc.campaign.type);
          }
        });
      }
      return [...activeCampaignTypes].map((type, index) => (
        <div key={index}>{CAMPAIGN_TYPES[type]}</div>
      ));
    },
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    filters: sources,
    filteredValue: filteredInfo.source || null,
    onFilter: (value, record) => record.source === value,
    render: (source) => {
      const found = sources.find((item) => item.value === source);
      return (found && found.text) || null;
    },
  },
  {
    title: 'Is Owner',
    dataIndex: 'isOwner',
    key: 'isOwner',
    filters: owner,
    filteredValue: filteredInfo.isOwner || null,
    onFilter: (value, record) => record.isOwner === value,
    render: (value) => {
      const found = owner.find((item) => item.value === value);
      return (found && found.text) || null;
    },
  },
  {
    title: 'Insights',
    key: 'all-insights',
    render: (id, row) =>
      ['ACTIVE', 'ACTIVEUPDATED', 'PAUSED', 'STOPPED', 'DELETED'].includes(
        row.status
      ) ? (
        <Row
          key={`row-${row.id}`}
          style={{
            justifyContent: 'center',
          }}
        >
          <Button
            key={`row-show-button-${row.id}`}
            type="default"
            icon={<EyeTwoTone style={{ fontSize: '16px' }} />}
            onClick={() => {
              setIsInsightsModalOpen(true);
              setSelectedListingForInsights(row);
            }}
          >
            Show
          </Button>
          {isInsightsModalOpen &&
            selectedListingForInsights &&
            selectedListingForInsights.id === row.id && (
              <Insights
                listing={selectedListingForInsights}
                isModalOpen={isInsightsModalOpen}
                closeModal={() => {
                  setIsInsightsModalOpen(false);
                  setSelectedListingForInsights(null);
                }}
                {...props}
              />
            )}
        </Row>
      ) : (
        ''
      ),
  },
];
