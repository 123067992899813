import React, { Component } from 'react';
import styled from 'styled-components';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import { key } from '../../utils/map';

const Search = styled.div`
  position: relative;
  img {
    position: absolute;
    right: 0;
    z-index: 10;
    top: -16px;
    margin: 0 5px;
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
`;

export default class GoogleSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      value: '',
    };
  }

  handleInputChange = (e) => {
    this.setState({ search: e.target.value, value: e.target.value });
  };

  resetSearch = () => {
    this.setState({ search: '', value: '' });
  };

  handleSelectSuggest(suggest) {
    this.setState({ search: '', value: suggest.formatted_address });

    const {
      geometry: { location },
      address_components,
      formatted_address,
    } = suggest;

    // const ne = viewport.getNorthEast();
    // const sw = viewport.getSouthWest();

    let city = '',
      area = '';

    address_components.forEach((address) => {
      if (address.types.indexOf('locality') > -1) {
        city = address.short_name;
      }
      if (address.types.indexOf('sublocality') > -1) {
        area = address.short_name;
      }
    });

    this.setSearchObject({
      lat: location.lat(),
      lng: location.lng(),
      city,
      area,
      address: formatted_address,
    });
  }

  setSearchObject = (obj) => {
    this.props.getLocation(obj);
  };

  render() {
    const { isValid } = this.props;
    const { search, value } = this.state;
    return (
      <React.Fragment>
        <ReactGoogleMapLoader
          params={{
            key,
            libraries: 'places,geocode',
            language: 'ar',
          }}
          render={(googleMaps) =>
            googleMaps && (
              <div className="google-search-section">
                <ReactGooglePlacesSuggest
                  autocompletionRequest={{
                    input: search,
                    componentRestrictions: {
                      country: 'JO',
                    },
                  }}
                  googleMaps={googleMaps}
                  onSelectSuggest={this.handleSelectSuggest.bind(this)}
                  textNoResults="لا يوجد اماكن مطابقة "
                >
                  <input
                    type="text"
                    value={value}
                    placeholder=".. بحث"
                    onChange={this.handleInputChange}
                    onFocus={this.resetSearch}
                    className="search-input"
                    style={!isValid ? { border: '1px solid red' } : {}}
                  />
                </ReactGooglePlacesSuggest>
              </div>
            )
          }
        />
        <Search>
          <img src={'/static/icons/searchbar.png'} alt="" />
        </Search>
      </React.Fragment>
    );
  }
}
