import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import './scroll.css';

export default class InfiniteListExample extends React.Component {
  state = {
    data: this.props.data,
    loading: false,
    hasMore: true,
  };

  handleInfiniteOnLoad = () => {
    let { data } = this.state;
    this.setState({
      loading: true,
    });
    if (data.length > 6) {
      this.setState({
        hasMore: false,
        loading: false,
      });
      return;
    }

    this.setState({
      data,
      loading: false,
    });
  };

  render() {
    return (
      <div className="demo-infinite-container">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={this.handleInfiniteOnLoad}
          hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          {this.props.component}
        </InfiniteScroll>
      </div>
    );
  }
}
