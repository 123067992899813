import React from 'react';
import { Row, Col, Input, Button, Spin } from 'antd';
import { Title } from '../../components/ui/Styled';
import Switch from '../../components/ui/Switch';
import ListingProps from './listingPropsComp';
import { ElementPriorityForCommercialProperty } from '../../utils/commercialPropertyUtils';
import { Admin } from '@nawasrah/khareta-graphql-queries';
const { saleIncomeAmountPriority } = ElementPriorityForCommercialProperty;

const { TextArea } = Input;

export default class EditListingDetails extends React.Component {
  state = {
    id: this.props.listingData.id,
    s3Prefix: this.props.listingData.s3Prefix,
    listingSellingType: this.props.listingData.sellingtype,
    listingCategory: this.props.listingData.category,
    listingTitle: this.props.listingData.title,
    listingDescription: this.props.listingData.description,
    ownerType: this.props.listingData.ownertype,
    paymentPlan: this.props.listingData.paymentplan,
    actualPrice: this.props.listingData.actualprice,
    rentPriceYear: this.props.listingData.rentPriceYear,
    rentPriceDay: this.props.listingData.rentPriceDay,
    listingFeatures: this.props.listingData.features[0],
    isHousing: this.props.listingData.isHousing,
    exchangeable: this.props.listingData.exchangeable,
    incomeAmount: this.props.listingData.incomeAmount,
    isWithValuation: this.props.listingData.isWithValuation,
    valuationFile: '',
    valuationLoading: false,
  };

  callBack(features) {
    this.setState({ listingFeatures: features });
  }
  async componentDidUpdate(prevProps) {
    if (this.props.timing !== prevProps.timing) {
      this.props.callback(this.state);
    }
  }

  deleteValuationFile = async () => {
    this.setState({ valuationLoading: true });
    const variables = {
      where: { id: this.state.id },
      data: {
        isWithValuation: {
          set: false,
        },
        s3Prefix: {
          set: this.state.s3Prefix,
        },
      },
    };

    try {
      const res = await this.props.client.mutate({
        variables,
        mutation: Admin.Listing.UPLOAD_OR_DELETE_VALUATION,
      });
      this.props.setState({
        isWithValuation: res.data.uploadOrDeleteValuation.isWithValuation,
      });
      this.setState({
        isWithValuation: res.data.uploadOrDeleteValuation.isWithValuation,
        valuationLoading: false,
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: listingDetailsEdit.js:62 ~ EditListingDetails ~ uploadValuationFile= ~ error:',
        error
      );
      this.setState({ valuationLoading: false });
    }
  };

  uploadValuationFile = async () => {
    const files = this.state.valuationFile;
    if (files.length > 0) {
      this.setState({ valuationLoading: true });
      const filesToCreate = files.map((file) => ({ file }));
      const variables = {
        where: { id: this.state.id },
        data: {
          isWithValuation: {
            set: true,
          },
          s3Prefix: {
            set: this.state.s3Prefix,
          },
        },
        files: filesToCreate,
      };
      try {
        const res = await this.props.client.mutate({
          variables,
          mutation: Admin.Listing.UPLOAD_OR_DELETE_VALUATION,
        });

        this.props.setState({
          isWithValuation: res.data.uploadOrDeleteValuation.isWithValuation,
        });
        this.setState({
          isWithValuation: res.data.uploadOrDeleteValuation.isWithValuation,
          valuationLoading: false,
        });
      } catch (error) {
        console.log(
          '🚀 ~ file: listingDetailsEdit.js:62 ~ EditListingDetails ~ uploadValuationFile= ~ error:',
          error
        );
        this.setState({ valuationLoading: false });
      }
    }
  };

  NotFarm = () => {
    return this.state.listingSellingType === 'SALE' ? (
      <div
        style={{
          textAlign: 'end',
        }}
      >
        <Title> هل تقبل التقسيط</Title>

        <Switch
          name="paymentPlan"
          values={['yes', 'no']}
          labels={['نعم', 'لا']}
          align={'center'}
          style={{
            border: `${
              this.state.listingSellingType === 'SALE' &&
              this.state.paymentPlan !== null
                ? ''
                : '4px solid red'
            }`,
          }}
          selected={
            this.state.paymentPlan !== null
              ? this.state.paymentPlan
                ? 'yes'
                : 'no'
              : null
          }
          onClick={(value) => {
            this.setState({
              paymentPlan: value === 'yes' ? true : false,
            });
          }}
        />

        <Title> هل تقبل البدل؟</Title>

        <Switch
          name="exchangeable"
          values={['yes', 'no']}
          labels={['نعم', 'لا']}
          align={'center'}
          style={{
            border: `${
              this.state.exchangeable !== null ? '' : '4px solid red'
            }`,
          }}
          selected={
            this.state.exchangeable !== null
              ? this.state.exchangeable
                ? 'yes'
                : 'no'
              : null
          }
          onClick={(value) => {
            this.setState({
              exchangeable: value === 'yes' ? true : false,
            });
          }}
        />
        {this.props.commercialType}
        {saleIncomeAmountPriority['SALE'].includes(
          this.state.listingFeatures.commercialType
        ) && (
          <>
            <Title>الدخل الحالي</Title>
            <Input
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.incomeAmount !== null &&
                  this.state.incomeAmount > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              name="incomeAmount"
              placeholder=""
              type="number"
              onChange={(value) => {
                this.setState({
                  incomeAmount:
                    value.target.value === ''
                      ? null
                      : parseInt(value.target.value),
                });
              }}
              value={this.state.incomeAmount}
            />
          </>
        )}

        <Title>
          {' '}
          <span>(دينار أردني)</span> السعر
        </Title>
        <Input
          style={{
            width: 200,
            direction: 'rtl',
            border: `${
              this.state.actualPrice !== null && this.state.actualPrice > 0
                ? ''
                : '4px solid red'
            }`,
          }}
          name="actualPrice"
          placeholder=""
          type="number"
          onChange={(value) => {
            this.setState({
              actualPrice:
                value.target.value === '' ? null : parseInt(value.target.value),
            });
          }}
          value={this.state.actualPrice}
        />
      </div>
    ) : this.state.listingCategory === 'LAND' ? (
      <div
        style={{
          textAlign: 'end',
        }}
      >
        <Title>
          {' '}
          <span>(دينار أردني)</span> &nbsp; الإيجار الشهري
        </Title>
        <Input
          name="actualPrice"
          placeholder=""
          type="number"
          style={{
            width: 200,
            direction: 'rtl',
            border: `${
              this.state.actualPrice !== null && this.state.actualPrice > 0
                ? ''
                : '4px solid red'
            }`,
          }}
          value={this.state.actualPrice}
          onChange={(value) => {
            this.setState({
              actualPrice:
                value.target.value === '' ? null : parseInt(value.target.value),
            });
          }}
        />
      </div>
    ) : (
      <div
        style={{
          textAlign: 'end',
        }}
      >
        {saleIncomeAmountPriority['RENT'].includes(
          this.state.listingFeatures.commercialType
        ) && (
          <>
            <Title>الدخل الحالي</Title>
            <Input
              style={{
                width: 200,
                direction: 'rtl',
                border: `${
                  this.state.incomeAmount !== null &&
                  this.state.incomeAmount > 0
                    ? ''
                    : '4px solid red'
                }`,
              }}
              name="incomeAmount"
              placeholder=""
              type="number"
              onChange={(value) => {
                this.setState({
                  incomeAmount:
                    value.target.value === ''
                      ? null
                      : parseInt(value.target.value),
                });
              }}
              value={this.state.incomeAmount}
            />
          </>
        )}
        <Title>
          {' '}
          <span>(دينار أردني)</span> &nbsp; الإيجار السنوي
        </Title>
        <Input
          name="rentPriceYear"
          placeholder=""
          type="number"
          style={{
            width: 200,
            direction: 'rtl',
            border: `${
              this.state.rentPriceYear !== null && this.state.rentPriceYear > 0
                ? ''
                : '4px solid red'
            }`,
          }}
          onChange={(value) => {
            this.setState({
              rentPriceYear:
                value.target.value === '' ? null : parseInt(value.target.value),
            });
          }}
          value={this.state.rentPriceYear}
        />

        <Title>
          {' '}
          <span>(دينار أردني)</span> &nbsp; الإيجار الشهري
        </Title>
        <Input
          name="actualPrice"
          placeholder=""
          type="number"
          style={{
            width: 200,
            direction: 'rtl',
            border: `${
              this.state.actualPrice !== null && this.state.actualPrice > 0
                ? ''
                : '4px solid red'
            }`,
          }}
          value={this.state.actualPrice}
          onChange={(value) => {
            this.setState({
              actualPrice:
                value.target.value === '' ? null : parseInt(value.target.value),
            });
          }}
        />

        <Title>
          {' '}
          <span>اختياري</span> &nbsp; <span>(دينار أردني)</span> &nbsp; الإيجار
          اليومي
        </Title>
        <Input
          name="rentPriceDay"
          placeholder=""
          type="number"
          style={{
            width: 200,
            direction: 'rtl',
            border: `${
              (this.state.rentPriceDay !== null &&
                this.state.rentPriceDay > 0 &&
                this.state.adCategory !== 'COMMERCIAL_PROPERTY') ||
              this.state.adCategory === 'COMMERCIAL_PROPERTY'
                ? ''
                : '4px solid red'
            }`,
          }}
          onChange={(value) => {
            this.setState({
              rentPriceDay:
                value.target.value === '' ? null : parseInt(value.target.value),
            });
          }}
          value={this.state.rentPriceDay}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <div>
          <Row>
            <Col span={8} offset={16}>
              <Title>التخمين</Title>
              {this.state.valuationLoading ? (
                <div
                  style={{
                    width: '100%',
                    height: '150px',
                    border: 'solid 1px #00a699',
                    padding: '5px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spin
                    size="large"
                    tip={
                      this.state.isWithValuation
                        ? 'جاري إزالة ملف التخمين . . .'
                        : 'جاري رفع ملف التخمين . . .'
                    }
                    // style={{ margin: '20% 45%' }}
                  />
                </div>
              ) : (
                <div
                  style={{
                    width: '100%',
                    height: '150px',
                    border: 'solid 1px #00a699',
                    padding: '5px',
                    borderRadius: '5px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                  }}
                >
                  {this.state.isWithValuation ? (
                    <div>
                      <a
                        target={'_blank'}
                        href={`${process.env.REACT_APP_IMAGES_BASE_URL}/listings/valuation-pdf/${this.state.s3Prefix}`}
                        // style={{ width: '100%', }}
                      >
                        <Button
                          type="primary"
                          style={{
                            direction: 'rtl',
                            width: '10vw',
                            margin: '10px 0',
                          }}
                        >
                          عرض التخمين
                        </Button>
                      </a>
                      <Button
                        type="danger"
                        style={{
                          direction: 'rtl',
                          width: '10vw',
                          margin: '10px 0',
                        }}
                        onClick={this.deleteValuationFile}
                      >
                        إزالة التخمين
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Input
                        type="file"
                        accept="application/pdf"
                        onChange={(e) => {
                          this.setState({
                            valuationFile: Array.from(e.target.files),
                          });
                        }}
                      />
                      <Button
                        style={{
                          direction: 'rtl',
                          width: '100%',
                          margin: '10px 0',
                        }}
                        type="primary"
                        onClick={this.uploadValuationFile}
                      >
                        تحميل ملف التخمين
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <Title> عنوان الإعلان </Title>
              <div
                style={{
                  textAlign: 'end',
                  border: `${
                    this.state.listingTitle.length < 7
                      ? '4px solid red'
                      : '1px solid black'
                  }`,
                }}
              >
                <TextArea
                  name="title"
                  placeholder="عنوان الإعلان  بما لا يتجاوز سطرين"
                  required
                  style={{
                    direction: 'rtl',
                    width: '100%',
                  }}
                  rows="2"
                  value={this.state.listingTitle}
                  onChange={(value) => {
                    this.setState({
                      listingTitle: value.target.value,
                    });
                  }}
                ></TextArea>
              </div>
              <br></br>

              <Title> تفاصيل الإعلان </Title>
              <div
                style={{
                  textAlign: 'end',
                  border: `${
                    this.state.listingDescription.length < 7
                      ? '4px solid red'
                      : '1px solid black'
                  }`,
                }}
              >
                <TextArea
                  name="title"
                  placeholder="اكتب هنا اي تفاصيل اضافية عن العقار"
                  required
                  style={{ direction: 'rtl', width: '100%' }}
                  rows="6"
                  value={this.state.listingDescription}
                  onChange={(value) => {
                    this.setState({
                      listingDescription: value.target.value,
                    });
                  }}
                ></TextArea>
              </div>
              <Title> هل أنت صاحب العقار</Title>
              <div
                style={{
                  textAlign: 'end',
                }}
              >
                <Switch
                  name="ownerType"
                  values={['yes', 'no']}
                  labels={['نعم، صاحب العقار', 'لا، وسيط تجاري']}
                  align={'center'}
                  style={{
                    border: `${
                      this.state.ownerType === null ? '4px solid red' : ''
                    }`,
                  }}
                  selected={
                    this.state.ownerType != null
                      ? this.state.ownerType
                        ? 'yes'
                        : 'no'
                      : null
                  }
                  onClick={(value) => {
                    this.setState({
                      ownerType: value === 'yes' ? true : false,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Title>صنف العقار</Title>
        <div
          style={{
            textAlign: 'end',
          }}
        >
          <Switch
            style={{
              border: `${
                this.state.listingCategory === null ? '4px solid red' : ''
              }`,
            }}
            name={'category'}
            labels={['شقة', 'فيلا/منزل', 'أرض', 'عمارة', 'مزرعة', 'تجاري']}
            values={[
              'APARTMENT',
              'HOUSE',
              'LAND',
              'BUILDING',
              'FARM',
              'COMMERCIAL_PROPERTY',
            ]}
            onClick={(value) => {
              this.setState({
                listingCategory: value,
              });
            }}
            path="/static/icons/property"
            icons={[
              'apartment.png',
              'villa.png',
              'land.png',
              'buildings.png',
              'farm.png',
              'commercialProperty.png',
              'development.png',
              'underConstruction.png',
            ]}
            selected={this.state.listingCategory}
          />
        </div>
        <div>
          <Title> نوع بيع لاعلان</Title>

          <div
            style={{
              textAlign: 'end',
            }}
          >
            <Switch
              name="sellingType"
              values={['SALE', 'RENT']}
              labels={['  للبيع', 'للايجار']}
              align={'center'}
              style={{
                border: `${
                  this.state.listingSellingType === null ? '4px solid red' : ''
                }`,
              }}
              selected={
                this.state.listingSellingType !== null
                  ? this.state.listingSellingType
                  : null
              }
              onClick={(value) => {
                this.setState({
                  listingSellingType: value,
                });
              }}
            />
          </div>

          {this.state.listingCategory === 'FARM' &&
          this.state.listingSellingType === 'RENT' ? (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              <Title>
                {' '}
                <span>(دينار أردني)</span> &nbsp; الإيجار اليومي
              </Title>
              <Input
                name="rentPriceDay"
                placeholder=""
                type="number"
                style={{
                  width: 200,
                  direction: 'rtl',
                  border: `${
                    this.state.rentPriceDay !== null &&
                    this.state.rentPriceDay > 0
                      ? ''
                      : '4px solid red'
                  }`,
                }}
                onChange={(value) => {
                  this.setState({
                    rentPriceDay:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  });
                }}
                value={this.state.rentPriceDay}
              />
            </div>
          ) : this.state.listingCategory === 'FARM' &&
            this.state.listingSellingType === 'SALE' ? (
            <div
              style={{
                textAlign: 'end',
              }}
            >
              <Title> هل تقبل التقسيط</Title>

              <Switch
                name="paymentPlan"
                values={['yes', 'no']}
                labels={['نعم', 'لا']}
                align={'center'}
                style={{
                  border: `${
                    this.state.listingSellingType === 'SALE' &&
                    this.state.paymentPlan !== null
                      ? ''
                      : '4px solid red'
                  }`,
                }}
                selected={
                  this.state.paymentPlan !== null
                    ? this.state.paymentPlan
                      ? 'yes'
                      : 'no'
                    : null
                }
                onClick={(value) => {
                  this.setState({
                    paymentPlan: value === 'yes' ? true : false,
                  });
                }}
              />

              <Title>
                {' '}
                <span>(دينار أردني)</span> السعر
              </Title>
              <Input
                style={{
                  width: 200,
                  direction: 'rtl',
                  border: `${
                    this.state.actualPrice !== null &&
                    this.state.actualPrice > 0
                      ? ''
                      : '4px solid red'
                  }`,
                }}
                name="actualPrice"
                placeholder=""
                type="number"
                onChange={(value) => {
                  this.setState({
                    actualPrice:
                      value.target.value === ''
                        ? null
                        : parseInt(value.target.value),
                  });
                }}
                value={this.state.actualPrice}
              />
            </div>
          ) : (
            ''
          )}

          {this.state.listingCategory !== 'FARM' ? this.NotFarm() : ''}

          <ListingProps
            category={this.state.listingCategory}
            type={this.state.listingSellingType}
            features={{
              ...this.state.listingFeatures,
              floorRange:
                this.state.listingFeatures.floorRange &&
                this.state.listingFeatures.floorRange.map(
                  (item) => item && item.value
                ),
              bathroomsRange:
                this.state.listingFeatures.bathroomsRange &&
                this.state.listingFeatures.bathroomsRange.map(
                  (item) => item && item.value
                ),
              bedroomsRange:
                this.state.listingFeatures.bedroomsRange &&
                this.state.listingFeatures.bedroomsRange.map(
                  (item) => item && item.value
                ),
              floorsApartmentsRange:
                this.state.listingFeatures.floorsApartmentsRange &&
                this.state.listingFeatures.floorsApartmentsRange.map(
                  (item) => item && item.value
                ),
            }}
            isHousing={this.state.isHousing}
            callBack={this.callBack.bind(this)}
            state={this.props.state}
            setState={this.props.setState}
          ></ListingProps>
        </div>
      </div>
    );
  }
}
