import React from 'react';
export default function Kitchen({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M5.85 15.871h5.409v-3.346h-5.41v3.346zm.397-2.977h4.614v2.608H6.247v-2.608z"
                  transform="translate(-313 -1172) translate(0 1078) translate(183 84) translate(130.828 10)"
                />
                <path
                  d="M5.082 16.885H17.028v-6.754h-3.506V9.065c0-.475.45-.862 1.005-.862.555 0 1.006.387 1.006.862 0 .102.089.185.199.185.11 0 .199-.083.199-.185 0-.679-.63-1.232-1.404-1.232-.773 0-1.403.553-1.403 1.232v1.066h-1.633c-.006-.149-.136-.267-.297-.267H9.259c-.16 0-.291.119-.297.267h-.816c-.006-.149-.136-.267-.297-.267H5.914c-.16 0-.29.119-.297.267H.08v6.754h5.002zm6.945-.37H5.082v-4.634h6.945v4.635zm.397-6.015h4.206v1.887h-4.206V10.5zm0 6.015v-3.759h4.206v3.76h-4.206zM5.082 10.5h6.945v1.011H5.082v-1.01zM.478 16.515V10.5h4.206V16.515H.478zM5.082.115H.08v3.108h5.002V.115zm-.398 2.738H.478V.485h4.206v2.368zM12.027 3.223h5.001V.115h-5.001v3.108zm.397-2.738h4.206v2.368h-4.206V.485zM7.161.115v2.52L5.14 4.58c-.016.015-.027.033-.037.052l-.006.016c-.006.016-.01.032-.01.05 0 .003-.003.006-.003.01v1.23c0 .102.09.185.2.185h6.541c.11 0 .199-.083.199-.185v-1.23l-.002-.01c-.002-.018-.005-.034-.011-.05-.002-.006-.003-.011-.006-.016-.01-.02-.02-.037-.037-.052L9.891 2.58c-.037-.036-.089-.056-.143-.056h-.037V.115h-2.55zm.398.37h1.755v2.04H7.559V.485zm4.067 5.268H5.482v-.86h6.144v.86zm-.27-1.23H5.753l1.692-1.629h2.22l1.693 1.63z"
                  transform="translate(-313 -1172) translate(0 1078) translate(183 84) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
