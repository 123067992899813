import React from 'react';
import ReactMapGL, {
  NavigationControl,
  FlyToInterpolator,
  Marker,
  SVGOverlay,
  Layer,
  Source,
} from 'react-map-gl';
import * as d3 from 'd3-ease';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import GoogleSearch from './Search/GoogleSearch';
import * as MAP from '../utils/map';
import styled from 'styled-components';
import Switch from './ui/Switch';
import { Container, Title } from './ui/Styled';

const ControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: flex-end;
  position: absolute;
  right: 8px;
  top: 64px;
  z-index: 1000;
`;

const Footer = styled.div`
  /* position: relative;
    bottom: 16px; */
  max-width: 600px;
  z-index: 1000;
  @media (max-width: 600px) {
    position: fixed;
    bottom: 28px;
  }
`;

const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

if (canUseDOM) {
  const { setRTLTextPlugin } = require('mapbox-gl');
  setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.0/mapbox-gl-rtl-text.js'
  );
}

export default class ListingOrCommercialAdLocation extends React.Component {
  constructor(props) {
    super(props);

    const {
      latitude,
      longitude,
      listing: { location: listingLocation = null } = {},
      commercialAd: { location: commercialAdLocation = null } = {},
      polygon,
    } = this.props;

    this._isMounted = false;
    const lat = listingLocation
      ? latitude
      : commercialAdLocation
      ? commercialAdLocation.lat
      : null;
    const lng = listingLocation
      ? longitude
      : commercialAdLocation
      ? commercialAdLocation.lng
      : null;

    const viewport = {
      width: 300,
      height: 300,
      latitude: lat ? parseFloat(lat) : 31.9598168,
      longitude: lng ? parseFloat(lng) : 35.9526277,
      zoom: this.props.zoom || 14,
      pitch: 0,
      bearing: 0,
    };

    this.state = {
      viewport,
      polygons: polygon ? [JSON.parse(polygon)] : [],
      transition: false,
      displayCard: false,
      currentLocation: props.currentLocation,
      bbox: null,
      mapStyle: 'streets-v10',
      marker: {
        lat: 31.9598168,
        lng: 35.9526277,
      },
    };

    this._resize = this._resize.bind(this);
    this.mapRef = React.createRef();
  }

  _onViewportChange = (viewport) => {
    this.setState({
      viewport,
      marker: {
        lat: parseFloat(viewport.latitude),
        lng: parseFloat(viewport.longitude),
      },
    });
    this.props.getValue('lat', viewport.latitude);
    this.props.getValue('lng', viewport.longitude);
  };

  _viewportChangeControl = (viewport) => {
    const { viewport: oldViewport } = this.state;
    this.setState({ viewport: { ...oldViewport, zoom: viewport.zoom } });
  };

  _resize = () => {
    const { endViewport } = this.props;
    let viewport = this.state.viewport;
    if (endViewport) {
      viewport.latitude = endViewport.latitude;
      viewport.longitude = endViewport.longitude;
      viewport.zoom = endViewport.zoom;
    }

    this.setState({
      viewport: {
        ...viewport,
        width: window.innerWidth < 670 ? window.innerWidth : 670,
        height:
          window.innerWidth < 670
            ? window.innerHeight
            : window.innerHeight - 150,
      },
    });
  };

  _goToLocation = (lat, lng) => {
    const latFloat = parseFloat(lat);
    const lngFloat = parseFloat(lng);
    const viewport = {
      ...this.state.viewport,
      longitude: lngFloat,
      latitude: latFloat,
      zoom: 14,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: d3.easeCubic,
    };
    const marker = { lat: latFloat, lng: lngFloat };
    this.setState({ viewport, marker });
    this.props.getValue('lat', latFloat);
    this.props.getValue('lng', lngFloat);
  };

  transitionStart = () => {
    // unused, needed
  };

  transitionEnd = () => {
    //
  };

  getMapStyle = () => {
    const { mapStyle } = this.state;

    return `mapbox://styles/mapbox/${mapStyle}`;
  };
  changeMapStyle = () => {
    let style = this.state.mapStyle;
    if (style.includes('streets-v10')) {
      style = style.replace('streets-v10', 'satellite-v8');
    } else {
      style = style.replace('satellite-v8', 'streets-v10');
    }
    this.setState({
      mapStyle: style,
    });
  };

  _goToCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this._goToLocation(position.coords.latitude, position.coords.longitude);
      });
    }
  };
  componentDidMount() {
    this._isMounted = true;

    const {
      listing: { id: listingId, location: listingLocation = {} } = {},
      commercialAd: { id: commercialAdId, location: commercialAdLocation } = {},
    } = this.props;

    this._isMounted = false;
    const lat = listingLocation
      ? listingLocation.lat
      : commercialAdLocation
      ? commercialAdLocation.lat
      : null;
    const lng = listingLocation
      ? listingLocation.lng
      : commercialAdLocation
      ? commercialAdLocation.lng
      : null;

    const id = listingId || commercialAdId;

    if (!id) this._goToCurrentLocation();
    if (lat && lng) this._goToLocation(lat, lng);

    const mapGl = this.mapRef.current.getMap();
    let language = new MapboxLanguage({ defaultLanguage: 'ar' });
    if (mapGl) {
      mapGl.addControl(language);
    }
    window.addEventListener('resize', this._resize);
    this._resize();
  }

  _getLocation = (location) => {
    this._goToLocation(location.lat, location.lng);
    this.props.getValue('city', location.city);
    this.props.getValue('area', location.area);
    this.props.getValue('address', location.address);
  };

  _onMarkerDragStart = (event) => {};

  _onMarkerDrag = (event) => {};

  _onMarkerDragEnd = (event) => {
    this.setState({
      marker: {
        lng: event.lngLat[0],
        lat: event.lngLat[1],
      },
      viewport: {
        longitude: event.lngLat[0],
        latitude: event.lngLat[1],
      },
    });
    this.props.getValue('lat', event.lngLat[1]);
    this.props.getValue('lng', event.lngLat[0]);
  };

  _getValue = (key, value) => {
    this.props.getValue(key, value);
  };

  _redraw = (project, center, zoom) => {
    const [cx, cy] = project([center.lng, center.lat]);
    const r = zoom * 5;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill="#334a4a"
        stroke="#6bb7b9"
        strokeWidth="4"
        style={{ opacity: 0.5, zIndex: 1000 }}
      />
    );
  };

  render() {
    const { viewport, mapStyle, marker } = this.state;
    const {
      listing: { location: listingLocation = null } = {},
      commercialAd: { location: commercialAdLocation = null } = {},
    } = this.props;
    const location = listingLocation || commercialAdLocation;
    const validation = [];
    return (
      <React.Fragment>
        <ReactMapGL
          ref={this.mapRef}
          mapboxApiAccessToken={MAP.mapboxToken}
          {...viewport}
          onViewportChange={(viewport) => this._onViewportChange(viewport)}
          mapStyle={this.getMapStyle()}
          onTransitionStart={this.transitionStart.bind(this)}
          onTransitionEnd={this.transitionEnd.bind(this)}
        >
          <div
            style={{
              position: 'absolute',
              top: 30,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1001,
            }}
          >
            <GoogleSearch
              style={{ minWidth: 300, width: '60%' }}
              getLocation={this._getLocation}
              isValid={validation.indexOf('location') === -1}
            />
          </div>
          <div
            className="map-btns-panel"
            style={{
              width: '32px',
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            {this.state.mapStyle.includes('satellite-v8') && (
              <button
                onClick={this.changeMapStyle}
                className="map-style"
                style={{
                  backgroundImage: 'url(/images/map-streets.png)',
                  marginTop: '10rem',
                  backgroundColor: 'white',
                  outline: 'none',
                  border: 'none',
                  borderRadius: '12%',
                  backgroundSize: '30px 30px',
                  width: '30px',
                  height: '30px',
                  cursor: `pointer`,
                  boxShadow:
                    '0 2px 2px 0 rgba(0, 0, 0, 0.14) 0 1px 5px 0 rgba(0, 0, 0, 0.12) 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                }}
                aria-label="خريطة القمر الصناعي"
              ></button>
            )}
            {this.state.mapStyle.includes('streets-v10') && (
              <button
                onClick={this.changeMapStyle}
                className="map-style"
                style={{
                  backgroundImage: 'url(/images/map-satellite.png)',
                  marginTop: '10rem',
                  backgroundColor: 'white',
                  outline: 'none',
                  border: 'none',
                  borderRadius: '12%',
                  backgroundSize: '30px 30px',
                  width: '30px',
                  height: '30px',
                  cursor: `pointer`,
                  boxShadow:
                    '0 2px 2px 0 rgba(0, 0, 0, 0.14) 0 1px 5px 0 rgba(0, 0, 0, 0.12) 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                }}
                aria-label="خريطة القمر الصناعي"
              ></button>
            )}
          </div>
          <ControlPanel>
            <div style={{ width: '32px' }}>
              <NavigationControl
                onViewportChange={(viewport) =>
                  this._viewportChangeControl(viewport)
                }
                captureScroll={true}
                captureDrag={true}
              />
            </div>
            <div>
              <button
                style={{
                  backgroundColor: 'white',
                  outline: 'none',
                  border: '1px solid white',
                  boxShadow: '0 0 0 2px rgba(0, 0, 0, 0.1)',
                  borderRadius: '4px',
                  background: `url(/static/images/myLocation.png) no-repeat #fff`,
                  width: '32px',
                  height: '32px',
                  marginTop: '2px',
                  cursor: 'pointer',
                  backgroundSize: 'contain',
                }}
                onClick={() => this._goToCurrentLocation()}
              ></button>
            </div>
            <div>
              <button
                style={{
                  backgroundColor: 'white',
                  outline: 'none',
                  border: 'none',
                  backgroundSize: '44px 44px',
                  backgroundImage: `url(${
                    mapStyle === 'satellite-v8'
                      ? '/static/images/map-satellite.png'
                      : '/static/images/map-streets.png'
                  })`,
                  width: '44px',
                  height: '44px',
                  marginTop: '2px',
                  cursor: 'pointer',
                }}
                onClick={() =>
                  this.setState({
                    mapStyle:
                      mapStyle === 'satellite-v8'
                        ? 'streets-v10'
                        : 'satellite-v8',
                  })
                }
              ></button>
            </div>
          </ControlPanel>

          {location.isAccurate ? (
            <Marker
              latitude={marker.lat}
              longitude={marker.lng}
              offsetLeft={0}
              offsetTop={-25}
              draggable
              onDragStart={this._onMarkerDragStart}
              onDrag={this._onMarkerDrag}
              onDragEnd={this._onMarkerDragEnd}
            >
              <img src="/static/images/mark@2x.png" alt={''} />
            </Marker>
          ) : (
            <SVGOverlay
              onDragStart={this._onMarkerDragStart}
              onDrag={this._onMarkerDrag}
              onDragEnd={this._onMarkerDragEnd}
              redraw={({ project }) =>
                this._redraw(project, marker, viewport.zoom)
              }
            />
          )}
          {this.state.polygons.length > 0 && (
            <>
              <Source
                id="maine"
                type="geojson"
                data={{
                  type: 'FeatureCollection',
                  features: this.state.polygons.map((polygon) => {
                    return {
                      type: 'Feature',
                      geometry: {
                        type: 'Polygon',
                        coordinates: [polygon],
                      },
                    };
                  }),
                }}
              >
                <Layer
                  id="maine"
                  type="fill"
                  source="maine"
                  paint={{
                    'fill-color': '#006391', // blue color fill
                    'fill-opacity': 0.15,
                  }}
                />
                <Layer
                  id="outline"
                  type="line"
                  source="maine"
                  paint={{
                    'line-color': '#006365',
                    'line-width': 2.25,
                  }}
                />
              </Source>
            </>
          )}
        </ReactMapGL>
        <Footer>
          <Container
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Title style={{ justifyContent: 'center', alignItems: 'center' }}>
              {' '}
              موقع العقار دقيق أو تقريبي؟
            </Title>
            <Switch
              name="isAccurate"
              values={['yes', 'no']}
              labels={['موقع دقيق', 'موقع تقريبي']}
              align={'center'}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
              selected={
                location.isAccurate != null
                  ? location.isAccurate
                    ? 'yes'
                    : 'no'
                  : null
              }
              onClick={(value) => this._getValue('isAccurate', value === 'yes')}
            />
          </Container>
          {/* <Container>
                              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                                <FullButton style={{minWidth: 180}} onClick={() => this.props.goToStep(6)}> اضافة </FullButton>
                                <Button style={{width: 80, minWidth: 80, fontSize: 14, justifyContent: 'center'}}
                                onClick={() => this.props.goToStep(4)}> السابق</Button>
                            </div>
                          </Container> */}
        </Footer>
      </React.Fragment>
    );
  }
}
