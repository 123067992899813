import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  padding: 0;
  margin: 0;

  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #4a4a4a;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 15px 5px 15px;
`;

export const FlexList = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 10px);
  padding: 2px;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row-reverse;
  justify-content: ${({ align }) => (align ? align : 'flex-start')};
  align-items: center;
  flex-wrap: nowrap;
  padding: 8px !important;
  margin: 3px;

  min-width: ${({ width }) => (width ? width : '148px')};
  height: 36px;

  border: ${({ active }) =>
    active ? '1px solid #6bb7b9' : '1px solid #e1e1e1'};
  border-radius: 4px;
  background-color: ${({ active }) => (active ? '#6bb7b9' : '#ffffff')};
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;

  &:hover {
    background-color: ${({ active }) => (active ? '#6bb7b9' : '#e1e1e1')};
  }

  &:disabled {
    background-color: #e1e1e1;
  }

  span {
    font-size: 12px;
    margin: 0 !important;
    color: ${({ active }) => (active ? '#ffffff' : '#4a4a4a')};
  }
  img {
    margin: 0 10px !important;
    width: 17px;
    height: 17px;
  }
`;

export const UnderLineList = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  width: calc(100% - 20px);
  border-bottom: ${(props) =>
    props.isValid ? '2px solid #e1e1e1' : '2px solid red'};
  margin: 10px !important;

  div {
    width: 150px;
    padding-right: 2px !important;
  }
  button {
    bottom: -2px;
    margin: 5px 5px 0 0;
    text-align: center;
    justify-self: flex-start;
  }
`;

export const UnderLineButton = styled.button`
  width: 80px;
  border: none;
  border-bottom: ${({ active }) =>
    active ? '5px solid #6bb7b9' : '5px solid #e1e1e1'};
  font-size: 18px;
  font-weight: bold;
  color: #4a4a4a;
  background-color: #ffffff;
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
`;

export const FullButton = styled.button`
  margin: 20px;
  width: calc(100% - 40px);
  max-width: 350px;
  height: 50px;
  border-radius: 4px;
  background-color: #f06664;
  font-size: 16;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2;
  text-align: center;
  color: #ffffff;
  outline: 0 !important;
  outline-offset: 0 !important;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  &:active {
    box-shadow: none;
  }
`;

export const Tag = styled.div`
  padding: 5px;
  margin: 5px;
  background-color: #6bb7b9;
  border: 1px solid #6bb7b9;
  border-radius: 6px;
`;

export const FiltersNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 2px solid #eeeeee;

  div {
    margin: 10px;
    flex: 1;
    text-align: center;

    &:first-child {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &:first-child::before {
      content: '';
      background-image: url('/static/images/back-black.png');
      background-size: 21px 21px;
      width: 21px;
      height: 21px;
    }

    &:last-child {
      cursor: pointer;
    }
  }
`;

export const Input = styled.input`
  min-width: ${({ width }) => (width ? width : '148px')};
  height: 36px;
  align-self: flex-end;
  border: ${({ active, isValid }) =>
    active
      ? '1px solid #6bb7b9'
      : isValid === false
      ? '1px solid red'
      : '1px solid #e1e1e1'};
  border-radius: 4px;
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  margin: 10px !important;

  &:hover {
    border-color: #6bb7b9;
  }
  &:disabled {
    background-color: #e1e1e1;
  }
`;

export const TextArea = styled.textarea`
  min-width: ${({ width }) => (width ? width : '148px')};
  width: calc(100% - 40px);
  max-width: 400px;
  align-self: flex-end;
  border: ${({ active, isValid }) =>
    active
      ? '1px solid #6bb7b9'
      : isValid === false
      ? '1px solid red'
      : '1px solid #e1e1e1'};
  border-radius: 4px;
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
  margin: 10px !important;

  &:hover {
    border-color: #6bb7b9;
  }
`;

export const FileInput = styled.input`
  margin: 20px;
  width: calc(100% - 40px);
  height: 50px;
  border-radius: 4px;
  background-color: #f06664;
  font-size: 16;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2;
  text-align: center;
  color: #ffffff;
  outline: 0 !important;
  outline-offset: 0 !important;
  border: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  &:active {
    box-shadow: none;
  }
`;

export const ImagePreveiw = styled.div`
  margin: 8px;
  padding: 4px;
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 1rem;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  img {
    width: 300px;
    border: none;

    &:hover {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      transition: all 0.8s ease-in-out;
    }
  }

  button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    outline: 0 !important;
    outline-offset: 0 !important;
    border: none;
    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    i {
      color: red;
    }
  }
`;
