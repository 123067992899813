import React from 'react';
import { Layout, PageHeader } from 'antd';
import MainMenu from './MainMenu';
import NotificationsIcon from '../notifications';
import { getCountOfNotifications } from '../../utils/NoSqlUtils';
import { withRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';

const { Header, Content, Footer } = Layout;

class MainLayout extends React.Component {
  state = {
    notificationsCount: 0,
  };

  componentDidMount() {
    getCountOfNotifications({
      where: {
        isAdminNotification: {
          equals: true,
        },
        isReaded: {
          equals: false,
        },
      },
    }).then((result) => {
      if (result.data.notificationsOrRegisteredUsersCount) {
        this.setState({
          notificationsCount:
            result.data.notificationsOrRegisteredUsersCount._count.id || 0,
        });
      }
    });
    Amplify.configure({
      API: {
        graphql_endpoint: process.env.REACT_APP_API_URL,
      },
    });
  }
  render() {
    const { notificationsCount } = this.state;
    return (
      <Layout>
        <Header
          style={{
            padding: '0 16px',
            height: 60,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', gap: '50px' }}>
            <a href={process.env.REACT_APP_DASHBOARD_URL}>
              <img
                src="/images/logo.png"
                style={{ height: 50, marginRight: 'auto' }}
                alt={''}
              />
            </a>
            <div
              color="#fff"
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {notificationsCount > 0 && (
                <div
                  style={{
                    color: '#fff',
                    backgroundColor: 'red',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    borderRadius: '50%',
                    height: '20px',
                    padding: '0 3px',
                    position: 'absolute',
                    zIndex: '9999999',
                    top: '10px',
                    left: '1px',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    this.props.history.push('/notifications-details')
                  }
                >
                  {notificationsCount <= 99 ? notificationsCount : '99+'}
                </div>
              )}
              <NotificationsIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              position: 'relative',
              top: 8,
            }}
          >
            <h1 style={{ fontSize: 30, color: 'white', textAlign: 'center' }}>
              {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'khareta-staging'
                ? 'STAGING ENV'
                : 'PRODUCTION'}
            </h1>
          </div>
          <MainMenu />
        </Header>

        <PageHeader
          title={this.props.title || ''}
          style={{ padding: '8px 16px' }}
        />
        <Content
          style={{
            margin: '8px 16px 16px 16px',
            padding: 16,
            background: '#fff',
            minHeight: 'calc(100vh - 200px)',
          }}
        >
          {this.props.children}
        </Content>
        <Footer
          style={{
            padding: '8px 16px',
            position: 'relative',
            textAlign: 'center',
            top: 50,
            backgroundColor: '#ffffff',
          }}
        >
          <p style={{ margin: 'auto', fontWeight: 'bold' }}>Khareta</p>
        </Footer>
      </Layout>
    );
  }
}

export default withRouter(MainLayout);
