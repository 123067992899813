import React from 'react';
import { Menu, Button } from 'antd';
import { withCookies } from 'react-cookie';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CalendarOutlined } from '@ant-design/icons';
const { SubMenu } = Menu;
export default withCookies(
  withRouter(function ({ history, cookies }) {
    const { pathname } = history.location;
    return (
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[
          pathname.replace('/', '').length > 0
            ? pathname.replace('/', '')
            : 'listings',
        ]}
        style={{ lineHeight: '50px', width: 300 }}
      >
        <SubMenu key="SubMenu" icon={<CalendarOutlined />} title="Navigation">
          <Menu.ItemGroup key="g1" title="Main">
            <Menu.Item key="listings">
              <Link to="/">Listings</Link>
            </Menu.Item>
            <Menu.Item key="commercial-ads">
              <Link to="/commercial-ads">Commercial Ads</Link>
            </Menu.Item>
            <Menu.Item key="users">
              <Link to="/users">Users</Link>
            </Menu.Item>
            <Menu.Item key="locations">
              <Link to="/locations">Locations</Link>
            </Menu.Item>

            {/* <Menu.Item key="notifications">
              <Link to="/notifications">Notifications</Link>
            </Menu.Item> */}
            <Menu.Item key="homePage">
              <Link to="/homePage">Insight</Link>
            </Menu.Item>
            <Menu.Item key="banner-ads">
              <Link to="/banner-ads">Banner Ads</Link>
            </Menu.Item>
            <Menu.Item key="system-config">
              <Link to="/system-config">System Config</Link>
            </Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key="g2" title="Payment">
            <Menu.Item key="orders">
              <Link to="/orders">Orders</Link>
            </Menu.Item>
            <Menu.Item key="transactions">
              <Link to="/transactions">Transactions</Link>
            </Menu.Item>
            <Menu.Item key="bundles">
              <Link to="/bundles">Bundles</Link>
            </Menu.Item>
            <Menu.Item key="campaigns">
              <Link to="/campaigns">Campaigns</Link>
            </Menu.Item>
            <Menu.Item key="payment-methods">
              <Link to="/payment-methods">Payment Methods</Link>
            </Menu.Item>
            <Menu.Item key="ordered-campaigns">
              <Link to="/ordered-campaigns">Ordered Campaigns</Link>
            </Menu.Item>
            <Menu.Item key="abuses">
              <Link to="/abuses">Abuses</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key="4" style={{ backgroundColor: 'transparent' }}>
          <Button
            type="link"
            onClick={() => {
              cookies.remove('token', { path: '/' });
              cookies.remove('username', { path: '/' });
              cookies.remove('user', { path: '/' });
              history.push('/login');
            }}
          >
            {' '}
            Logout{' '}
          </Button>
        </Menu.Item>
      </Menu>
    );
  })
);
