export const DATAGRID_PAGENATION = 'DATAGRID_PAGENATION';
export const dataGridPagenation = (pageniation) => {
  return {
    type: DATAGRID_PAGENATION,
    payload: { ...pageniation },
  };
};

export const DATAGRID_SEARCH = 'DATAGRID_SEARCH';
export const dataGridSearch = (text) => {
  return {
    type: DATAGRID_SEARCH,
    payload: text,
  };
};
export const DATAGRID_RESET = 'DATAGRID_RESET';
export const dataGridReset = () => {
  return {
    type: DATAGRID_RESET,
    payload: null,
  };
};
