import React from 'react';
export default function Grill({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M4.244.343l-.045.011c-.134.024-.24.12-.278.25-.038.13.001.268.1.359.26.256.274.367.256.476-.018.11-.124.274-.278.476-.155.202-.365.44-.424.786-.058.345.088.742.446 1.095.142.141.37.141.512 0 .142-.14.142-.368 0-.509-.259-.256-.274-.367-.256-.476.018-.11.135-.274.29-.476.154-.202.353-.44.412-.786.058-.345-.088-.742-.446-1.095-.074-.08-.18-.12-.29-.11zm2.138 0l-.044.011c-.134.024-.241.12-.279.25-.037.13.002.268.1.359.26.256.275.367.257.476-.018.11-.124.274-.279.476-.154.202-.365.44-.423.786-.059.345.088.742.445 1.095.142.141.37.141.513 0 .142-.14.142-.368 0-.509-.26-.256-.274-.367-.256-.476.018-.11.135-.274.29-.476.154-.202.353-.44.411-.786.059-.345-.087-.742-.445-1.095-.074-.08-.181-.12-.29-.11zm2.139 0l-.045.011c-.133.024-.24.12-.278.25-.038.13.001.268.1.359.26.256.274.367.256.476-.018.11-.124.274-.278.476-.155.202-.365.44-.424.786-.058.345.088.742.446 1.095.142.141.37.141.512 0 .142-.14.142-.368 0-.509-.259-.256-.274-.367-.256-.476.018-.11.135-.274.29-.476.154-.202.353-.44.412-.786.058-.345-.088-.742-.446-1.095-.073-.08-.18-.12-.29-.11zM0 4.604v.354c0 2.326 1.266 4.354 3.141 5.468l-1.704 5.766c-.056.19.054.387.245.443.19.055.39-.054.445-.244l.87-2.933h6.816l.167.554c-.422.246-.713.698-.713 1.217 0 .78.642 1.417 1.426 1.417.784 0 1.425-.638 1.425-1.417s-.641-1.416-1.425-1.416h-.034l-.969-3.376c1.878-1.112 3.141-3.152 3.141-5.479v-.354H0zm.746.708h11.339C11.958 7.31 10.8 9.033 9.133 9.94c-.002.001-.008-.002-.01 0-.024.012-.047.027-.068.044l-.022.011c-.782.405-1.67.631-2.617.631-.935 0-1.809-.224-2.584-.62-.044-.043-.098-.073-.156-.088C2.023 9.005.872 7.299.746 5.313zm3.041 5.457c.803.361 1.69.564 2.629.564.934 0 1.817-.206 2.617-.564l.58 1.981H3.196l.59-1.981zm6.839 3.752c.022-.002.044 0 .067 0 .398 0 .713.312.713.708 0 .396-.315.709-.713.709-.399 0-.713-.313-.713-.709 0-.288.168-.53.412-.642.078.007.157-.012.223-.055.004 0 .007-.011.01-.011z"
                    transform="translate(-150 -1423) translate(0 1078) translate(20 335) translate(130.828 10) translate(2.139)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
