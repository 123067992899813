export default function NotificationsIcon({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      style={{ position: 'relative', top: '-2px' }}
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="ctkgfnlyfa"
          d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-1.7 1.387H7.7v-6.45C7.7 8.273 9.323 6.1 12 6.1c2.677 0 4.3 2.171 4.3 4.838v6.45z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <path d="M0 0L24 0 24 24 0 24z" />
          <mask id="2h9ulq3gob" fill="#fff">
            <use xlinkHref="#ctkgfnlyfa" />
          </mask>
          <path
            fill={active ? '#00a699' : '#fff'}
            d="M0 0H24V24H0z"
            mask="url(#2h9ulq3gob)"
          />
        </g>
      </g>
    </svg>
  );
}
