import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Card } from 'antd';
import { Admin } from '../../queries';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import ListingStatusCtrl from './listingStatusCtrl';
import RejectListing from './hooks/RejectListing';
import CustomNotification from './CustomnotificationListings';
import { TextArea } from '../../components/ui/Styled';
import { ListingFeaturesBlocks } from './listing-features-expandable';

export default function ListingDetails({
  record,
  index,
  token,
  changeAListing,
  props,
}) {
  const [fetchedListing, setFetchedListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [notes, setNotes] = useState(false);
  const [textAreaOwnership, setTextAreaOwnerShip] = useState(null);
  const [ownership, setOwnerShip] = useState(null);
  const [features, setFeatures] = useState(null);

  const updateListing = async ({ variables }) => {
    try {
      const listingResponse = await API.graphql(
        graphqlOperation(Admin.Listing.UPDATE_LISTING, variables)
      );
      setOwnerShip(listingResponse.data.updateListing.ownership);
      setNotes(false);
    } catch (error) {
      console.log(
        '🚀 ~ file: ListingDetails.js ~ line 34 ~ getListing ~ error',
        error
      );
    }
  };
  const getListing = async () => {
    try {
      setLoading(true);
      const listingResponse = await API.graphql(
        graphqlOperation(Admin.Listing.GET_LISTING_BY_ID, {
          where: { id: record.id },
        })
      );

      setFetchedListing(listingResponse.data.listing);
      setFeatures(listingResponse.data.listing.features[0]);
      setOwnerShip(listingResponse.data.listing.ownership);
      setTextAreaOwnerShip(listingResponse.data.listing.ownership);
    } catch (error) {
      setError(true);
      console.log(
        '🚀 ~ file: ListingDetails.js ~ line 85 ~ getListing ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
    getListing();
  }, [record.status]);
  return loading ? (
    <div>Loading ...</div>
  ) : error ? (
    <div> Error ... </div>
  ) : (
    <Row gutter={8}>
      <Col span={7}>
        <Card title="Listing Description" style={{ textAlign: 'center' }}>
          <p style={{ direction: 'rtl' }}>{fetchedListing.description}</p>
        </Card>
        {fetchedListing.ownership && (
          <div>
            <Card title="Admin Notes" style={{ textAlign: 'center' }}>
              <p style={{ direction: 'rtl' }}>{ownership}</p>

              {notes ? (
                <div>
                  <TextArea
                    name="ownership"
                    rows={6}
                    value={textAreaOwnership}
                    onChange={(value) => {
                      setTextAreaOwnerShip(value.target.value);
                    }}
                  />

                  <Button
                    onClick={() => {
                      updateListing({
                        variables: {
                          where: {
                            id: record.id,
                          },
                          data: {
                            ownership: { set: textAreaOwnership },
                          },
                        },
                      });
                    }}
                  >
                    Update Notes
                  </Button>
                  <Button
                    onClick={() => {
                      setNotes(false);
                    }}
                  >
                    Close
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    onClick={() => {
                      setNotes(true);
                    }}
                  >
                    Edit?
                  </Button>
                </div>
              )}
            </Card>
          </div>
        )}
      </Col>
      <Col span={9}>
        <div
          style={{
            display: 'relative',
          }}
        >
          {
            <ListingFeaturesBlocks
              features={Object.keys(features).filter(
                (key) =>
                  features[key] !== null &&
                  features[key] !== '' &&
                  key !== '__typename' &&
                  !!features[key]
              )}
              values={features}
              views={fetchedListing.views}
            />
          }
        </div>
        <CustomNotification
          props={props}
          data={{
            user: fetchedListing.author.id,
            listing: fetchedListing.id,
            images: fetchedListing.images,
            s3: fetchedListing.s3Prefix,
          }}
        ></CustomNotification>
      </Col>
      <Col span={8}>
        <Card title="" style={{ textAlign: 'center' }}>
          <ListingStatusCtrl
            changeAListing={changeAListing}
            index={index}
            listing={fetchedListing}
            view={'VIEW'}
          />
        </Card>

        <Card title="Author Information" style={{ textAlign: 'center' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <div>{fetchedListing.author.name}</div>
            <div>{fetchedListing.author.mobile}</div>
            <div>{fetchedListing.author.email}</div>
            <div>
              <a
                href={`${process.env.REACT_APP_HOST_NAME}/user-details/${fetchedListing.author.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                User profile on Website
              </a>
            </div>
            <div>
              <a
                href={`${process.env.REACT_APP_HOST_NAME}/properties/${fetchedListing.url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Listing Link on Website
              </a>
            </div>
            <div>
              <a
                href={`/user/profile/${fetchedListing.author.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Edit User Profile
              </a>
            </div>
          </div>
        </Card>
        {(fetchedListing.status === 'REJECTED' ||
          fetchedListing.status === 'REJECTEDUPDATED' ||
          fetchedListing.status === 'ACTIVE' ||
          fetchedListing.status === 'ACTIVEUPDATED') && (
          <RejectListing data={fetchedListing}></RejectListing>
        )}
      </Col>
    </Row>
  );
}
