import React from 'react';
// import { Query } from 'react-apollo';
import { MainLayout } from '../../components/Layout';
import { CSVLink } from 'react-csv';
import { Admin } from '../../queries';

function flattenDeep(arr1) {
  return arr1.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
    []
  );
}

class ExportListings extends React.Component {
  parseObj = (listing) => {
    return Object.values(listing).map((value) => {
      if (!value) return '';

      if (Array.isArray(value) && value.length > 0) {
        const x = this.parseObj(value[0]);
        return x;
      }
      if (typeof value === 'object') {
        const x = this.parseObj(value);
        return x;
      }
      return value;
    });
  };

  parseHeader = (listing) => {
    if (listing)
      return Object.keys(listing).map((key) => {
        const value = listing[key];

        if (Array.isArray(value) && value.length > 0) {
          const x = this.parseHeader(value[0]);
          return x;
        }
        if (typeof value === 'object' && value != null) {
          const x = this.parseHeader(value);
          return x;
        }
        return key;
      });
  };

  render() {
    const variables = {
      orderBy: { createdAt: 'desc' },
      take: 1000,
      skip: 8000,
    };

    return (
      <MainLayout>
        {/* <Query
          query={Admin.Listing.GET_LISTINGS_FOR_EXPORT}
          variables={{ ...variables }}
          fetchPolicy="cache-and-network"
        >
          {({ loading, error, data }) => {
            if (error) return <div> ERROR </div>;
            if (loading || !data) return <div> LOADING ...</div>;

            let csvArr = data.listings.map((listing) => {
              let csvRow = this.parseObj(listing);
              return flattenDeep(csvRow);
            });
            const obj = data.listings;

            const header = flattenDeep(this.parseHeader(obj[0]));
            const csvData = [header, ...csvArr];
            return <CSVLink data={csvData}>Download me</CSVLink>;
          }}
        </Query> */}
      </MainLayout>
    );
  }
}

export default ExportListings;
