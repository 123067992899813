import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import Amplify from 'aws-amplify';
import Router from './routes';
import './App.css';
import { getApolloClient } from './clients/apollo';
import { ApolloProvider } from '@apollo/client';

class App extends Component {
  render() {
    const { cookies } = this.props;
    const token = cookies.get('token');
    const client = getApolloClient({ token });
    Amplify.configure({
      API: {
        graphql_endpoint: process.env.REACT_APP_API_URL,
        graphql_headers: () =>
          token
            ? {
                Authorization: `Bearer ${token}`,
              }
            : {},
      },
    });
    return (
      <ApolloProvider client={client}>
        <Router cookies={cookies} {...this.props} client={client} />
      </ApolloProvider>
    );
  }
}

export default withCookies(App);
