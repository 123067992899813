import {
  DATAGRID_SEARCH,
  DATAGRID_PAGENATION,
  DATAGRID_RESET,
} from './actions';

const initialStateUSers = {
  filteredInfo: null,
  sortedInfo: {
    columnKey: 'createdAt',
    field: 'createdAt',
    order: 'DESC',
  },
  pagination: {
    total: 0,
    current: 1,
    pageSize: 20,
    position: ['topLeft', 'bottomLeft'],
  },
  loading: false,
  searchText: '',
  currentEdited: 0,
  scrollPostion: 0,
};

const usersReducer = (state = initialStateUSers, action) => {
  switch (action.type) {
    case DATAGRID_SEARCH: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case DATAGRID_PAGENATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }

    case DATAGRID_RESET: {
      return {
        ...initialStateUSers,
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
