import React from 'react';

export default function AirConditioning({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M1.71 0C.772 0 0 .766 0 1.7v5.408c0 .303.08.585.203.829l.01.01.792 1.403c.3.51.862.85 1.497.85h1.604v-.68H2.502c-.389 0-.718-.207-.898-.51l-.096-.17H15.6l-.097.17c-.175.29-.552.51-.898.51h-1.604v.68h1.604c.61 0 1.191-.328 1.497-.85l.791-1.402.011-.011c.123-.245.203-.526.203-.829V1.7c0-.934-.77-1.7-1.71-1.7H1.71zm0 .68h13.687c.567 0 1.027.457 1.027 1.02v5.1H.684V1.7c0-.563.46-1.02 1.027-1.02zm11.292 1.36v1.105l-.962-.553-.342.595.962.553-.962.553.342.595.962-.553V5.44h.685V4.335l.962.553.342-.595-.962-.553.962-.553-.342-.595-.962.553V2.04h-.685zM.77 7.48h15.568c-.018.05-.029.1-.053.149l-.3.531H1.124l-.3-.531C.8 7.58.79 7.53.77 7.48zm4.705 2.04c-.379 0-.685.304-.685.68 0 .376.306.68.685.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm3.08 0c-.38 0-.685.304-.685.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm3.079 0c-.379 0-.685.304-.685.68 0 .376.306.68.685.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm-6.502 2.04c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.379 0 .685-.304.685-.68 0-.376-.306-.68-.685-.68zm3.422 0c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm3.422 0c-.378 0-.685.304-.685.68 0 .376.307.68.685.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zM4.448 13.6c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm4.106 0c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm4.106 0c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm-9.58 2.04c-.379 0-.685.304-.685.68 0 .376.306.68.684.68.379 0 .685-.304.685-.68 0-.376-.306-.68-.685-.68zm5.474 0c-.378 0-.684.304-.684.68 0 .376.306.68.684.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68zm5.475 0c-.378 0-.685.304-.685.68 0 .376.307.68.685.68.378 0 .684-.304.684-.68 0-.376-.306-.68-.684-.68z"
                  transform="translate(-313 -1256) translate(0 1078) translate(183 168) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
