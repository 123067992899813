import React from 'react';

export default function Bathroom({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M20.95 11.814H2.937v-9.18c0-.778.68-1.413 1.524-1.432-.006.042-.012.084-.011.127.005.224.104.436.278.595l.17.158c-.5.773-.431 1.747.239 2.363l.097.089-.39.357.584.536.39-.357 3.888-3.574.389-.357-.583-.536-.39.357-.096-.089C8.66.535 8.161.35 7.622.35c-.406 0-.81.108-1.167.303L6.283.495C5.947.185 5.382.169 5.03.442h-.532c-1.315 0-2.386.984-2.386 2.193v9.178H1.15c-.531 0-.962.397-.962.885s.431.884.962.884h.963v1.211c0 2.242 1.427 4.18 3.483 5.078-.14.497-.626.853-1.191.853-.113 0-.226-.015-.335-.043l-.224.73c.182.047.37.07.559.07.907 0 1.7-.548 1.965-1.33.57.165 1.175.254 1.803.254h5.754c.61 0 1.2-.085 1.758-.242.269.776 1.058 1.319 1.96 1.319.19 0 .377-.024.56-.071l-.224-.73c-.11.028-.223.043-.336.043-.557 0-1.039-.347-1.185-.834 2.08-.89 3.527-2.84 3.527-5.097v-1.21h.963c.53 0 .963-.397.963-.885s-.432-.884-.963-.884zM8.443 1.407l.096.089-2.722 2.501-.097-.088c-.483-.444-.424-1.22.13-1.729l.712-.653c.29-.267.676-.419 1.06-.419.32 0 .61.106.82.299zM5.296 1.26l.264-.243c.018-.016.04-.02.056-.02.018 0 .052.005.084.034l.117.108-.389.357-.117-.108c-.03-.027-.036-.058-.036-.074 0-.015.003-.037.021-.054zm13.866 13.534c0 2.676-2.348 4.853-5.235 4.853H8.173c-2.887 0-5.235-2.177-5.235-4.853v-1.211h16.224v1.21zm1.788-1.97H1.15c-.075 0-.137-.057-.137-.126 0-.068.063-.126.137-.126h19.8c.075 0 .138.058.138.126 0 .069-.063.126-.138.126z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866)"
              />
              <path
                d="M13.279 8.4L14.099 8.405 14.105 9.422 13.286 9.417z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-45 13.692 8.911)"
              />
              <path
                d="M7.819 3.889L9.459 3.879 9.454 4.642 7.815 4.652z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-135 8.637 4.266)"
              />
              <path
                d="M9.569 5.497L11.209 5.487 11.205 6.25 9.564 6.26z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-135 10.387 5.874)"
              />
              <path
                d="M11.722 6.717L12.542 6.722 12.552 8.247 11.732 8.242z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 12.137 7.482)"
              />
              <path
                d="M9.374 7.106L11.014 7.096 11.009 7.859 9.369 7.869z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-135.008 10.192 7.482)"
              />
              <path
                d="M11.527 8.325L12.347 8.33 12.357 9.855 11.537 9.85z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-45 11.942 9.09)"
              />
              <path
                d="M8.028 5.109L8.848 5.114 8.857 6.639 8.037 6.634z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 8.442 5.874)"
              />
              <path
                d="M12.953 10.142L14.047 10.135 14.042 10.898 12.948 10.904z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(45 13.498 10.52)"
              />
              <path
                d="M9.375 3.532L11.014 3.522 11.01 4.285 9.37 4.295z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-135 10.192 3.908)"
              />
              <path
                d="M13.278 6.359L14.098 6.364 14.107 7.89 13.287 7.885z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-45 13.692 7.124)"
              />
              <path
                d="M14.704 8.176L15.797 8.169 15.792 8.932 14.699 8.938z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(45 15.248 8.554)"
              />
              <path
                d="M11.528 4.751L12.348 4.756 12.357 6.282 11.537 6.277z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-45 11.942 5.517)"
              />
              <path
                d="M10.166 1.714L10.986 1.719 10.996 3.244 10.176 3.239z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 10.581 2.48)"
              />
              <path
                d="M11.917 3.322L12.737 3.327 12.746 4.852 11.926 4.847z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 12.331 4.087)"
              />
              <path
                d="M15.224 6.614L16.044 6.619 16.05 7.636 15.23 7.631z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 15.637 7.125)"
              />
              <path
                d="M13.667 4.93L14.487 4.935 14.496 6.46 13.676 6.455z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(135 14.081 5.695)"
              />
              <path
                d="M11.203 10.32L12.297 10.313 12.292 11.076 11.199 11.083z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(45 11.748 10.698)"
              />
              <path
                d="M7.624 7.284L9.265 7.274 9.26 8.037 7.62 8.047z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(45 8.442 7.66)"
              />
              <path
                d="M5.875 5.676L7.514 5.666 7.51 6.429 5.87 6.439z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(-135 6.692 6.053)"
              />
              <path
                d="M9.375 8.892L11.014 8.882 11.01 9.645 9.37 9.655z"
                transform="translate(-317 -117) translate(0 106.683) translate(317 10.866) rotate(45 10.192 9.269)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
