import React, { useEffect, useState } from 'react';
import { Table, Row, Switch, Input, Button, Popover, Image } from 'antd';
import * as moment from 'moment';
import { MainLayout } from '../../components/Layout';
import CreateBannerAd from './modals/create-banner-ad.modal.js';
import UpdateBannerAdImages from './modals/update-banner-ad-images.modal';
import { Admin, Shared } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

const { TextArea } = Input;

const BANNER_AD_PAGE_TYPES = {
  HOME: 'Home',
  PROPERTY: 'Property',
};

let client = null;

export default function BannerAds(props) {
  const [isLoading, setLoading] = useState(false);
  const [bannerAds, setBannerAds] = useState([]);
  const [isEditNotes, setIsEditBannerAdsNotes] = useState({});
  const [bannerAdNotes, setBannerAdNotes] = useState({});
  const [bannerAdUrl, setBannerAdUrl] = useState({});
  const [isEditUrl, setIsEditUrl] = useState({});
  const [isUpdateImagesModalOpen, setUpdateImagesModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });

  const getBannerAds = async (
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination
  ) => {
    try {
      setLoading(true);
      const bannerAdsResponse = await client.query({
        query: Admin.BannerAd.GET_BANNER_ADS_WITH_USER,
        variables: { where, orderBy, skip, take },
      });
      const bannerAdsCount = await client.query({
        query: Shared.BannerAd.GET_BANNER_ADS_COUNT,
        variables: { where },
      });

      setPagination({
        ...newPagination,
        total: bannerAdsCount.data.bannerAdsConnection._count.id,
      });
      setBannerAds(bannerAdsResponse.data.bannerAds);
    } catch (error) {
      console.log(
        '🚀 ~ file: bannerAds.js ~ line 63 ~ getBannerAds ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getBannerAds({ where, orderBy, skip, newPagination });
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getBannerAds({});
  }, []);

  const ImagesPopOver = ({ row }) => {
    return (
      <Table
        key={`${row.id}-row-image-pop-over`}
        columns={[
          {
            title: 'Desktop Small Image',
            key: 'desktop-small-image',
            render: () => {
              return (
                <Image
                  preview={false}
                  width={200}
                  height={200}
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-small/${row.desktopSmallImageFile.fileName}`}
                />
              );
            },
          },
          {
            title: 'Desktop Big Image',
            key: 'desktop-big-image',
            render: () => {
              return (
                <Image
                  preview={false}
                  width={200}
                  height={200}
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/ka/desktop-big/${row.desktopBigImageFile.fileName}`}
                />
              );
            },
          },
          {
            title: 'Mobile Image',
            key: 'mobile-image',
            render: () => {
              return (
                <Image
                  preview={false}
                  width={200}
                  height={200}
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/ka/mobile/${row.mobileImageFile.fileName}`}
                />
              );
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id-user',
          },
        ]}
        dataSource={[row]}
        size="small"
        pagination={false}
        bordered
        rowKey={(record) => `row-image-pop-over-${record.id}`}
      />
    );
  };

  return (
    <MainLayout title="BannerAds">
      <Row>
        <CreateBannerAd
          getBannerAds={() => getBannerAds({})}
          {...props}
          client={client}
        ></CreateBannerAd>

        {isUpdateImagesModalOpen && (
          <UpdateBannerAdImages
            getBannerAds={() => getBannerAds({})}
            {...props}
            client={client}
            isUpdateImagesModalOpen={isUpdateImagesModalOpen}
            setUpdateImagesModalOpen={setUpdateImagesModalOpen}
            selectedRow={selectedRow}
          ></UpdateBannerAdImages>
        )}
      </Row>
      <Table
        columns={[
          {
            title: 'Page',
            dataIndex: 'page',
            key: 'page',
            render: (page) => BANNER_AD_PAGE_TYPES[page],
            sorter: (a, b) => a.page.length - b.page.length,
            filters: [
              {
                text: 'Home',
                value: 'HOME',
              },
              {
                text: 'Property',
                value: 'PROPERTY',
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.page.indexOf(value) === 0,
          },
          {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive, row) => (
              <div>
                <span
                  style={{
                    paddingRight: '10px',
                    color: isActive ? '#41ce41' : '#f34646',
                    fontWeight: 500,
                  }}
                >
                  {' '}
                  {isActive ? 'Enabled' : 'Disabled'}
                </span>
                <Switch
                  checked={isActive}
                  onChange={async (value) => {
                    try {
                      setLoading(true);
                      await client.query({
                        query: Admin.BannerAd.UPDATE_BANNER_AD,
                        variables: {
                          where: {
                            id: row.id,
                          },
                          data: {
                            isActive: { set: value },
                          },
                        },
                      });
                      getBannerAds({});
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: bannerAds.js ~ line 93 ~ onChange={ ~ error',
                        error
                      );
                    } finally {
                      setLoading(false);
                    }
                  }}
                ></Switch>
              </div>
            ),
            sorter: (a, b) => a.isActive - b.isActive,
            filters: [
              {
                text: 'Enabled',
                value: true,
              },
              {
                text: 'Disabled',
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.isActive === value,
          },
          {
            title: 'Url',
            dataIndex: 'url',
            key: 'url',
            render: (url, row) => {
              if (isEditUrl[row.id]) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextArea
                      showCount
                      value={bannerAdUrl[row.id]}
                      maxLength={512}
                      onChange={(e) => {
                        setBannerAdUrl({
                          [`${row.id}`]: e.target.value,
                        });
                      }}
                      style={{ width: '300px', direction: 'ltr' }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={() => setIsEditUrl({ [`${row.id}`]: false })}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await client.query({
                              query: Admin.BannerAd.UPDATE_BANNER_AD,
                              variables: {
                                where: {
                                  id: row.id,
                                },
                                data: {
                                  url: { set: bannerAdUrl[row.id] },
                                },
                              },
                            });
                            getBannerAds({});
                            setIsEditUrl({
                              [`${row.id}`]: false,
                            });
                          } catch (error) {
                            console.log(
                              '🚀 ~ file: bannerAds.js ~ line 236 ~ onClick={ ~ error',
                              error
                            );
                          } finally {
                            setLoading(false);
                            setIsEditUrl({
                              [`${row.id}`]: false,
                            });
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Popover content={'Click to edit'}>
                    <p
                      onClick={() => {
                        setIsEditUrl({ [`${row.id}`]: true });
                        setBannerAdUrl({ [`${row.id}`]: url });
                      }}
                      className="long-text"
                      style={{ maxWidth: '300px', textAlign: 'left' }}
                    >
                      {url}
                    </p>
                  </Popover>
                );
              }
            },
            width: '200px',
            sorter: (a, b) => a.url.length - b.url.length,
          },
          {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
            render: (notes, row) => {
              if (isEditNotes[row.id]) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextArea
                      showCount
                      value={bannerAdNotes[row.id]}
                      maxLength={100}
                      style={{ width: '300px' }}
                      onChange={(e) =>
                        setBannerAdNotes({
                          [`${row.id}`]: e.target.value,
                        })
                      }
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={() =>
                          setIsEditBannerAdsNotes({ [`${row.id}`]: false })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await client.query({
                              query: Admin.BannerAd.UPDATE_BANNER_AD,
                              variables: {
                                where: {
                                  id: row.id,
                                },
                                data: {
                                  notes: { set: bannerAdNotes[row.id] },
                                },
                              },
                            });
                            getBannerAds({});
                            setIsEditBannerAdsNotes({
                              [`${row.id}`]: false,
                            });
                          } catch (error) {
                            console.log(
                              '🚀 ~ file: bannerAds.js ~ line 236 ~ onClick={ ~ error',
                              error
                            );
                          } finally {
                            setLoading(false);
                            setIsEditBannerAdsNotes({
                              [`${row.id}`]: false,
                            });
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Popover content={'Click to edit'}>
                    <p
                      onClick={() => {
                        setIsEditBannerAdsNotes({ [`${row.id}`]: true });
                        setBannerAdNotes({ [`${row.id}`]: notes });
                      }}
                      className="long-text"
                      style={{ maxWidth: '300px' }}
                    >
                      {notes || 'ملاحظات اختيارية'}
                    </p>
                  </Popover>
                );
              }
            },
            width: '200px',
            sorter: (a, b) => a.notes.length - b.notes.length,
          },
          {
            title: 'Images',
            key: 'images',
            render: (row) => {
              return (
                <Row
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  <Popover
                    key={`${row.id}-images-pop-over-main`}
                    content={ImagesPopOver({ row })}
                    title="Banner Ad Images"
                    trigger="click"
                    arrowPointAtCenter
                    placement="bottom"
                  >
                    <Button>Show</Button>
                  </Popover>
                  <Button
                    onClick={() => {
                      setSelectedRow(row);
                      setUpdateImagesModalOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </Row>
              );
            },
          },

          {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            render: (clicks) => clicks || 0,
            width: '200px',
            sorter: (a, b) => a.clicks - b.clicks,
          },

          {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (user) => user.name || `no name [${user.id}]`,
            width: '200px',
            sorter: (a, b) => a.user.name - b.user.name,
          },

          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={bannerAds}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
