import React, { PureComponent } from 'react';
import { FlexList, Button } from './Styled';

export default class Switch extends PureComponent {
  _onClick = (value) => {
    this.props.onClick(value);
  };

  render() {
    const {
      labels,
      name,
      values,
      width,
      align,
      path,
      icons,
      selected,
      style,
      isValid,
      multiple,
      isCommercial,
    } = this.props;

    return (
      <FlexList style={style}>
        {values &&
          values.map((value, i) => {
            let iconPath = null;
            if (Array.isArray(icons)) {
              if (icons.length > 0 && selected === value && !isCommercial) {
                iconPath = `${path}/white/${icons[i]}`;
              } else {
                iconPath = `${path}/black/${icons[i]}`;
              }
            }

            return (
              <Button
                key={i}
                active={
                  !multiple ? selected === value : selected.includes(value)
                }
                name={name}
                style={
                  isValid == undefined || isValid == true
                    ? {}
                    : { border: '1px solid red' }
                }
                value={value}
                onClick={() => this._onClick(value)}
                width={width}
                align={align}
                disabled={
                  value === 'DEVELOPMENT' || value === 'UNDER_CONSTRACTION'
                }
              >
                {iconPath && <img src={iconPath} alt={labels[i]} />}
                <span>{labels[i]}</span>
              </Button>
            );
          })}
      </FlexList>
    );
  }
}
