import React, { useEffect, useState } from 'react';
import {
  Table,
  Row,
  Switch,
  InputNumber,
  Input,
  Button,
  Popover,
  Popconfirm,
} from 'antd';
import * as moment from 'moment';
import { MainLayout } from '../../components/Layout';
import CreateCampaign from './modals/create-campaign.modal';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';

const { TextArea } = Input;

const CAMPAIGN_TYPES = {
  PRIME: 'Prime',
  FEATURED: 'Featured',
  ON_FACEBOOK: 'On Facebook',
};

let client = null;

export default function Campaigns(props) {
  const [isLoading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [isEditDescription, setEditDescription] = useState({});
  const [campaignUsageCount, setCampaignUsageCount] = useState({});
  const [isVisibleConfirm, setIsVisibleConfirm] = useState({});
  const [campaignStatusValue, setCampaignStatusValue] = useState({});
  const [campaignDescription, setDescription] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    position: ['topLeft', 'bottomLeft'],
  });

  const getCampaigns = async ({
    where = {},
    orderBy = { createdAt: 'desc' },
    skip = 0,
    take = 10,
    newPagination,
  }) => {
    try {
      setLoading(true);
      const campaignsResponse = await client.query({
        query: Admin.Campaign.GET_CAMPAIGNS,
        variables: {
          where,
          orderBy,
          skip,
          take,
        },
      });
      const campaignsCount = await client.query({
        query: Admin.Campaign.GET_CAMPAIGNS_COUNT,
        variables: {
          where,
        },
      });

      setPagination({
        ...newPagination,
        total: campaignsCount.data.campaignsConnection._count.id,
      });
      setCampaigns(campaignsResponse.data.campaigns);
    } catch (error) {
      console.log(
        '🚀 ~ file: campaigns.js ~ line 63 ~ getCampaigns ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = async (currentPagination, filters, sorter) => {
    const where = {};
    let orderBy = {};
    if (filters.category) {
      where.category = {
        in: filters.category,
      };
    }

    if (filters.source) {
      where.source = {
        equals: filters.source,
      };
    }

    if (filters.status) {
      where.status = {
        in: filters.status,
      };
    }

    if (sorter.field && sorter.order) {
      orderBy[sorter.field] = sorter.order === 'ascend' ? 'asc' : 'desc';
    } else {
      orderBy = {
        createdAt: 'desc',
      };
    }

    const skip = (currentPagination.current - 1) * currentPagination.pageSize;

    const newPagination = {
      ...pagination,
      ...currentPagination,
    };
    await getCampaigns({ where, orderBy, skip, newPagination });
  };

  const updateCampaignStatus = async ({ id, value }) => {
    try {
      await client.query({
        query: Admin.Campaign.UPDATE_CAMPAIGN,
        variables: {
          where: {
            id,
          },
          data: {
            isActive: { set: value },
          },
        },
      });
      getCampaigns({});
    } catch (error) {
      console.log(
        '🚀 ~ file: campaigns.js ~ line 93 ~ onChange={ ~ error',
        error
      );
    }
  };

  const getCampaignUsageCount = async ({ id }) => {
    try {
      const countResponse = await client.query({
        query: Admin.OrderedCampaign.GET_ORDERED_CAMPAIGNS_COUNT,
        variables: {
          where: {
            campaign: {
              is: {
                id: {
                  equals: id,
                },
              },
            },
            status: {
              in: [
                'PENDING_BY_ADMIN',
                'PENDING_PAYMENT',
                'PENDING_FROM_PROVIDER',
                'PENDING_PAYMENT_REVIEW',
                'REJECTED_PAYMENT',
              ],
            },
          },
        },
      });
      setCampaignUsageCount({
        ...campaignUsageCount,
        [`${id}`]: countResponse.data.orderedCampaignsConnection._count.id,
      });
    } catch (error) {
      console.log(
        '🚀 ~ file: campaigns.js ~ line 57 ~ campaignUsageCount ~ error',
        error
      );
    }
  };

  const onChange = (e) => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    const { token } = props;
    client = getApolloClient({ token });
    getCampaigns({});
  }, []);

  const getConfirmMessage = ({ count }) => {
    if (count > 0) {
      return `This campaign is currently needed in ${count} ordered campaigns. Please do not disable it!`;
    }
    return 'Disabling means it will not show on mobile and website for new orders.';
  };

  return (
    <MainLayout title="Campaigns">
      <Row>
        <CreateCampaign
          getCampaigns={() => getCampaigns({})}
          {...props}
        ></CreateCampaign>
      </Row>
      <Table
        columns={[
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => CAMPAIGN_TYPES[type],
            sorter: (a, b) => a.type.length - b.type.length,
            filters: [
              {
                text: 'Featured',
                value: 'FEATURED',
              },
              {
                text: 'Prime',
                value: 'PRIME',
              },
              {
                text: 'On Facebook',
                value: 'ON_FACEBOOK',
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.type.indexOf(value) === 0,
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => `JOD${price}`,
            sorter: (a, b) => a.price - b.price,
          },
          {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive, row) => (
              <Popconfirm
                title={getConfirmMessage({
                  count: campaignUsageCount[row.id],
                })}
                visible={isVisibleConfirm[row.id] === true}
                onConfirm={async () => {
                  try {
                    await updateCampaignStatus({
                      id: row.id,
                      value: campaignStatusValue[row.id],
                    });
                    setIsVisibleConfirm({
                      ...isVisibleConfirm,
                      [`${row.id}`]: false,
                    });
                  } catch (error) {
                    setIsVisibleConfirm({
                      ...isVisibleConfirm,
                      [`${row.id}`]: false,
                    });
                    console.log(
                      '🚀 ~ file: campaigns.js ~ line 238 ~ onConfirm={ ~ error',
                      error
                    );
                  }
                }}
                onCancel={() => {
                  setIsVisibleConfirm({
                    ...isVisibleConfirm,
                    [`${row.id}`]: false,
                  });
                }}
                okText={
                  campaignStatusValue[row.id]
                    ? 'Activate'
                    : campaignUsageCount[row.id] === 0
                    ? 'Disable'
                    : 'Disable Anyway'
                }
                okType={campaignStatusValue[row.id] ? 'primary' : 'danger'}
                cancelText="Cancel"
                placement="rightTop"
              >
                <Switch
                  checked={isActive}
                  checkedChildren="Enabled"
                  unCheckedChildren="Disabled"
                  onChange={async (value) => {
                    try {
                      if (value === true) {
                        setCampaignStatusValue({
                          ...campaignStatusValue,
                          [`${row.id}`]: value,
                        });
                        await updateCampaignStatus({
                          id: row.id,
                          value,
                        });
                      } else {
                        setCampaignStatusValue({
                          ...campaignStatusValue,
                          [`${row.id}`]: value,
                        });
                        setIsVisibleConfirm({
                          ...isVisibleConfirm,
                          [`${row.id}`]: true,
                        });
                        await getCampaignUsageCount({ id: row.id });
                      }
                    } catch (error) {
                      setIsVisibleConfirm({
                        ...isVisibleConfirm,
                        [`${row.id}`]: false,
                      });
                      console.log(
                        '🚀 ~ file: campaigns.js ~ line 242 ~ onChange={ ~ error',
                        error
                      );
                    }
                  }}
                ></Switch>
              </Popconfirm>
            ),
            sorter: (a, b) => a.isActive - b.isActive,
            filters: [
              {
                text: 'Enabled',
                value: true,
              },
              {
                text: 'Disabled',
                value: false,
              },
            ],
            filterMultiple: false,
            onFilter: (value, row) => row.isActive === value,
          },
          {
            title: 'Valid For Days',
            dataIndex: 'validForDays',
            key: 'validForDays',
            render: (validForDays, row) => {
              return (
                <InputNumber
                  value={validForDays}
                  min={0}
                  max={365}
                  step={5}
                  onChange={async (value) => {
                    try {
                      setLoading(true);
                      await client.query({
                        query: Admin.Campaign.UPDATE_CAMPAIGN,
                        variables: {
                          where: {
                            id: row.id,
                          },
                          data: {
                            validForDays: { set: value },
                          },
                        },
                      });
                      getCampaigns({});
                    } catch (error) {
                      console.log(
                        '🚀 ~ file: campaigns.js ~ line 157 ~ onChange={ ~ error',
                        error
                      );
                    } finally {
                      setLoading(false);
                    }
                  }}
                />
              );
            },
            sorter: (a, b) => a.validForDays - b.validForDays,
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (description, row) => {
              if (isEditDescription[row.id]) {
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <TextArea
                      showCount
                      value={campaignDescription}
                      maxLength={100}
                      onChange={onChange}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-evenly',
                      }}
                    >
                      <Button
                        onClick={() =>
                          setEditDescription({
                            ...isEditDescription,
                            [`${row.id}`]: false,
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        onClick={async () => {
                          try {
                            setLoading(true);
                            await client.query({
                              query: Admin.Campaign.UPDATE_CAMPAIGN,
                              variables: {
                                where: {
                                  id: row.id,
                                },
                                data: {
                                  description: { set: campaignDescription },
                                },
                              },
                            });
                            getCampaigns({});
                            setEditDescription({
                              ...isEditDescription,
                              [`${row.id}`]: false,
                            });
                          } catch (error) {
                            console.log(
                              '🚀 ~ file: campaigns.js ~ line 236 ~ onClick={ ~ error',
                              error
                            );
                          } finally {
                            setLoading(false);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                );
              } else {
                return (
                  <Popover content={'Click to edit'}>
                    <p
                      onClick={() => {
                        setEditDescription({
                          ...isEditDescription,
                          [`${row.id}`]: true,
                        });
                        setDescription(description);
                      }}
                    >
                      {description}
                    </p>
                  </Popover>
                );
              }
            },
            width: '200px',
            sorter: (a, b) => a.description.length - b.description.length,
          },
          {
            title: 'Times Ordered',
            key: 'timesOrdered',
            render: (value, row) => `${row._count.orderedCampaigns} times`,
            sorter: (a, b) =>
              a._count.orderedCampaigns - b._count.orderedCampaigns,
          },
          {
            title: 'Created at',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt) => moment(createdAt).format('L'),
            sorter: (a, b) => {
              if (!a.createdAt || !b.createdAt) return false;
              const diff = moment(a.createdAt).diff(
                moment(b.createdAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Updated at',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt) => moment(updatedAt).format('L'),
            sorter: (a, b) => {
              if (!a.updatedAt || !b.updatedAt) return false;
              const diff = moment(a.updatedAt).diff(
                moment(b.updatedAt),
                'seconds'
              );
              return diff;
            },
          },
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
          },
        ]}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={pagination}
        dataSource={campaigns}
        bordered
        scroll={{ x: true }}
        onChange={handleTableChange}
      />
    </MainLayout>
  );
}
