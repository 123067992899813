import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Input, InputNumber, Switch, Select } from 'antd';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Admin } from '../../../queries';
import './create-campaign-modal.css';

const { Option } = Select;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export default function CreateCampaign(props) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    const { token } = props;
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
  }, []);
  return (
    <>
      <Button
        style={{ borderRadius: '5px' }}
        type="primary"
        onClick={() => {
          setModalOpen(true);
        }}
      >
        Create New Campaign
      </Button>
      <Modal
        title="Create New Campaign"
        visible={isModalOpen}
        centered={true}
        onOk={() => {
          form
            .validateFields()
            .then(async (values) => {
              try {
                await API.graphql(
                  graphqlOperation(Admin.Campaign.CREATE_CAMPAIGN, {
                    data: {
                      ...values,
                      isActive: values.isActive || false,
                    },
                  })
                );
                const { getCampaigns } = props;
                getCampaigns();
                setModalOpen(false);
                form.resetFields();
              } catch (error) {
                console.log(
                  '🚀 ~ file: create-campaign.modal.js ~ line 60 ~ .then ~ error',
                  error
                );
              }
            })
            .catch((errors) => console.error('errors: ', errors));
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form {...layout} form={form} name="create-campaign">
          <Form.Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Please select campaign type!',
              },
            ]}
          >
            <Select style={{ width: 125 }}>
              <Option value="FEATURED">Featured</Option>
              <Option value="PRIME">Prime</Option>
              <Option value="ON_FACEBOOK">On Facebook</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="price"
            label="Price(JOD)"
            rules={[
              {
                required: true,
                message: 'Please input price!',
              },
            ]}
          >
            <InputNumber size="middle" min={1.0} step={1.0} />
          </Form.Item>
          <Form.Item
            name="validForDays"
            label="Valid For Days"
            rules={[
              {
                required: true,
                message: 'Please input valid for days period!',
              },
            ]}
          >
            <InputNumber size="middle" min={1.0} step={1.0} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please input description!',
              },
            ]}
          >
            <Input type="text" minLength="10" />
          </Form.Item>
          <Form.Item
            name="isActive"
            label="Status"
            style={{
              paddingLeft: '11px',
            }}
          >
            <div>
              <Switch
                checkedChildren="Enabled"
                unCheckedChildren="Disabled"
                onChange={(value) => {
                  form.setFieldsValue({ isActive: value });
                }}
              ></Switch>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
