//The button box responsible for the status of the advertisement
import React from 'react';
import { Button, Modal, message } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import { API, graphqlOperation } from 'aws-amplify';
import {
  PauseCircleOutlined,
  CheckSquareTwoTone,
  StopTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons';
import DisApprovalMenu from '../../components/DisApprovalMenu';
import { withRouter } from 'react-router';
import { Admin } from '../../queries';

class HandleStatusModal extends React.Component {
  getWarningMessage = () => {
    const listing = this.props.listing;
    if (!listing.orderedCampaigns || listing.orderedCampaigns.length === 0)
      return '';
    const activeOrderedCampaignsCount = listing.orderedCampaigns.filter(
      (c) => c.status === 'ACTIVE'
    ).length;
    const pendingOrderedCampaignsCount = listing.orderedCampaigns.filter((c) =>
      [
        'PENDING_PAYMENT',
        'PENDING_BY_ADMIN',
        'PENDING_BY_CLIENT',
        'PENDING_FROM_PROVIDER',
        'PENDING_PAYMENT_REVIEW',
      ].includes(c.status)
    ).length;

    const message =
      "Please make sure to handle this listing's ordered campaigns before changing it's status,\nsince it currently has:";

    return (
      <div>
        <p>{message}</p>

        <ul>
          {activeOrderedCampaignsCount > 0 ? (
            <li>
              <b>{activeOrderedCampaignsCount}</b>
              <span style={{ color: '#52c41a', margin: '0px 6px 0px 6px' }}>
                active
              </span>
              campaigns
            </li>
          ) : (
            ''
          )}

          {pendingOrderedCampaignsCount > 0 ? (
            <li>
              <b>{pendingOrderedCampaignsCount}</b>
              <span style={{ color: '#faad14', margin: '0px 6px 0px 6px' }}>
                pending
              </span>
              campaigns
            </li>
          ) : (
            ''
          )}
        </ul>
      </div>
    );
  };
  render() {
    const { listing, handleSave, handleCancel, isVisible } = this.props;
    return (
      <Modal
        visible={isVisible}
        title={<span style={{ color: '#ff4d4f' }}>Warning</span>}
        onOk={null}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="danger" onClick={handleSave}>
            Change Anyway
          </Button>,
          <Button
            key="link"
            href={`${process.env.REACT_APP_DASHBOARD_URL}/listing/edit/${listing.id}/ordered-campaigns/active`}
            type="primary"
            target="_blank"
          >
            Review Now(new tab)
          </Button>,
        ]}
      >
        <div
          style={{
            display: 'flex',
            placeContent: 'center',
            marginBottom: '2rem',
          }}
        >
          <WarningFilled style={{ color: 'red', fontSize: '55px' }} />
        </div>
        {listing.orderedCampaigns.length > 0 && this.getWarningMessage(listing)}
      </Modal>
    );
  }
}

class listingStatusCtrl extends React.Component {
  state = {
    [`DELETED_${this.props.listing.id}_loading`]: false,
    [`REJECTED_${this.props.listing.id}_loading`]: false,
    [`STOPPED_${this.props.listing.id}_loading`]: false,
    [`ACTIVE_${this.props.listing.id}_loading`]: false,
    [`isModalOpen_${this.props.listing.id}_DELETED`]: false,
    [`isModalOpen_${this.props.listing.id}_REJECTED`]: false,
    [`isModalOpen_${this.props.listing.id}_STOPPED`]: false,
    [`isModalOpen_${this.props.listing.id}_ACTIVE`]: false,
    [`isModalOpen_${this.props.listing.id}_PENDING`]: false,
    shouldDisableActivate: false,
  };

  componentDidMount() {
    const activeOrderedCampaignsCount =
      this.props.listing.orderedCampaigns.filter(
        (c) => c.status === 'ACTIVE'
      ).length;
    const pendingOrderedCampaignsCount =
      this.props.listing.orderedCampaigns.filter((c) =>
        [
          'PENDING_PAYMENT',
          'PENDING_BY_ADMIN',
          'PENDING_BY_CLIENT',
          'PENDING_FROM_PROVIDER',
          'PENDING_PAYMENT_REVIEW',
          'CHECKING_STATUS',
        ].includes(c.status)
      ).length;

    this.setState({
      shouldDisableActivate:
        pendingOrderedCampaignsCount > 0 || activeOrderedCampaignsCount === 0,
    });
  }
  back() {
    this.props.history.push('/');
  }

  //////////////////////////change status function//////////////////////////
  listingStatusChangeHandler = async (listing, status, data) => {
    let that = this;
    const { id } = listing;
    let result = data;
    const { changeAListing } = this.props;
    const Index = this.props.index;
    const { getListingAfterChange } = this.props;
    const view = this.props.view;

    const saveAndActivate = this.props.saveAndActivate;
    if (status === 'ACTIVE') {
      const back = this.back.bind(this);

      if (view === 'EDIT') {
        message.warning(
          'Activating from editing page, please make sure all fields are correctly filled'
        );
      }
      if (
        view === 'EDIT' &&
        saveAndActivate !== undefined &&
        (await saveAndActivate(status)) === true
      ) {
        const { listing } = that.props;
        const { id, location } = listing;
        const { listedArea } = location;
        const pushNotification = [];
        if (listedArea) {
          let city = listedArea.city.keyName
            .replace("'", '')
            .replace(/\W /g, '')
            .replace(' ', '-');

          let area = listedArea.keyName
            .replace("'", '')
            .replace(/\W /g, '')
            .replace(' ', '-');
          const topicInit =
            `v3-${listing.category}-${listing.type}-${city}-${area}`
              .replace(' ', '-')
              .replace('(', '')
              .replace(')', '');

          const topic = topicInit.replace(/\s+/g, '-').replace(`'`, '');
          const category = {
            APARTMENT: 'شقة',
            HOUSE: 'فيلا/منزل',
            LAND: 'أرض',
            BUILDING: 'عمارة',
            FARM: 'مزرعة',
            COMMERCIAL_PROPERTY: 'تجاري',
            DEVELOPMENT: 'تطوير',
            UNDER_CONSTRACTION: 'قيد الإنشاء',
          };
          const type1 = { RENT: 'للايجار', SALE: 'للبيع' };
          const headTitle = `اعلان جديد ل${category[listing.category]} ${
            type1[listing.type]
          } في ${listedArea.name}`;

          if (topic) {
            pushNotification.push({
              pushToMultiUser: true,
              pushType: 'TOPIC',
              topic,
              headTitle,
              notificationDescription: 'اضغط لعرض الاعلان',
              notificationType: 'NEW_AD',
            });
          }
        }
        pushNotification.push({
          pushToMultiUser: false,
          pushType: 'APROVED_AD',
          headTitle: 'تم تفعيل اعلانك بنجاح',
          notificationDescription: 'اضغط لعرض الاعلان',
          notificationType: 'APROVED_AD',
        });
        that
          .changeListingStatus({
            listingId: id,
            status,
            variables: {
              where: {
                id,
              },
              data: {
                status: { set: status },
                approvedAt: { set: new Date() },
                disapproveMessage: { set: '' },
                pushNotification,
              },
            },
          })
          .then((res) => {
            if (getListingAfterChange) {
              getListingAfterChange();
            }
          });
        back();
      }

      if (view === 'VIEW') {
        const { listing } = that.props;
        const { id, title, location, author, images, s3Prefix } = listing;
        const { listedArea } = location;
        const pushNotification = [];
        if (listedArea) {
          let city = listedArea.city.keyName
            .replace("'", '')
            .replace(/\W /g, '')
            .replace(' ', '-');

          let area = listedArea.keyName
            .replace("'", '')
            .replace(/\W /g, '')
            .replace(' ', '-');
          const topicInit =
            `v3-${listing.category}-${listing.type}-${city}-${area}`
              .replace(' ', '-')
              .replace('(', '')
              .replace(')', '');

          const topic = topicInit.replace(/\s+/g, '-').replace(`'`, '');
          console.log(topic, ' || ', listedArea.keyName);
          const category = {
            APARTMENT: 'شقة',
            HOUSE: 'فيلا/منزل',
            LAND: 'أرض',
            BUILDING: 'عمارة',
            FARM: 'مزرعة',
            COMMERCIAL_PROPERTY: 'تجاري',
            DEVELOPMENT: 'تطوير',
            UNDER_CONSTRACTION: 'قيد الإنشاء',
          };
          const type1 = { RENT: 'للايجار', SALE: 'للبيع' };
          const headTitle = `اعلان جديد ل${category[listing.category]} ${
            type1[listing.type]
          } في ${listedArea.name}`;

          if (topic) {
            pushNotification.push({
              pushToMultiUser: true,
              pushType: 'TOPIC',
              topic,
              headTitle,
              notificationDescription: 'اضغط لعرض الاعلان',
              notificationType: 'NEW_AD',
            });
          }
        }
        pushNotification.push({
          pushToMultiUser: false,
          pushType: 'APROVED_AD',
          headTitle: 'تم تفعيل اعلانك بنجاح',
          notificationDescription: 'اضغط لعرض الاعلان',
          notificationType: 'APROVED_AD',
        });
        that
          .changeListingStatus({
            status,
            listingId: id,
            variables: {
              where: {
                id,
              },
              data: {
                status: { set: status },
                approvedAt: { set: new Date() },
                disapproveMessage: { set: '' },
                pushNotification,
              },
            },
          })
          .then((res) => {
            if (getListingAfterChange) {
              getListingAfterChange();
            }
            if (changeAListing) {
              changeAListing(res.data.updateListing, Index);
            }
          });
      }
    } else if (status === 'REJECTED') {
      const back = this.back.bind(this);

      Modal.confirm({
        title: 'هل تريد التقدم بذكر اسباب لرفض هذا الاعلان ؟',
        content: (
          <DisApprovalMenu
            data={data}
            getData={(response) => (result = response)}
          />
        ),
        width: 600,
        async onOk() {
          if (view === 'EDIT') {
            message.warning(
              'Rejecting from editing page, please make sure all fields are correctly filled'
            );
          }
          if (
            view === 'EDIT' &&
            saveAndActivate !== undefined &&
            (await saveAndActivate(status)) === true
          ) {
            const { listing } = that.props;
            const { id } = listing;
            const pushNotification = [];
            const headTitle = `نعتذر لقد تم رفض اعلانك ${
              result.message == null ? '' : ', ' + result.message + '.'
            }`;
            pushNotification.push({
              pushToMultiUser: false,
              pushType: 'REJECT_AD',
              headTitle,
              notificationDescription: 'اضغط لعرض الاعلان',
              notificationType: 'REJECT_AD',
            });
            that
              .changeListingStatus({
                status,
                listingId: id,
                variables: {
                  where: {
                    id,
                  },
                  data: {
                    status: {
                      set: status,
                    },
                    disapproveType: {
                      set: Array.isArray(result.types)
                        ? result.types.join(',')
                        : result.types,
                    },
                    disapproveMessage: { set: result.message || '' },
                    pushNotification,
                  },
                },
              })
              .then(() => {
                if (getListingAfterChange) {
                  getListingAfterChange();
                }
              });

            back();
          }
          if (view === 'VIEW') {
            const { listing } = that.props;
            const { id } = listing;
            const pushNotification = [];
            const headTitle = `نعتذر لقد تم رفض اعلانك ${
              result.message == null ? '' : ', ' + result.message + '.'
            }`;
            pushNotification.push({
              pushToMultiUser: false,
              pushType: 'REJECT_AD',
              headTitle,
              notificationDescription: 'اضغط لعرض الاعلان',
              notificationType: 'REJECT_AD',
            });
            that
              .changeListingStatus({
                status,
                listingId: id,
                variables: {
                  where: {
                    id,
                  },
                  data: {
                    status: {
                      set: status,
                    },
                    disapproveType: {
                      set: Array.isArray(result.types)
                        ? result.types.join(',')
                        : result.types,
                    },
                    disapproveMessage: { set: result.message || '' },
                    pushNotification,
                  },
                },
              })
              .then((res) => {
                if (getListingAfterChange) {
                  getListingAfterChange();
                }
                if (changeAListing) {
                  changeAListing(res.data.updateListing, Index);
                }
              });
          }
        },
        onCancel() {},
      });
    } else {
      const back = this.back.bind(this);
      Modal.confirm({
        title: 'هل تريد تغيير حالة هذا الاعلان ؟',
        content: '',
        width: 600,
        async onOk() {
          if (view === 'EDIT') {
            message.warning(
              'Changing status from editing page, please make sure all fields are correctly filled'
            );
          }
          if (
            view === 'EDIT' &&
            saveAndActivate !== undefined &&
            (await saveAndActivate(status)) === true
          ) {
            that
              .changeListingStatus({
                status,
                listingId: id,
                variables: {
                  where: {
                    id,
                  },
                  data: {
                    status: {
                      set: status,
                    },
                  },
                },
              })
              .then(() => {
                if (getListingAfterChange) {
                  getListingAfterChange();
                }
              });
            back();
          }

          if (view === 'VIEW') {
            that
              .changeListingStatus({
                status,
                listingId: id,
                variables: {
                  where: {
                    id,
                  },
                  data: {
                    status: {
                      set: status,
                    },
                  },
                },
              })
              .then((res) => {
                if (getListingAfterChange) {
                  getListingAfterChange();
                }
                if (changeAListing) {
                  changeAListing(res.data.updateListing, Index);
                }
              });
          }
        },
        onCancel() {},
      });
    }
  };

  getStatusMap(status) {
    const statusMap = {
      DELETED: 'DELETED',
      REJECTED: 'REJECTED',
      REJECTEDUPDATED: 'REJECTED',
      STOPPED: 'STOPPED',
      ACTIVE: 'ACTIVE',
      ACTIVEUPDATED: 'ACTIVE',
    };
    return statusMap[status];
  }

  async changeListingStatus({ variables, status, listingId }) {
    const loadingStatus = this.getStatusMap(status);
    this.setState({
      [`${loadingStatus}_${listingId}`]: true,
    });
    const result = await API.graphql(
      graphqlOperation(Admin.Listing.CHANGE_LISTING_STATUS, variables)
    );
    this.setState({
      [`${loadingStatus}_${listingId}`]: false,
    });
    return result;
  }

  _getAddress = (address, scope, short) => {
    const findType = (type) =>
      type.types.indexOf(scope) !== -1 ? scope : false;
    const location = address.map((obj) => obj);
    if (findType) {
      const rr = location.filter(findType)[0];
      let newAddress = rr ? (short ? rr.short_name : rr.long_name) : '';
      newAddress = newAddress.replace('منطقة ', '');
      return newAddress;
    }
    return '';
  };

  getNewActiveListingStatus(listing) {
    return listing.status === 'ACTIVE' || listing.status === 'ACTIVEUPDATED'
      ? 'PENDING'
      : 'ACTIVE';
  }

  render() {
    const { listing } = this.props;
    const newActiveListingStatus = this.getNewActiveListingStatus(listing);
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'center',
        }}
      >
        {/* Delete Button */}
        <Button
          size="large"
          type="danger"
          icon={<DeleteTwoTone twoToneColor="white" />}
          style={{ margin: '0 2px', borderRadius: 5 }}
          disabled={listing.status === 'DELETED'}
          loading={this.state[this.getStatusMap('DELETED')]}
          onClick={() => {
            if (listing.orderedCampaigns.length > 0) {
              this.setState({ [`isModalOpen_${listing.id}_DELETED`]: true });
            } else {
              this.listingStatusChangeHandler(listing, 'DELETED');
            }
          }}
        >
          {listing.orderedCampaigns.length > 0 &&
            this.state[`isModalOpen_${listing.id}_DELETED`] && (
              <HandleStatusModal
                key={`${listing.id}_DELETED`}
                listing={listing}
                isVisible={this.state[`isModalOpen_${listing.id}_DELETED`]}
                handleSave={() => {
                  this.setState(
                    { [`isModalOpen_${listing.id}_DELETED`]: false },
                    () => {
                      this.listingStatusChangeHandler(listing, 'DELETED');
                    }
                  );
                }}
                handleCancel={() => {
                  setTimeout(
                    () =>
                      this.setState({
                        [`isModalOpen_${listing.id}_DELETED`]: false,
                      }),
                    10
                  );
                }}
              />
            )}
          Delete
        </Button>
        {/* Reject Button */}
        <Button
          size="large"
          type="ghost"
          icon={<CloseCircleTwoTone twoToneColor="red" />}
          style={{ margin: '0 2px', borderRadius: 5 }}
          disabled={listing.status === 'REJECTED'}
          loading={this.state[this.getStatusMap('REJECTED')]}
          onClick={() => {
            if (listing.orderedCampaigns.length > 0) {
              this.setState({
                [`isModalOpen_${this.props.listing.id}_REJECTED`]: true,
              });
            } else {
              this.listingStatusChangeHandler(listing, 'REJECTED', {
                types: listing.disapproveType,
                message: listing.disapproveMessage || '',
              });
            }
          }}
        >
          {listing.orderedCampaigns.length > 0 &&
            this.state[`isModalOpen_${listing.id}_REJECTED`] && (
              <HandleStatusModal
                key={`${listing.id}_REJECTED`}
                listing={listing}
                isVisible={this.state[`isModalOpen_${listing.id}_REJECTED`]}
                handleSave={() => {
                  this.setState(
                    { [`isModalOpen_${listing.id}_REJECTED`]: false },
                    () => {
                      this.listingStatusChangeHandler(listing, 'REJECTED', {
                        types: listing.disapproveType,
                        message: listing.disapproveMessage || '',
                      });
                    }
                  );
                }}
                handleCancel={() => {
                  setTimeout(
                    () =>
                      this.setState({
                        [`isModalOpen_${listing.id}_REJECTED`]: false,
                      }),
                    10
                  );
                }}
              />
            )}
          Reject
        </Button>
        {/* Stop Button */}
        <Button
          size="large"
          type="default"
          icon={<StopTwoTone twoToneColor="#99A3A4" />}
          style={{ margin: '0 2px', borderRadius: 5 }}
          disabled={listing.status === 'STOPPED'}
          loading={this.state[this.getStatusMap('STOPPED')]}
          onClick={() => {
            if (listing.orderedCampaigns.length > 0) {
              this.setState({
                [`isModalOpen_${listing.id}_STOPPED`]: true,
              });
            } else {
              this.listingStatusChangeHandler(listing, 'STOPPED');
            }
          }}
        >
          {listing.orderedCampaigns.length > 0 &&
            this.state[`isModalOpen_${listing.id}_STOPPED`] && (
              <HandleStatusModal
                key={`${listing.id}_STOPPED`}
                listing={listing}
                isVisible={this.state[`isModalOpen_${listing.id}_STOPPED`]}
                handleSave={() => {
                  this.setState(
                    { [`isModalOpen_${listing.id}_STOPPED`]: false },
                    () => {
                      this.listingStatusChangeHandler(listing, 'STOPPED');
                    }
                  );
                }}
                handleCancel={() => {
                  setTimeout(
                    () =>
                      this.setState({
                        [`isModalOpen_${listing.id}_STOPPED`]: false,
                      }),
                    10
                  );
                }}
              />
            )}
          Stop
        </Button>
        {/* Activate Button or deactivate */}
        <Button
          size="large"
          type={
            listing.status === 'ACTIVE' || listing.status === 'ACTIVEUPDATED'
              ? 'default'
              : 'primary'
          }
          icon={
            listing.status === 'ACTIVE' ||
            listing.status === 'ACTIVEUPDATED' ? (
              <PauseCircleOutlined />
            ) : (
              <CheckSquareTwoTone />
            )
          }
          disabled={
            listing.isHousing &&
            listing.status !== 'ACTIVE' &&
            listing.status !== 'ACTIVEUPDATED' &&
            this.state.shouldDisableActivate
          }
          style={{
            borderRadius: 5,
            margin: '0 2px',
            color:
              listing.status === 'ACTIVE' || listing.status === 'ACTIVEUPDATED'
                ? 'red'
                : 'white',
          }}
          loading={this.state[this.getStatusMap('ACTIVE')]}
          onClick={() => {
            //If the ad has an ordered Campaign, it will show a list to confirm before changing the status
            if (listing.orderedCampaigns.length > 0) {
              this.setState({
                [`isModalOpen_${listing.id}_${newActiveListingStatus}`]: true,
              });
            } else {
              //active the ad
              this.listingStatusChangeHandler(listing, newActiveListingStatus);
            }
          }}
        >
          {listing.orderedCampaigns.length > 0 &&
            this.state[
              `isModalOpen_${listing.id}_${newActiveListingStatus}`
            ] && (
              <HandleStatusModal
                key={`${listing.id}_${newActiveListingStatus}`}
                listing={listing}
                isVisible={
                  this.state[
                    `isModalOpen_${listing.id}_${newActiveListingStatus}`
                  ]
                }
                handleSave={() => {
                  this.listingStatusChangeHandler(
                    listing,
                    newActiveListingStatus
                  );
                }}
                handleCancel={() => {
                  setTimeout(
                    () =>
                      this.setState({
                        [`isModalOpen_${
                          listing.id
                        }_${this.getNewActiveListingStatus(listing)}`]: false,
                      }),
                    10
                  );
                }}
              />
            )}

          {listing.status === 'ACTIVE' || listing.status === 'ACTIVEUPDATED'
            ? 'Deactivate'
            : 'Activate'}
        </Button>
      </div>
    );
  }
}

export default withRouter(listingStatusCtrl);
