import { Button, Table, Tag } from 'antd';
import {
  EyeOutlined,
  EditTwoTone,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { MainLayout } from '../../components/Layout';
import {
  getCountOfNotifications,
  getManyNotifications,
  setNotificationIsReaded,
} from '../../utils/NoSqlUtils';
import * as moment from 'moment';
import { withRouter } from 'react-router-dom';

function NotificationsDetails({ history }) {
  const [notifications, setNotifications] = useState();
  const [loading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [filteredInfo, setFilteredInfo] = useState({});
  const getData = async () => {
    setLoading(true);
    await getManyNotifications({
      where: {
        isAdminNotification: {
          equals: true,
        },
      },
      take: 10,
      skip: 0,
    }).then((result) => {
      if (result.data.findManyNotificationReceivers) {
        const data = result.data.findManyNotificationReceivers.map(
          ({ id, isReaded, notifications }, i) => ({
            ...notifications,
            key: i,
            message: notifications.headTitle,
            createdAt: notifications.createdAt,
            isReaded,
            notificationId: id,
          })
        );
        setNotifications(data);
      }
      setLoading(false);
    });
    await getCountOfNotifications({
      where: {
        isAdminNotification: {
          equals: true,
        },
      },
    }).then((result) => {
      if (result.data.notificationsOrRegisteredUsersCount) {
        setTotal(
          result.data.notificationsOrRegisteredUsersCount._count.id || 0
        );
      }
    });
  };
  useEffect(() => {
    getData();
  }, []);
  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (text, record) => {
        return (
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <Button
                type="default"
                icon={<EyeOutlined />}
                size="small"
                onClick={() => {
                  setNotificationIsReaded({
                    notificationId: record.notificationId,
                  });
                  window.open(record.listingUrl, '_blank');
                  getData();
                }}
              ></Button>

              <Button
                type="primary"
                icon={<EditTwoTone twoToneColor="white" />}
                size="small"
                onClick={() => {
                  setNotificationIsReaded({
                    notificationId: record.notificationId,
                  });
                  window.open(`/listing/edit/${record.listingId}`);
                  getData();
                }}
              ></Button>
              {record.listingChanges && (
                <Button
                  type="dashed"
                  icon={<UnorderedListOutlined />}
                  size="small"
                  onClick={() => {
                    setNotificationIsReaded({
                      notificationId: record.notificationId,
                    });
                    history.push(
                      `/notification/details/${record.notificationId}`
                    );
                  }}
                ></Button>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('HH:mm - DD/MM/YY'),
    },
    {
      title: 'Redded',
      key: 'isReaded',
      dataIndex: 'isReaded',
      filters: [
        {
          text: 'Yes',
          value: true,
        },
        {
          text: 'No',
          value: false,
        },
      ],
      filteredValue: filteredInfo.isReaded || null,
      onFilter: (value, record) => record.isReaded === value,
      render: (_, { isReaded }) => {
        return (
          <Tag color={isReaded ? 'geekblue' : 'volcano'}>
            {isReaded ? 'is Redded' : 'is Not Readed'}
          </Tag>
        );
      },
    },
  ];

  const handleChange = async (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setLoading(true);
    await getManyNotifications({
      where: {
        isAdminNotification: {
          equals: true,
        },
      },
      take: pagination.pageSize,
      skip: pagination.pageSize * (pagination.current - 1),
    }).then((result) => {
      if (result.data.findManyNotificationReceivers) {
        const data = result.data.findManyNotificationReceivers.map(
          ({ id, isReaded, notifications }, i) => ({
            ...notifications,
            key: i,
            message: notifications.headTitle,
            createdAt: notifications.createdAt,
            isReaded,
            notificationId: id,
          })
        );
        setNotifications(data);
      }
      setLoading(false);
    });
    setCurrent(pagination.current);
  };
  return (
    <MainLayout title="Notifications Details">
      <Table
        dataSource={notifications}
        columns={columns}
        loading={loading}
        pagination={{
          simple: true,
          showQuickJumper: false,
          current: current,
          total: total,
          pageSize: 10,
          position: ['topLeft', 'bottomLeft'],
        }}
        onChange={handleChange}
        bordered
      />
    </MainLayout>
  );
}

export default withRouter(NotificationsDetails);
