import React from 'react';
import { Checkbox, message } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button } from 'antd';
import Icon from '@ant-design/icons';
import { LoginLayout } from '../../components/Layout';
import { withCookies } from 'react-cookie';
import { API, graphqlOperation } from 'aws-amplify';
import './login.css';
import { Admin } from '../../queries';

class LoginForm extends React.Component {
  state = {
    loading: false,
  };
  handleSubmit = (e) => {
    this.setState({ loading: true });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { username, password } = values;
        API.graphql(
          graphqlOperation(Admin.Auth.AUTH_LOGIN, {
            mobile: username,
            password: password,
          })
        )
          .then(({ data }) => {
            const { login: { token, user = {} } = {} } = data || {};
            if (token && user && user.role === 'ADMIN') {
              const { token, user } = data.login;
              const { cookies } = this.props;
              cookies.set('token', token, { path: '/' });
              cookies.set('username', user.name, { path: '/' });
              cookies.set('user', user, { path: '/' });
              this.props.history.push('/');
            }

            if (user && user.role !== 'ADMIN') {
              message.error(
                "You're not authorized to access this website",
                30000
              );
            }
          })
          .catch((err) => {
            message.error(err.errors[0].message);
            this.setState({ loading: false });
          });
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
    } = this.props;

    return (
      <LoginLayout>
        <img src="/images/logo.png" style={{ margin: '24px auto' }} alt={''} />
        <h1 style={{ fontSize: 30 }}>
          {process.env.REACT_APP_FIREBASE_PROJECT_ID === 'khareta-staging'
            ? 'STAGING ENV'
            : 'PRODUCTION'}
        </h1>
        <Form onSubmit={(e) => this.handleSubmit(e)} className="login-form">
          <Form.Item>
            {getFieldDecorator('username', {
              rules: [
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                placeholder="Username"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ],
            })(
              <Input
                prefix={
                  <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                }
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('remember', {
              valuePropName: 'checked',
              initialValue: true,
            })(<Checkbox>Remember me</Checkbox>)}
            <a className="login-form-forgot" href="/#">
              Forgot password
            </a>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={this.state.loading}
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </LoginLayout>
    );
  }
}

const Login = Form.create({ name: 'normal_login' })(LoginForm);

export default withCookies(Login);
