import React from 'react';
import { Layout } from 'antd';
import { isBrowser } from 'react-device-detect';

const { Header, Footer, Sider, Content } = Layout;

export default class LoginLayout extends React.PureComponent {
  render() {
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Header></Header>
        <Layout>
          {isBrowser && <Sider></Sider>}
          <Content>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {this.props.children}
            </div>
          </Content>
          {isBrowser && <Sider></Sider>}
        </Layout>
        <Footer>Khareta </Footer>
      </Layout>
    );
  }
}
