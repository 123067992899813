import React from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import FileType from 'file-type/browser';
import Tiles from '../../../components/image-tile-gallery';
import { Button, message, Spin } from 'antd';
import { Admin } from '../../../queries';
import { generateImageName, sleep } from '../../../utils';
export default class ListingImages extends React.Component {
  state = {
    loading: true,
    imagesObjects: [],
    preSignedImages: [],
    url360: '',
  };
  async componentDidMount() {
    const {
      token,
      listing: { url360 },
    } = this.props;
    Amplify.configure({
      API: {
        graphql_headers: () => {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    });
    this.setState({ loading: true, url360 });
    await this.getS3Images();
    this.setState({ loading: false });
  }

  setImgAsMain = async ({ newImage }) => {
    this.setState({ loading: true });
    const originalImage = this.state.preSignedImages.find(
      (image) => image.main
    );

    const updateMany = [
      {
        where: {
          id: {
            equals: newImage.id,
          },
        },
        data: {
          main: {
            set: true,
          },
        },
      },
    ];

    if (originalImage) {
      updateMany.push({
        where: {
          id: {
            equals: originalImage.id,
          },
        },
        data: {
          main: {
            set: false,
          },
        },
      });
    }

    await API.graphql(
      graphqlOperation(Admin.Listing.UPDATE_LISTING_IMAGES, {
        where: {
          id: this.props.listing.id,
        },
        data: {
          images: {
            updateMany,
          },
        },
      })
    );
    this.setState({ loading: true });
    await this.getS3Images();
  };

  deleteImage = async ({ imageData, s3Prefix }) => {
    this.setState({ loading: true });
    await API.graphql(
      graphqlOperation(Admin.Listing.UPDATE_LISTING_IMAGES, {
        where: { id: imageData.listingId },
        data: {
          s3Prefix: {
            set: s3Prefix,
          },
          images: {
            deleteMany: [
              {
                id: {
                  equals: imageData.id,
                },
                fileName: {
                  equals: imageData.name,
                },
              },
            ],
          },
        },
      })
    );

    this.setState({ loading: true });
    await this.getS3Images();
  };

  getS3Images = async () => {
    this.setState({ loading: true });
    const { listing } = this.props;
    const urls = await this.props.client.query({
      query: Admin.Listing.GET_PRESIGNED_LISTING_IMAGES_URLS,
      variables: {
        listingId: listing.id,
      },
    });

    const mappedPreSignedImagesUrls =
      urls && urls.data && urls.data.getPresignedListingImagesUrls;

    this.setState({
      preSignedImages: mappedPreSignedImagesUrls,
    });

    this.setState({ loading: false });
  };

  uploadNewImages = async ({ filesToCreate, imagesToCreate }) => {
    this.setState({ loading: true });
    const { id, s3Prefix } = this.props.listing;
    const variables = {
      where: { id },
      data: {
        s3Prefix: {
          set: s3Prefix,
        },
        images: {
          create: imagesToCreate,
        },
      },
      files: filesToCreate,
    };

    await this.props.client.mutate({
      variables,
      mutation: Admin.Listing.UPDATE_LISTING_IMAGES,
    });
    this.setState({ loading: false });
  };

  uploadImagefromEditor = async ({
    originalImageObj,
    newImageFile,
    newFileName,
  }) => {
    const { s3Prefix } = this.props.listing;
    const variables = {
      where: { id: originalImageObj.listingId },
      data: {
        s3Prefix: {
          set: s3Prefix,
        },
        images: {
          create: [
            {
              fileName: newFileName,
              fileType: newImageFile.type,
              main: originalImageObj.main,
            },
          ],
          deleteMany: [
            {
              id: {
                equals: originalImageObj.id,
              },
              fileName: {
                equals: originalImageObj.name,
              },
            },
          ],
        },
      },
      files: [{ file: newImageFile }],
    };
    this.setState({ loading: true });
    await this.props.client.mutate({
      variables,
      mutation: Admin.Listing.UPDATE_LISTING_IMAGES,
    });
    await sleep(3000);
    this.setState({ loading: false });
    await this.getS3Images();
  };

  handleNewImages = async (e) => {
    const files = Array.from(e.target.files);
    e.target.value = null;
    const imagesToCreate = [];

    for (const [i, file] of files.entries()) {
      const { mime } = await FileType.fromBlob(file);
      if (mime.includes('image')) {
        imagesToCreate.push({
          fileName: generateImageName(i),
          fileType: file.type,
          main: false,
        });
      }
    }

    if (files.length > 0) {
      const filesToCreate = files.map((file) => ({ file }));
      await this.uploadNewImages({ filesToCreate, imagesToCreate });
      this.setState({ loading: true });
      await sleep(3000);
      await this.getS3Images();
    }
  };

  _handelSave = async () => {
    const { url360 } = this.state;
    const {
      listing: { id, url360: propUrl360 },
      setState,
    } = this.props;
    if (propUrl360 === url360) {
      message.info('There is no change to the url360 to save');
      return;
    }
    try {
      const {
        data: {
          updateListing: { url360: updatedUrl360 },
        },
      } = await API.graphql(
        graphqlOperation(Admin.Listing.UPDATE_LISTING, {
          where: { id },
          data: { url360: { set: url360 } },
        })
      );
      setState({
        url360: updatedUrl360,
      });
      if (updatedUrl360 === url360) {
        message.success('url360 updated successfully');
      } else {
        message.error('url360 updated failed');
      }
      return true;
    } catch (error) {
      console.log(
        '🚀 ~ file: imagesViewer.js:241 ~ ListingImages ~ _handelSave= ~ error',
        error
      );
      message.error(
        'error check the console, or refresh and check locations tab'
      );
      return false;
    }
  };

  render() {
    const { preSignedImages, url360 } = this.state;
    return (
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              dir="rtl"
              style={{
                margin: '10px 0',
              }}
            >
              <label>رابط عرض 360°: </label>
              <input
                style={{
                  border: '1px solid #ccc',
                  display: 'inline-block',
                  padding: '6px 12px',
                  cursor: 'pointer',
                  width: '400px',
                  margin: '0 0 0 10px',
                }}
                type="text"
                onChange={(e) => {
                  this.setState({ url360: e.target.value });
                }}
                value={url360}
                autoFocus
              />
              <Button
                type="primary"
                onClick={() => {
                  this._handelSave();
                }}
              >
                save
              </Button>
            </div>
            <div style={{ margin: '0 16px' }}></div>
            <input
              style={{
                border: '1px solid #ccc',
                display: 'inline-block',
                padding: '6px 12px',
                cursor: 'pointer',
              }}
              type="file"
              onChange={this.handleNewImages}
              autoFocus
              multiple
            />
          </div>
          <br></br>
        </div>
        <div>
          {this.state.loading ? (
            <Spin
              size="large"
              tip="Loading . . ."
              style={{ margin: '20% 45%' }}
            />
          ) : (
            <div>
              <Tiles
                methods={{
                  deleteImage: this.deleteImage,
                  uploadImagefromEditor: this.uploadImagefromEditor,
                  setImgAsMain: this.setImgAsMain,
                }}
                listingId={this.props.listing.id}
                s3Prefix={this.props.listing.s3Prefix}
                originalImages={preSignedImages}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
