import React from 'react';
export default function KitchenAluminium({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <g>
                <path
                  d="M13.687 3.4h1.14V.567h-1.14V3.4zm2.527 1.7H12.3l.95-1.133h2.015l.95 1.133zM15.112 0h-1.71c-.158 0-.286.127-.286.283V3.4c-.084 0-.164.037-.219.102l-1.425 1.7c-.071.084-.086.202-.04.301.047.1.148.164.259.164h5.132c.11 0 .211-.064.258-.164.048-.1.032-.217-.039-.301l-1.426-1.7c-.054-.065-.134-.102-.219-.102V.283c0-.156-.127-.283-.285-.283zM11.406 16.433h5.132v-5.666h-5.132v5.666zm-4.563 0h3.992v-2.55H6.843v2.55zM.57 9.633h5.703v6.8H.57v-6.8zm0-.566h5.703V5.1H.57v3.967zm6.273 4.25h3.992v-2.55H6.843v2.55zm9.98-3.117h-9.98V4.817c0-.157-.127-.284-.285-.284H.285c-.157 0-.285.127-.285.284v11.9c0 .156.128.283.285.283h16.538c.158 0 .285-.127.285-.283v-6.234c0-.156-.127-.283-.285-.283z"
                  transform="translate(-313 -1130) translate(0 1078) translate(183 42) translate(130.828 10)"
                />
                <path
                  d="M12.546 15.3h2.851v-1.7h-2.85v1.7zm-.285.567h3.422c.157 0 .285-.127.285-.284v-2.266c0-.157-.128-.284-.285-.284H12.26c-.157 0-.285.127-.285.284v2.266c0 .157.128.284.285.284zM8.269 11.9L9.41 11.9 9.41 11.333 8.269 11.333zM8.269 15.017L9.41 15.017 9.41 14.45 8.269 14.45zM5.132 7.65L5.703 7.65 5.703 6.517 5.132 6.517zM5.132 13.033L5.703 13.033 5.703 11.617 5.132 11.617zM11.976 12.183L12.546 12.183 12.546 11.617 11.976 11.617zM13.687 12.183L14.257 12.183 14.257 11.617 13.687 11.617zM15.397 12.183L15.968 12.183 15.968 11.617 15.397 11.617zM.57 3.4h4.848V.567H.57V3.4zm5.418 0h4.847V.567H5.988V3.4zm-5.703.567H11.12c.158 0 .286-.127.286-.284v-3.4c0-.156-.128-.283-.286-.283H.285C.128 0 0 .127 0 .283v3.4c0 .157.128.284.285.284z"
                  transform="translate(-313 -1130) translate(0 1078) translate(183 42) translate(130.828 10)"
                />
                <path
                  d="M4.277 2.267L4.847 2.267 4.847 1.7 4.277 1.7zM6.558 2.267L7.128 2.267 7.128 1.7 6.558 1.7zM10.55 9.067c-.057 0-.21-.199-.265-.567h.53c-.055.368-.207.567-.265.567zm-2.566 0h.57V8.5h-.57v.567zm3.422-2.55V5.95H7.414v.567h.57v1.416h-.285c-.158 0-.285.127-.285.284V9.35c0 .156.127.283.285.283h1.14c.158 0 .285-.127.285-.283V8.217c0-.157-.127-.284-.285-.284h-.285V6.517h1.711v1.416H9.98c-.158 0-.285.127-.285.284 0 .807.368 1.416.855 1.416.488 0 .856-.609.856-1.416 0-.157-.128-.284-.286-.284h-.285V6.517h.57z"
                  transform="translate(-313 -1130) translate(0 1078) translate(183 42) translate(130.828 10)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
