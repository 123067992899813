import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Row,
  Col,
  message,
  Button,
  Card,
  Select,
  Spin,
  Tabs,
  Tag,
  AutoComplete,
} from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Title, Input, TextArea } from '../../components/ui/Styled';
import ListingImages from './images/imagesViewer';
import ListingOrCommercialAdLocation from '../../components/listing-commercial-ad-location';
import { MainLayout } from '../../components/Layout';
import ListingStatusCtrl from './listingStatusCtrl';
import * as moment from 'moment';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import profilePic from '../../assets/images/profile.jpg';
import EditListingDetails from './listingDetailsEdit';
import { Admin } from '../../queries';
import { getApolloClient } from '../../clients/apollo';
import OrderedCampaigns from './ordered-campaigns';
import ManualPromotion from '../../components/ManualPromotion';

const { TabPane } = Tabs;
const { Option } = Select;

let client = null;
class EditListing extends React.Component {
  state = {
    id: this.props.match.params.id,
    no: '',
    title: '',
    description: '',
    createdAt: '',
    updatedAt: '',
    type: '',
    category: '',
    price: '',
    rentPriceYear: '',
    rentPriceDay: '',
    installment: '',
    exchangeable: '',
    isWithValuation: '',
    incomeAmount: '',
    isOwner: '',
    isHousing: '',
    ownership: '',
    s3Prefix: '',
    url: '',
    url360: '',
    status: '',
    location: {
      id: '',
      city: '',
      area: '',
      address: '',
      lat: '',
      lng: '',
      isAccurate: '',
      listedArea: {
        id: '',
        name: '',
        city: {
          id: '',
          name: '',
        },
      },
      listedHod: {
        id: '',
        nameAr: '',
        Area: {
          id: '',
          name: '',
        },
      },
      listedLand: {
        id: '',
        sectNameAr: '',
        landKey: '',
        Hod: {
          id: '',
          nameAr: '',
        },
      },
    },
    author: {
      id: '',
      company: '',
      name: '',
      role: '',
      password: '',
      email: '',
      mobile: '',
      createdAt: '',
      status: '',
      whatsApp: '',
      about: '',
      businessPhone: '',
      businessAddress: '',
      photo: '',
      facebookId: '',
      googleId: '',
      mobileVerificationCode: '',
      phone: '',
    },
    contactMethod: {
      type: null,
      phone: null,
      whatsApp: null,
      url: null,
      message: null,
    },
    features: {},
    images: [],
    cities: [],
    hods: [],
    sects: [],
    lands: [],
    lat: '',
    lng: '',
    laoding: true,
    i: 0,
    dataSource: [],
    hodsDataSource: [],
    sectsDataSource: [],
    landsDataSource: [],
    areaId: '',
    hodId: '',
    landId: '',
    sectId: '',
    timing: false,
    selectedOrderedCampaignsStatus: 'ACTIVE',
    ownerName: '',
    ownerPhoneNumber: '',
    note: '',
    referenceNumber: '',
    ownerWhatsappNumber: '',
    polygon: [],
    zoom: 14,
  };

  async componentDidMount() {
    const { token } = this.props;
    client = getApolloClient({ token });
    Amplify.configure({
      API: {
        graphql_headers: () => {
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      },
    });
    setTimeout(() => this.handleScroll(), 2000);
    this.getData();
  }
  handleScroll = () => {
    window.scrollTo(0, 0);
  };
  getData = async () => {
    const listingData = await API.graphql(
      graphqlOperation(Admin.Listing.GET_LISTING_FOR_EDIT, {
        where: { id: this.state.id },
      })
    );
    this.setState({
      title: listingData.data.listing.title,
      no: listingData.data.listing.no,
      description: listingData.data.listing.description,
      createdAt: listingData.data.listing.createdAt,
      updatedAt: listingData.data.listing.updatedAt,
      type: listingData.data.listing.type,
      category: listingData.data.listing.category,
      price: listingData.data.listing.price,
      rentPriceYear: listingData.data.listing.rentPriceYear,
      rentPriceDay: listingData.data.listing.rentPriceDay,
      installment: listingData.data.listing.installment,
      exchangeable: listingData.data.listing.exchangeable,
      isWithValuation: listingData.data.listing.isWithValuation,
      incomeAmount: listingData.data.listing.incomeAmount,
      isOwner: listingData.data.listing.isOwner,
      isHousing: listingData.data.listing.isHousing,
      ownership: listingData.data.listing.ownership,
      s3Prefix: listingData.data.listing.s3Prefix,
      url: listingData.data.listing.url,
      url360: listingData.data.listing.url360,
      status: listingData.data.listing.status,
      location: listingData.data.listing.location,
      features: listingData.data.listing.features,
      images: listingData.data.listing.images,
      author: listingData.data.listing.author,
      disapproveType: listingData.data.listing.disapproveType,
      disapproveMessage: listingData.data.listing.disapproveMessage || '',
      orderedCampaigns: listingData.data.listing.orderedCampaigns,
      contactMethod: listingData.data.listing.contactMethod,
      ownerName: listingData.data.listing.ownerName,
      ownerPhoneNumber: listingData.data.listing.ownerPhoneNumber,
      note: listingData.data.listing.note,
      referenceNumber: listingData.data.listing.referenceNumber,
      ownerWhatsappNumber: listingData.data.listing.ownerWhatsappNumber,
      hodId: listingData.data.listing.location.listedHod
        ? listingData.data.listing.location.listedHod.id
        : '',
      landId: listingData.data.listing.location.listedLand
        ? listingData.data.listing.location.listedLand.id
        : '',
      sectId: listingData.data.listing.location.listedLand
        ? listingData.data.listing.location.listedLand.sectId
        : '',
      lat: listingData.data.listing.location.lat,
      lng: listingData.data.listing.location.lng,
      polygon:
        listingData.data.listing.location.listedLand &&
        listingData.data.listing.location.listedLand.polygon,
      zoom:
        listingData.data.listing.location.listedLand &&
        listingData.data.listing.location.listedLand.zoom,
    });
    const cities = await API.graphql(
      graphqlOperation(Admin.City.GET_V3_CITIES_AND_AREAS_NAMES)
    );
    this.setState({
      cities: cities.data.cities,
    });

    if (this.state.location.listedArea === null) {
      message.error('must edit location first or refresh on save error', 30);

      this.setState({
        location: {
          ...this.state.location,
          listedArea: {
            id: '',
            name: 'not listed',
            city: {
              id: '',
              name: 'not listed',
            },
          },
        },
      });
    } else {
      for (let i = 0; i < this.state.cities.length; i++) {
        if (
          this.state.location.listedArea.city.name === this.state.cities[i].name
        ) {
          this.setState({
            i: i,
          });
          break;
        }
      }
    }

    let areaArr = this.state.cities[this.state.i].areas.map((area) => {
      if (this.state.location.listedArea.name === area.name) {
        this.setState({
          areaId: area.id,
        });
      }
      return area.name;
    });
    this.setState({
      dataSource: areaArr,
    });
    this.setState({
      laoding: false,
    });
    this._getHods({
      where: {
        Area: {
          is: {
            id: {
              equals: this.state.areaId,
            },
          },
        },
      },
    });

    this._getLands({
      where: {
        Hod: {
          is: {
            id: {
              equals: this.state.hodId,
            },
          },
        },
        sectId: { equals: this.state.sectId },
      },
      take: 100,
    });

    this._getSects({
      where: {
        Hod: {
          is: {
            id: {
              equals: this.state.hodId,
            },
          },
        },
      },
      take: 100,
    });
  };

  _getHods = async (variables) => {
    let hods = await API.graphql(
      graphqlOperation(Admin.City.GET_HODS, variables)
    );
    this.setState({
      hods: hods.data.hods,
    });
    hods = await hods.data.hods.map((hod) => hod.nameAr);
    this.setState({
      hodsDataSource: hods,
    });
  };
  _getSects = async (variables) => {
    let sects = await API.graphql(
      graphqlOperation(Admin.City.GET_LANDS, {
        ...variables,
        distinct: 'sectId',
      })
    );
    this.setState({
      sects: sects.data.lands,
    });
    sects = await sects.data.lands.map((sect) => sect.sectId);
    this.setState({
      sectsDataSource: sects,
    });
  };

  _getLands = async (variables) => {
    let lands = await API.graphql(
      graphqlOperation(Admin.City.GET_LANDS, variables)
    );
    this.setState({
      lands: lands.data.lands,
    });
    lands = await lands.data.lands.map((land) => land.landKey);
    this.setState({
      landsDataSource: lands,
    });
  };

  _getNewAreas = async (i) => {
    let areaArr = this.state.cities[i].areas.map((area) => {
      return area.name;
    });
    this.setState({
      dataSource: areaArr,
    });
  };
  _changeArea = async (value) => {
    let length = this.state.cities[this.state.i].areas.length;
    let array = this.state.cities[this.state.i].areas;

    for (let i = 0; i < length; i++) {
      if (value === array[i].name) {
        this.setState({
          areaId: array[i].id,
        });
        this.setState({
          lat: array[i].lat,
          lng: array[i].lng,
        });
        this._getHods({
          where: {
            Area: {
              is: {
                id: {
                  equals: array[i].id,
                },
              },
            },
          },
        });
        break;
      }
      this.setState({
        hodsDataSource: [],
      });
    }
  };
  _changeHod = async (value) => {
    let hods = this.state.hods;
    for (let i = 0; i < hods.length; i++) {
      if (value === hods[i].nameAr) {
        this.setState({
          hodId: hods[i].id,
        });
        this._getSects({
          where: {
            Hod: {
              is: {
                id: {
                  equals: hods[i].id,
                },
              },
            },
          },
          take: 100,
        });
        break;
      }
      this.setState({
        landsDataSource: [],
        sectsDataSource: [],
      });
    }
  };
  _changeSect = async (value) => {
    let sects = this.state.sects;
    for (let i = 0; i < sects.length; i++) {
      if (value === sects[i].sectId) {
        this.setState({
          sectId: sects[i].sectId,
        });
        this._getLands({
          where: {
            Hod: {
              is: {
                id: {
                  equals: this.state.hodId,
                },
              },
            },
            sectId: { equals: sects[i].sectId },
          },
          take: 100,
        });
        break;
      }
      this.setState({
        landsDataSource: [],
        sectsDataSource: [],
      });
    }
  };
  _changeLand = async (value) => {
    let lands = this.state.lands;
    for (let i = 0; i < lands.length; i++) {
      if (value === lands[i].landKey) {
        this.setState({
          landId: lands[i].id,
        });
        this.setState({
          lat: lands[i].lat,
          lng: lands[i].lng,
        });
        break;
      }
    }
    this._getLands({
      where: {
        Hod: {
          is: {
            id: {
              equals: this.state.hodId,
            },
          },
        },
        landKey: { contains: value },
        sectId: { equals: this.state.sectId },
      },
      take: 100,
    });
  };
  async callback(stateValues) {
    const res = [stateValues.listingFeatures];

    await this.setState({
      installment: stateValues.paymentPlan,
      type: stateValues.listingSellingType,
      description: stateValues.listingDescription,
      title: stateValues.listingTitle,
      price: stateValues.actualPrice,
      category: stateValues.listingCategory,
      rentPriceDay: stateValues.rentPriceDay,
      rentPriceYear: stateValues.rentPriceYear,
      isOwner: stateValues.ownerType,
      isHousing: stateValues.isHousing,
      exchangeable: stateValues.exchangeable,
      incomeAmount: stateValues.incomeAmount,
      features: res,
    });
  }

  checkValid() {
    const result = [];
    const feats = this.state.features[0];
    const titleLength = this.state.title.length;
    const detailsLength = this.state.description.length;
    const ownerL = this.state.isOwner;
    const isHousing = this.state.isHousing;
    const sellingType = this.state.type;
    const salePriceField = this.state.price;
    const salePayField = this.state.installment;
    const rentDay = this.state.rentPriceDay;
    const rentYear = this.state.rentPriceYear;
    const category = this.state.category;
    const title = titleLength >= 6 && titleLength <= 100 ? null : 'Title';
    const details = detailsLength >= 6 ? null : 'Details';
    const owner = ownerL === null ? 'Owner' : null;
    const selling = sellingType === null ? 'Selling Type' : null;
    const categories = category === null ? 'Category' : null;
    const salefields =
      sellingType === 'SALE'
        ? salePayField === null || salePriceField === null || salePriceField < 1
          ? 'Sale Price'
          : null
        : null;
    // const farmField =
    //   (this.state.category === 'FARM' &&
    //     this.state.type === 'RENT' &&
    //     this.state.rentPriceDay === null) ||
    //   this.state.rentPriceYear < 1
    //     ? 'Farm rent field'
    //     : null;
    const rentfields =
      sellingType === 'RENT' && category !== 'FARM'
        ? salePriceField === null ||
          salePriceField < 0 ||
          rentYear === null ||
          rentYear < 0
          ? 'Rent Price field'
          : null
        : null;

    const buildingAge = null;
    // feats.isNew === true
    //   ? null
    //   : feats.buildingAge === null || feats.buildingAge <= 0
    //   ? 'Building Age'
    //   : null;
    const totalApartments =
      feats.totalApartments === null || feats.totalApartments <= 0
        ? 'Total Apartments'
        : null;
    ///push none valid items to validity array
    const buildingArea =
      feats.buildingArea === null || feats.buildingArea <= 0
        ? 'Building Area'
        : null;
    const terraceArea =
      feats.terraceArea === null || feats.terraceArea <= 0
        ? 'Terrace Area'
        : null;
    const roofArea =
      feats.roofArea === null || feats.roofArea <= 0 ? 'Roof Area' : null;
    const landArea =
      feats.landArea === null || feats.landArea <= 0 ? 'Land Area' : null;
    const streetFront = null;
    // feats.streetFront === null || feats.streetFront <= 0
    //   ? 'Street Front'
    //   : null;
    const totalFloors =
      feats.totalFloors === null || feats.totalFloors <= 0
        ? 'Total floors'
        : null;
    const totalUnits =
      feats.totalUnits === null || feats.totalUnits <= 0 ? 'Total Units' : null;

    const floorRange = feats.floorRange === null ? 'Floor Range' : null;
    const floorsApartmentsRange =
      feats.floorsApartmentsRange === null ? 'Floors Apartments Range' : null;
    const bedroomsRange =
      feats.bedroomsRange === null ? 'Bedrooms Range' : null;
    const bathroomsRange =
      feats.bathroomsRange === null ? 'Bedrooms Range' : null;

    ///category features that are numbers validations
    const apartment =
      category === 'APARTMENT' && !isHousing
        ? [
            buildingArea,
            buildingAge,
            totalApartments,
            feats.withTerrace === true ? terraceArea : null,
            feats.withRoof === true ? roofArea : null,
          ]
        : [null];

    const housing =
      category === 'APARTMENT' && isHousing
        ? [
            totalApartments,
            floorRange,
            totalFloors,
            floorsApartmentsRange,
            bedroomsRange,
            bathroomsRange,
            feats.withTerrace === true ? terraceArea : null,
            feats.withRoof === true ? roofArea : null,
          ]
        : [null];

    const house =
      category === 'HOUSE' ? [buildingArea, buildingAge, landArea] : [null];
    const land = category === 'LAND' ? [streetFront] : [null];
    const building =
      category === 'BUILDING'
        ? [totalFloors, totalUnits, buildingArea, buildingAge, landArea]
        : [null];
    const farm =
      category === 'FARM'
        ? [landArea, feats.withBuilding === true ? buildingArea : null]
        : [null];
    const commercial =
      category === 'COMMERCIAL_PROPERTY' ? [buildingArea, buildingAge] : [null];
    result.push(
      title,
      details,
      owner,
      selling,
      salefields,
      rentfields,
      categories
    );
    const results = result
      .concat(apartment)
      .concat(housing)
      .concat(house)
      .concat(land)
      .concat(building)
      .concat(farm)
      .concat(commercial);
    const res = results.filter((v) => v !== null);

    return res;
  }

  _handelSave = async (value) => {
    await this.setState({ timing: !this.state.timing });

    const valid = this.checkValid();
    if (valid.length > 0) {
      const fields = valid.join(',  ');
      message.error(`Please check the following fields : ${fields}`);
      return false;
    } else {
      try {
        const feat = this.state.features[0];
        const id = feat.id;
        delete feat.id;

        const featureUpdate = Object.keys(feat).reduce((result, current) => {
          if (
            [
              'bathroomsRange',
              'bedroomsRange',
              'floorRange',
              'floorsApartmentsRange',
            ].includes(current)
          ) {
            if (feat[current].every((item) => typeof item === 'object')) {
              result[current] = feat[current].map((item) => item.value);
            } else {
              result[current] = feat[current];
            }
          } else {
            result[current] = { set: feat[current] };
          }
          return result;
        }, {});

        const listingData = await API.graphql(
          graphqlOperation(Admin.Listing.UPDATE_LISTING, {
            where: { id: this.state.id },
            data: {
              title: { set: this.state.title },
              ownership: { set: this.state.ownership },
              description: { set: this.state.description },
              isOwner: { set: this.state.isOwner },
              isHousing: { set: this.state.isHousing },
              type: { set: this.state.type },
              category: { set: this.state.category },
              price: { set: this.state.price },
              rentPriceYear: { set: this.state.rentPriceYear },
              rentPriceDay: { set: this.state.rentPriceDay },
              installment: { set: this.state.installment },
              exchangeable: { set: this.state.exchangeable },
              incomeAmount: { set: this.state.incomeAmount },
              s3Prefix: { set: this.state.s3Prefix },
              url: { set: this.state.url },
              status: { set: value ? value : this.state.status },
              ...(this.state.ownerName && {
                ownerName: { set: this.state.ownerName },
              }),
              ...(this.state.ownerPhoneNumber && {
                ownerPhoneNumber: { set: this.state.ownerPhoneNumber },
              }),
              ...(this.state.note && { note: { set: this.state.note } }),
              ...(this.state.referenceNumber && {
                referenceNumber: { set: this.state.referenceNumber },
              }),
              ...(this.state.ownerWhatsappNumber && {
                ownerWhatsappNumber: { set: this.state.ownerWhatsappNumber },
              }),
              ...(this.state.contactMethod && {
                contactMethod: {
                  update: {
                    type: { set: this.state.contactMethod.type },
                    phone: { set: this.state.contactMethod.phone },
                    whatsApp: { set: this.state.contactMethod.whatsApp },
                    url: { set: this.state.contactMethod.url },
                    message: { set: this.state.contactMethod.message },
                  },
                },
              }),
              features: {
                update: {
                  where: {
                    id: id,
                  },
                  data: featureUpdate,
                },
              },
              location: {
                update: {
                  city: { set: this.state.location.city },
                  area: { set: this.state.location.area },
                  address: { set: this.state.location.address },
                  lat: {
                    set:
                      parseFloat(this.state.lat) ||
                      parseFloat(this.state.location.lat),
                  },
                  lng: {
                    set:
                      parseFloat(this.state.lng) ||
                      parseFloat(this.state.location.lng),
                  },
                  isAccurate: { set: this.state.location.isAccurate },
                  listedArea: {
                    connect: {
                      id: this.state.areaId,
                    },
                  },
                  ...(this.state.hodId
                    ? {
                        listedHod: {
                          connect: {
                            id: this.state.hodId,
                          },
                        },
                      }
                    : {
                        listedHod: {
                          disconnect: true,
                        },
                      }),
                  ...(this.state.landId
                    ? {
                        listedLand: {
                          connect: {
                            id: this.state.landId,
                          },
                        },
                      }
                    : {
                        listedLand: {
                          disconnect: true,
                        },
                      }),
                },
              },
            },
          })
        );

        feat.id = id;
        this.setState({
          title: listingData.data.updateListing.title,
          description: listingData.data.updateListing.description,
          createdAt: listingData.data.updateListing.createdAt,
          updatedAt: listingData.data.updateListing.updatedAt,
          type: listingData.data.updateListing.type,
          category: listingData.data.updateListing.category,
          price: listingData.data.updateListing.price,
          rentPriceYear: listingData.data.updateListing.rentPriceYear,
          rentPriceDay: listingData.data.updateListing.rentPriceDay,
          installment: listingData.data.updateListing.installment,
          exchangeable: listingData.data.updateListing.exchangeable,
          isWithValuation: listingData.data.updateListing.isWithValuation,
          incomeAmount: listingData.data.updateListing.incomeAmount,
          isOwner: listingData.data.updateListing.isOwner,
          ownership: listingData.data.updateListing.ownership,
          s3Prefix: listingData.data.updateListing.s3Prefix,
          url: listingData.data.updateListing.url,
          url360: listingData.data.updateListing.url360,
          status: value ? value : listingData.data.updateListing.status,
          location: listingData.data.updateListing.location,
          features: listingData.data.updateListing.features,
          images: listingData.data.updateListing.images,
          contactMethod: listingData.data.updateListing.contactMethod,
        });

        message.success('updated successfully');
        return true;
      } catch (e) {
        message.error(
          'error check the console, or refresh and check locations tab'
        );
        return false;
      }
    }
  };

  getActiveTab() {
    if (!this.props.location.pathname || !this.props.match.url)
      return 'details';
    const TABS = {
      details: 'details',
      location: 'location',
      images: 'images',
      info: 'info',
      'ordered-campaigns': 'ordered-campaigns',
      'ordered-campaigns/pending-by-admin': 'ordered-campaigns',
      'ordered-campaigns/pending-by-client': 'ordered-campaigns',
      'ordered-campaigns/active': 'ordered-campaigns',
      'ordered-campaigns/completed': 'ordered-campaigns',
      'ordered-campaigns/checking-status': 'ordered-campaigns',
      'ordered-campaigns/pending-payment': 'ordered-campaigns',
      'ordered-campaigns/pending-payment-review': 'ordered-campaigns',
      'ordered-campaigns/pending-from-provider': 'ordered-campaigns',
      'ordered-campaigns/rejected-payment': 'ordered-campaigns',
      'ordered-campaigns/abandoned': 'ordered-campaigns',
      promote: 'promote',
    };
    const tabKey = this.props.location.pathname.slice(
      this.props.match.url.length + 1
    );
    if (TABS[tabKey]) return TABS[tabKey];
    return 'details';
  }

  getActiveTabForOrderedCampaigns() {
    if (!this.props.location.pathname || !this.props.match.url)
      return 'ordered-campaigns/pending-by-admin';
    const TABS = {
      'ordered-campaigns/pending-by-admin':
        'ordered-campaigns/pending-by-admin',
      'ordered-campaigns/pending-by-client':
        'ordered-campaigns/pending-by-client',
      'ordered-campaigns/active': 'ordered-campaigns/active',
      'ordered-campaigns/completed': 'ordered-campaigns/completed',
      'ordered-campaigns/checking-status': 'ordered-campaigns/checking-status',
      'ordered-campaigns/pending-payment': 'ordered-campaigns/pending-payment',
      'ordered-campaigns/pending-payment-review':
        'ordered-campaigns/pending-payment-review',
      'ordered-campaigns/pending-from-provider':
        'ordered-campaigns/pending-from-provider',
      'ordered-campaigns/rejected-payment':
        'ordered-campaigns/rejected-payment',
      'ordered-campaigns/abandoned': 'ordered-campaigns/abandoned',
    };
    const tabKey = this.props.location.pathname.slice(
      this.props.match.url.length + 1
    );
    if (TABS[tabKey]) return TABS[tabKey];
    return 'ordered-campaigns/pending-by-admin';
  }

  render() {
    const { history, token } = this.props;
    const saveAndActivate = this._handelSave;
    const operations = (
      <ListingStatusCtrl
        saveAndActivate={saveAndActivate}
        view={'EDIT'}
        listing={this.state}
        getListingAfterChange={this.getData}
      />
    );
    return (
      <MainLayout>
        {this.state.laoding ? (
          <Spin
            size="large"
            tip="Loading . . ."
            style={{ margin: '20% 45%' }}
          />
        ) : (
          <React.Fragment>
            <Button
              type="primary"
              onClick={() => {
                this._handelSave();
              }}
            >
              save changes
            </Button>{' '}
            <Tag color={this.state.status !== 'ACTIVE' ? 'red' : 'green'}>
              {this.state.status}
            </Tag>
            <Tag color={'green'}>{this.state.isHousing ? 'اسكان' : 'عقار'}</Tag>
            <Tabs
              tabBarExtraContent={operations}
              activeKey={this.getActiveTab()}
            >
              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/details`}>
                    <Button type="link">Details</Button>
                  </Link>
                }
                key="details"
              >
                <EditListingDetails
                  listingData={{
                    id: this.state.id,
                    s3Prefix: this.state.s3Prefix,
                    sellingtype: this.state.type,
                    category: this.state.category,
                    title: this.state.title,
                    description: this.state.description,
                    ownertype: this.state.isOwner,
                    paymentplan: this.state.installment,
                    actualprice: this.state.price,
                    rentPriceYear: this.state.rentPriceYear,
                    rentPriceDay: this.state.rentPriceDay,
                    features: this.state.features,
                    isHousing: this.state.isHousing,
                    contactMethod: this.state.contactMethod,
                    exchangeable: this.state.exchangeable,
                    incomeAmount: this.state.incomeAmount,
                    isWithValuation: this.state.isWithValuation,
                  }}
                  timing={this.state.timing}
                  callback={this.callback.bind(this)}
                  state={this.state}
                  setState={(state) => this.setState(state)}
                  client={client}
                ></EditListingDetails>
              </TabPane>
              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/location`}>
                    <Button type="link">Location</Button>
                  </Link>
                }
                key="location"
              >
                {/* <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1 style={{ textAlign: 'center', color: 'blue' }}>الموقع</h1>
                <br></br>
                <br></br>
                <br></br>
                <br></br> */}
                <Row gutter={24}>
                  <Col span={1}></Col>
                  <Col span={10}>
                    <div>
                      <Title> المدينة </Title>

                      <Select
                        defaultValue={this.state.location.listedArea.city.name}
                        style={{ width: '100%' }}
                        onChange={(value) => {
                          this._getNewAreas(value);
                          this.setState({
                            location: {
                              ...this.state.location,
                              city: this.state.cities[value].name,
                            },
                            i: value,
                          });
                        }}
                      >
                        {this.state.cities.map((province, i) => (
                          <Option key={i}>{province.name}</Option>
                        ))}
                      </Select>
                      <div>
                        <Title> المنطقة </Title>
                        <AutoComplete
                          style={{ width: '100%' }}
                          value={this.state.location.area}
                          dataSource={this.state.dataSource}
                          placeholder="المنطقة"
                          onChange={(value) => {
                            this._changeArea(value);
                            this.setState({
                              location: {
                                ...this.state.location,
                                area: value,
                              },
                            });
                          }}
                          filterOption={true}
                          optionFilterProp={'children'}
                        />
                        <>
                          <Title> الحوض </Title>
                          <AutoComplete
                            style={{ width: '100%' }}
                            value={
                              this.state.location.listedHod
                                ? this.state.location.listedHod.nameAr
                                : ''
                            }
                            dataSource={this.state.hodsDataSource}
                            placeholder="الحوض"
                            onChange={(value) => {
                              this._changeHod(value);
                              this.setState({
                                location: {
                                  ...this.state.location,
                                  listedHod: { nameAr: value },
                                },
                              });
                            }}
                            filterOption={true}
                            optionFilterProp={'children'}
                            disabled={this.state.category !== 'LAND'}
                          />
                          <>
                            <Title> رقم الحي </Title>
                            <AutoComplete
                              style={{ width: '100%' }}
                              value={this.state.sectId}
                              dataSource={this.state.sectsDataSource}
                              placeholder="رقم الحي"
                              onChange={(value) => {
                                this._changeSect(value);
                                this.setState({
                                  sectId: value,
                                });
                              }}
                              filterOption={true}
                              optionFilterProp={'children'}
                              disabled={this.state.category !== 'LAND'}
                            />
                          </>
                          <>
                            <Title> رقم القطعة </Title>
                            <AutoComplete
                              style={{ width: '100%' }}
                              value={
                                this.state.location.listedLand
                                  ? this.state.location.listedLand.landKey
                                  : ''
                              }
                              dataSource={this.state.landsDataSource}
                              placeholder="رقم القطعة"
                              onChange={(value) => {
                                this._changeLand(value);
                                this.setState({
                                  location: {
                                    ...this.state.location,
                                    listedLand: { landKey: value },
                                  },
                                });
                              }}
                              filterOption={true}
                              optionFilterProp={'children'}
                              disabled={this.state.category !== 'LAND'}
                            />
                          </>
                        </>

                        <Title> العنوان </Title>
                        <Input
                          type="text"
                          name="address"
                          onChange={(value) => {
                            this.setState({
                              location: {
                                ...this.state.location,
                                address: value.target.value,
                              },
                            });
                          }}
                          value={this.state.location.address}
                          style={{ width: '95%' }}
                        />
                      </div>
                    </div>
                    <Title> الاحداثيات </Title>
                    <Card style={{ width: '100%' }}>
                      <h1> lat : </h1>
                      <Input
                        type="text"
                        name="lat"
                        style={{ width: '90%' }}
                        value={`${this.state.lat}`}
                        onChange={(value) => {
                          this.setState({
                            lat:
                              value.target.value === ''
                                ? null
                                : parseInt(value.target.value),
                          });
                        }}
                      />
                      <h1> lng : </h1>
                      <Input
                        type="text"
                        name="lng"
                        style={{ width: '90%' }}
                        value={`${this.state.lng}`}
                        onChange={(value) => {
                          this.setState({
                            lng:
                              value.target.value === ''
                                ? null
                                : parseInt(value.target.value),
                          });
                        }}
                      />
                    </Card>
                  </Col>
                  <Col span={3}></Col>
                  <Col span={10}>
                    <ListingOrCommercialAdLocation
                      listing={this.state}
                      latitude={this.state.lat}
                      longitude={this.state.lng}
                      polygon={this.state.polygon}
                      zoom={this.state.zoom}
                      getValue={(key, value) => {
                        let obj = {
                          ...this.state.location,
                        };
                        obj[key] = value;
                        this.setState({
                          location: obj,
                          lat: obj.lat,
                          lng: obj.lng,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/images`}>
                    <Button type="link">Images</Button>
                  </Link>
                }
                key="images"
              >
                {/* <br></br>
                <br></br>
                <br></br>
                <br></br>
                <h1 style={{ textAlign: 'center', color: 'blue' }}>الصور</h1>
                <br></br>
                <br></br>
                <br></br>
                <br></br> */}
                <Row gutter={8}>
                  <Col span={24}>
                    <ListingImages
                      listing={{
                        ...this.state,
                        id: this.props.match.params.id,
                      }}
                      token={token}
                      client={client}
                      setState={(states) => {
                        this.setState(states);
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/info`}>
                    <Button type="link">Info</Button>
                  </Link>
                }
                key="info"
              >
                <Row gutter={8}>
                  <Col span={8}>
                    <Card title="User Details">
                      <img
                        style={{ marginBottom: 20, width: '100%' }}
                        src={`${process.env.REACT_APP_IMAGES_BASE_URL}/users/${this.state.author.id}/${this.state.author.photo}`} //was 150x150
                        alt={''}
                        onError={(i) => (i.target.src = profilePic)}
                      />
                      <Tag color="green">{this.state.author.role}</Tag>
                      <Tag color="green">
                        {this.state.author.createdAt
                          .split('')
                          .splice(0, 10)
                          .join('')}
                      </Tag>
                      <Tag
                        color={
                          this.state.author.status === 'ACTIVE'
                            ? 'green'
                            : 'red'
                        }
                      >
                        {this.state.author.status}
                      </Tag>
                      <Tag color="green">
                        <Icon
                          type={
                            this.state.author.facebookId ||
                            this.state.author.googleId
                              ? this.state.author.facebookId
                                ? 'facebook'
                                : 'google'
                              : 'phone'
                          }
                        />
                      </Tag>
                      <h2>{this.state.author.name}</h2>
                      {this.state.author.email ? (
                        <p> email : {this.state.author.email}</p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.mobile ? (
                        <p> mobile : {this.state.author.mobile}</p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.phone ? (
                        <p> phone : {this.state.author.phone}</p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.about ? (
                        <p> about : {this.state.author.about}</p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.company ? (
                        <p> company : {this.state.author.company}</p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.businessPhone ? (
                        <p>
                          {' '}
                          businessPhone : {this.state.author.businessPhone}
                        </p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.businessAddress ? (
                        <p>
                          {' '}
                          businessAddress : {this.state.author.businessAddress}
                        </p>
                      ) : (
                        <div></div>
                      )}
                      {this.state.author.mobileVerificationCode ? (
                        <p>
                          {' '}
                          mobileVerificationCode :{' '}
                          {this.state.author.mobileVerificationCode}
                        </p>
                      ) : (
                        <div></div>
                      )}
                      <br></br> <br></br>
                      <Button
                        onClick={() => {
                          history.push(`/user/profile/${this.state.author.id}`);
                        }}
                        style={{ width: '100%', height: 50 }}
                      >
                        <EditTwoTone />
                      </Button>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title="Listing Status" size="small">
                      {(this.state.status === 'ACTIVE' ||
                        this.state.status === 'ACTIVEUPDATED') && (
                        <p>
                          <a
                            href={`${process.env.REACT_APP_HOST_NAME}/properties/${this.state.url}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Listing Link on Website
                          </a>
                        </p>
                      )}
                      {
                        <Tag
                          color={
                            this.state.status === 'PENDING' ||
                            this.state.status === 'DELETED'
                              ? 'red'
                              : 'green'
                          }
                        >
                          {this.state.status}
                        </Tag>
                      }
                      <br></br>
                      <br></br>
                      {this.state.no && <p>Listing No: {this.state.no}</p>}
                      {this.state.createdAt && (
                        <p>
                          Created At:{' '}
                          {moment(this.state.createdAt).format(
                            'HH:mm - DD/MM/YY'
                          )}
                        </p>
                      )}
                      {this.state.updatedAt && (
                        <p>
                          Upddated At:{' '}
                          {moment(this.state.updatedAt).format(
                            'HH:mm - DD/MM/YY'
                          )}
                        </p>
                      )}
                      {this.state.disapproveType &&
                        this.state.disapproveType.length > 0 && (
                          <p>Disapprove: {this.state.disapproveType}</p>
                        )}
                      {this.state.disapproveMessage && (
                        <p>Message: {this.state.disapproveMessage}</p>
                      )}
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title="Admin Notes" size="small">
                      <TextArea
                        name="ownership"
                        rows={6}
                        value={this.state.ownership}
                        onChange={(value) => {
                          this.setState({
                            ownership: value.target.value,
                          });
                        }}
                      />
                      <Button type="primary" onClick={() => this._handelSave()}>
                        save changes
                      </Button>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/ordered-campaigns`}>
                    <Button type="link">Ordered Campaigns</Button>
                  </Link>
                }
                key="ordered-campaigns"
              >
                <Tabs
                  activeKey={this.getActiveTabForOrderedCampaigns()}
                  centered
                  type="line"
                  defaultActiveKey="ordered-campaigns/pending-by-admin"
                >
                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/pending-payment`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'PENDING_PAYMENT',
                          });
                        }}
                      >
                        <Button>Pending Payment</Button>
                      </Link>
                    }
                    key="ordered-campaigns/pending-payment"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'PENDING_PAYMENT' && (
                      <OrderedCampaigns
                        key={'pending-payment'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/checking-status`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'CHECKING_STATUS',
                          });
                        }}
                      >
                        <Button>Checking Status</Button>
                      </Link>
                    }
                    key="ordered-campaigns/checking-status"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'CHECKING_STATUS' && (
                      <OrderedCampaigns
                        key={'checking-status'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/pending-payment-review`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus:
                              'PENDING_PAYMENT_REVIEW',
                          });
                        }}
                      >
                        <Button>Pending Payment Review</Button>
                      </Link>
                    }
                    key="ordered-campaigns/pending-payment-review"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'PENDING_PAYMENT_REVIEW' && (
                      <OrderedCampaigns
                        key={'pending-payment-review'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/pending-from-provider`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus:
                              'PENDING_FROM_PROVIDER',
                          });
                        }}
                      >
                        <Button>Pending From Provider</Button>
                      </Link>
                    }
                    key="ordered-campaigns/pending-from-provider"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'PENDING_FROM_PROVIDER' && (
                      <OrderedCampaigns
                        key={'pending-from-provider'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/rejected-payment`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'REJECTED_PAYMENT',
                          });
                        }}
                      >
                        <Button>Rejected Payment</Button>
                      </Link>
                    }
                    key="ordered-campaigns/rejected-payment"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'REJECTED_PAYMENT' && (
                      <OrderedCampaigns
                        key={'rejected-payment'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/abandoned`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'ABANDONED',
                          });
                        }}
                      >
                        <Button>Abandoned</Button>
                      </Link>
                    }
                    key="ordered-campaigns/abandoned"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'ABANDONED' && (
                      <OrderedCampaigns
                        key={'abandoned'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>

                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/pending-by-admin`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'PENDING_BY_ADMIN',
                          });
                        }}
                      >
                        <Button type="default">Pending By Admin</Button>
                      </Link>
                    }
                    key="ordered-campaigns/pending-by-admin"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'PENDING_BY_ADMIN' && (
                      <OrderedCampaigns
                        key={'pending_by_Admin'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/pending-by-client`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'PENDING_BY_CLIENT',
                          });
                        }}
                      >
                        <Button>Pending By Client</Button>
                      </Link>
                    }
                    key="ordered-campaigns/pending-by-client"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'PENDING_BY_CLIENT' && (
                      <OrderedCampaigns
                        key={'pending_by_client'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/active`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'ACTIVE',
                          });
                        }}
                      >
                        <Button>Active</Button>
                      </Link>
                    }
                    key="ordered-campaigns/active"
                  >
                    {this.state.selectedOrderedCampaignsStatus === 'ACTIVE' && (
                      <OrderedCampaigns
                        key={'active'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>
                  <TabPane
                    tab={
                      <Link
                        to={`/listing/edit/${this.state.id}/ordered-campaigns/completed`}
                        onClick={() => {
                          this.setState({
                            selectedOrderedCampaignsStatus: 'COMPLETED',
                          });
                        }}
                      >
                        <Button>Completed</Button>
                      </Link>
                    }
                    key="ordered-campaigns/completed"
                  >
                    {this.state.selectedOrderedCampaignsStatus ===
                      'COMPLETED' && (
                      <OrderedCampaigns
                        key={'completed'}
                        id={this.state.id}
                        status={this.state.selectedOrderedCampaignsStatus}
                        {...this.props}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </TabPane>

              <TabPane
                tab={
                  <Link to={`/listing/edit/${this.state.id}/promote`}>
                    <Button type="link">Promote</Button>
                  </Link>
                }
                key="promote"
              >
                <ManualPromotion
                  campaignType={this.state.isHousing ? 'PRIME' : 'FEATURED'}
                  promotedItemType={
                    this.state.isHousing ? 'HOUSING' : 'REAL_ESTATE'
                  }
                  listingId={this.state.id}
                  {...this.props}
                />
              </TabPane>
            </Tabs>
          </React.Fragment>
        )}
      </MainLayout>
    );
  }
}

export default withRouter(EditListing);
