import React, { useEffect, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Table, Button, Row, Popconfirm, message } from 'antd';
import * as moment from 'moment';
import {
  UserOutlined,
  PlaySquareOutlined,
  PauseCircleTwoTone,
} from '@ant-design/icons';
import { Admin } from '../../queries';

const CAMPAIGN_TYPES = {
  PRIME: 'Prime',
  FEATURED: 'Featured',
  ON_FACEBOOK: 'On Facebook',
};

const arabicRegex = /[\u0600-\u06FF]/;

export default function OrderedCampaigns(props) {
  const { token, id, status, allCookies, isCommercialAd } = props;
  const [isLoading, setLoading] = useState(false);
  const [orderedCampaigns, setOrderedCampaigns] = useState([]);
  const [visibleConfirm, setConfirmVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const getOrderedCampaigns = async ({ id, status }) => {
    try {
      setLoading(true);
      const orderedCampaignsResponse = await API.graphql(
        graphqlOperation(Admin.OrderedCampaign.GET_ORDERED_CAMPAIGNS, {
          where: {
            ...(isCommercialAd
              ? {
                  commercialAd: {
                    is: {
                      id: {
                        equals: id,
                      },
                    },
                  },
                }
              : {
                  listing: {
                    is: {
                      id: {
                        equals: id,
                      },
                    },
                  },
                }),
            status: { equals: status },
          },
        })
      );
      setOrderedCampaigns(orderedCampaignsResponse.data.orderedCampaigns);
    } catch (error) {
      console.log(
        '🚀 ~ file: ordered-campaigns.js ~ line 45 ~ getOrderedCampaigns ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };

  const activateOrderedCampaign = async ({ id }) => {
    try {
      setLoading(true);
      await API.graphql(
        graphqlOperation(Admin.OrderedCampaign.ACTIVATE_ORDERED_CAMPAIGN, {
          orderedCampaignId: id,
          userId: allCookies['user']['id'],
        })
      );
      await getOrderedCampaigns({ id, status });
    } catch (error) {
      console.log(
        '🚀 ~ file: ordered-campaigns.js ~ line 67 ~ getOrderedCampaigns ~ error',
        error
      );
      const errorMessage = error.errors.map((error) => error.message + '\n');
      if (errorMessage && arabicRegex.test(errorMessage)) {
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  const pauseOrderedCampaign = async ({ id }) => {
    try {
      setLoading(true);
      await API.graphql(
        graphqlOperation(Admin.OrderedCampaign.PAUSE_ORDERED_CAMPAIGN, {
          orderedCampaignId: id,
          userId: allCookies['user']['id'],
        })
      );
      await getOrderedCampaigns({ id, status });
    } catch (error) {
      console.log(
        '🚀 ~ file: ordered-campaigns.js ~ line 86 ~ pauseOrderedCampaign ~ error',
        error
      );
      const errorMessage = error.errors.map((error) => error.message + '\n');
      if (errorMessage && arabicRegex.test(errorMessage)) {
        message.error(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });
    getOrderedCampaigns({ id, status });
  }, []);

  return (
    <Table
      columns={[
        // 1st column Campaign Type
        {
          title: 'Campaign Type',
          dataIndex: ['campaign', 'type'],
          key: 'campaignType',
          render: (type) => CAMPAIGN_TYPES[type],
          sorter: (a, b) => a.campaign.type.length - b.campaign.type.length,
          filters: [
            {
              text: 'Featured',
              value: 'FEATURED',
            },
            {
              text: 'Prime',
              value: 'PRIME',
            },
            {
              text: 'On Facebook',
              value: 'ON_FACEBOOK',
            },
          ],
          filterMultiple: false,
          onFilter: (value, row) => row.campaign.type.indexOf(value) === 0,
        },
        // {
        //   title: 'Daily Views',
        //   dataIndex: 'dailyViews',
        //   key: 'dailyViews',
        //   sorter: (a, b) => a.dailyViews - b.dailyViews,
        // },
        // {
        //   title: 'Paused At',
        //   dataIndex: 'pausedAt',
        //   key: 'pausedAt',
        //   render: (pausedAt) => (pausedAt ? moment(pausedAt).format('L') : ''),
        //   sorter: (a, b) => {
        //     if (!a.pausedAt || !b.pausedAt) return false;
        //     const diff = moment(a.pausedAt).diff(moment(b.pausedAt), 'seconds');
        //     return diff;
        //   },
        // },
        // 2nd column First Started At
        {
          title: 'First Started At',
          dataIndex: 'firstStartedAt',
          key: 'firstStartedAt',
          render: (firstStartedAt) =>
            firstStartedAt ? moment(firstStartedAt).format('L') : '',
          sorter: (a, b) => {
            if (!a.firstStartedAt || !b.firstStartedAt) return false;
            const diff = moment(a.firstStartedAt).diff(
              moment(b.firstStartedAt),
              'seconds'
            );
            return diff;
          },
        },
        {
          title: 'Remaining Active Days',
          dataIndex: 'remainingActiveDays',
          key: 'remainingActiveDays',
          render: (remainingActiveDays) => `${remainingActiveDays} days`,
          sorter: (a, b) => a.dailyViews - b.dailyViews,
        },
        {
          title: 'Pro Photography',
          dataIndex: 'withProPhotography',
          key: 'withProPhotography',
          render: (withProPhotography, row) =>
            withProPhotography && !row.isAdditional
              ? 'Yes'
              : !withProPhotography && !row.isAdditional
              ? 'No'
              : '',
          sorter: (a, b) => a.withProPhotography - b.withProPhotography,
        },
        {
          title: 'User',
          dataIndex: 'user',
          key: 'view-user',
          render: (user) => (
            <Button
              type="primary"
              href={`${process.env.REACT_APP_DASHBOARD_URL}/user/profile/${user.id}`}
              target="_blank"
              icon={<UserOutlined />}
            >
              View User
            </Button>
          ),
        },
        {
          title: 'Created at',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (createdAt) => moment(createdAt).format('L'),
          sorter: (a, b) => {
            if (!a.createdAt || !b.createdAt) return false;
            const diff = moment(a.createdAt).diff(
              moment(b.createdAt),
              'seconds'
            );
            return diff;
          },
        },
        {
          title: 'Updated at',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (updatedAt) => moment(updatedAt).format('L'),
          sorter: (a, b) => {
            if (!a.updatedAt || !b.updatedAt) return false;
            const diff = moment(a.updatedAt).diff(
              moment(b.updatedAt),
              'seconds'
            );
            return diff;
          },
        },
        {
          title: 'Actions',
          key: 'actions',
          render: (value, row) => {
            const hasPending = row.additionalOrderedCampaigns.some((campaign) =>
              ['PENDING_BY_ADMIN', 'PENDING_BY_CLIENT'].includes(
                campaign.status
              )
            );
            return (
              <Row
                style={{
                  justifyContent: 'center',
                }}
              >
                {row.status === 'ACTIVE' ? (
                  <Button
                    type="primary"
                    danger
                    shape="circle"
                    icon={<PauseCircleTwoTone twoToneColor="#eb2f96" />}
                    onClick={async () => {
                      await pauseOrderedCampaign({ id: row.id });
                    }}
                  />
                ) : [
                    'PENDING_BY_ADMIN',
                    'PENDING_BY_CLIENT',
                    'PENDING_FROM_PROVIDER',
                  ].includes(row.status) ? (
                  <Popconfirm
                    title="Please make sure to review the additional ordered campaigns before activating this campaign!"
                    visibleConfirm={visibleConfirm}
                    onConfirm={async () => {
                      setConfirmLoading(true);
                      await activateOrderedCampaign({ id: row.id });
                      setConfirmLoading(false);
                    }}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => {
                      setConfirmVisible(false);
                    }}
                    okText="Activate"
                    okType="danger"
                    cancelText="Cancel"
                    disabled={
                      !hasPending && row.additionalOrderedCampaigns.length === 0
                    }
                    placement="rightTop"
                  >
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PlaySquareOutlined />}
                      onClick={async () => {
                        if (
                          row.additionalOrderedCampaigns.length > 0 &&
                          hasPending
                        ) {
                          setConfirmVisible(true);
                        } else {
                          await activateOrderedCampaign({ id: row.id });
                        }
                      }}
                    />
                  </Popconfirm>
                ) : (
                  'No Actions'
                )}
              </Row>
            );
          },
        },
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        },
      ]}
      rowKey="id"
      loading={isLoading}
      pagination={{
        position: ['topLeft', 'bottomLeft'],
        defaultPageSize: 20,
        defaultCurrent: 1,
      }}
      dataSource={orderedCampaigns}
      bordered
      scroll={{ x: true }}
    />
  );
}
