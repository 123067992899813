import React from 'react';
export default function WaterHeater({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M2.232 0C1.209 0 .372.832.372 1.848v8.807c-.007.04-.007.08 0 .12v2.16c0 1.424 1.17 2.587 2.603 2.587v1.108c0 .205.167.37.372.37h1.488c.205 0 .372-.165.372-.37v-1.108h2.231v1.108c0 .205.167.37.372.37h1.488c.205 0 .372-.165.372-.37v-1.108c1.433 0 2.603-1.163 2.603-2.587v-2.156c.007-.04.007-.08 0-.12V1.848C12.273.832 11.436 0 10.413 0H2.233zm0 .74h8.182c.62 0 1.115.491 1.115 1.108v8.5H1.116v-8.5c0-.617.495-1.109 1.116-1.109zm.743 1.477c-.205 0-.372.166-.372.37V8.5c0 .204.167.37.372.37H9.67c.205 0 .372-.166.372-.37V2.587c0-.204-.167-.37-.372-.37H2.975zm.372.74h5.951V8.13h-5.95V2.957zm3.486.739s-1.998.904-1.998 2.45c0 .964 1.194 1.245 1.194 1.245s-.39-.522-.39-1.245c0-.883.803-1.406.803-1.406-.26 1.044.195 1.406.195 1.406s-.043-.322.152-.522c.13.462.456.663.456 1.084 0 .302-.347.683-.347.683.543-.18.933-.702.912-1.245 0-.944-.456-1.165-.391-1.708-.152.04-.282.181-.391.262-.195-.241-.26-.603-.195-1.004zm-5.717 7.391h10.413v1.848c0 1.025-.828 1.848-1.86 1.848H2.976c-1.031 0-1.86-.823-1.86-1.848v-1.848zm2.417.37c-.714 0-1.301.583-1.301 1.293s.587 1.293 1.301 1.293c.715 0 1.302-.583 1.302-1.293s-.587-1.293-1.302-1.293zm5.579 0c-.714 0-1.302.583-1.302 1.293s.588 1.293 1.302 1.293c.715 0 1.302-.583 1.302-1.293s-.587-1.293-1.302-1.293zm-5.579.739c.313 0 .558.243.558.554 0 .31-.245.554-.558.554-.312 0-.558-.243-.558-.554 0-.31.246-.554.558-.554zm5.579 0c.313 0 .558.243.558.554 0 .31-.245.554-.558.554-.312 0-.558-.243-.558-.554 0-.31.246-.554.558-.554zm-5.393 3.326h.744v.739h-.744v-.74zm4.463 0h.744v.739h-.744v-.74z"
                    transform="translate(-313 -1340) translate(0 1078) translate(183 252) translate(130.828 10) translate(1.86)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
