import {
  DATAGRID_CURRENT_EDITED,
  DATAGRID_FILTER,
  DATAGRID_SEARCH,
  DATAGRID_PAGINATION,
  DATAGRID_SORT,
  DATAGRID_RESET,
  DATAGRID_SCROLL,
} from './actions';

const initialState = {
  filteredInfo: null,
  sortedInfo: {
    columnKey: 'createdAt',
    field: 'createdAt',
    order: 'DESC',
  },
  pagination: {
    total: 0,
    current: 1,
    pageSize: 50,
    position: ['topLeft', 'bottomLeft'],
  },
  loading: false,
  searchText: '',
  currentEdited: 0,
  scrollPosition: 0,
};

const ListingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATAGRID_CURRENT_EDITED: {
      return {
        ...state,
        currentEdited: action.payload,
      };
    }
    case DATAGRID_FILTER: {
      return {
        ...state,
        filteredInfo: action.payload,
      };
    }
    case DATAGRID_SEARCH: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case DATAGRID_PAGINATION: {
      return {
        ...state,
        pagination: action.payload,
      };
    }
    case DATAGRID_SORT: {
      return {
        ...state,
        sortedInfo: action.payload,
      };
    }
    case DATAGRID_RESET: {
      return {
        ...initialState,
      };
    }
    case DATAGRID_SCROLL: {
      return {
        ...state,
        scrollPosition: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ListingsReducer;
