import React from 'react';

export default function State({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 22 22"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M10.983.025c-.096.004-.189.037-.265.097L.176 8.42c-.2.157-.235.448-.08.65.156.201.445.237.644.08l.635-.5v12.157c0 .255.205.462.458.462h6.34c.05.009.1.009.15 0h5.35c.05.009.1.009.15 0h6.344c.253 0 .458-.207.458-.462V8.651l.635.499c.129.102.302.126.454.064.152-.062.26-.201.282-.365.023-.164-.043-.327-.172-.429l-3.49-2.747V2.312h-2.75v1.196L11.281.122c-.085-.067-.191-.101-.299-.097zM11 1.074l8.708 6.855v12.416h-5.5v-8.786H7.792v8.786h-5.5V7.929L11 1.074zm5.5 2.163h.917v1.715l-.917-.723v-.992zm-7.792 9.247h4.584v7.86H8.708v-7.86z"
                transform="translate(-317 -247) translate(0 237.073) translate(317 10.866)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
