export const DATAGRID_SEARCH = 'DATAGRID_SEARCH';
export const dataGridSearch = (text) => {
  return {
    type: DATAGRID_SEARCH,
    payload: text,
  };
};

export const DATAGRID_FILTER = 'DATAGRID_FILTER';
export const dataGridFilter = (filters) => {
  return {
    type: DATAGRID_FILTER,
    payload: { ...filters },
  };
};

export const DATAGRID_PAGINATION = 'DATAGRID_PAGINATION';
export const dataGridPagination = (pagination) => {
  return {
    type: DATAGRID_PAGINATION,
    payload: { ...pagination },
  };
};

export const DATAGRID_CURRENT_EDITED = 'DATAGRID_CURRENT_EDITED';
export const dataGridCurrentEdited = (currentEdited) => {
  return {
    type: DATAGRID_CURRENT_EDITED,
    payload: currentEdited,
  };
};

export const DATAGRID_SORT = 'DATAGRID_SORT';
export const dataGridSort = (sortBy) => {
  return {
    type: DATAGRID_SORT,
    payload: { ...sortBy },
  };
};

export const DATAGRID_RESET = 'DATAGRID_RESET';
export const dataGridReset = () => {
  return {
    type: DATAGRID_RESET,
    payload: null,
  };
};

export const DATAGRID_SCROLL = 'DATAGRID_SCROLL';
export const dataGridScrollPosition = (scrollPosition) => {
  return {
    type: DATAGRID_SCROLL,
    payload: scrollPosition,
  };
};
