import React from 'react';

import { MainLayout } from '../../components/Layout';
import { Tabs } from 'antd';
import Icon from '@ant-design/icons';
import ListingsChart from './listingsChart';
import UserChart from './userChart';

const { TabPane } = Tabs;

class HomePageView extends React.Component {
  render() {
    const { token } = this.props;

    return (
      <MainLayout title="Home Page">
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <Icon type="fire" />
                listings data
              </span>
            }
            key="1"
          >
            <ListingsChart token={token} />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="user" />
                users data
              </span>
            }
            key="2"
          >
            <UserChart token={token} />
          </TabPane>
        </Tabs>
        ,
      </MainLayout>
    );
  }
}

export default HomePageView;
