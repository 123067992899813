import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { Table } from 'antd';
import { withRouter } from 'react-router-dom';
import { MainLayout } from '../../components/Layout';
import {
  dataGridFilter,
  dataGridPagination,
  dataGridSearch,
  dataGridSort,
  dataGridCurrentEdited,
  dataGridReset,
  dataGridScrollPosition,
} from './actions';
import { Admin } from '../../queries';
import ListingDetails from './get-listing-details';
import { columns } from './columns';
import TableHeader from './table-header-buttons';
const ViewListings = (props) => {
  const {
    dataGridFilter,
    dataGridSort,
    dataGridPagination,
    history,
    dataGridCurrentEdited,
    listingsDataGrid,
  } = props;

  const [listings, setListings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [where, setWhere] = useState({
    status: {
      in: [
        'PENDING',
        'ACTIVE',
        'STOPPED',
        'REJECTED',
        'REJECTEDUPDATED',
        'ACTIVEUPDATED',
      ],
    },
  });
  const [count, setCount] = useState(null);
  const [reset, setReset] = useState(false);
  const [isInsightsModalOpen, setIsInsightsModalOpen] = useState(false);
  const [selectedListingForInsights, setSelectedListingForInsights] =
    useState(null);
  const [filterState, setFilterState] = useState(false);

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    dataGridSearch(selectedKeys[0]);
  };

  const handleReset = (clearFilters, dataIndex) => {
    const { sortedInfo, filteredInfo, pagination } = listingsDataGrid;
    if (filteredInfo) {
      delete filteredInfo[dataIndex];
    }
    dataGridFilter(filteredInfo);
    dataGridSearch('');
    clearFilters();
    handleChange(pagination, filteredInfo, sortedInfo);
  };

  const resetSorter = () => {
    const { sortedInfo, filteredInfo, pagination } = listingsDataGrid;
    sortedInfo.column = undefined;
    sortedInfo.order = undefined;
    sortedInfo.field = undefined;
    sortedInfo.columnKey = undefined;
    handleChange(pagination, filteredInfo, sortedInfo);
  };

  const handleChange = (pagination, filters, sorter) => {
    dataGridFilter(filters);
    dataGridSort(sorter);
    dataGridPagination(pagination);
  };
  const clearAll = () => {
    setWhere({
      status: {
        in: [
          'PENDING',
          'ACTIVE',
          'STOPPED',
          'REJECTED',
          'REJECTEDUPDATED',
          'ACTIVEUPDATED',
        ],
      },
    });
    resetSorter();
    dataGridSearch('');
    dataGridFilter(null);
    setReset(true);
  };
  let { sortedInfo, filteredInfo, pagination } = listingsDataGrid;
  if (filteredInfo)
    Object.keys(filteredInfo).forEach((key) => {
      if (filteredInfo[key] == null || filteredInfo[key].length <= 0) {
        delete filteredInfo[key];
      }
    });

  const whereArr = filteredInfo
    ? Object.keys(filteredInfo)
        .map((key) => {
          let obj = {};

          let op = 'in',
            values = filteredInfo[key];

          if (['title', 'no'].indexOf(key) > -1) {
            op = 'contains';
            values = filteredInfo[key][0].replace(/^\s+/g, '');
            if (values) {
              obj[`${key}`] = { [`${op}`]: values };
            }
          } else if (key === 'isHousing') {
            obj[`${key}`] = { equals: values[0] };
          } else if (key === 'area') {
            values = filteredInfo[key][0].replace(/^\s+/g, '');
            if (values) {
              obj[`location`] = {
                is: {
                  listedArea: {
                    is: { name: { contains: values } },
                  },
                },
              };
            }
          } else if (['name', 'mobile'].indexOf(key) > -1) {
            op = 'contains';
            values = filteredInfo[key][0].replace(/^\s+/g, '');
            if (values) {
              obj['author'] = {
                is: { [`${key}`]: { [`${op}`]: values } },
              };
            }
          } else if (key === 'isOwner') {
            obj[`${key}`] = { equals: values[0] };
          } else {
            values = values
              ? key === 'activeCampaignType'
                ? values
                : typeof values === 'string'
                ? values.replace(/^\s+/g, '')
                : [values[0].replace(/^\s+/g, '')]
              : null;
            if (values) {
              obj[`${key}`] = { [`${op}`]: values };
            }
          }

          return obj;
        })
        .filter((item) => item != null)
    : null;

  let whereAssignment = where;

  if (whereArr && whereArr.length > 0) {
    whereArr.forEach((value) => {
      whereAssignment = { ...whereAssignment, ...value };
    });
  }
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};
  const whereSetter = (value) => {
    if (
      JSON.stringify(value.where) !== JSON.stringify({}) &&
      JSON.stringify(where) !== JSON.stringify(value.where)
    ) {
      setWhere(value.where);
    }
    return value;
  };

  const getListings = async () => {
    const variables = {
      orderByListing: {
        [`${sortedInfo.field || 'createdAt'}`]:
          sortedInfo.order === 'ascend' ? 'asc' : 'desc',
      },
      take: pagination.pageSize,
      skip: pagination.pageSize * (pagination.current - 1),
      whereListing: whereAssignment,
    };
    try {
      setLoading(true);
      const listingsResponse = await API.graphql(
        graphqlOperation(Admin.Listing.GET_ALL_LISTINGS, variables)
      );
      const listings = listingsResponse.data.listings.map((listingItem) => {
        const { listing } = listingItem;
        return {
          ...listing,
          key: listing.id,
          price: {
            price: `${listing.price ? listing.price : 'Not Given'} ${
              listing.type === 'RENT'
                ? listing.category === 'FARM'
                  ? '/d'
                  : '/m'
                : ''
            }`,
            yearly: `${
              listing.rentPriceYear ? listing.rentPriceYear : 'Not Given'
            }/y`,
            daily: `${
              listing.rentPriceDay ? listing.rentPriceDay : 'Not Given'
            }/d`,
          },
        };
      });

      setListings(listings);
      setCount(listingsResponse.data.listingsConnection._count.id);
    } catch (error) {
      console.log(
        '🚀 ~ file: view-listings-table.js ~ line 147 ~ getListings ~ error',
        error
      );
    } finally {
      setLoading(false);
    }
  };
  const getListingsCount = async () => {
    try {
      const listingsCountResponse = await API.graphql(
        graphqlOperation(Admin.Listing.GET_LISTINGS_COUNT, {
          where: whereAssignment,
        })
      );

      setCount(listingsCountResponse.data.listingsConnection._count.id);
    } catch (error) {
      console.log(
        '🚀 ~ file: view-listings-table ~ line 184 ~ getListingsCount ~ error',
        error
      );
    }
  };
  useEffect(() => {
    const { token } = props;
    Amplify.configure({
      API: {
        graphql_headers: async () => ({
          Authorization: `Bearer ${token}`,
        }),
      },
    });

    if (reset) {
      getListingsCount();
      setReset(false);
      setFilterState(false);
    } else if (filterState) {
      getListingsCount();
    }

    pagination.total = count;
    getListings();
  }, [where, count, pagination, filterState]);
  const datGridFunction = (status) => {
    dataGridFilter({
      ...filteredInfo,
      status: status,
    });
  };
  const paginationCallback = (QueryType) => {
    if (QueryType !== where.status.in[0]) {
      pagination.current = 1;
    }
  };
  const changeAListing = (item, index) => {
    const currentListings = [...listings];
    item.price = {
      price: `${item.price ? item.price : 'Not Given'} ${
        item.type === 'RENT' ? '/m' : ''
      }`,
      yearly: `${item.rentPriceYear ? item.rentPriceYear : 'Not Given'}/y`,
      daily: `${item.rentPriceDay ? item.rentPriceDay : 'Not Given'}/d`,
    };
    currentListings[index] = item;

    setListings(currentListings);
  };
  return (
    <MainLayout>
      <TableHeader
        paginationCallback={paginationCallback}
        clearAll={clearAll}
        where={where}
        whereSetter={whereSetter}
        setCount={setCount}
        datGridFunction={datGridFunction}
      />
      <Table
        columns={columns({
          props,
          history,
          dataGridCurrentEdited,
          filteredInfo,
          sortedInfo,
          isInsightsModalOpen,
          selectedListingForInsights,
          setIsInsightsModalOpen,
          setSelectedListingForInsights,
          handleSearch,
          handleReset,
          listingsDataGrid,
        })}
        dataSource={listings}
        loading={loading}
        onChange={handleChange}
        pagination={{ ...pagination }}
        expandedRowRender={(record, index) => (
          <ListingDetails
            changeAListing={changeAListing}
            record={record}
            index={index}
            token={props.token}
            props={props}
          />
        )}
        bordered
        scroll={{ x: true }}
        rowKey={(row) => row.id}
        rowClassName={(record, index) =>
          Number(record.no) === Number(listingsDataGrid.currentEdited)
            ? 'scroll-row'
            : ''
        }
      />
    </MainLayout>
  );
};
const mapStateToProps = ({ listings }) => {
  return { listingsDataGrid: listings };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      dataGridCurrentEdited,
      dataGridFilter,
      dataGridPagination,
      dataGridSearch,
      dataGridSort,
      dataGridReset,
      dataGridScrollPosition,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewListings));
